import React from "react";
import { UserOutlined } from "@ant-design/icons";
import { Avatar } from "antd";

type SalesRepType =
  | "salesRepresentative"
  | "salesRepresentative2"
  | "salesBusinessDeveloper";

export default function SalesRepIcon({
  type,
  label,
}: {
  type: SalesRepType;
  label: string;
}) {
  return (
    <div style={{ display: "flex", alignItems: "center", marginBottom: "4px" }}>
      <Avatar
        shape="circle"
        size="small"
        style={{
          display: "inline-block",
          //purple shade for salesBusinessDeveloper and blue shade for salesRepresentative,
          backgroundColor:
            type === "salesBusinessDeveloper"
              ? "rgb(26, 162, 26,0.4)"
              : "rgb(26, 26, 162,0.4)",
          marginRight: "8px",
        }}
        icon={<UserOutlined />}
      ></Avatar>
      <span style={{ display: "inline-block" }}>{label}</span>
    </div>
  );
}
