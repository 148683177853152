import React, { useState } from "react";
import { IScheduledPush } from "../../../../../../interfaces/scheduledPush.interface";
import {
  Button,
  Card,
  Descriptions,
  Divider,
  Form,
  Popconfirm,
  Tag,
} from "antd";
import { useAppDispatch } from "../../../../../../app/hooks";
import { scheduleAPushThunk } from "../../../../notificationsSlice";
import { ClockCircleOutlined, CalendarOutlined } from "@ant-design/icons";
import {
  formatDate,
  formatLocalDate,
} from "../../../../../../utils/formatDate";
import {
  ROLE_OPTIONS_MAP,
  STATUS_OPTIONS_MAP,
} from "../Target-Attributes-Form/TargetAttributesForm";
import TargetAttributeTagsPreview from "../../../Target-Attribute-Tags-Preview/TargetAttributeTagsPreview";
interface IPropsNotificationStartForm {
  scheduledPush: IScheduledPush;
  onSubmit: () => void;
}

export default function PreviewSendNotificationForm({
  scheduledPush,
  onSubmit,
}: IPropsNotificationStartForm) {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const handleSubmit = async () => {
    setLoading(true);

    await dispatch(scheduleAPushThunk(scheduledPush));
    setLoading(false);
    onSubmit();
  };

  return (
    <div>
      <h2 style={{ marginBottom: "30px", opacity: 0.8 }}>Preview & Schedule</h2>
      {/* {JSON.stringify(scheduledPush)} */}
      <div style={{ marginBottom: "50px" }}>
        <Descriptions
          title={"Details"}
          labelStyle={{ opacity: 0.6, width: "130px" }}
          column={1}
        >
          <Descriptions.Item label={"Type"}>
            {scheduledPush.notification.type}
          </Descriptions.Item>
          <Descriptions.Item label={"Scheduled To"}>
            <CalendarOutlined style={{ marginRight: "4px" }} />{" "}
            {formatLocalDate(new Date(scheduledPush.timestamp))}
          </Descriptions.Item>
          <Descriptions.Item
            label={"Target"}
            contentStyle={{ display: "flex", width: "200px", flexWrap: "wrap" }}
          >
            <TargetAttributeTagsPreview scheduledPush={scheduledPush} />
          </Descriptions.Item>
        </Descriptions>
        <Descriptions
          title={"Parameters"}
          labelStyle={{ opacity: 0.6, width: "130px" }}
          column={1}
        >
          {scheduledPush.notification?.type === "EMAIL" &&
            scheduledPush.notification?.templateParams && (
              <>
                {Object.entries(
                  scheduledPush.notification?.templateParams || {}
                ).map(([key, value]) => (
                  <Descriptions.Item label={key}>{value}</Descriptions.Item>
                ))}
              </>
            )}
          {scheduledPush.notification?.type === "EMAIL" &&
            !scheduledPush.notification?.templateParams && (
              <Descriptions.Item>
                No parameters for this template.
              </Descriptions.Item>
            )}

          {scheduledPush.notification?.type !== "EMAIL" &&
            scheduledPush.notification?.title && (
              <>
                <Descriptions.Item label={"Title"}>
                  {scheduledPush.notification?.title}
                </Descriptions.Item>
                <Descriptions.Item label={"Description"}>
                  {scheduledPush.notification?.description}
                </Descriptions.Item>
              </>
            )}
        </Descriptions>
      </div>
      <Form.Item style={{ padding: 0, margin: 0 }}>
        <Popconfirm
          placement="topRight"
          title={`Are you sure you want to push this notification?`}
          onConfirm={handleSubmit}
          okText="Yes"
          cancelText="No"
          key={"3"}
        >
          <Button
            type="primary"
            key="1"
            disabled={loading}
            style={{ float: "right" }}
          >
            Schedule
          </Button>
        </Popconfirm>
      </Form.Item>
    </div>
  );
}
