import { Form, Input } from "antd";

export default function CompanyAddressEditor() {
  const bankFieldLayout = {
    labelCol: { span: 12 },
    wrapperCol: { span: 12 },
  };
  return (
    <>
      <Form.Item
        label="Street"
        name={["address", "street"]}
        wrapperCol={{ ...bankFieldLayout.wrapperCol }}
        labelCol={{ ...bankFieldLayout.labelCol }}
        labelAlign="left"
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="House Number"
        name={["address", "houseNumber"]}
        wrapperCol={{ ...bankFieldLayout.wrapperCol }}
        labelCol={{ ...bankFieldLayout.labelCol }}
        labelAlign="left"
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="House Number Suffix"
        name={["address", "houseNumberSuffix"]}
        wrapperCol={{ ...bankFieldLayout.wrapperCol }}
        labelCol={{ ...bankFieldLayout.labelCol }}
        labelAlign="left"
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="City"
        name={["address", "city"]}
        wrapperCol={{ ...bankFieldLayout.wrapperCol }}
        labelCol={{ ...bankFieldLayout.labelCol }}
        labelAlign="left"
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Postcode"
        name={["address", "postcode"]}
        wrapperCol={{ ...bankFieldLayout.wrapperCol }}
        labelCol={{ ...bankFieldLayout.labelCol }}
        labelAlign="left"
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Country"
        name={["address", "country"]}
        wrapperCol={{ ...bankFieldLayout.wrapperCol }}
        labelCol={{ ...bankFieldLayout.labelCol }}
        labelAlign="left"
      >
        <Input />
      </Form.Item>
    </>
  );
}
