import { Button, Input, Form, Radio, DatePicker, Select, Modal } from "antd";
import { ChangeEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import styles from "./NotificationForm.module.css";
import {
  selectCurrentGroup,
  selectCurrentNotification,
  selectCurrentTargetAttributes,
  setCurrentGroup,
  setCurrentScheduledPush,
  setCurrentTargetAttributes,
} from "../../notificationsSlice";

import {
  NotificationType,
  NotificationGroup,
} from "../../../../interfaces/notification.interface";
import NotificationSendModal from "../Notification-Send-Modal/NotificationSendModal";
import { RangePickerProps } from "antd/lib/date-picker";
import moment from "moment";
import { IScheduledPush } from "../../../../interfaces/scheduledPush.interface";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { convertToDutchTimeZone } from "../../../../utils/convertTimezone";
import NotificationStartForm from "./components/Notification-Start-Form/NotificationStartForm";
import TargetAttributesForm from "./components/Target-Attributes-Form/TargetAttributesForm";
import PreviewSendNotificationForm from "./components/Preview-Send-Notification-Form/PreviewSendNotificationForm";
import { LocalDateAsUTC } from "../../../../utils/LocalDateAsUTC";

const range = (start: number, end: number) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};

const NotificationFieldLayout = {
  labelCol: { span: 12 },
  wrapperCol: { span: 12 },
};

interface IPropsNotificationForm {
  visible: boolean;
  setVisible: (boolean: boolean) => void;
}

const DEFAULT_SCHEDULED_PUSH: IScheduledPush = {
  _id: "",
  status: "pending",
  targetAttributes: {
    active: true,
    deviceType: ["Android", "IOS", "Pax", "Sunmi"],
    role: ["ADMIN", "USER", "ROOT"],
    status: ["verified", "pendingSubmission", "unverified"],
    lastActive: new LocalDateAsUTC().subtractDays(30).ISO,
    version: [],
    isTest: false,
  },
  targetCount: 0,
  executionArn: "",
  stateMachineInstanceName: "",
  timestamp: "",
  notification: {
    type: "INAPP",
    description: "",
    title: "",
    analyticsIdentifier: "",
    expiration: "",
  },
};

export default function NotificationForm({
  visible,
  setVisible,
}: IPropsNotificationForm) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const randomUUID = uuidv4();
  const navigate = useNavigate();
  const [scheduledPush, setScheduledPush] = useState<IScheduledPush>(
    DEFAULT_SCHEDULED_PUSH
  );

  const [stage, setStage] = useState(1);

  const NOTIFICATION_TYPES_CONFIG: {
    label: string;
    value: NotificationType;
  }[] = [
    { label: "Push", value: "NOTIFICATION" },
    { label: "In-App", value: "INAPP" },
    { label: "Email", value: "EMAIL" },
  ];

  const dispatch = useAppDispatch();
  const [isSendModalShown, setIsSendModalShown] = useState(false);
  // const handleSubmit = async (
  //   values: IScheduledPush & { timestamp: moment.Moment } & {
  //     notification: { expiration?: any };
  //   }
  // ) => {
  //   values.notification.analyticsIdentifier = randomUUID;
  //   if (values.notification.type === "INAPP" && values.notification.expiration)
  //     values.notification.expiration =
  //       values.notification.expiration?.toISOString();
  //   let scheduledPush = {
  //     ...values,
  //     timestamp: values.timestamp.toISOString(),
  //   };

  //   console.log("Push scheduled:", scheduledPush);

  //   dispatch(setCurrentScheduledPush(scheduledPush));
  //   dispatch(setCurrentTargetAttributes(values.targetAttributes));
  //   setIsSendModalShown(true);
  // };

  const disabledDate: RangePickerProps["disabledDate"] = (selected) => {
    // Can not select days before today
    let selectedDutchDate = convertToDutchTimeZone(selected.toISOString());
    let currentDutchDate = convertToDutchTimeZone(new Date());
    return (
      selectedDutchDate &&
      selectedDutchDate.getDate() < currentDutchDate.getDate()
    );
  };
  const disabledTime: RangePickerProps["disabledTime"] = (_, type) => {
    return {};
  };

  const handleSubmitStartForm = (scheduledPush: IScheduledPush) => {
    setScheduledPush((prev) => ({ ...prev, ...scheduledPush }));
    setStage(2);
  };
  const handleSubmitTargetAttributesForm = (scheduledPush: IScheduledPush) => {
    console.log("reveviced", scheduledPush);
    setScheduledPush((prev) => ({ ...prev, ...scheduledPush }));
    setStage(3);
  };
  const resetFields = () => {
    setScheduledPush(DEFAULT_SCHEDULED_PUSH);
    setStage(1);
  };
  const handleSubmitNotification = () => {
    //reset
    setScheduledPush(DEFAULT_SCHEDULED_PUSH);
    setStage(1);
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const goBack = () => {
    setStage((prev) => {
      if (prev === 1) return prev;
      else return prev - 1;
    });
  };

  return (
    <div className={styles.container}>
      <Modal
        visible={visible}
        title={`Notification Form `}
        onCancel={handleCancel}
        footer={null}
        width={650}
      >
        {stage !== 1 && (
          <Button
            type="default"
            onClick={goBack}
            style={{ marginBottom: "20px" }}
          >
            Back
          </Button>
        )}

        {stage === 1 && (
          <NotificationStartForm
            onSubmit={handleSubmitStartForm}
            scheduledPush={scheduledPush}
          />
        )}
        {stage === 2 && (
          <TargetAttributesForm
            onSubmit={handleSubmitTargetAttributesForm}
            scheduledPush={scheduledPush}
          />
        )}
        {stage === 3 && (
          <PreviewSendNotificationForm
            onSubmit={handleSubmitNotification}
            scheduledPush={scheduledPush}
          />
        )}
      </Modal>
    </div>
  );
}

/***
 * 
 * 
 *  <Form
        form={form}
        onFinish={handleSubmit}
        style={{ width: "500px" }}
        layout="vertical"
      >
        <Form.Item
          name="targetAttributes.status"
          label={"Status"}
          rules={[{ required: true, message: "Target attributes required" }]}
          wrapperCol={{ ...NotificationFieldLayout.wrapperCol }}
          labelCol={{ ...NotificationFieldLayout.labelCol }}
          labelAlign="left"
        >
          <Select
            mode="multiple"
            allowClear
            style={{ width: "500px" }}
            placeholder="Please select"
            defaultValue={["verified"]}
            options={[
              { value: "verified", label: "Verified" },
              { value: "pendingSubmission", label: "Pending Submission" },
              { value: "declined", label: "Declined" },
            ]}
          />
        </Form.Item>
        <Form.Item
          name="targetAttributes.role"
          label={"Role"}
          rules={[{ required: true, message: "Target attributes required" }]}
          wrapperCol={{ ...NotificationFieldLayout.wrapperCol }}
          labelCol={{ ...NotificationFieldLayout.labelCol }}
          labelAlign="left"
        >
          <Select
            mode="multiple"
            allowClear
            style={{ width: "500px" }}
            placeholder="Please select"
            defaultValue={["ADMIN", "ROOT"]}
            options={[
              { value: "USER", label: "Employees" },
              { value: "ADMIN", label: "Admins" },
              { value: "ROOT", label: "Owners" },
            ]}
          />
        </Form.Item>
        <Form.Item
          name="targetAttributes.active"
          rules={[{ required: true, message: "Target attributes required" }]}
          label={"Activity"}
          wrapperCol={{ ...NotificationFieldLayout.wrapperCol }}
          labelCol={{ ...NotificationFieldLayout.labelCol }}
          labelAlign="left"
        >
          <Radio.Group>
            <Radio.Button value={true}>{"Active In Last 30 Days"}</Radio.Button>
            <Radio.Button value={false}>{"All"}</Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="timestamp"
          label="Date & Time"
          rules={[{ required: true, message: "Timestamp is required" }]}
          help="Shown as local time zone"
          wrapperCol={{ ...NotificationFieldLayout.wrapperCol }}
          labelCol={{ ...NotificationFieldLayout.labelCol }}
          labelAlign="left"
        >
          <DatePicker
            showTime
            format="DD-MM-YYYY HH:mm:ss"
            disabledDate={disabledDate}
            //@ts-ignore
            disabledTime={disabledTime}
            showSecond={false}
            showNow={false}
          />
        </Form.Item>
        <Input.Group>
          <Form.Item
            name={["notification", "type"]}
            // noStyle
            rules={[{ required: true, message: "Type is required" }]}
            label={"Type"}
            wrapperCol={{ ...NotificationFieldLayout.wrapperCol }}
            labelCol={{ ...NotificationFieldLayout.labelCol }}
            labelAlign="left"
          >
            <Radio.Group defaultValue="a" buttonStyle="solid">
              {NOTIFICATION_TYPES_CONFIG.map((g) => (
                <Radio value={g.value}>{g.label}</Radio>
              ))}
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name={["notification", "title"]}
            // noStyle
            rules={[{ required: true, message: "Title is required" }]}
          >
            <Input placeholder="title" />
          </Form.Item>
          <Form.Item
            name={["notification", "description"]}
            // noStyle
            rules={[{ required: true, message: "Description is required" }]}
          >
            <Input.TextArea
              rows={4}
              placeholder="Description"
              maxLength={200}
            />
          </Form.Item>
          <Form.Item name={["notification", "analyticsIdentifier"]}>
            <Input placeholder={randomUUID} disabled />
          </Form.Item>
          <Form.Item shouldUpdate>
            {() => {
              return (
                <Form.Item
                  name={["notification", "expiration"]}
                  // rules={[
                  //   { required: true, message: "Expiration is required" },
                  // ]}
                >
                  <DatePicker
                    showTime
                    format="DD-MM-YYYY HH:mm:ss"
                    disabled={
                      form.getFieldValue("notification")?.type !== "INAPP"
                    }
                    disabledDate={disabledDate}
                    //@ts-ignore
                    disabledTime={disabledTime}
                    showSecond={false}
                    showNow={false}
                    placeholder="Select expiration date"
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Input.Group>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Preview
          </Button>
        </Form.Item>
      </Form>
      <NotificationSendModal
        setVisible={setIsSendModalShown}
        visible={isSendModalShown}
      ></NotificationSendModal>
 */
