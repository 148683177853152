import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState, AppThunk } from "../../app/store";
import { IBusinessSimple } from "../../interfaces/businessSimple.interface";
import {
  ITransactionTag,
  ITransactionTags,
} from "../../interfaces/transactionTags.interface";
import { getBusinessThunk } from "../businesses/businessesSlice";
import {
  getTransactionTagsFromServer,
  updateTransactionTagsServer,
  createTransactionTagServer,
  editTransactionTagServer,
  deleteTransactionTagServer,
} from "./transactionTagsApi";

// import { fetchCount } from './counterAPI';

export interface TransactionTagsState {
  currentbusiness: IBusinessSimple;
  currentTransactionTags: ITransactionTag[];
  currentTransactionTag: ITransactionTag;
}

const initialState: TransactionTagsState = {
  currentbusiness: {} as any,
  currentTransactionTags: [],
  currentTransactionTag: {} as any,
};

export const getTransactionTagsThunk = createAsyncThunk(
  "transactionTags/getTransactionsTag",
  async (props: { businessId: string }) => {
    const response = await getTransactionTagsFromServer(props.businessId);
    return response;
  }
);

export const createTagThunk = createAsyncThunk(
  "transactionTags/createTag",
  async ({ businessId, tag }: { businessId: string; tag: ITransactionTag }) => {
    const response = await createTransactionTagServer({
      businessId,
      tag,
    });
    return response;
  }
);
export const editTagThunk = createAsyncThunk(
  "transactionTags/editTag",
  async ({ businessId, tag }: { businessId: string; tag: ITransactionTag }) => {
    const response = await editTransactionTagServer({
      businessId,
      tag,
    });
    return response;
  }
);
export const deleteTagThunk = createAsyncThunk(
  "transactionTags/deleteTag",
  async ({ businessId, tagId }: { businessId: string; tagId: string }) => {
    const response = await deleteTransactionTagServer({
      businessId,
      tagId,
    });
    return response;
  }
);
export const updateTransactionTagThunk = createAsyncThunk(
  "transactionTags/updateTransactionTagThunk",
  async ({ _id, tag }: { _id: string; tag: ITransactionTag }, { getState }) => {
    const currentTransactionTags = (getState() as any)?.transactionTags
      .currentTransactionTags as ITransactionTags;
    const updatedTags = currentTransactionTags.tags.map((t) =>
      t._id == tag._id ? tag : t
    );
    const updatedCatalog = await updateTransactionTagsServer({
      _id,
      tags: updatedTags,
    });

    return updatedCatalog;
  }
);
// export const createTransactionTagThunk = createAsyncThunk(
//   "transactionTags/createTransactionTagThunk",
//   async (
//     { _id, tag }: { _id: string; tag: Partial<ITransactionTag> },
//     { getState }
//   ) => {
//     const currentTransactionTags = (getState() as any)?.transactionTags
//       .currentTransactionTags as ITransactionTags;
//     const updatedTags = currentTransactionTags.tags.concat(
//       tag as ITransactionTag
//     );

//     const updatedCatalog = await updateTransactionTagsServer({
//       _id,
//       tags: updatedTags,
//     });

//     return updatedCatalog;
//   }
// );
// export const deleteTransactionTagThunk = createAsyncThunk(
//   "transactionTags/deleteTransactionTagThunk",
//   async (
//     { transactionTagsId, tagId }: { transactionTagsId: string; tagId: string },
//     { getState }
//   ) => {
//     const currentTransactionTags = (getState() as any)?.transactionTags
//       .currentTransactionTags as ITransactionTags;

//     const filteredTags = currentTransactionTags.tags.filter(
//       (t) => t._id !== tagId
//     );
//     const updatedCatalog = await updateTransactionTagsServer({
//       _id: transactionTagsId,
//       tags: filteredTags,
//     });

//     return updatedCatalog;
//   }
// );

export const transactionTagsSlice = createSlice({
  name: "transactionTags",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setCurrentTransactionTag: (
      state,
      action: PayloadAction<ITransactionTag>
    ) => {
      state.currentTransactionTag = action.payload;
    },
    // setSubmenu: (state, action: PayloadAction<ICatalog | null>) => {
    //   state.currentSubmenu = action.payload;
    // },
    // resetCatalogState: (state) => {
    //   state.currentItem = {} as any;
    //   state.currentSubmenu = {} as any;
    // },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder

      .addCase(getTransactionTagsThunk.fulfilled, (state, action) => {
        state.currentTransactionTags = action.payload.tags;
      })
      .addCase(getBusinessThunk.fulfilled, (state, action) => {
        state.currentbusiness = action.payload;
      })

      .addCase(createTagThunk.fulfilled, (state, action) => {
        state.currentTransactionTags = action.payload.tags;
      })
      .addCase(editTagThunk.fulfilled, (state, action) => {
        state.currentTransactionTags = action.payload.tags;
      })
      .addCase(deleteTagThunk.fulfilled, (state, action) => {
        state.currentTransactionTags = action.payload.tags;
      });
  },
});

export const { setCurrentTransactionTag } = transactionTagsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const selectCurrentBusiness = (state: RootState) =>
  state.transactionTags.currentbusiness;
export const selectCurrentTransactionTags = (state: RootState) =>
  state.transactionTags.currentTransactionTags;
export const selectCurrentTransactionTag = (state: RootState) =>
  state.transactionTags.currentTransactionTag;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
//   (amount: number): AppThunk =>
//   (dispatch, getState) => {
//     const currentValue = selectCount(getState());
//     if (currentValue % 2 === 1) {
//       dispatch(incrementByAmount(amount));
//     }
//   };

export default transactionTagsSlice.reducer;
