import { Button, Divider, Input, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState, ChangeEvent } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  filterPromotionsByName,
  getPromotionsThunk,
  selectCurrentPromotions,
  selectPromotionStatus,
  selectPromotionType,
  updatePromotionsStatusThunk,
} from "../promotionsSlice";
import styles from "../Promotions.module.css";
import SearchBox from "../../search-box/SearchBox";
import {
  IPromotion,
  PromoStatus,
  PromoType,
} from "../../../interfaces/promotion.interface";
import { convertPromotionsToCSV } from "../utils/convertPromotionsToCSV";
import { downloadFile } from "../../../utils/downloadFile";
import { formatCommasAndDots } from "../utils/formatCommasAndDots";
import { Checkbox } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";

export default function PromotionsTable() {
  const promotionType = useAppSelector(selectPromotionType);
  const promotionStatus = useAppSelector(selectPromotionStatus);
  const currentPromotions = useAppSelector(selectCurrentPromotions).map(
    (b) => ({
      ...b,
      key: b._id,
    })
  );

  const [loading, setLoading] = useState(false);
  const [willNotify, setWillNotify] = useState(true);

  const [selectedPromotions, setSelectedPromotions] = useState([] as any);

  const dispatch = useAppDispatch();
  useEffect(() => {
    fetchRefferalPromotions({ status: promotionStatus, type: promotionType });
  }, [promotionType, promotionStatus]);

  const fetchRefferalPromotions = async (params: {
    status: PromoStatus;
    type: PromoType;
  }) => {
    const { status, type } = params;

    setLoading(true);
    await dispatch(getPromotionsThunk({ status, type }));
    setLoading(false);
  };
  const handleNotifyState = (e: CheckboxChangeEvent) => {
    let isChecked = e.target.checked;
    setWillNotify(isChecked);
  };

  const getPromotionColumns = (): ColumnsType<IPromotion> => {
    let columns: ColumnsType<IPromotion> = [
      {
        title: "Business Name",
        dataIndex: "businessName",
      },
      {
        title: "IBAN",
        dataIndex: "IBAN",
      },
      {
        title: "Bank Account Holder Name",
        dataIndex: "businessOwnerName",
      },
      {
        title: "Amount",
        dataIndex: "amount",
        render: (value) => "€" + formatCommasAndDots(value, "NL"),
      },
    ];

    if (promotionType === "referredABusiness") {
      columns.push({
        title: "Referred Business Name",
        dataIndex: "referredBusinessName",
      });
    }

    return columns;
  };

  const promotionsRowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: IPromotion[]) => {
      setSelectedPromotions(selectedRows);
    },
    getCheckboxProps: (record: IPromotion) => ({
      name: record.businessName,
    }),
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
  };

  const handleUpdateStatus = async () => {
    setLoading(true);
    if (promotionStatus === "eligible") _generateCSVFile();

    await dispatch(
      updatePromotionsStatusThunk({
        promotions: selectedPromotions,
        status: promotionStatus === "eligible" ? "pending" : "closed",
        notify: willNotify,
      })
    );
    setLoading(false);
  };

  const handleSelectedRowsExport = async () => {
    await handleUpdateStatus();
  };

  const _generateCSVFile = () => {
    if (selectedPromotions?.length == 0) return;
    const fileName = "Promotions-" + new Date().toISOString() + ".csv";
    const csv = convertPromotionsToCSV(selectedPromotions);
    if (csv) downloadFile(csv, fileName);
  };
  const handleNameFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value.toLowerCase();
    dispatch(filterPromotionsByName(name));
  };

  return (
    <div>
      {(promotionStatus === "eligible" || promotionStatus === "pending") && (
        <div className={styles.button_container}>
          {promotionStatus === "pending" && (
            <Checkbox
              style={{ paddingRight: "5px" }}
              onChange={handleNotifyState}
              defaultChecked={willNotify}
              disabled={
                loading ||
                currentPromotions?.length === 0 ||
                selectedPromotions?.length == 0
              }
            >
              Notify Merchant
            </Checkbox>
          )}
          <Button
            type="primary"
            onClick={() => {
              handleSelectedRowsExport();
            }}
            disabled={
              loading ||
              currentPromotions?.length === 0 ||
              selectedPromotions?.length == 0
            }
            loading={loading}
          >
            {promotionStatus === "eligible"
              ? "Export Selected"
              : "Close Promotions"}
          </Button>
        </div>
      )}
      <div>
        <Divider />
        {/* <SearchBox
          allData={openPromotions.map((c) => ({ ...c, key: c._id }))}
          setData={setOpenPromotionsTableData}
          options={openPromotionsTableData.map(
            (c: IBusinessPromotionStatus) => ({
              key: c._id,
              value: c.businessName,
            })
          )}
        /> */}
        <Input
          onChange={handleNameFilterChange}
          className={styles.searchInput}
        />

        <Table
          rowSelection={
            promotionStatus === "eligible" || promotionStatus === "pending"
              ? {
                  type: "checkbox",
                  ...promotionsRowSelection,
                }
              : undefined
          }
          columns={getPromotionColumns()}
          dataSource={currentPromotions.length > 0 ? currentPromotions : []}
          loading={loading}
        />
      </div>
      <br />
    </div>
  );
}
