import { Button, message } from "antd";
import React, { useEffect } from "react";
import { useAppSelector } from "../../app/hooks";
import { selectError } from "./errorsSlice";
import { v4 as uuidv4 } from "uuid";
export default function ErrorAlerter() {
  const error = useAppSelector(selectError);

  let key = uuidv4();
  let handleMessageClose = (key: string) => {
    message.destroy();
  };
  useEffect(() => {
    if (error?.message || error?.code) {
      let ErrorReceived = (
        <div>
          {error.message && <span>MESSAGE: {error.message}</span>}
          <br></br>
          {error.code && <span>CODE: {error.code}</span>}
          <br></br>
          <div className="center">
            <Button
              danger
              onClick={() => {
                handleMessageClose(key);
              }}
            >
              Close
            </Button>
          </div>
        </div>
      );
      message.warning(ErrorReceived, 30);
    }
  }, [error]);

  return <></>;
}
