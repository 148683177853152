import { Form, Input } from "antd";
import Select, { BaseOptionType } from "antd/lib/select";

const getPayoutScheduleOptions = () => {
  return [
    { label: "Daily", value: "daily" },
    { label: "Weekly Wed", value: "weekly-wed" },
    { label: "Weekly Thur", value: "weekly-thur" },
    { label: "Weekly Fri", value: "weekly-fri" },
    { label: "Monthly", value: "monthly" },
  ] as BaseOptionType[];
};
export default function PayoutDetailsEditor() {
  const bankFieldLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
  };
  return (
    <>
      <Form.Item
        label="Payout Schedule"
        name={["payoutOptions", "schedule"]}
        wrapperCol={{ ...bankFieldLayout.wrapperCol }}
        labelCol={{ ...bankFieldLayout.labelCol }}
        labelAlign="left"
      >
        <Select style={{ width: 200 }} options={getPayoutScheduleOptions()} />
      </Form.Item>
    </>
  );
}
