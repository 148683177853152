import { Avatar, Empty, List, Skeleton } from "antd";
import { useAppSelector } from "../../../../../../app/hooks";
import { selectCurrentPartner } from "../../../../partnersSlice";
import styles from "./PartnerTeamMemberList.module.css";

import { DEFAULT_PURCH_PRICE_PROFILE } from "../../../../utils/defaultPurchPriceProfile";
import { useState } from "react";
import { UserAddOutlined } from "@ant-design/icons";
const partnerFormFieldLayout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 12 },
};
interface IPropsPartnerTeamMemberList {}

let NEW_PURCH_PRICE_PROFILE = { ...DEFAULT_PURCH_PRICE_PROFILE, name: "" };

export default function PartnerTeamMemberList(
  props: IPropsPartnerTeamMemberList
) {
  const partner = useAppSelector(selectCurrentPartner);
  return (
    <div>
      {/* {partner?.teamMembers?.length > 0 && ( */}
      <List
        itemLayout="horizontal"
        className={styles.list}
        bordered
        header={"Team Members"}
        dataSource={partner?.teamMembers}
        renderItem={(item) => (
          <List.Item className={styles.list_item} actions={[]}>
            <List.Item.Meta
              avatar={<Avatar children={<UserAddOutlined />} />}
              title={item.name}
              description={
                <div>
                  <span>{item.email}</span>
                </div>
              }
            />
          </List.Item>
        )}
      />
      {/* )}

      {partner?.teamMembers?.length === 0 && (
        <Empty description="No team members" />
      )} */}
    </div>
  );
}
