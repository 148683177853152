import {
  Table,
  Space,
  Button,
  Input,
  TablePaginationConfig,
  Statistic,
  Select,
} from "antd";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import styles from "./SalesBusinessActivityTable.module.css";
import {
  getBusinessesThunk,
  setCurrentBusiness,
  selectTotalBusinessCount,
  selectLastUpdateKey,
  selectFilters,
  setFilters,
} from "../../../businesses/businessesSlice";

import { Empty } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import Loader from "../../../common/loader/Loader";
import {
  BusinessType,
  IBusinessFull,
  IBusinessFullQuery,
} from "../../../../interfaces/businessFull.interface";
import { TableParams } from "../../../../interfaces/tableParams.interface";
import { IPagination } from "../../../../interfaces/pagination.interface";
import debounce from "lodash/debounce";
import SalesBusinessEditorModal from "../Sales-Business-Editor/SalesBusinessEditor";
import SalesBusinessActivityDetails from "../Sales-Business-Activity-Details/SalesBusinessActivityDetails";
import { selectSalesReps } from "../../SalesSlice";
import SalesRepIcon from "../Sales-Rep-Icon/SalesRepIcon";
import {
  selectIsKlearlySalesAdmin,
  selectIsKlearlySalesSuperAdmin,
  selectUser,
} from "../../../access-control/accountSlice";
const { Column, ColumnGroup } = Table;

export default function SalesBusinessActivityTable() {
  const [isDetailModalVisible, setIsDetailModalVisible] = useState(false);
  const [isEditorModalVisible, setIsEditorModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const lastUpdateKey = useAppSelector(selectLastUpdateKey);
  const filters = useAppSelector(selectFilters);
  const salesReps = useAppSelector(selectSalesReps);
  const [saleRepSelectionOptions, setSaleRepSelectionOptions] = useState([
    ...salesReps,
  ]);
  const isSalesSuperAdmin = useAppSelector(selectIsKlearlySalesSuperAdmin);
  const isSalesAdmin = useAppSelector(selectIsKlearlySalesAdmin);
  const user = useAppSelector(selectUser);
  const [businessesInTable, setBusinessesInTable] = useState([]);
  const totalCount = useAppSelector(selectTotalBusinessCount);
  const initialPagination = { current: 1, pageSize: 10 };
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: { ...initialPagination },
  });
  // const [filters, setFilters] = useState<Partial<IBusinessFullQuery>>({});
  const dispatch = useAppDispatch();

  const fetchAllBusinesses = async (params: {
    pagination: IPagination;
    filters: Partial<IBusinessFullQuery>;
  }) => {
    setLoading(true);
    await dispatch(
      getBusinessesThunk({
        currentPage: params?.pagination?.current!,
        pageSize: params?.pagination?.pageSize!,
        ...filters,
      })
    )
      .then(({ payload }) => {
        //@ts-ignore
        let businesses = payload.data;
        //@ts-ignore
        let totalCount = payload.totalCount;
        let businessesWithKeys = businesses.map((b: IBusinessFull) => ({
          ...b,
          key: b._id,
        }));
        setBusinessesInTable(businessesWithKeys);

        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: totalCount,
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchAllBusinesses({
      pagination: {
        current: tableParams.pagination?.current || 1,
        pageSize: tableParams.pagination?.pageSize!,
      },
      filters,
    });

    if (isSalesAdmin && !isSalesSuperAdmin) {
      setSaleRepSelectionOptions((prev) =>
        prev.filter((r) => r.email === user?.email)
      );
    }
  }, [JSON.stringify(tableParams), JSON.stringify(filters), lastUpdateKey]);

  const showBusinessDetailsModal = (business: IBusinessFull) => {
    dispatch(setCurrentBusiness(business));
    setIsDetailModalVisible(true);
  };
  const showBusinessEditorModal = (business: IBusinessFull) => {
    dispatch(setCurrentBusiness(business));
    setIsEditorModalVisible(true);
  };

  const handleSalesRepresentativeFilterChange = (value: string) => {
    dispatch(setFilters({ salesRepresentative: value, ...initialPagination }));
    setTableParams((prev) => {
      return { ...prev, pagination: { ...initialPagination } };
    });
  };

  const handleTypeFilterChange = (value: BusinessType) => {
    dispatch(setFilters({ businessType: value, ...initialPagination }));
    setTableParams((prev) => {
      return { ...prev, pagination: { ...initialPagination } };
    });
  };
  const debouncedNameFilterChange = useCallback(
    debounce((nextValue) => handleNameFilterChange(nextValue), 400),
    [] // will be created only once initially
  );

  const handleDebouncedNameFilterChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    debouncedNameFilterChange(event);
  };
  const handleNameFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value.toLowerCase();
    dispatch(
      setFilters({
        name: name.replace(/\s+/g, " ").trim(),
        ...initialPagination,
      })
    );
    setTableParams((prev) => {
      return { ...prev, pagination: { ...initialPagination } };
    });
  };

  const handleTableChange = (
    pagination: TablePaginationConfig
    // filters: Record<string, FilterValue>,
    // sorter: SorterResult<IBucketDeal>
  ) => {
    setTableParams({
      pagination,
      // filters,
      // ...sorter,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      // dispatch(emptyBucketDealsTable());
    }
  };

  return (
    <div className={styles.container} key="divTable">
      <Space wrap>
        <span>Sales Representative </span>
        <Select
          defaultValue=""
          style={{ width: 120 }}
          onChange={(value: string) => {
            handleSalesRepresentativeFilterChange(value);
          }}
          options={[...saleRepSelectionOptions, { label: "All", value: "" }]}
        />
        {/* <span>Business Type </span>

        <Select
          defaultValue="All"
          style={{ width: 120 }}
          onChange={(value: any) => {
            handleTypeFilterChange(value);
          }}
          options={[
            {
              label: "All",
              value: "",
            },
            {
              label: "Sole Traders",
              value: "soleTrader",
            },
            {
              label: "Partnership",
              value: "partnership",
            },
            {
              label: "Private Limited Company",
              value: "privateLimitedCompany",
            },
            {
              label: "Public Limited Company",
              value: "publicLimitedCompany",
            },
            {
              label: "Other",
              value: "other",
            },
          ]}
        /> */}
      </Space>

      <div className={styles.table_header}>
        <div style={{ height: "80px" }} className="center">
          <>
            <Statistic
              title="Amount of Merchants:"
              value={totalCount}
              loading={loading}
              // precision={2}
            />
          </>
        </div>

        <Input
          onChange={handleDebouncedNameFilterChange}
          placeholder="Business Name, Email, Kvk ..."
          value={filters.businessName}
          className={styles.searchInput}
          prefix={<SearchOutlined />}
          style={{ width: "400px" }}
        />
      </div>
      <br />

      <div className={styles.table_container}>
        {businessesInTable.length === 0 && !loading && (
          <div style={{ height: "300px" }} className="center">
            <Empty></Empty>
          </div>
        )}
        {businessesInTable.length > 0 && !loading && (
          <Table
            dataSource={businessesInTable}
            pagination={tableParams.pagination}
            loading={loading}
            //@ts-ignore
            onChange={handleTableChange}
          >
            <Column
              title="Business "
              className={styles.table_business_column}
              key="businessDetails"
              render={(_: any, record: IBusinessFull) => (
                <div className={styles.business_details}>
                  <span key={record._id + "businessName"}>
                    {record.businessName}
                  </span>
                  <span
                    style={{ color: "gray" }}
                    key={record._id + "businessRegistrationNumber"}
                  >
                    {record.businessRegistrationNumber}
                  </span>
                </div>
              )}
            />

            <Column
              title="Email"
              key="email"
              className={styles.table_column}
              render={(_: any, record: IBusinessFull) => (
                <span key={record._id + "email"}>{record.rootUser?.email}</span>
              )}
            />
            <Column
              title="Industry"
              key="industry"
              className={styles.table_column}
              render={(_: any, record: IBusinessFull) => (
                <span key={record._id + "industry"}>{record.industry}</span>
              )}
            />

            <Column
              title="Sales Representatives"
              key="salesRepresentatives"
              className={styles.table_column}
              render={(_: any, record: IBusinessFull) => (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {record.salesRepresentative && (
                    <SalesRepIcon
                      type={"salesRepresentative"}
                      label={record.salesRepresentative}
                    />
                  )}
                  {record.salesRepresentative2 && (
                    <SalesRepIcon
                      type={"salesRepresentative2"}
                      label={record.salesRepresentative2}
                    />
                  )}
                  {record.salesBusinessDeveloper && (
                    <SalesRepIcon
                      type={"salesBusinessDeveloper"}
                      label={record.salesBusinessDeveloper}
                    />
                  )}
                </div>
              )}
            />

            <Column
              title=""
              key="details"
              className={styles.table_column}
              render={(_: any, record: IBusinessFull) => (
                <Space
                  size="small"
                  key={record._id}
                  className={styles.tables_buttons_container}
                >
                  <Button
                    shape="round"
                    type="dashed"
                    onClick={() => {
                      showBusinessDetailsModal(record);
                    }}
                  >
                    View Details
                  </Button>
                  <Button
                    type="link"
                    onClick={async () => {
                      showBusinessEditorModal(record);
                    }}
                  >
                    Assign
                  </Button>
                </Space>
              )}
            />
          </Table>
        )}
        {loading && <Loader text="Fetching businesses from server"></Loader>}
      </div>
      <SalesBusinessActivityDetails
        visible={isDetailModalVisible}
        setVisible={setIsDetailModalVisible}
      ></SalesBusinessActivityDetails>
      <SalesBusinessEditorModal
        visible={isEditorModalVisible}
        setVisible={setIsEditorModalVisible}
      ></SalesBusinessEditorModal>
    </div>
  );
}
