import { BusinessStatus } from "../../../interfaces/businessFull.interface";

export const mapStatusLabels = (status: BusinessStatus) => {
  switch (status) {
    case "pendingSubmission":
      return { label: "Pending Submission", color: "yellow" };
    case "pendingVerification":
      return { label: "Pending Verification", color: "orange" };
    case "verified":
      return { label: "Approved", color: "green" };
    case "unverified":
      return { label: "Declined", color: "red" };
    case "inactivated":
      return { label: "Inactivated", color: "gray" };
    default:
      return { label: "", color: "white" };
  }
};
