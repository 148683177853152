import { CONFIG } from "../../config";
import { IReportRequest } from "../../interfaces/requestReport.interface";
import { TimeFormat } from "../../interfaces/timeFormat.enum";
import { getTokenizedAxios } from "../../utils/axiosWithTokenGen";

export async function getSalesOverviewReportFromServer(
  reportRequest: IReportRequest
): Promise<{
  transactionsReport: string;
  isEmailed: boolean;
  isEmpty: boolean;
}> {
  const axios = await getTokenizedAxios();
  const path = "/transactions/report";
  return axios
    .get(CONFIG.SERVER.BASE_URL + path, {
      params: { ...reportRequest, ["Api-Version"]: "2024-01-21" },
    })
    .then((data) => data.data);
}
export async function getTransactionsOverviewReportFromServer(
  reportRequest: IReportRequest
): Promise<{
  transactionsReport: string;
  isEmailed: boolean;
  isEmpty: boolean;
}> {
  const axios = await getTokenizedAxios();
  const path = "/transactions/report/list";
  return axios
    .get(CONFIG.SERVER.BASE_URL + path, {
      params: { ...reportRequest, ["Api-Version"]: "2024-01-21" },
    })
    .then((data) => data.data);
}
export async function getPayoutReportFromServer(
  reportRequest: IReportRequest
): Promise<{
  transactionsReport: string;
  isEmailed: boolean;
  isEmpty: boolean;
}> {
  const axios = await getTokenizedAxios();
  const path = "/transactions/report/payout";
  return axios
    .get(CONFIG.SERVER.BASE_URL + path, {
      params: { ...reportRequest, ["Api-Version"]: "2024-01-21" },
    })
    .then((data) => data.data);
}
export async function getAccountingReportFromServer(
  reportRequest: IReportRequest
): Promise<{
  transactionsReport: string;
  isEmailed: boolean;
  isEmpty: boolean;
}> {
  const axios = await getTokenizedAxios();
  const path = "/transactions/report/accounting";
  return axios
    .get(CONFIG.SERVER.BASE_URL + path, {
      params: { ...reportRequest, ["Api-Version"]: "2024-01-21" },
    })
    .then((data) => data.data);
}

// Analytics Reports

export type ApplePerformanceReportRequest = {
  reportFrequency: "monthly" | "quarterly";
  returnType: "buffer" | "url";
};
export async function getApplePerformanceReportFromServer({
  reportFrequency,
  returnType = "url",
}: {
  reportFrequency: "monthly" | "quarterly";
  returnType: "buffer" | "url";
}): Promise<{
  value: string;
  returnType: "buffer" | "url";
  // isEmailed: boolean;
  // isEmpty: boolean;
}> {
  const axios = await getTokenizedAxios();
  const path = "/ios/reports/performance";
  return axios
    .get(CONFIG.SERVER.BASE_URL + path, {
      params: { reportFrequency, returnType },
    })
    .then((data) => data.data);
}
export type MainAnalyticsReportRequest = {
  startDate?: string;
  endDate?: string;
  formatType?: "PDF" | "JSON" | "XLSX" | "CSV";
  receiveViaEmail?: boolean;
  email?: string;
  isCustomTime?: "true" | "false";
  timeFormat?: TimeFormat;
};
export async function getMainAnalyticsReportFromServer({
  startDate,
  endDate,
  formatType,
  receiveViaEmail,
  email,
  isCustomTime,
  timeFormat,
}: MainAnalyticsReportRequest): Promise<{
  data?: string;
  isEmailed: boolean;
  isEmpty: boolean;
}> {
  const axios = await getTokenizedAxios();
  const path = "/businesses/analytics/start";
  return axios
    .post(CONFIG.ADMIN_SERVER.BASE_URL + path, {
      start: startDate,
      end: endDate,
      email,
    })
    .then((data) => data.data);
}
