import React from "react";
import Businesses from "../features/businesses/Businesses";

export default function BusinessesPage() {
  return (
    <div>
      <Businesses></Businesses>
    </div>
  );
}
