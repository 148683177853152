import { CONFIG } from "../../config";
import { IBusinessFullQuery } from "../../interfaces/businessFull.interface";
import {
  IPaxTerminal,
  IPaxTerminalQuery,
  PlatformType,
} from "../../interfaces/paxTerminal.interface";
import { IQueryFilters } from "../../interfaces/queryFilters.interface";
import { getTokenizedAxios } from "../../utils/axiosWithTokenGen";

export interface ICoupleMerchantToPaxTerminalRequest {
  businessId: string;
  serialNumber: string;
  partnerId?: string;
  platform?: PlatformType;
}
export async function coupleMerchantToPaxTerminalServer(
  params: ICoupleMerchantToPaxTerminalRequest
) {
  const axios = await getTokenizedAxios();
  const path = "/paxTerminals/couple";
  return axios
    .post<{ isCoupled: boolean; lastPushId: string }>(
      CONFIG.SERVER.BASE_URL + path,
      params
    )
    .then((data) => data.data)
    .catch((e) => ({
      error: e,
    }));
}

export type IGetTerminalsServerRequest = IQueryFilters<IPaxTerminalQuery>;

export async function getTerminalsServer(params: IGetTerminalsServerRequest) {
  const axios = await getTokenizedAxios();

  const path = "/terminals/";
  return axios
    .get<{ data: IPaxTerminal[]; totalCount: number }>(
      CONFIG.ADMIN_SERVER.BASE_URL + path,
      { params }
    )
    .then((data) => data.data);
}

export interface IValidateCouplingServerRequest {
  lastPushIds: number[];
}
export async function validateCouplingServer(
  params: IValidateCouplingServerRequest
) {
  const axios = await getTokenizedAxios();
  const path = "/terminals/validate";
  return axios
    .post<IPaxTerminal[]>(CONFIG.ADMIN_SERVER.BASE_URL + path, params)
    .then((data) => data.data);
}
