import { Tag } from "antd";
import React, { HTMLAttributes } from "react";
import { ClockCircleOutlined } from "@ant-design/icons";
import { IScheduledPush } from "../../../../interfaces/scheduledPush.interface";
import {
  ROLE_OPTIONS_MAP,
  STATUS_OPTIONS_MAP,
} from "../Notification-Form/components/Target-Attributes-Form/TargetAttributesForm";
import { formatLocalDate } from "../../../../utils/formatDate";

interface IPropsTargetAttributesPreview extends HTMLAttributes<HTMLDivElement> {
  scheduledPush: IScheduledPush;
}

const TargetAttributeTagsPreview: React.FC<IPropsTargetAttributesPreview> = ({
  scheduledPush,
  ...other
}) => {
  if (scheduledPush.targetAttributes?.isTest) {
    return (
      <div {...other}>
        <Tag
          style={{ marginBottom: "10px", width: "min-content" }}
          color="blue"
        >
          {"Test"}
        </Tag>
      </div>
    );
  }

  return (
    <div {...other}>
      {scheduledPush.targetAttributes?.active && (
        <div>
          <Tag
            style={{ marginBottom: "10px", width: "min-content" }}
            icon={<ClockCircleOutlined />}
            color="magenta"
          >
            Active users
          </Tag>

          {scheduledPush.targetAttributes.lastActive && (
            <Tag
              style={{ marginBottom: "10px", width: "min-content" }}
              //   icon={<ClockCircleOutlined />}
              color="magenta"
            >
              {`last seen from ${formatLocalDate(
                new Date(scheduledPush.targetAttributes.lastActive)
              )}`}
            </Tag>
          )}
        </div>
      )}

      {scheduledPush.targetAttributes?.deviceType?.length > 0 && (
        <div>
          {scheduledPush.targetAttributes?.deviceType.map((d) => (
            <Tag
              style={{ marginBottom: "10px", width: "min-content" }}
              color="volcano"
            >
              {d}
            </Tag>
          ))}
        </div>
      )}

      {scheduledPush.targetAttributes?.role?.length > 0 && (
        <div>
          {scheduledPush.targetAttributes?.role.map((r) => (
            <Tag
              style={{ marginBottom: "10px", width: "min-content" }}
              color="green"
            >
              {
                //@ts-ignore
                ROLE_OPTIONS_MAP[r]
              }
            </Tag>
          ))}
        </div>
      )}

      {scheduledPush.targetAttributes?.status?.length > 0 && (
        <div>
          {scheduledPush.targetAttributes?.status.map((s) => (
            <Tag
              style={{ marginBottom: "10px", width: "min-content" }}
              color="purple"
            >
              {
                //@ts-ignore
                STATUS_OPTIONS_MAP[s]
              }
            </Tag>
          ))}
        </div>
      )}
      {scheduledPush.targetAttributes?.version?.length > 0 && (
        <div>
          {scheduledPush.targetAttributes?.version.map((v) => (
            <Tag
              style={{
                marginBottom: "10px",
                width: "min-content",
                wordBreak: "break-word",
                display: "inline-block",
              }}
              color="gold"
            >
              {v}
            </Tag>
          ))}
        </div>
      )}

      {scheduledPush?.group && scheduledPush.group}
    </div>
  );
};

export default TargetAttributeTagsPreview;
