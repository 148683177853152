import React from "react";
import Referrals from "../../features/promotions/Promotions";

export default function ReferralsPage() {
  return (
    <div>
      <Referrals></Referrals>
    </div>
  );
}
