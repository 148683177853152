import { Button, Form, Input, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import Loader from "../../../common/loader/Loader";
import {
  editBusinessThunk,
  editBusinessUserThunk,
  getBusinessUsersThunk,
  selectCurrentBusiness,
  selectCurrentBusinessUsers,
} from "../../businessesSlice";
import styles from "./BusinessEditor.module.css";
import { UserOutlined } from "@ant-design/icons";
import { IBusinessUser } from "../../../../interfaces/businessUser.interface";
import { BusinessPerson } from "../../../../interfaces/businessFull.interface";
import {
  selectUser,
  selectUserAccessLevel,
} from "../../../access-control/accountSlice";
import EmailEditor from "./EmailEditor";
import { AccessLevel } from "../../../../interfaces/accessLevel.enum";
interface IPropsBusinessEditorModel {}

export default function BusinessUsersEditor({}: IPropsBusinessEditorModel) {
  const [loading, setLoading] = useState(false);
  const currentBusinessUsers = useAppSelector(selectCurrentBusinessUsers);
  const currentBusiness = useAppSelector(selectCurrentBusiness);
  const accessLevel = useAppSelector(selectUserAccessLevel);
  const dispatch = useAppDispatch();
  const [isEmailEditorShown, setIsEmailEditorShown] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (currentBusiness._id) fetchBusinessUsers(currentBusiness._id);
  }, [currentBusiness]);
  const handleSubmit = async (values: Partial<IBusinessUser>) => {
    setLoading(true);
    if (!values._id) return;
    await dispatch(
      editBusinessUserThunk({
        _id: values._id,
        updatedFields: values,
      })
    );
    //update person 1 if root user was updated
    if (values._id === currentBusiness.rootUser._id) {
      let rootUser = { ...currentBusiness.persons[0] };
      if (values.firstName) rootUser.firstName = values.firstName;
      if (values.lastName) rootUser.lastName = values.lastName;
      if (values.dateofbirth) rootUser.lastName = values.dateofbirth;
      if (values.address) rootUser.address = values.address;
      if (values.phone) rootUser.phone = values.phone;
      let persons = [...currentBusiness.persons];
      persons[0] = rootUser;

      await dispatch(
        editBusinessThunk({
          _id: currentBusiness._id,
          updatedFields: { persons },
        })
      );
    }
    setLoading(false);
  };

  const fetchBusinessUsers = async (businessId: string) => {
    setLoading(true);
    await dispatch(getBusinessUsersThunk(businessId));

    setLoading(false);
  };

  return (
    <div style={{ minHeight: "200px" }}>
      {!loading && currentBusinessUsers && currentBusinessUsers.length > 0 && (
        <Tabs tabPosition="left" key={currentBusiness?._id}>
          {currentBusinessUsers
            ?.filter((u) => u.name)
            .map((u, i) => (
              <Tabs.TabPane
                tab={
                  <>
                    <span className="center">
                      <UserOutlined></UserOutlined>
                    </span>
                    <span
                      style={{
                        display: "inline-block",
                        width: "100px",
                        whiteSpace: "break-spaces",
                      }}
                    >
                      {u?.name}
                    </span>
                  </>
                }
                key={u?._id}
              >
                <Form
                  initialValues={u}
                  onFinish={handleSubmit}
                  labelCol={{ span: 5, offset: 0 }}
                  labelAlign="left"
                >
                  <Form.Item name="_id" hidden>
                    <Input disabled />
                  </Form.Item>
                  <Form.Item name="firstName" label="First Name">
                    <Input />
                  </Form.Item>
                  <Form.Item name="lastName" label="Last Name">
                    <Input />
                  </Form.Item>
                  <Form.Item name="role" label="Role">
                    <Input disabled />
                  </Form.Item>
                  <Form.Item label="Phone">
                    <Form.Item
                      name={["phone", "countryCode"]}
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 80px)",
                      }}
                      initialValue={"31"}
                    >
                      <Input addonBefore="+" />
                    </Form.Item>
                    <Form.Item
                      name={["phone", "number"]}
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 12px)",
                      }}
                    >
                      <Input />
                    </Form.Item>
                  </Form.Item>
                  <Form.Item>
                    {i === 0 &&
                      currentBusiness?.status !== "verified" &&
                      accessLevel.includes(AccessLevel.KlearlySuperAdmin) && (
                        <Button
                          style={{ float: "left" }}
                          onClick={() => {
                            setIsEmailEditorShown(true);
                          }}
                          danger
                        >
                          Change email
                        </Button>
                      )}
                    <Button
                      style={{ float: "right" }}
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                    >
                      Update
                    </Button>
                  </Form.Item>
                </Form>

                <EmailEditor
                  _id={u._id}
                  currentEmail={u.email}
                  setIsVisible={setIsEmailEditorShown}
                  isVisible={isEmailEditorShown}
                ></EmailEditor>
              </Tabs.TabPane>
            ))}
        </Tabs>
      )}
      {loading && <Loader text="Loading" />}
    </div>
  );
}
