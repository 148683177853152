import { Avatar, Descriptions, List } from "antd";
import { HddOutlined } from "@ant-design/icons";
import Loader from "../../../../common/loader/Loader";
import { IBusinessFull } from "../../../../../interfaces/businessFull.interface";
import { formatDate } from "../../../../../utils/formatDate";
import { LocalDateAsUTC } from "../../../../../utils/LocalDateAsUTC";
interface IPropsBusinessDevices {
  business: Partial<IBusinessFull>;
}

export default function BusinessDevices({ business }: IPropsBusinessDevices) {
  return (
    <div style={{ position: "relative", minHeight: "100px" }}>
      {business?.devices?.length! > 0 && (
        <>
          <Descriptions title="Devices"></Descriptions>
          <List
            size="small"
            dataSource={business?.devices}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  avatar={
                    <span>
                      <HddOutlined />
                      <br />
                      <span style={{ width: "60px", display: "inline-block" }}>
                        {item?.type}
                      </span>
                    </span>
                  }
                  title={
                    <span style={{ width: "300px", display: "inline-block" }}>
                      {item?.deviceId}
                    </span>
                  }
                  description={`${item?.model || ""}`}
                ></List.Item.Meta>
                <List.Item.Meta
                  style={{ marginLeft: "60px" }}
                  title={<span>{item?.buildName || ""}</span>}
                  description={`${item?.buildNumber || ""}`}
                ></List.Item.Meta>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    width: "300px",
                  }}
                >
                  <span>
                    {item.lastOnline &&
                    new LocalDateAsUTC(item?.lastOnline).time >
                      new LocalDateAsUTC().subtractMinutes(30).time ? (
                      <Avatar
                        shape="circle"
                        size="small"
                        style={{
                          backgroundColor: "rgb(26, 162, 26)",
                          opacity: "0.8",
                          boxShadow: "1px 1px 5px grey",
                        }}
                      />
                    ) : (
                      <Avatar size="small" shape="circle" />
                    )}
                  </span>
                  <span
                    style={{
                      marginLeft: "10px",
                      fontSize: "smaller",
                      color: "gray",
                    }}
                  >
                    {item.lastOnline &&
                      formatDate(new LocalDateAsUTC(item?.lastOnline).rawDate)}
                  </span>
                </div>
              </List.Item>
            )}
          />
        </>
      )}
    </div>
  );
}
