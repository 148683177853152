import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import ErrorAlerter from "../errors/ErrorAlerter";

import styles from "./Home.module.css";
import { selectUser } from "../access-control/accountSlice";
import PaymentAnimation from "./components/PaymentAnimation";

// Mock data for the user
const mockUser = {
  userName: "John Doe",
};

// Mock company logo
const mockLogoUrl = "https://example.com/your-logo.png";

export default function Home() {
  const user = useAppSelector(selectUser);

  useEffect(() => {
    return () => {};
  }, [user]);

  return (
    <div className={styles.landingPage}>
      <header>{/* <PaymentAnimation /> */}</header>
      <main>
        <section className={styles.welcomeSection}>
          <h1 className={styles.welcomeMessage}>Welcome, {user?.name}!</h1>
          <p className={styles.subMessage}>
            Enter the Klearly Admin Console. Your command center for streamlined
            tasks and efficient management. Dive in and navigate your
            responsibilities with ease.
          </p>
        </section>
        {/* Add more sections/content as needed */}
      </main>
      <footer>
        <p className={styles.footerText}>
          &copy; {new Date().getFullYear()} Klearly
        </p>
      </footer>
    </div>
  );
}
