// import axios from "axios";
import { store } from "../../app/store";
import { CONFIG } from "../../config";
import { IBusinessSimple } from "../../interfaces/businessSimple.interface";
import { ICatalog, IItem } from "../../interfaces/catalog.interface";
import { getTokenizedAxios } from "../../utils/axiosWithTokenGen";

export async function getCatalogFromServer(
  businessId: string
): Promise<ICatalog> {
  const axios = await getTokenizedAxios();
  const path = "/catalogs/";

  return axios
    .get(CONFIG.SERVER.BASE_URL + path + businessId)
    .then((data) => data.data.catalog);
}

export async function createCatalogServer(props: { businessId: string }) {
  const { businessId } = props;
  const initialCatalog = { name: "Main", items: [], submenus: [] };
  const axios = await getTokenizedAxios();
  const path = "/catalogs/create";
  return axios
    .post(CONFIG.SERVER.BASE_URL + path, { businessId, initialCatalog })
    .then((data) => data.data);
}

export async function updateCatalogServer(props: { catalog: ICatalog | null }) {
  const { catalog } = props;
  const axios = await getTokenizedAxios();
  const path = "/catalogs/update";
  return axios
    .patch(CONFIG.SERVER.BASE_URL + path, catalog)
    .then((data) => data.data);
}
export async function deleteCatalogServer(props: { _id: string }) {
  const { _id } = props;
  const axios = await getTokenizedAxios();
  const path = "/catalogs/delete/" + _id;
  return axios.delete(CONFIG.SERVER.BASE_URL + path).then((data) => data.data);
}

export async function addSubmenuServer(props: {
  businessId: string;
  parentSubmenuId: string;
  submenu: Partial<ICatalog>;
}): Promise<{ catalog: ICatalog; newSubmenuId: string }> {
  const { businessId, parentSubmenuId, submenu } = props;
  const axios = await getTokenizedAxios();
  const path = "/catalogs/addSubmenu";
  return axios
    .post(CONFIG.SERVER.BASE_URL + path, {
      businessId,
      parentSubmenuId,
      submenu,
    })
    .then((data) => data.data);
}

export async function addItemsServer(props: {
  businessId: string;
  parentSubmenuId: string;
  items: IItem[];
}): Promise<ICatalog> {
  const { businessId, parentSubmenuId, items } = props;
  const axios = await getTokenizedAxios();
  const path = "/catalogs/addItems";
  return axios
    .post(CONFIG.SERVER.BASE_URL + path, {
      businessId,
      parentSubmenuId,
      items,
    })
    .then((data) => data.data.catalog);
}

export async function updateSubmenuServer(props: {
  businessId: string;
  submenu: Partial<ICatalog>;
}): Promise<ICatalog> {
  const { businessId, submenu } = props;
  const axios = await getTokenizedAxios();
  const path = "/catalogs/updateSubmenu";
  return axios
    .post(CONFIG.SERVER.BASE_URL + path, {
      businessId,
      submenu,
    })
    .then((data) => data.data.catalog);
}
export async function updateItemServer(props: {
  businessId: string;
  item: Partial<IItem>;
}): Promise<ICatalog> {
  const { businessId, item } = props;
  item.price = Number(item.price);
  const axios = await getTokenizedAxios();
  const path = "/catalogs/updateItem";
  return axios
    .post(CONFIG.SERVER.BASE_URL + path, {
      businessId,
      item,
    })
    .then((data) => data.data.catalog);
}

export async function deleteSubmenuServer(props: {
  businessId: string;
  submenuId: string;
}): Promise<ICatalog> {
  const { businessId, submenuId } = props;
  const axios = await getTokenizedAxios();
  const path = "/catalogs/deleteSubmenu";
  return axios
    .delete(CONFIG.SERVER.BASE_URL + path, {
      params: {
        businessId,
        submenuId,
      },
    })
    .then((data) => data.data.catalog);
}

export async function deleteItemServer(props: {
  businessId: string;
  itemId: string;
}): Promise<ICatalog> {
  const { businessId, itemId } = props;
  const axios = await getTokenizedAxios();
  const path = "/catalogs/deleteItem";
  return axios
    .delete(CONFIG.SERVER.BASE_URL + path, {
      params: {
        businessId,
        itemId,
      },
    })
    .then((data) => data.data.catalog);
}
