// import axios from "axios";
import { CONFIG } from "../../config";
import { IBusinessSimple } from "../../interfaces/businessSimple.interface";
import {
  ITransactionTag,
  ITransactionTags,
} from "../../interfaces/transactionTags.interface";
import { getTokenizedAxios } from "../../utils/axiosWithTokenGen";

export async function getTransactionTagsFromServer(
  businessId: string
): Promise<{ tags: ITransactionTag[] }> {
  const axios = await getTokenizedAxios();
  const path = "/transactionTags/";

  return axios
    .get(CONFIG.SERVER.BASE_URL + path + businessId)
    .then((data) => data.data);
}

export async function createTransactionTagServer(props: {
  businessId: string;
  tag: ITransactionTag;
}): Promise<{ tags: ITransactionTag[] }> {
  const { businessId, tag } = props;
  const axios = await getTokenizedAxios();
  const path = "/transactionTags/addTag";
  return axios
    .post(CONFIG.SERVER.BASE_URL + path, {
      businessId,
      tag,
    })
    .then((data) => data.data);
}
export async function editTransactionTagServer(props: {
  businessId: string;
  tag: ITransactionTag;
}): Promise<{ tags: ITransactionTag[] }> {
  const { businessId, tag } = props;
  const axios = await getTokenizedAxios();
  const path = "/transactionTags/editTag";
  return axios
    .post(CONFIG.SERVER.BASE_URL + path, {
      businessId,
      tag,
    })
    .then((data) => data.data);
}
export async function deleteTransactionTagServer(props: {
  businessId: string;
  tagId: string;
}): Promise<{ tags: ITransactionTag[] }> {
  const { businessId, tagId } = props;
  const axios = await getTokenizedAxios();
  const path = "/transactionTags/deleteTag/";
  return axios
    .delete(CONFIG.SERVER.BASE_URL + path, {
      params: { businessId, tagId },
    })
    .then((data) => data.data);
}

export async function updateTransactionTagsServer(props: {
  _id: string;
  tags: Partial<ITransactionTag>[];
}) {
  const { _id, tags } = props;
  const axios = await getTokenizedAxios();
  const path = "/transactionTags/";
  return axios
    .patch(CONFIG.SERVER.BASE_URL + path, { _id, tags })
    .then((data) => data.data);
}
