import { Input, Segmented } from "antd";
import { SegmentedValue } from "antd/lib/segmented";
import React, { useEffect, ChangeEvent } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

// import ReferringBusinessesTable from "./components/ReferringBusinessesTable";
import {
  filterPromotionsByName,
  getPromotionsThunk,
  setPromotionStatus,
  setPromotionType,
} from "./promotionsSlice";
import styles from "./Promotions.module.css";
import PromotionsTable from "./components/PromotionsTable";
import { PromoStatus, PromoType } from "../../interfaces/promotion.interface";
export default function Promotions() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    // fetchRefferalPromotions();
  }, []);
  const fetchRefferalPromotions = async () => {
    await dispatch(
      getPromotionsThunk({ status: "open", type: "referredABusiness" })
    );
  };

  const handleStatusTabChange = (value: SegmentedValue) => {
    dispatch(setPromotionStatus(value as PromoStatus));
  };
  const handleTypeTabChange = (value: SegmentedValue) => {
    dispatch(setPromotionType(value as PromoType));
  };

  return (
    <div className={styles.container}>
      <div className={styles.tab_container}>
        <Segmented
          // className={style.tab_container}
          defaultValue={"open"}
          onChange={handleStatusTabChange}
          options={[
            {
              label: <div style={{ padding: 4 }}>Open</div>,
              value: "open",
            },
            {
              label: <div style={{ padding: 4 }}>Eligible</div>,
              value: "eligible",
            },
            {
              label: <div style={{ padding: 4 }}>Pending</div>,
              value: "pending",
            },
            {
              label: <div style={{ padding: 4 }}>Closed</div>,
              value: "closed",
            },
          ]}
        />
      </div>
      <div className={styles.tab_container}>
        <Segmented
          defaultValue={"referredABusiness"}
          onChange={handleTypeTabChange}
          options={[
            {
              label: <div style={{ padding: 4 }}>Referred</div>,
              value: "referredABusiness",
            },
            {
              label: <div style={{ padding: 4 }}>Was Referred </div>,
              value: "referredByBusiness",
            },
            {
              label: <div style={{ padding: 4 }}>First 500 Euros</div>,
              value: "first500EurosDone",
            },
          ]}
        />
      </div>
      <PromotionsTable></PromotionsTable>
    </div>
  );
}
