import { IBusinessPromotionStatus } from "../../../interfaces/businessPromotionStatus.interface";
import { IPromotion } from "../../../interfaces/promotion.interface";
import { IReferralPromotion } from "../../../interfaces/referralPromotion.interface";

export function removeManyPromotions(
  promotions: IPromotion[],
  promotionsToRemove: IPromotion[]
) {
  try {
    if (!promotions) return;
    const hashedOpenPromotions = promotions.reduce(
      (prev: any, cur: IPromotion) => {
        prev[cur._id] = cur;
        return prev;
      },
      {}
    );

    promotionsToRemove.forEach((p) => {
      if (hashedOpenPromotions[p._id]) delete hashedOpenPromotions[p._id];
    });

    return Object.values(hashedOpenPromotions) as IPromotion[];
  } catch (error) {
    console.log(error);
  }
}
