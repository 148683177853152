import { IPurchPriceProfile } from "../../../interfaces/partner.interface";

export const DEFAULT_PURCH_PRICE_PROFILE: IPurchPriceProfile = {
  name: "default",
  _id: "",
  bucketGroups: {
    domesticDebitCards: {
      value: 0.06,
      valueType: "Fixed",
      max: 0.1,
    },
    european: {
      value: 1.39,
      valueType: "Percentage",
      max: 1.89,
    },
    internationalAndCommercial: {
      value: 2.29,
      valueType: "Percentage",
      max: 2.79,
    },
  },

  // [
  //   {
  //     name: "Domestic Debit",
  //     value: 0.06,
  //     valueType: "Fixed",
  //     max: 1.75,
  //   },
  //   {
  //     name: "Domestic Credit",
  //     value: 1.39,
  //     valueType: "Percentage",
  //     max: 1.95,
  //   },
  //   {
  //     name: "Domestic Corporate Debit",
  //     value: 2.29,
  //     valueType: "Percentage",
  //     max: 2.8,
  //   },
  //   {
  //     name: "Domestic Corporate Credit",
  //     value: 2.29,
  //     valueType: "Percentage",
  //     max: 2.8,
  //   },
  //   {
  //     name: "European Debit",
  //     value: 1.39,
  //     valueType: "Percentage",
  //     max: 1.85,
  //   },
  //   {
  //     name: "European Credit",
  //     value: 1.39,
  //     valueType: "Percentage",
  //     max: 1.85,
  //   },
  //   {
  //     name: "European Corporate Credit",
  //     value: 2.29,
  //     valueType: "Percentage",
  //     max: 2.8,
  //   },
  //   {
  //     name: "European Corporate Debit",
  //     value: 2.29,
  //     valueType: "Percentage",
  //     max: 2.8,
  //   },
  //   {
  //     name: "International Debit",
  //     value: 2.29,
  //     valueType: "Percentage",
  //     max: 2.8,
  //   },
  //   {
  //     name: "International Credit",
  //     value: 2.29,
  //     valueType: "Percentage",
  //     max: 2.8,
  //   },
  //   {
  //     name: "International Corporate Debit",
  //     value: 2.29,
  //     valueType: "Percentage",
  //     max: 2.8,
  //   },
  //   {
  //     name: "International Corporate Credit",
  //     value: 2.29,
  //     valueType: "Percentage",
  //     max: 2.8,
  //   },
  // ],
};
