import React, { useEffect, useState } from "react";
import {
  IPartnerDetails,
  PartnerType,
} from "../../../../interfaces/partner.interface";
import { Button, Form, Input, InputNumber, Select, Space } from "antd";
import styles from "./partnerTypeForm.module.css";
import { useAppSelector } from "../../../../app/hooks";
import { selectSoftwareVendors } from "../../partnersSlice";
const partnerFormFieldLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
interface IPropsPartnerTypeForm {
  onSubmit: (values: {
    partnerType: PartnerType;
    softwareVendorId?: string;
  }) => any;
  partnerType?: PartnerType;
  softwareVendorId?: string;
  loading?: boolean;
}
export default function PartnerTypeForm({
  partnerType,
  softwareVendorId,
  onSubmit,
  loading,
}: IPropsPartnerTypeForm) {
  const [form] = Form.useForm();
  const softwareVendors = useAppSelector(selectSoftwareVendors);

  useEffect(() => {
    form.resetFields();
    populateForm();
  }, []);
  const populateForm = () => {
    if (!partnerType) return;

    form.setFieldsValue({
      partnerType: partnerType,
      softwareVendorId: softwareVendorId,
    });
  };
  const [currentPartnerTypeSelected, setCurrentPartnerTypeSelected] =
    useState(partnerType);

  const handlePartnerTypeSelection = (partnerType: PartnerType) => {
    setCurrentPartnerTypeSelected(partnerType);
  };

  return (
    <div>
      <Form
        onFinish={onSubmit}
        form={form}
        // labelCol={{ ...partnerFormFieldLayout.labelCol }}
        // wrapperCol={{ ...partnerFormFieldLayout.wrapperCol }}
      >
        <Form.Item
          name="partnerType"
          label="Partner Type"
          labelCol={{ ...partnerFormFieldLayout.labelCol }}
          labelAlign="left"
          rules={[
            {
              required: true,
              message: "Missing Partner Type",
            },
          ]}
        >
          <Select
            style={{
              minWidth: "250px",
              alignSelf: "start",
            }}
            onChange={handlePartnerTypeSelection}
            options={[
              { value: PartnerType.Reseller, label: "Reseller" },
              {
                value: PartnerType.SoftwareVendor,
                label: "Software Vendor",
              },
              {
                value: PartnerType.FullServicePartner,
                label: "Full Service Partner",
              },
              {
                value: PartnerType.FullServiceWithResellers,
                label: "Full Service Partner with Resellers",
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          name="softwareVendorId"
          label="Software Vendor"
          labelCol={{ ...partnerFormFieldLayout.labelCol }}
          labelAlign="left"
          hidden={currentPartnerTypeSelected !== PartnerType.Reseller}
          // rules={[
          //   {
          //     required:
          //       currentPartnerTypeSelected === PartnerType.Reseller
          //         ? true
          //         : false,
          //     message: "A reseller must be associated to a software vendor.",
          //   },
          // ]}
        >
          <Select
            style={{
              minWidth: "250px",
              alignSelf: "start",
            }}
            // onChange={handleSoftwareVendorSelection}
            options={softwareVendors?.map((vendor) => ({
              value: vendor._id,
              label: vendor.name,
            }))}
          />
        </Form.Item>

        <Form.Item>
          <Button
            style={{ float: "right" }}
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            OK
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
