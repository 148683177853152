import { Button, Checkbox, Form, Input, message } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import ErrorAlerter from "../errors/ErrorAlerter";
import {
  respondToSignIn,
  selectIdToken,
  selectMFASetupComplete,
  selectMFASetupQrcode,
  selectSessionToken,
  selectUserAccessLevel,
  signIn,
  verifySoftwareToken,
} from "./accountSlice";

export default function AccessControl() {
  const session = useAppSelector(selectSessionToken);
  // const idToken = useAppSelector(selectIdToken);
  const userAccessLevel = useAppSelector(selectUserAccessLevel);
  const mfaSetupComplete = useAppSelector(selectMFASetupComplete);
  const qrcode = useAppSelector(selectMFASetupQrcode);
  const [email, setEmail] = useState("");
  const [signInLoading, setSignInLoading] = useState(false);
  const [confirmCodeLoading, setConfirmCodeLoading] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const refreshToken = sessionStorage.getItem("refreshToken");
    console.log("LOG IN PAGE");
    if ((userAccessLevel && userAccessLevel.length > 0) || refreshToken)
      navigate("/home");

    return () => {};
  }, [userAccessLevel, qrcode]);

  const onSignInFinish = (values: any) => {
    const { email, password } = values;
    setEmail(email);
    setSignInLoading(true);
    dispatch(signIn({ email, password }))
      .then((result) => {
        if ((result?.payload as any).qrcode) navigate("/mfaSetup");
      })
      .finally(() => setSignInLoading(false));
  };

  const onSigninFailed = (errorInfo: any) => {};

  const onConfirmationCodeFinish = (values: any) => {
    const { confirmationCode } = values;
    if (session) {
      setConfirmCodeLoading(true);
      dispatch(
        respondToSignIn({ confirmationCode, session, username: email })
      ).finally(() => setConfirmCodeLoading(false));
    }
  };

  return (
    <div>
      {!session && (
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onSignInFinish}
          onFinishFailed={onSigninFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "Please input your email!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password />
          </Form.Item>

          {/* <Form.Item
            name="remember"
            valuePropName="checked"
            wrapperCol={{ offset: 8, span: 16 }}
          >
            <Checkbox>Remember me</Checkbox>
          </Form.Item> */}

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit" loading={signInLoading}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      )}

      {session && !qrcode && (
        <Form onFinish={onConfirmationCodeFinish}>
          <Form.Item
            label="Confirmation Code"
            name="confirmationCode"
            rules={[
              {
                required: true,
                message: "Please enter your confirmationCode!",
              },
            ]}
          >
            <Input aria-autocomplete="none" autoComplete="off" />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={confirmCodeLoading}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
}
