import React from "react";
import Notifications from "../../features/notifications/Notifications";

export default function NotificationsPage() {
  return (
    <div>
      <Notifications></Notifications>
    </div>
  );
}
