import { Table, Space, Button, Input } from "antd";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import styles from "./BucketDealTable.module.css";
import {
  deleteMultBucketDealThunk,
  emptyBucketDealsTable,
  getAllBucketDealsThunk,
  selectLastUpdateKey,
  setCurrentBucketDeal,
} from "../../bucketDealsSlice";
import BucketDealDetailsModal from "../Bucket-Deal-Details-Modal/BucketDealDetails";
import BucketDealEditorModal from "../Bucket-Deal-Editor-Modal/BucketDealEditor";
import {
  PlusCircleOutlined,
  MinusCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Empty } from "antd";
import Loader from "../../../common/loader/Loader";
import { IBucketDeal } from "../../../../interfaces/bucketDeal.interface";
import BucketDealCreatorModal from "../Bucket-Deal-Creator-Modal/BucketDealCreator";
import { ColumnsType, TablePaginationConfig } from "antd/lib/table";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import { IPagination } from "../../../../interfaces/pagination.interface";
import { TableParams } from "../../../../interfaces/tableParams.interface";

const { Column, ColumnGroup } = Table;

const MOCK_DEV_BUCKETS = [
  {
    name: "Domestic debit cards",
    value: 0.5,
    valueType: "Percentage",
  },
  {
    name: "Domestic credit cards",
    value: 0.5,
    valueType: "Percentage",
  },
  {
    name: "Foreign debit cards",
    value: 0.5,
    valueType: "Percentage" as "Percentage",
  },
  {
    name: "Foreign credit cards",
    value: 0.5,
    valueType: "Percentage" as "Percentage",
  },
];
const DEFAULT_PRICING_BUCKETS =
  process.env.REACT_APP_STAGE === "prod"
    ? [
        {
          name: "Domestic Debit",
          value: 1,
          valueType: "Percentage",
        },
        {
          name: "Domestic Credit",
          value: 1,
          valueType: "Percentage",
        },
        {
          name: "Domestic Corporate Credit",
          value: 1,
          valueType: "Percentage",
        },
        {
          name: "Domestic Corporate Debit",
          value: 1,
          valueType: "Percentage",
        },
        {
          name: "European Debit",
          value: 1,
          valueType: "Percentage",
        },
        {
          name: "European Credit",
          value: 1,
          valueType: "Percentage",
        },
        {
          name: "European Corporate Credit",
          value: 1,
          valueType: "Percentage",
        },
        {
          name: "European Corporate Debit",
          value: 1,
          valueType: "Percentage",
        },
        {
          name: "International Debit",
          value: 1,
          valueType: "Percentage",
        },
        {
          name: "International Credit",
          value: 1,
          valueType: "Percentage",
        },
        {
          name: "International Corporate Credit",
          value: 1,
          valueType: "Percentage",
        },
        {
          name: "International Corporate Debit",
          value: 1,
          valueType: "Percentage",
        },
      ]
    : MOCK_DEV_BUCKETS;
export default function BucketDealTable() {
  const emptyBucketDeal = {
    _id: "",
    buckets: DEFAULT_PRICING_BUCKETS,
    businessRegistrationNumber: "",
    klearlyEmployee: "",
    businessName: "",
  } as IBucketDeal;
  const [isDetailModalVisible, setIsDetailModalVisible] = useState(false);
  const [isEditorModalVisible, setIsEditorModalVisible] = useState(false);
  const [isCreatorModalVisible, setIsCreatorModalVisible] = useState(false);
  const [selectedBuckets, setSelectedBuckets] = useState<IBucketDeal[]>([]);
  const [loading, setLoading] = useState(false);
  const [bucketDealsInTable, setBucketDealsInTable] = useState([]);
  const lastUpdateKey = useAppSelector(selectLastUpdateKey);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [filters, setFilters] = useState<Partial<IBucketDeal>>({
    businessRegistrationNumber: "",
    businessName: "",
  });

  const dispatch = useAppDispatch();

  const handleNameFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value.toLowerCase();
    // dispatch(filterTableByName(name));
    setFilters((prev) => ({ ...prev, businessName: name }));
  };
  const handleKVKFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    const kvk = event.target.value.toLowerCase();
    setFilters((prev) => ({ ...prev, businessRegistrationNumber: kvk }));
  };

  const fetchAllBucketDeals = async (params: {
    pagination: IPagination;
    filters: Partial<IBucketDeal>;
  }) => {
    setLoading(true);
    await dispatch(
      getAllBucketDealsThunk({
        currentPage: params?.pagination?.current!,
        pageSize: params?.pagination?.pageSize!,
        ...filters,
      })
    )
      .then(({ payload }) => {
        //@ts-ignore
        let bucketDeals = payload.data;
        //@ts-ignore
        let totalCount = payload.totalCount;
        let bucketDealsWithKeys = bucketDeals.map((b: IBucketDeal) => ({
          ...b,
          key: b._id,
        }));
        setBucketDealsInTable(bucketDealsWithKeys);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: totalCount,
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchAllBucketDeals({
      pagination: {
        current: tableParams.pagination?.current || 1,
        pageSize: tableParams.pagination?.pageSize!,
      },
      filters,
    });
  }, [JSON.stringify(tableParams), JSON.stringify(filters), lastUpdateKey]);

  const columns: ColumnsType<IBucketDeal> = [
    {
      title: "KVK",
      dataIndex: "businessRegistrationNumber",
      key: "businessRegistrationNumber",
    },
    {
      title: "Name",
      dataIndex: "businessName",
      key: "name",
    },
    {
      title: "",
      key: "details",
      render: (_: any, record: IBucketDeal) => (
        <Space size="middle" key={record._id}>
          <Button
            type="primary"
            shape="round"
            onClick={() => {
              showBucketDealDetailsModal(record);
            }}
          >
            View Details
          </Button>
          <Button
            type="primary"
            onClick={() => {
              showEditorBucketDealsModalEdit(record);
            }}
          >
            Edit
          </Button>
        </Space>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: IBucketDeal[]) => {
      setSelectedBuckets(selectedRows);
    },
    getCheckboxProps: (record: IBucketDeal) => ({
      name: record.businessRegistrationNumber,
    }),
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
  };

  const deleteSelectedBuckets = async () => {
    let idStringList = "";
    selectedBuckets.forEach(
      (b, index) => (idStringList += (index === 0 ? "" : ",") + b._id)
    );

    setLoading(true);
    await dispatch(deleteMultBucketDealThunk({ _ids: idStringList }));
    setLoading(false);
  };

  const showBucketDealDetailsModal = (bucketDeal: IBucketDeal) => {
    dispatch(setCurrentBucketDeal(bucketDeal));
    setIsDetailModalVisible(true);
  };
  const showCreatorBucketDealsModalCreate = () => {
    dispatch(setCurrentBucketDeal(emptyBucketDeal));
    setIsCreatorModalVisible(true);
  };
  const showEditorBucketDealsModalEdit = async (bucketDeal: IBucketDeal) => {
    dispatch(setCurrentBucketDeal(bucketDeal));
    setIsEditorModalVisible(true);
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue>,
    sorter: SorterResult<IBucketDeal>
  ) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      dispatch(emptyBucketDealsTable());
    }
  };
  return (
    <div className={styles.container} key="divTable">
      <div className={styles.button_container}>
        <Button
          type="primary"
          icon={<PlusCircleOutlined />}
          onClick={() => {
            showCreatorBucketDealsModalCreate();
            // showEditorBucketDealsModalEdit(emptyBucketDeal);
          }}
        >
          New
        </Button>
        <Button
          icon={<MinusCircleOutlined />}
          danger
          disabled={selectedBuckets?.length <= 0}
          onClick={() => {
            deleteSelectedBuckets();
            // showEditorBucketDealsModalEdit(emptyBucketDeal);
          }}
        >
          Delete
        </Button>
      </div>

      <div className={styles.table_container}>
        {
          <Input
            onChange={handleNameFilterChange}
            className={styles.searchInput}
            placeholder="Business Name"
            value={filters.businessName}
            prefix={<SearchOutlined />}
          />
        }
        {
          <Input
            onChange={handleKVKFilterChange}
            className={styles.searchInput}
            placeholder="KVK Number"
            prefix={<SearchOutlined />}
          />
        }

        {bucketDealsInTable.length === 0 && !loading && (
          <div style={{ height: "300px" }} className="center">
            <Empty></Empty>
          </div>
        )}
        {bucketDealsInTable.length > 0 && !loading && (
          <Table
            columns={columns}
            rowSelection={{
              type: "checkbox",
              ...rowSelection,
            }}
            dataSource={bucketDealsInTable}
            loading={loading}
            pagination={tableParams.pagination}
            //@ts-ignore
            onChange={handleTableChange}
          >
            <Column
              title="KVK"
              dataIndex="businessRegistrationNumber"
              key="businessRegistrationNumber"
            />
            <Column title="Name" dataIndex="businessName" key="name" />

            <Column
              title=""
              key="details"
              render={(_: any, record: IBucketDeal) => (
                <Space size="middle" key={record._id}>
                  <Button
                    type="primary"
                    shape="round"
                    onClick={() => {
                      showBucketDealDetailsModal(record);
                    }}
                  >
                    View Details
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => {
                      showEditorBucketDealsModalEdit(record);
                    }}
                  >
                    Edit
                  </Button>
                </Space>
              )}
            />
          </Table>
        )}
      </div>

      {loading && <Loader text="Fetching bucket deals from server"></Loader>}
      <BucketDealDetailsModal
        visible={isDetailModalVisible}
        setVisible={setIsDetailModalVisible}
      ></BucketDealDetailsModal>
      <BucketDealEditorModal
        visible={isEditorModalVisible}
        setVisible={setIsEditorModalVisible}
      ></BucketDealEditorModal>
      <BucketDealCreatorModal
        visible={isCreatorModalVisible}
        setVisible={setIsCreatorModalVisible}
      ></BucketDealCreatorModal>
    </div>
  );
}
