import React, { useEffect, useState } from "react";
import PartnerDealsTable from "./components/partner-deals-table/PartnerDealsTable";
import { Button } from "antd";
// import CreatePartnerForm from "./components/create-partner-deals-form";
import GeneralTableHeader from "../common/general-table-header/GeneralTableHeader";
import { PlusCircleOutlined } from "@ant-design/icons";
import styles from "./PartnerDeals.module.css";
import CreatePartnerDealsForm from "./components/create-partner-deals-form/CreatePartnerDealsForm";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  selectIsKlearlyAdmin,
  selectUserPartnerId,
} from "../access-control/accountSlice";
import { getPartnerThunk } from "../partners/partnersSlice";
import InviteMerchantLink from "./components/invite-merchant-link/InviteMerchantLink";

export default function PartnerDeals() {
  const [isCreatePartnerFormVisible, setIsCreatePartnerFormVisible] =
    useState(false);
  const showCreatePartnerFormModal = () => {
    setIsCreatePartnerFormVisible(true);
  };

  const partnerId = useAppSelector(selectUserPartnerId);
  const isKlearlyAdmin = useAppSelector(selectIsKlearlyAdmin);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (partnerId) {
      fetchPartner(partnerId);
    }
  }, []);

  const fetchPartner = async (partnerId: string) => {
    await dispatch(getPartnerThunk(partnerId));
  };

  return (
    <div className={styles.container}>
      {!isKlearlyAdmin}
      {<InviteMerchantLink />}
      <GeneralTableHeader
        title="Custom Merchant Deals"
        description=" Create new merchants and set pricing for them. You can list future
        merchants here, as merchants will not be notified or contacted."
        actionButtons={[
          <Button
            type="primary"
            onClick={showCreatePartnerFormModal}
            icon={<PlusCircleOutlined></PlusCircleOutlined>}
            size={"middle"}
          >
            Create Custom
          </Button>,
        ]}
      />
      <PartnerDealsTable />
      <CreatePartnerDealsForm
        visible={isCreatePartnerFormVisible}
        setVisible={setIsCreatePartnerFormVisible}
      ></CreatePartnerDealsForm>
      {/* <CreatePartnerForm
        visible={isCreatePartnerFormVisible}
        setVisible={setIsCreatePartnerFormVisible}
      /> */}
    </div>
  );
}
