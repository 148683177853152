import React from "react";
import TransactionTagsList from "../../features/transaction-tags/components/transaction-tags-list/TransactionTagsList";
import styles from "./TransactionTagsEditorPage.module.css";
export default function TransactionTagsEditorPage() {
  return (
    <div className={styles.container}>
      <TransactionTagsList />
    </div>
  );
}
