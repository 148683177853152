import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

import { IQueryFilters } from "../../interfaces/queryFilters.interface";
import { IBusinessFullQuery } from "../../interfaces/businessFull.interface";
import {
  coupleMerchantToPaxTerminalServer,
  ICoupleMerchantToPaxTerminalRequest,
  getTerminalsServer,
  IGetTerminalsServerRequest,
  validateCouplingServer,
  IValidateCouplingServerRequest,
} from "./TerminalsApi";

import { CoupleTerminalErrorCode } from "../../interfaces/coupleTerminalErrors";
import { IPaxTerminal } from "../../interfaces/paxTerminal.interface";

export interface TerminalsState {
  recentlyCoupledTerminals: IPaxTerminal[];
  recentlyCoupledTerminalsCount: number;
  businessTerminals: IPaxTerminal[];
  businessTerminalsCount: number;
}

const initialState: TerminalsState = {
  recentlyCoupledTerminals: [],
  recentlyCoupledTerminalsCount: 0,
  businessTerminals: [],
  businessTerminalsCount: 0,
};

export const getTerminalsThunk = createAsyncThunk(
  "terminals/",
  async (params: IGetTerminalsServerRequest) => {
    const results = await getTerminalsServer(params);
    return results;
  }
);

export const getRecentlyCoupledTerminalsThunk = createAsyncThunk(
  "terminals/recentlyCoupled",
  async (params: IGetTerminalsServerRequest) => {
    let coupledOnStart = new Date();
    coupledOnStart.setDate(coupledOnStart.getDate() - 10);

    params.coupledOnStart = coupledOnStart.toISOString();

    const results = await getTerminalsServer(params);

    return results;
  }
);
export const getBusinessTerminalsThunk = createAsyncThunk(
  "terminals/business",
  async (params: IGetTerminalsServerRequest) => {
    const results = await getTerminalsServer(params);

    return results;
  }
);

export const coupleMerchantToPaxTerminalThunk = createAsyncThunk(
  "terminals/couple",
  async (params: ICoupleMerchantToPaxTerminalRequest) => {
    const results = await coupleMerchantToPaxTerminalServer(params);
    return results;
  }
);

export const validateCouplingThunk = createAsyncThunk(
  "terminals/validateCoupling",
  async (params: IValidateCouplingServerRequest) => {
    const results = await validateCouplingServer(params);
    return results;
  }
);

export const terminalsSlice = createSlice({
  name: "terminals",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // .addCase(coupleMerchantToPaxTerminalThunk.rejected, (state, action) => {
      //   state.coupleTerminalErrorCode = action.error
      //     .code as CoupleTerminalErrorCode;
      // })
      // .addCase(coupleMerchantToPaxTerminalThunk.rejected, (state, action) => {
      //   state.coupleTerminalErrorCode = action.error
      //     .code as CoupleTerminalErrorCode;
      // })
      .addCase(getRecentlyCoupledTerminalsThunk.fulfilled, (state, action) => {
        state.recentlyCoupledTerminals = action.payload.data;
        state.recentlyCoupledTerminalsCount = action.payload.totalCount;
      })
      .addCase(getBusinessTerminalsThunk.fulfilled, (state, action) => {
        state.businessTerminals = action.payload.data;
        state.businessTerminalsCount = action.payload.totalCount;
      });
  },
});

export const selectRecentlyCoupledTerminal = (state: RootState) =>
  state.terminals.recentlyCoupledTerminals;
export const selectRecentlyCoupledTerminalCount = (state: RootState) =>
  state.terminals.recentlyCoupledTerminalsCount;
export const selectBusinessTerminals = (state: RootState) =>
  state.terminals.businessTerminals;
export const selectBusinessTerminalsCount = (state: RootState) =>
  state.terminals.businessTerminalsCount;

export default terminalsSlice.reducer;
