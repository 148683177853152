import { useEffect } from "react";
import { IPurchPriceProfile } from "../../../../interfaces/partner.interface";
import { Button, Form, Input, Select, Typography } from "antd";
import styles from "./PurchPriceProfileForm.module.css";
import BucketGroupPriceFormItems from "../../../common/bucket-group-price-form-items/BucketGroupPriceFormItems";
import { useAppSelector } from "../../../../app/hooks";
import { selectCurrentPartner } from "../../partnersSlice";
const { Title, Text } = Typography;
interface IPropsPurchPriceProfileForm {
  initialProfile: IPurchPriceProfile;
  onSubmit: (value: IPurchPriceProfile) => any;
  hideNameField?: boolean;
  hideWhitelistField?: boolean;
  removeIdField?: boolean;
  loading?: boolean;
}
export default function PurchPriceProfileForm({
  initialProfile,
  onSubmit,
  hideNameField = false,
  removeIdField = false,
  hideWhitelistField = false,
  loading = false,
}: IPropsPurchPriceProfileForm) {
  const [form] = Form.useForm();
  useEffect(() => {
    form.resetFields();
    populateForm();
  }, [initialProfile]);
  const populateForm = () => {
    if (!initialProfile) return;
    // initialProfile.buckets[0].value = 0.023;
    form.setFieldsValue({
      name: initialProfile?.name,
      whitelist: initialProfile?.whitelist || [],
      bucketGroups: initialProfile.bucketGroups,
      _id: initialProfile?._id,
    });
  };

  return (
    <div className={styles.container}>
      <Form onFinish={onSubmit} form={form}>
        <div className={styles.bucketContainer}>
          {!removeIdField && (
            <Form.Item label="_id" name={"_id"} hidden={true}>
              <Input />
            </Form.Item>
          )}

          <Form.Item
            label="Whitelist"
            name={"whitelist"}
            hidden={hideWhitelistField}
          >
            <Select
              mode="tags"
              placeholder="Whitelisted kvk numbers..."
              key={"whitelisted-kvk"}
              // onChange={handleChange}
              options={initialProfile?.whitelist?.map((kvk, i) => ({
                label: kvk,
                value: kvk,
                key: `${kvk} ${i}`,
              }))}
            />
          </Form.Item>

          <Form.Item
            label="Name"
            name={"name"}
            hidden={hideNameField}
            required
            rules={[
              {
                required: true,
                message: "Please name the purchase price profile.",
              },
              { min: 1, message: "Name must be at least 1 char." },
            ]}
          >
            <Input style={{ width: "200px" }} />
          </Form.Item>

          <BucketGroupPriceFormItems
            formItemName="domesticDebitCards"
            label="Domestic Debit Cards"
          />
          <BucketGroupPriceFormItems
            formItemName="european"
            label="E.E.A Cards"
          />
          <BucketGroupPriceFormItems
            formItemName="internationalAndCommercial"
            label="Non E.E.A & Corporate Cards"
          />
        </div>
        <Form.Item>
          <Button
            style={{ float: "right" }}
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            OK
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

{
  /* 
          <Form.List name={"buckets"}>
            {(fields) => (
              <div>
                {fields.map(({ key, name, ...restField }) => (
                  <Space key={key} align="baseline">
                    <Form.Item {...restField} name={[name, "name"]}>
                      <Input
                        className={styles.bucket_name_input}
                        disabled
                      ></Input>
                    </Form.Item>

                    <Form.Item {...restField} name={[name, "value"]}>
                      <InputNumber
                        controls={false}
                        className={styles.bucket_value_input}
                        min={0}
                      />
                    </Form.Item>
                    <Form.Item {...restField} name={[name, "valueType"]}>
                      <Select
                        className={styles.bucket_value_input}
                        style={{ width: "85px" }}
                      >
                        <Select.Option value={"Percentage"}>%</Select.Option>
                        <Select.Option value={"Fixed"}>FIXED</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item {...restField} name={[name, "max"]}>
                      <InputNumber
                        controls={false}
                        className={styles.bucket_value_input}
                        min={0}
                        max={3}
                      />
                    </Form.Item>
                  </Space>
                ))}
              </div>
            )}
          </Form.List>  */
}
