import { Button, Card, Divider, Form, Input } from "antd";
import { CheckCircleOutlined, UserOutlined } from "@ant-design/icons";
import IbanChecker from "./IbanChecker";

export default function BankAccountEditor() {
  const bankFieldLayout = {
    labelCol: { span: 12 },
    wrapperCol: { span: 12 },
  };
  return (
    <>
      <Form.Item
        label="IBAN"
        name="bankAccountIban"
        wrapperCol={{ ...bankFieldLayout.wrapperCol }}
        labelCol={{ ...bankFieldLayout.labelCol }}
        labelAlign="left"
      >
        <Input />
      </Form.Item>
      <IbanChecker />
      <Form.Item
        label="Bank Name"
        name="bankName"
        wrapperCol={{ ...bankFieldLayout.wrapperCol }}
        labelCol={{ ...bankFieldLayout.labelCol }}
        labelAlign="left"
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Account Number"
        name="bankAccountNumber"
        wrapperCol={{ ...bankFieldLayout.wrapperCol }}
        labelCol={{ ...bankFieldLayout.labelCol }}
        labelAlign="left"
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="BIC"
        name="bic"
        wrapperCol={{ ...bankFieldLayout.wrapperCol }}
        labelCol={{ ...bankFieldLayout.labelCol }}
        labelAlign="left"
      >
        <Input />
      </Form.Item>

      <Divider plain>Address</Divider>
      <Form.Item
        label="Bank Address Street"
        name={["bankAddress", "street"]}
        wrapperCol={{ ...bankFieldLayout.wrapperCol }}
        labelCol={{ ...bankFieldLayout.labelCol }}
        labelAlign="left"
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Bank Address City"
        name={["bankAddress", "city"]}
        wrapperCol={{ ...bankFieldLayout.wrapperCol }}
        labelCol={{ ...bankFieldLayout.labelCol }}
        labelAlign="left"
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Bank Address Postcode"
        name={["bankAddress", "postcode"]}
        wrapperCol={{ ...bankFieldLayout.wrapperCol }}
        labelCol={{ ...bankFieldLayout.labelCol }}
        labelAlign="left"
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Bank Address Country"
        name={["bankAddress", "country"]}
        wrapperCol={{ ...bankFieldLayout.wrapperCol }}
        labelCol={{ ...bankFieldLayout.labelCol }}
        labelAlign="left"
      >
        <Input />
      </Form.Item>
    </>
  );
}
