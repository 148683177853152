export interface IBusinessFile {
  file: string;
  fileName: string;
  fileExtension: "PDF" | "CSV" | "JSON";
  type: BusinessFileType;
}

export enum BusinessFileType {
  COMPANY_INFO_FILE = "COMPANY_INFO",
  TMA_FILE = "TMA_FILE",
  TMA_METADATA_FILE = "TMA_METADATA_FILE",
  BANK_CONFIRMATION_FILE = "BANK_CONFIRMATION_FILE",
}
