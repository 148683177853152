import {
  Button,
  Form,
  Input,
  List,
  Modal,
  Popconfirm,
  Select,
  Table,
  Tabs,
  Tag,
} from "antd";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  selectCurrentBusiness,
  selectFilters,
  selectLastUpdateKey,
} from "../../../businesses/businessesSlice";
import styles from "./BusinessTerminalsList.module.css";
import { CoupleTerminalErrorCode } from "../../../../interfaces/coupleTerminalErrors";
import { coupleMerchantToPaxTerminalServer } from "../../TerminalsApi";
import {
  coupleMerchantToPaxTerminalThunk,
  getBusinessTerminalsThunk,
  selectBusinessTerminalsCount,
} from "../../TerminalsSlice";
import {
  CheckCircleFilled,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import {
  CouplingStatus,
  IPaxTerminal,
  IPaxTerminalQuery,
  IPopulatedPaxTerminal,
} from "../../../../interfaces/paxTerminal.interface";
import {
  IPagination,
  TableParams,
} from "../../../../interfaces/tableParams.interface";
import Column from "antd/lib/table/Column";
import { formatDate } from "../../../../utils/formatDate";
import Loader from "../../../common/loader/Loader";
interface IPropsBusinessTerminalsList {
  visible: boolean;
  setVisible: any;
}

export default function BusinessTerminalsList({
  visible,
  setVisible,
}: IPropsBusinessTerminalsList) {
  const currentBusiness = useAppSelector(selectCurrentBusiness);
  const [loading, setLoading] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const lastUpdateKey = useAppSelector(selectLastUpdateKey);
  const filters = useAppSelector(selectFilters);
  const [businessTerminalsList, setBusinessTerminalsList] = useState<
    IPaxTerminal[]
  >([]);

  const totalCount = useAppSelector(selectBusinessTerminalsCount);
  const initialPagination = { current: 1, pageSize: 10 };
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: { ...initialPagination },
  });
  const dispatch = useAppDispatch();

  const fetchBusinessTerminalsList = async (params: {
    pagination: IPagination;
    filters: Partial<IPaxTerminalQuery>;
  }) => {
    setLoading(true);
    await dispatch(
      getBusinessTerminalsThunk({
        currentPage: params?.pagination?.current!,
        pageSize: params?.pagination?.pageSize!,
        businessId: currentBusiness._id,
        ...filters,
      })
    )
      .then(({ payload }) => {
        //@ts-ignore
        let businessTerminals = payload.data;
        //@ts-ignore
        let totalCount = payload.totalCount;
        let businessTerminalsWithKeys = businessTerminals.map(
          (t: IPaxTerminal) => ({
            ...t,
            key: t?._id,
          })
        );
        setBusinessTerminalsList(businessTerminalsWithKeys);

        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: totalCount,
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchBusinessTerminalsList({
      pagination: {
        current: tableParams.pagination?.current || 1,
        pageSize: tableParams.pagination?.pageSize!,
      },
      filters: {},
    });
  }, [currentBusiness]);

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      visible={visible}
      title={
        <span style={{ opacity: 0.9 }}>
          {"Terminals of " + currentBusiness?.businessName}
        </span>
      }
      onCancel={handleCancel}
      footer={null}
      width={650}
    >
      <div className={styles.container}>
        {!loading && (
          <Table
            dataSource={businessTerminalsList}
            pagination={tableParams.pagination}
            loading={loading}

            //@ts-ignore
            // onChange={handleTableChange}
          >
            <Column
              title="Serial Number"
              className={styles.table_business_column}
              key="serialNumber"
              render={(_: any, record: IPaxTerminal) => (
                <div className={styles.business_details}>
                  <span key={record._id + "serialNumber"}>
                    {record.serialNumber}
                  </span>
                  {/* <span
                    style={{ color: "gray" }}
                    key={record._id + "businessRegistrationNumber"}
                  >
                    {record.businessRegistrationNumber}
                  </span> */}
                </div>
              )}
            />

            <Column
              title="Physical TID"
              key="physicalTid"
              className={styles.table_column}
              render={(_: any, record: IPopulatedPaxTerminal) => (
                <span key={record._id + "physicalTid"}>
                  {record.physicalTid}
                </span>
              )}
            />
            <Column
              title="Push Id"
              key="lastPushId"
              className={styles.table_column}
              render={(_: any, record: IPopulatedPaxTerminal) => (
                <span key={record._id + "lastPushId"}>{record.lastPushId}</span>
              )}
            />
            <Column
              title="Coupling Date"
              key="couplingDate"
              className={styles.table_column}
              render={(_: any, record: IPopulatedPaxTerminal) => (
                <span key={record._id + "couplingDate"}>
                  {formatDate(new Date(record.coupledOn), true)}
                </span>
              )}
            />

            <Column
              title="Status"
              key="status"
              className={styles.table_column}
              render={(_: any, record: IPopulatedPaxTerminal) => (
                <div key={record._id + "status"}>
                  <Tag
                    color={
                      record.couplingStatus === CouplingStatus.coupled
                        ? "green"
                        : record.couplingStatus === CouplingStatus.pending
                        ? "orange"
                        : "red"
                    }
                  >
                    {record.couplingStatus}
                  </Tag>
                </div>
              )}
            />
          </Table>
        )}

        {loading && <Loader text="Fetching terminal list." />}
      </div>
    </Modal>
  );
}
