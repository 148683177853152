import {
  Button,
  DatePicker,
  Form,
  FormInstance,
  Input,
  Select,
  Space,
  Upload,
  UploadProps,
} from "antd";
import { BusinessPerson } from "../../../../../interfaces/businessFull.interface";
import {
  PlusOutlined,
  MinusCircleOutlined,
  UploadOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import styles from "../BusinessEditor.module.css";
import { Option } from "antd/lib/mentions";
import { getImageFromServer, uploadImageServer } from "../../../businessesApi";
import { RcFile } from "antd/lib/upload";
const dateFormat = "DD/MM/YYYY";
const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
const handlePersonsValidation = (persons: BusinessPerson[]) => {
  let index = 0;
  let field: string | undefined = undefined;
  let keys = [
    "firstName",
    "lastName",
    "dateOfBirth",
    "email",
    "phone",
    "roles",
    "address",
    "identificationAttachment",
  ];

  persons.forEach((p, i) => {
    keys.forEach((key) => {
      let isIdMissingForNonDirectorOrAuthorizedSignatory =
        key === "identificationAttachment" &&
        !p?.roles?.includes("PrimaryContact") &&
        (!p?.identificationAttachment?.fileName ||
          !p?.identificationAttachment?.bucketName);

      //@ts-ignore
      let isValueMissing = p && !p[key];
      if (!isValueMissing) return;
      if (isIdMissingForNonDirectorOrAuthorizedSignatory) return;

      if (
        (key === "phone" && p && !p?.phone?.countryCode) ||
        (p && !p?.phone?.number)
      ) {
        index = i;
        field = key;
        return;
      }
      index = i;
      field = key;
    });
  });
  return { index, field };
};

export default function PersonsEditor({ form }: { form: FormInstance }) {
  const handleUpload = async (props: UploadProps<any>, personIndex: number) => {
    //@ts-ignore
    const rcFile = props.fileList[0].originFileObj as RcFile;
    let base64Image = await getBase64(rcFile);

    const { fileName, bucketName } = await uploadImageServer({
      image: base64Image,
    });

    const curPersons = form.getFieldValue("persons");
    let updatedPersons = curPersons.map((p: BusinessPerson, i: number) =>
      i === personIndex
        ? { ...p, identificationAttachment: { fileName, bucketName } }
        : p
    );
    form.setFieldsValue({ persons: updatedPersons });
  };
  const handleImagePreview = async (personIndex: number) => {
    // let src = file.url as string;|
    const person = form?.getFieldValue("persons")[personIndex];
    if (!person) return;

    const fileName = person.identificationAttachment.fileName;
    const bucketName = person.identificationAttachment?.bucketName;

    const src = await getImageFromServer({
      fileName,
      bucketName,
    });

    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };
  const dummyRequest = ({ file, onSuccess }: any) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const DEFAULT_NULL_ADDRESS_VALUE = "";

  return (
    <>
      <Form.List
        name="persons"
        rules={[
          {
            validator: async (_, persons) => {
              if (!persons || persons.length < 1) return;
              const { index, field } = handlePersonsValidation(persons);

              if (index || field)
                return Promise.reject(
                  new Error(
                    `"Missing details for Person ${index + 1} at ${field}`
                  )
                );
            },
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Space
                key={key}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 30,
                }}
                align="baseline"
              >
                <h3>
                  Person {name + 1}{" "}
                  {fields.length >= 1 ? (
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  ) : null}
                </h3>
                <Space>
                  <label className={styles.custom_label}>First Name</label>
                  <Form.Item
                    {...restField}
                    name={[name, "firstName"]}
                    label="First Name"
                    noStyle
                    rules={[
                      {
                        required: true,
                        message: "Missing First Name",
                      },
                    ]}
                  >
                    <Input placeholder="First Name" />
                  </Form.Item>
                </Space>

                <Space>
                  <label className={styles.custom_label}>Last Name</label>
                  <Form.Item
                    {...restField}
                    name={[name, "lastName"]}
                    label="Last Name"
                    noStyle
                    rules={[
                      {
                        required: true,
                        message: "Missing Last Name",
                      },
                    ]}
                  >
                    <Input placeholder="Last Name" />
                  </Form.Item>{" "}
                </Space>
                <Space>
                  <label className={styles.custom_label}>Email</label>
                  <Form.Item
                    {...restField}
                    name={[name, "email"]}
                    noStyle
                    rules={[
                      {
                        required: true,
                        message: "Missing Email",
                      },
                    ]}
                  >
                    <Input placeholder="Email" />
                  </Form.Item>
                </Space>

                <Space>
                  <label className={styles.custom_label}>Roles</label>
                  <Form.Item
                    {...restField}
                    name={[name, "roles"]}
                    noStyle
                    rules={[
                      {
                        required: true,
                        message: "Missing Roles",
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      placeholder="Roles"
                      style={{ width: "178px" }}
                    >
                      <Option value="PrimaryContact">Primary Contact</Option>
                      <Option value="Director">Director</Option>
                      <Option value="AuthorizedSignatory">
                        Authorized Signatory
                      </Option>
                      <Option value="BoardOfDirectors">
                        Board Of Directors
                      </Option>
                      <Option value="BeneficialOwner">Beneficial Owner</Option>
                      <Option value="VotingControl">Voting Control</Option>
                      <Option value="PersonShareOwner">
                        Person Share Owner
                      </Option>
                    </Select>
                  </Form.Item>
                </Space>
                <Space>
                  <label className={styles.custom_label}>Date Of Birth</label>
                  <Form.Item
                    {...restField}
                    name={[name, "dateOfBirth"]}
                    label="Date Of Birth"
                    noStyle
                    rules={[
                      {
                        required: true,
                        message: "Missing Date Of Birth",
                      },
                    ]}
                  >
                    <DatePicker format={dateFormat} />
                    {/* <Input placeholder="Date Of Birth" /> */}
                  </Form.Item>
                </Space>
                <Space>
                  <label className={styles.custom_label}>Street</label>
                  <Form.Item
                    {...restField}
                    name={[name, "address", "street"]}
                    initialValue={DEFAULT_NULL_ADDRESS_VALUE}
                    noStyle
                    rules={[
                      {
                        required: true,
                        message: "Missing Street",
                      },
                    ]}
                  >
                    <Input placeholder="Street Name" />
                  </Form.Item>
                </Space>

                <Space>
                  <label className={styles.custom_label}>House Number</label>
                  <Form.Item
                    {...restField}
                    initialValue={DEFAULT_NULL_ADDRESS_VALUE}
                    name={[name, "address", "houseNumber"]}
                    noStyle
                    rules={[
                      {
                        required: true,
                        message: "Missing House Number",
                      },
                    ]}
                  >
                    <Input placeholder="House Number" />
                  </Form.Item>{" "}
                </Space>
                <Space>
                  <label className={styles.custom_label}>Postcode</label>
                  <Form.Item
                    {...restField}
                    initialValue={DEFAULT_NULL_ADDRESS_VALUE}
                    name={[name, "address", "postcode"]}
                    noStyle
                    rules={[
                      {
                        required: true,
                        message: "Missing Postcode",
                      },
                    ]}
                  >
                    <Input placeholder="Postcode" />
                  </Form.Item>{" "}
                </Space>
                <Space>
                  <label className={styles.custom_label}>City</label>
                  <Form.Item
                    {...restField}
                    initialValue={DEFAULT_NULL_ADDRESS_VALUE}
                    name={[name, "address", "city"]}
                    noStyle
                    rules={[
                      {
                        required: true,
                        message: "Missing City",
                      },
                    ]}
                  >
                    <Input placeholder="City" />
                  </Form.Item>
                </Space>
                <Space>
                  <label className={styles.custom_label}>Country</label>
                  <Form.Item
                    {...restField}
                    name={[name, "address", "country"]}
                    initialValue={"NL"}
                    noStyle
                    rules={[
                      {
                        required: true,
                        message: "Missing Country",
                      },
                    ]}
                  >
                    <Input placeholder="Country" />
                  </Form.Item>
                </Space>

                <Space>
                  <label className={styles.custom_label}>Phone</label>
                  <Form.Item {...restField} label="Phone" noStyle>
                    <Space size={0}>
                      <Form.Item
                        name={[name, "phone", "countryCode"]}
                        initialValue={"31"}
                        {...restField}
                        noStyle
                        rules={[
                          {
                            required: true,
                            message: "Missing Country Code",
                          },
                        ]}
                      >
                        <Input style={{ width: "80px" }} addonBefore="+" />
                      </Form.Item>
                      <Form.Item
                        name={[name, "phone", "number"]}
                        noStyle
                        {...restField}
                        rules={[
                          {
                            required: true,
                            message: "Missing Number",
                          },
                        ]}
                      >
                        <Input style={{ width: "120px" }} />
                      </Form.Item>
                    </Space>
                  </Form.Item>
                </Space>
                <Space>
                  <label className={styles.custom_label}>File</label>
                  <Upload
                    name="logo"
                    customRequest={dummyRequest}
                    listType="picture"
                    maxCount={1}
                    onChange={(props) => {
                      handleUpload(props, name);
                    }}
                  >
                    <Button type="primary" icon={<UploadOutlined />}>
                      Click & upload
                    </Button>
                  </Upload>
                  {form?.getFieldValue("persons")[name]
                    ?.identificationAttachment?.fileName && (
                    <Button
                      type="primary"
                      onClick={() => handleImagePreview(name)}
                    >
                      <EyeOutlined />
                    </Button>
                  )}
                  <Form.Item
                    {...restField}
                    name={[name, "identificationAttachment", "fileName"]}
                    hidden
                    noStyle
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "identificationAttachment", "bucketName"]}
                    hidden
                    noStyle
                  >
                    <Input />
                  </Form.Item>
                </Space>
              </Space>
            ))}
            <Form.Item>
              <Space>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add Person
                </Button>
              </Space>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        )}
      </Form.List>
    </>
  );
}
