import { Badge, Button, Descriptions, Divider, List, Modal, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { IBusinessFull } from "../../../../interfaces/businessFull.interface";
import Loader from "../../../common/loader/Loader";
import { selectCurrentBusiness } from "../../../businesses/businessesSlice";
import { useAppSelector } from "../../../../app/hooks";
import BusinessDevices from "../../../businesses/components/Business-Details-Modal/components/BusinessDevices";
interface IPropsBusinessModel {
  visible: boolean;
  setVisible: any;
}

export default function PartnerBusinessDetailsModal({
  visible,
  setVisible,
}: IPropsBusinessModel) {
  const [loading, setLoading] = useState(false);

  const business = useAppSelector(selectCurrentBusiness);
  const [currentBusiness, setCurrentBusiness] =
    useState<Partial<IBusinessFull>>();

  useEffect(() => {
    if (business) {
      setCurrentBusiness(business);
    }
  }, [business]);

  const getFullDate = (date?: string): string => {
    if (!date) return "";
    const dateAndTime = date.split("T");
    return dateAndTime[0].split("-").reverse().join("-");
  };

  const MissingTag = <Badge count={"Missing"} />;

  const getFullAddress = (address?: {
    street?: string;
    houseNumber?: string;
    postcode?: string;
    city?: string;
    country?: string;
    houseNumberSuffix?: string;
  }): string => {
    if (!address?.street) return "";
    const {
      street = "",
      houseNumber = "",
      houseNumberSuffix = "",
      postcode = "",
      city = "",
      country = "",
    } = address;

    const fullAddress = `${street} ${houseNumber} ${houseNumberSuffix}, ${postcode} ${city} ${country}`;

    return fullAddress;
  };

  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setVisible(false);
    }, 3000);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  function isNumeric(value: any) {
    return /^\d+$/.test(value);
  }
  return (
    <Modal
      visible={visible}
      title={business?.businessName}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      width={1130}
    >
      {currentBusiness?._id && currentBusiness.rootUser?._id && !loading && (
        <div>
          <Descriptions
            title="General"
            labelStyle={{ opacity: 0.6, width: "130px" }}
          >
            <Descriptions.Item label="Onboarded On">
              {getFullDate(currentBusiness.createdAt)}
            </Descriptions.Item>
            <Descriptions.Item label="Internal ID">
              {currentBusiness._id}
            </Descriptions.Item>
            <Descriptions.Item label="Last Updated On">
              {getFullDate(currentBusiness.updatedAt)}
            </Descriptions.Item>
            <Descriptions.Item label="Status">
              {currentBusiness.status}
            </Descriptions.Item>
            <Descriptions.Item label="Type">
              {currentBusiness.businessType}
            </Descriptions.Item>
            <Descriptions.Item label="Industry">
              {currentBusiness.industry}
            </Descriptions.Item>

            <Descriptions.Item label="Address">
              {getFullAddress(currentBusiness?.address)}
            </Descriptions.Item>
            <Descriptions.Item label="Incorporation Date">
              {currentBusiness.incorporationDate || "Unknown"}
            </Descriptions.Item>
            <Descriptions.Item label="Referral Code">
              {currentBusiness.referralCode}
            </Descriptions.Item>
            <Descriptions.Item label="Description" span={3}>
              {currentBusiness.description || MissingTag}
            </Descriptions.Item>
          </Descriptions>
          <Divider />
          <Descriptions
            title="Merchant Service Fees"
            labelStyle={{ opacity: 0.6, width: "130px" }}
          >
            {currentBusiness.merchantServiceChargeFees?.map((f) => (
              <Descriptions.Item label={f?.name}>
                {f?.value}
                {f?.valueType === "Percentage" ? (
                  "%"
                ) : (
                  <Tag style={{ marginLeft: "8px" }}>FIXED</Tag>
                )}
              </Descriptions.Item>
            ))}
          </Descriptions>
          <Divider />
          <Descriptions
            title="Expected Transaction Volumes"
            labelStyle={{ opacity: 0.6, width: "130px" }}
          >
            <Descriptions.Item label="Average Monthly Volume ">
              {currentBusiness.transactionInformation?.averageMonthlyVolume}
            </Descriptions.Item>
            <Descriptions.Item label="Average Monthly Count">
              {
                currentBusiness.transactionInformation
                  ?.expectedAverageMonthlyNumberOfTransactions
              }
            </Descriptions.Item>
            <Descriptions.Item label="Average Transaction Value">
              {
                currentBusiness.transactionInformation
                  ?.expectedAverageTransactionAmount
              }
            </Descriptions.Item>
            <Descriptions.Item label="Maximum Transaction Amount">
              {
                currentBusiness.transactionInformation
                  ?.expectedMaximumTransactionAmount
              }
            </Descriptions.Item>
          </Descriptions>

          {currentBusiness.devices && currentBusiness.devices.length > 0 && (
            <>
              <Divider />
              <BusinessDevices business={currentBusiness} />
            </>
          )}
        </div>
      )}

      {loading && <Loader text="Fetching Business Details" />}
    </Modal>
  );
}
