import { Descriptions, Modal, Tag } from "antd";
import { useState } from "react";

import Loader from "../../../common/loader/Loader";
import {
  selectCurrentBucketDeal,
  setCurrentBucketDeal,
} from "../../bucketDealsSlice";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
interface IPropsBucketDealDetailsModel {
  visible: boolean;
  setVisible: any;
}

export default function BucketDealDetailsModal({
  visible,
  setVisible,
}: IPropsBucketDealDetailsModel) {
  const [loading, setLoading] = useState(false);
  const currentBucketDeal = useAppSelector(selectCurrentBucketDeal);

  const dispatch = useAppDispatch();

  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setVisible(false);
    }, 3000);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleNameChange = (value: string) => {
    dispatch(
      setCurrentBucketDeal({ ...currentBucketDeal, businessName: value })
    );
  };
  function isNumeric(value: any) {
    return /^\d+$/.test(value);
  }
  return (
    <Modal
      visible={visible}
      title={currentBucketDeal?.businessName}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      width={1000}
    >
      {currentBucketDeal?._id &&
        currentBucketDeal.buckets?.length > 0 &&
        !loading && (
          <div>
            <Descriptions title="Merchant Service Fees">
              {currentBucketDeal.buckets.map((b) => (
                <Descriptions.Item label={b?.name}>
                  {b?.value}
                  {b?.valueType === "Percentage" ? (
                    "%"
                  ) : (
                    <Tag style={{ marginLeft: "8px" }}>FIXED</Tag>
                  )}
                </Descriptions.Item>
              ))}
            </Descriptions>
          </div>
        )}

      {loading && <Loader text="Fetching Business Details" />}
    </Modal>
  );
}
