import React from "react";
import { PartnerBucketGroupPrice } from "../../../../../../interfaces/partnerDeal.interface";
import styles from "./BucketGroupValue.module.css";
import { Statistic } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
export interface IPropsBucketGroupValue extends PartnerBucketGroupPrice {}
export default function BucketGroupValue({
  value,
  valueType,
  label,
  max,
}: IPropsBucketGroupValue & { label?: string; max?: number }) {
  return (
    <>
      {label && <div style={{ opacity: 0.6 }}>{label}</div>}

      <Statistic
        style={{ display: max ? "inline-block" : "block" }}
        value={value}
        precision={3}
        valueStyle={{ fontSize: "14px" }}
        // valueStyle={{ color: "#3f8600" }}
        suffix={valueType === "Percentage" ? "%" : "€"}
      />

      {max && (
        <span style={{ marginLeft: "16px" }}>
          <ArrowRightOutlined style={{ marginRight: "16px" }} />

          <Statistic
            style={{ display: "inline-block" }}
            value={max}
            precision={3}
            valueStyle={{ fontSize: "14px" }}
            // valueStyle={{ color: "#3f8600" }}
            suffix={valueType === "Percentage" ? "%" : "€"}
          />
          <br />
        </span>
      )}
    </>
  );
}
