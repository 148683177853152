import { Button, Divider, Form, Input, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import Loader from "../../../common/loader/Loader";
import { editBusinessUsersEmailThunk } from "../../businessesSlice";
import styles from "./BusinessUsersEditor.module.css";
import { UserOutlined } from "@ant-design/icons";
import { IBusinessUser } from "../../../../interfaces/businessUser.interface";
import { selectUserAccessLevel } from "../../../access-control/accountSlice";
interface IPropsEmailEditorModel {
  isVisible: boolean;
  setIsVisible: (state: boolean) => void;
  _id: string;
  currentEmail: string;
}

export default function EmailEditor({
  _id,
  currentEmail,
  isVisible,
  setIsVisible,
}: IPropsEmailEditorModel) {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const [isEmailChanged, setIsEmailChanged] = useState(false);
  const [isEmailTaken, setIsEmailTaken] = useState(false);

  useEffect(() => {
    setIsEmailTaken(false);
  }, []);

  const handleSubmit = async (values: { newEmail: string }) => {
    setLoading(true);
    setIsEmailChanged(false);
    setIsEmailTaken(false);
    if (!values) {
      setLoading(false);
      return;
    }
    const results = await dispatch(
      editBusinessUsersEmailThunk({
        _id,
        newEmail: values.newEmail,
        oldEmail: currentEmail,
      })
    );

    //@ts-ignore
    if (results?.error?.code === "EmailBelongsToUserException") {
      setIsEmailTaken(true);
    }
    if (results.payload) {
      setIsEmailChanged(true);
    }
    setLoading(false);
  };
  const handleCancel = () => {
    setIsVisible(false);
    setIsEmailChanged(false);
    setIsEmailTaken(false);
  };

  return (
    <div>
      {!loading && (
        <Modal
          visible={isVisible}
          title={"Change Email"}
          onCancel={handleCancel}
          footer={null}
          width={550}
          style={{ marginTop: "20px" }}
        >
          <div className={styles.email_editor_container}>
            {!isEmailChanged && (
              <>
                <div>
                  As an extra level of precaution, please type the current email
                  below
                  <span className={styles.current_email}> {currentEmail}</span>
                </div>
                <br></br>
                <Form
                  // initialValues={u}
                  onFinish={handleSubmit}
                  labelCol={{ span: 5, offset: 0 }}
                  labelAlign="left"
                >
                  <Form.Item
                    name="oldEmail"
                    label="Current Email"
                    rules={[
                      {
                        validator: async (_, email) => {
                          if (email !== currentEmail)
                            // if (index || field)
                            return Promise.reject(
                              new Error(`Please insert the current email`)
                            );
                        },
                      },
                    ]}
                  >
                    <Input style={{ maxWidth: "240px" }} />
                  </Form.Item>
                  <Form.Item name="newEmail" label="New Email">
                    <Input style={{ maxWidth: "240px" }} />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      style={{ float: "right" }}
                      type="primary"
                      danger
                      htmlType="submit"
                      loading={loading}
                    >
                      Change
                    </Button>
                  </Form.Item>
                </Form>
              </>
            )}

            {isEmailChanged && <>Email successfully changed.</>}
            {isEmailTaken && (
              <span className={styles.email_used}>
                Email is already used by a different user.
              </span>
            )}
            <Divider></Divider>
          </div>
        </Modal>
      )}
      {loading && <Loader text="Updating Email" />}
    </div>
  );
}
