export enum NameMatchResult {
  Match = "MATCH",
  NoMatch = "NO_MATCH",
  CloseMatch = "CLOSE_MATCH",
  CouldNotMatch = "COULD_NOT_MATCH",
}
export enum CompanyIdMatchResult {
  Match = "MATCH",
  NoMatch = "NO_MATCH",
}

export enum DataUsedForMatching {
  Verified = "VERIFIED",
  Derived = "DERIVED",
}

export enum AccountNumberValidation {
  Valid = "VALID",
  NotValid = "NOT_UNVALID",
}

export enum PaymentPreValidation {
  Pass = "PASS",
}

export enum Scheme {
  SurePay = "SurePay",
  SWIFT = "SWIFT",
  COP_UK = "COP_UK",
  SEPAMAIL = "SEPAMAIL",
}

export interface ISurepayIbanCheckResponse {
  nameMatchResult: NameMatchResult;
  dataUsedForMatching?: DataUsedForMatching;
  nameSuggestion: string;
  companyIds: {
    type: string; //"NL_KVK",
    matchResult: CompanyIdMatchResult; // "MATCH"
  }[];
  account: {
    accountNumberValidation: AccountNumberValidation;
    paymentPreValidation: PaymentPreValidation;
    optedOut: false;
    status: "ACTIVE";
    accountType: string;
    countryCode: string;
  };
  scheme: Scheme;
}
