import { Badge, Button, Descriptions, Divider, List, Modal, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { IBusinessFull } from "../../../../interfaces/businessFull.interface";
import { FileOutlined, DownloadOutlined } from "@ant-design/icons";
import Loader from "../../../common/loader/Loader";
import {
  selectCurrentBusiness,
  selectCurrentBusinessFiles,
} from "../../businessesSlice";
import { useAppSelector } from "../../../../app/hooks";
import { downloadFile } from "../../../../utils/downloadFile";
import BusinessUsers from "./components/BusinessUsers";
import BusinessFiles from "./components/BusinessFiles";
import BusinessDevices from "./components/BusinessDevices";
interface IPropsBusinessModel {
  visible: boolean;
  setVisible: any;
}

export default function BusinessDetailsModal({
  visible,
  setVisible,
}: IPropsBusinessModel) {
  const [loading, setLoading] = useState(false);

  const business = useAppSelector(selectCurrentBusiness);
  const [currentBusiness, setCurrentBusiness] = useState<IBusinessFull>();
  const businessFiles = useAppSelector(selectCurrentBusinessFiles);

  useEffect(() => {
    if (business) {
      setCurrentBusiness(business);
    }
  }, [business]);

  const getFullDate = (date: string): string => {
    if (!date) return "";
    const dateAndTime = date.split("T");
    return dateAndTime[0].split("-").reverse().join("-");
  };

  const MissingTag = <Badge count={"Missing"} />;

  const getFullAddress = (address: {
    street?: string;
    houseNumber?: string;
    postcode?: string;
    city?: string;
    country?: string;
    houseNumberSuffix?: string;
  }): string => {
    if (!address?.street) return "";
    const {
      street = "",
      houseNumber = "",
      houseNumberSuffix = "",
      postcode = "",
      city = "",
      country = "",
    } = address;

    const fullAddress = `${street} ${houseNumber} ${houseNumberSuffix}, ${postcode} ${city} ${country}`;

    return fullAddress;
  };

  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setVisible(false);
    }, 3000);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  function isNumeric(value: any) {
    return /^\d+$/.test(value);
  }
  return (
    <Modal
      visible={visible}
      title={business?.businessName}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      width={1130}
    >
      {currentBusiness?._id && currentBusiness.rootUser?._id && !loading && (
        <div>
          <Descriptions
            title="General"
            labelStyle={{ opacity: 0.6, width: "130px" }}
          >
            <Descriptions.Item label="Onboarded On">
              {getFullDate(currentBusiness.createdAt)}
            </Descriptions.Item>
            <Descriptions.Item label="Internal ID">
              {currentBusiness._id}
            </Descriptions.Item>
            <Descriptions.Item label="Last Updated On">
              {getFullDate(currentBusiness.updatedAt)}
            </Descriptions.Item>
            <Descriptions.Item label="Status">
              {currentBusiness.status}
            </Descriptions.Item>
            <Descriptions.Item label="Type">
              {currentBusiness.businessType}
            </Descriptions.Item>
            <Descriptions.Item label="Industry">
              {currentBusiness.industry}
            </Descriptions.Item>

            <Descriptions.Item label="Address">
              {getFullAddress(currentBusiness.address)}
            </Descriptions.Item>
            <Descriptions.Item label="Incorporation Date">
              {currentBusiness.incorporationDate || "Unknown"}
            </Descriptions.Item>
            <Descriptions.Item label="Referral Code">
              {currentBusiness.referralCode}
            </Descriptions.Item>
            <Descriptions.Item label="Description" span={3}>
              {currentBusiness.description || MissingTag}
            </Descriptions.Item>
          </Descriptions>
          <Divider />
          <Descriptions
            title="Contact Info"
            labelStyle={{ opacity: 0.6, width: "130px" }}
          >
            <Descriptions.Item label="UserName">
              {currentBusiness.rootUser?.email}
            </Descriptions.Item>
            <Descriptions.Item label="Telephone">
              {currentBusiness.rootUser.phone?.countryCode +
                currentBusiness.rootUser.phone?.number}
            </Descriptions.Item>
            <Descriptions.Item label="Name">
              {currentBusiness.rootUser?.name}
            </Descriptions.Item>
            <Descriptions.Item label="Internal ID">
              {currentBusiness.rootUser?._id}
            </Descriptions.Item>
            <Descriptions.Item label="Address">
              {/* {getFullAddress(currentBusiness.rootUser.address)} */}
              {!currentBusiness.address?.street
                ? MissingTag
                : `${currentBusiness.address?.street} ${currentBusiness.address?.houseNumber},${currentBusiness.address?.postcode} ${currentBusiness.address?.city} , ${currentBusiness.address?.country}`}
            </Descriptions.Item>
          </Descriptions>
          <Divider />
          <Descriptions
            title="Transaction Info"
            labelStyle={{ opacity: 0.6, width: "130px" }}
          >
            <Descriptions.Item label="Merchant Catergory Code (MCC)">
              {currentBusiness.merchantCategoryCode}
            </Descriptions.Item>
            <Descriptions.Item label="IBAN">
              {currentBusiness.bankAccountIban}
            </Descriptions.Item>
            <Descriptions.Item label="Registration Number (KVK)">
              {currentBusiness.businessRegistrationNumber}
            </Descriptions.Item>
            <Descriptions.Item label="Terminal ID">
              {currentBusiness.terminalId || MissingTag}
            </Descriptions.Item>
            <Descriptions.Item label="Agreement Id">
              {currentBusiness.agreementId || MissingTag}
            </Descriptions.Item>
            <Descriptions.Item label="MID">
              {currentBusiness.mid || MissingTag}
            </Descriptions.Item>
            <Descriptions.Item label="Phos Merchant Token">
              {currentBusiness.phosMerchantToken || MissingTag}
            </Descriptions.Item>
            <Descriptions.Item label="Yazara Merchant Id">
              {currentBusiness.yazaraMerchantId || MissingTag}
            </Descriptions.Item>
            <Descriptions.Item label="Yazara Terminal Count">
              {currentBusiness.yazaraTerminals?.length > 0
                ? currentBusiness.yazaraTerminals?.length
                : MissingTag}
            </Descriptions.Item>
            <Descriptions.Item label="Vat">
              {currentBusiness.vat}
            </Descriptions.Item>
            <Descriptions.Item label="Payout Schedule">
              {currentBusiness?.payoutOptions?.schedule}
            </Descriptions.Item>
          </Descriptions>
          <Divider />
          <Descriptions
            title="Merchant Service Fees"
            labelStyle={{ opacity: 0.6, width: "130px" }}
          >
            {currentBusiness.merchantServiceChargeFees.map((f) => (
              <Descriptions.Item label={f?.name}>
                {f?.value}
                {f?.valueType === "Percentage" ? (
                  "%"
                ) : (
                  <Tag style={{ marginLeft: "8px" }}>FIXED</Tag>
                )}
              </Descriptions.Item>
            ))}
          </Descriptions>
          <Divider />
          <Descriptions
            title="Bank Details"
            labelStyle={{ opacity: 0.6, width: "130px" }}
          >
            <Descriptions.Item label="IBAN">
              {currentBusiness.bankAccountIban}
            </Descriptions.Item>
            <Descriptions.Item label="Name">
              {currentBusiness.bankName}
            </Descriptions.Item>
            <Descriptions.Item label="Account Number">
              {currentBusiness.bankAccountNumber || MissingTag}
            </Descriptions.Item>
            <Descriptions.Item label="BIC">
              {currentBusiness.bic || MissingTag}
            </Descriptions.Item>
            <Descriptions.Item label="Address">
              {/* {getFullAddress(currentBusiness.rootUser.address)} */}
              {!currentBusiness.bankAddress?.street
                ? MissingTag
                : `${currentBusiness.bankAddress?.street}, ${currentBusiness.bankAddress?.postcode} ${currentBusiness.bankAddress?.city}, ${currentBusiness.bankAddress?.country}`}
            </Descriptions.Item>
          </Descriptions>

          <Divider />
          <Descriptions
            title="Expected Transaction Volumes"
            labelStyle={{ opacity: 0.6, width: "130px" }}
          >
            <Descriptions.Item label="Average Monthly Volume ">
              {currentBusiness.transactionInformation?.averageMonthlyVolume}
            </Descriptions.Item>
            <Descriptions.Item label="Average Monthly Count">
              {
                currentBusiness.transactionInformation
                  ?.expectedAverageMonthlyNumberOfTransactions
              }
            </Descriptions.Item>
            <Descriptions.Item label="Average Transaction Value">
              {
                currentBusiness.transactionInformation
                  ?.expectedAverageTransactionAmount
              }
            </Descriptions.Item>
            <Descriptions.Item label="Maximum Transaction Amount">
              {
                currentBusiness.transactionInformation
                  ?.expectedMaximumTransactionAmount
              }
            </Descriptions.Item>
          </Descriptions>
          {currentBusiness?.partnerId && (
            <>
              <Divider />
              <Descriptions
                title="Partner Details"
                labelStyle={{ opacity: 0.6, width: "130px" }}
              >
                <Descriptions.Item label={"Partner Name"}>
                  {currentBusiness?.partnership}
                </Descriptions.Item>
                <Descriptions.Item label={"Purchase Price Profile Id"}>
                  {currentBusiness.partnerPurchPriceProfileId}
                </Descriptions.Item>
              </Descriptions>
            </>
          )}

          {currentBusiness.persons?.map((p, i) => (
            <>
              <Divider />
              <Descriptions
                title={"Person " + (i + 1)}
                labelStyle={{ opacity: 0.6, width: "130px" }}
              >
                <Descriptions.Item label={"First Name"}>
                  {p.firstName}
                </Descriptions.Item>
                <Descriptions.Item label={"Last Name"}>
                  {p.lastName}
                </Descriptions.Item>
                <Descriptions.Item label={"Phone"}>
                  {!p?.phone?.number
                    ? MissingTag
                    : `${p.phone?.countryCode} ${p?.phone?.number}`}
                </Descriptions.Item>
                <Descriptions.Item label={"Email"}>
                  {!p.email ? MissingTag : p.email}
                </Descriptions.Item>
                <Descriptions.Item label={"Date Of Birth"}>
                  {!p.dateOfBirth ? MissingTag : p.dateOfBirth}
                </Descriptions.Item>
                <Descriptions.Item label={"Roles"}>
                  <div
                    style={{
                      display: "flex",
                      flexBasis: "250px",
                      flexWrap: "wrap",
                    }}
                  >
                    {p.roles.map((r) => (
                      <Tag style={{ marginBottom: "8px" }}>{r}</Tag>
                    ))}
                  </div>
                </Descriptions.Item>
                <Descriptions.Item label={"Address"}>
                  {!p.address?.street
                    ? MissingTag
                    : `${p.address?.street} ${p.address?.houseNumber}, ${p.address?.city}, ${p.address?.country}`}
                </Descriptions.Item>
                {p?.isCustom && (
                  <Descriptions.Item label={"Is Custom"}>
                    <Tag>CUSTOM PERSON</Tag>
                  </Descriptions.Item>
                )}
              </Descriptions>
            </>
          ))}

          {business.devices && business.devices.length > 0 && (
            <>
              <Divider />
              <BusinessDevices business={business} />
            </>
          )}

          <BusinessUsers></BusinessUsers>
        </div>
      )}

      {loading && <Loader text="Fetching Business Details" />}
    </Modal>
  );
}
