import { IBusinessPromotionStatus } from "../../../interfaces/businessPromotionStatus.interface";
import { IPromotion } from "../../../interfaces/promotion.interface";
import { formatCommasAndDots } from "./formatCommasAndDots";

export function convertPromotionsToCSV(promoArr: IPromotion[]) {
  try {
    let businessList = promoArr.reduce((prev, cur, index) => {
      const businessPromotion = {
        ["naam begunstigde"]: cur.businessOwnerName,
        ["IBAN begunstigde"]: cur.IBAN,
        ["Bedrag"]: formatCommasAndDots(cur.amount, "NL"),
        ["omschrijving"]:
          cur.type === "first500EurosDone"
            ? "Klearly - Transactiekosten terugverdiend"
            : "Klearly - Bedankt voor de referral",
      };

      if (index === 0) prev.push(Object.keys(businessPromotion));

      prev.push(Object.values(businessPromotion));
      return prev;
    }, [] as any);

    let csvContent =
      "data:text/csv;charset=utf-8," +
      businessList.map((e: any) => e.join(";")).join("\n");

    var encodedUri = encodeURI(csvContent);
    return encodedUri;
  } catch (error) {
    console.log(error);
  }
}
