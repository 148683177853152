import React, { useEffect } from "react";
import {
  getPartnersThunk,
  selectPartners,
} from "../../../partners/partnersSlice";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { selectIsKlearlyAdmin } from "../../../access-control/accountSlice";
import { Select } from "antd";
import styles from "./PartnersSelector.module.css";

export interface IPropsPartnerSelector {
  onChange: (partnerId: string) => any;
  allowClear?: boolean;
  addDefaultValue?: boolean;
}
export default function PartnerSelector({
  onChange,
  allowClear = true,
  addDefaultValue,
}: IPropsPartnerSelector) {
  const partners = useAppSelector(selectPartners) || [];
  const isKlearlyAdmin = useAppSelector(selectIsKlearlyAdmin);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (isKlearlyAdmin) {
      fetchAllPartners();
    }
  }, []);
  const fetchAllPartners = async () => {
    await dispatch(getPartnersThunk());
  };
  return (
    <div>
      {isKlearlyAdmin && partners?.length > 0 && (
        <Select
          // prefix={<SearchOutlined />}
          defaultValue={addDefaultValue ? partners[0]._id : null}
          placeholder="Partner Name"
          onChange={onChange}
          className={styles.searchInput}
          allowClear={allowClear}
          options={partners.map((p) => ({ label: p.name, value: p._id }))}
          style={{ width: "200px" }}
        />
      )}
    </div>
  );
}
