import React, { useEffect, useState } from "react";
import { IPurchPriceProfile } from "../../../../interfaces/partner.interface";
import { Button, Form, Input, InputNumber, Radio, Select, Space } from "antd";
import styles from "./CreateEditBucketGroupsForm.module.css";
import {
  PartnerBucketGroups,
  ValueType,
} from "../../../../interfaces/partnerDeal.interface";

export const valueTypeLabel = (valueType: ValueType) => {
  let label = valueType === "Percentage" ? "%" : valueType;
  return <span>{label}</span>;
};
const partnerDealsFormFieldLayout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 18 },
};

interface IBucketGroupsForm {
  purchPriceProfiles?: IPurchPriceProfile[];
  bucketGroupsToEdit?: PartnerBucketGroups;
  purchPriceProfileToEdit?: IPurchPriceProfile;
  disableProfileSelection?: boolean;
  onSubmit: (value: {
    purchPriceProfileId: string;
    bucketGroups: PartnerBucketGroups;
  }) => void;
}
export default function CreateEditBucketGroupsForm({
  purchPriceProfiles,
  onSubmit,
  bucketGroupsToEdit,
  purchPriceProfileToEdit,
  disableProfileSelection,
}: IBucketGroupsForm) {
  const [form] = Form.useForm();
  const [purchPriceProfile, setPurchPriceProfile] = useState(
    purchPriceProfileToEdit || purchPriceProfiles?.[0]
  );
  useEffect(() => {
    console.log("bucketGroupsToEdit", bucketGroupsToEdit);
    form.resetFields();
    populateForm();
  }, []);

  const populateForm = () => {
    // if (!purchPriceProfiles) return;
    // initialProfile.buckets[0].value = 0.023;

    form.setFieldsValue({
      name: purchPriceProfile?.name,
      bucketGroups: bucketGroupsToEdit || purchPriceProfile?.bucketGroups,
      purchPriceProfileId:
        purchPriceProfileToEdit?._id || purchPriceProfiles?.[0]?._id,
    });
  };

  const handlePurchPriceProfileChange = (purchPriceProfileId: string) => {
    let purchPriceProfile = purchPriceProfiles?.find(
      (pp) => pp._id === purchPriceProfileId
    );
    if (purchPriceProfile) setPurchPriceProfile(purchPriceProfile);
  };

  const PricingFormItem = (
    type: "domesticDebitCards" | "european" | "internationalAndCommercial"
  ) => {
    let label =
      type === "domesticDebitCards"
        ? "Domestic Debit Cards"
        : type === "european"
        ? "E.E.A Cards"
        : "Non E.E.A & Corporate Cards";
    let toolTipText =
      type === "domesticDebitCards"
        ? "Includes domestic debit cards only."
        : type === "european"
        ? "Includes all European debit and credit cards that are not commerical or international as well as all domestic credit cards."
        : "Includes all commerical & international debit and credit cards.";
    return (
      <>
        <Form.Item
          label={label}
          name={["bucketGroups", type, "value"]}
          labelCol={{ ...partnerDealsFormFieldLayout.labelCol }}
          labelAlign="left"
          tooltip={toolTipText}
          help={
            purchPriceProfile
              ? `Klearly Price ${purchPriceProfile?.bucketGroups?.[type]?.value} | Max Total Price ${purchPriceProfile?.bucketGroups?.[type]?.max}`
              : ""
          }
          className={styles.bucket_group_form_item}
        >
          <InputNumber
            controls={false}
            className={styles.bucket_value_input}
            min={
              purchPriceProfile
                ? purchPriceProfile?.bucketGroups?.[type]?.value
                : Number.NEGATIVE_INFINITY
            }
            max={
              purchPriceProfile
                ? purchPriceProfile?.bucketGroups?.[type]?.max
                : Number.POSITIVE_INFINITY
            }
            style={{ width: "140px" }}
            addonAfter={
              purchPriceProfile
                ? valueTypeLabel(
                    purchPriceProfile?.bucketGroups?.[type]?.valueType
                  )
                : type === "domesticDebitCards"
                ? "Fixed"
                : "%"
            }
          />
        </Form.Item>

        <Form.Item hidden name={["bucketGroups", type, "valueType"]}>
          <Input />
        </Form.Item>
        {purchPriceProfile?.bucketGroups && (
          <Form.Item hidden name={["bucketGroups", type, "valueType"]}>
            <Input />
          </Form.Item>
        )}
        {!purchPriceProfile?.bucketGroups && (
          <Form.Item
            hidden
            name={["bucketGroups", type, "valueType"]}
            initialValue={
              type === "domesticDebitCards" ? "Fixed" : "Percentage"
            }
          >
            <Input />
          </Form.Item>
        )}
      </>
    );
  };

  return (
    <div className={styles.container}>
      <div>
        <Form
          onFinish={onSubmit}
          form={form}
          style={{
            minWidth: 600,
          }}
        >
          {purchPriceProfiles && purchPriceProfiles?.length > 0 && (
            <Form.Item
              label={"Purchase Price Profile"}
              name={["purchPriceProfileId"]}
              labelCol={{ ...partnerDealsFormFieldLayout.labelCol }}
              labelAlign="left"
              hidden={purchPriceProfiles?.length === 1}
            >
              <Select
                style={{ width: "200px" }}
                onChange={handlePurchPriceProfileChange}
                disabled={disableProfileSelection}
                options={purchPriceProfiles?.map((pp) => ({
                  value: pp._id,
                  label: pp.name,
                }))}
              ></Select>
            </Form.Item>
          )}

          {PricingFormItem("domesticDebitCards")}
          {PricingFormItem("european")}
          {PricingFormItem("internationalAndCommercial")}

          <Form.Item>
            <Button style={{ float: "right" }} type="primary" htmlType="submit">
              OK
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

{
  /* 
          <Form.List name={"buckets"}>
            {(fields) => (
              <div>
                {fields.map(({ key, name, ...restField }) => (
                  <Space key={key} align="baseline">
                    <Form.Item {...restField} name={[name, "name"]}>
                      <Input
                        className={styles.bucket_name_input}
                        disabled
                      ></Input>
                    </Form.Item>

                    <Form.Item {...restField} name={[name, "value"]}>
                      <InputNumber
                        controls={false}
                        className={styles.bucket_value_input}
                        min={0}
                      />
                    </Form.Item>
                    <Form.Item {...restField} name={[name, "valueType"]}>
                      <Select
                        className={styles.bucket_value_input}
                        style={{ width: "85px" }}
                      >
                        <Select.Option value={"Percentage"}>%</Select.Option>
                        <Select.Option value={"Fixed"}>FIXED</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item {...restField} name={[name, "max"]}>
                      <InputNumber
                        controls={false}
                        className={styles.bucket_value_input}
                        min={0}
                        max={3}
                      />
                    </Form.Item>
                  </Space>
                ))}
              </div>
            )}
          </Form.List>  */
}
