import React from "react";
import ChatBot from "../../features/chat-bot/ChatBot";

export default function ChatBotPage() {
  return (
    <div>
      <ChatBot></ChatBot>
    </div>
  );
}
