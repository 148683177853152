import { IBusinessFull } from "./businessFull.interface";

export enum CouplingStatus {
  coupled = "coupled",
  pending = "pending",
  failed = "failed",
}
export enum CouplingActionStatus {
  notStarted = 0,
  started = 1,
  success = 2,
  failed = 3,
  waiting = 4,
}
export enum CoupleType {
  merchant = "merchant",
  admin = "admin",
}

export enum PlatformType {
  secureRetail = "secureRetail",
  unitouch = "unitouch",
}

export type IPaxTerminal = {
  _id: string;
  serialNumber: string;
  businessId: string;
  partnerId: string;
  physicalTid: string;
  merchantName: string;
  model: string;
  coupledBy: string;
  coupledOn: string;
  coupledOnUTC: string;
  validatedOn: string;
  validatedOnUTC: string;
  couplingStatus: CouplingStatus;
  couplingActionStatus: CouplingActionStatus;
  coupleType: CoupleType;
  lastPushId: number;
  platform?: PlatformType;
  createdAt: string;
  updatedAt: string;
};

export type IPopulatedPaxTerminal = IPaxTerminal & {
  businessId: Partial<IBusinessFull>;
};

export type IPaxTerminalQuery = IPaxTerminal & {
  coupledOnStart: string;
  coupledOnEnd: string;
};
