import { PageHeader, Statistic } from "antd";
import React from "react";
import BusinessTable from "./components/Business-Table/BusinessTable";
import BusinessesActionMenu from "./components/Businesses-Action-Menu/BusinessesActionMenu";
import GeneralTableHeader from "../common/general-table-header/GeneralTableHeader";
import { useAppSelector } from "../../app/hooks";
import {
  selectIsLoadingBusinesses,
  selectTotalBusinessCount,
} from "./businessesSlice";

export default function Businesses() {
  const totalCount = useAppSelector(selectTotalBusinessCount);
  const loading = useAppSelector(selectIsLoadingBusinesses);
  return (
    <div>
      <GeneralTableHeader
        title="Business Manager"
        description="A list of all of Klearly's merchants."
        actionButtons={[<BusinessesActionMenu />]}
        avatarUrl="https://secureretail.whatspos.com/admin/assets/img/model/A920PRO.png"
      >
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Statistic
              title="Amount of Merchants:"
              value={totalCount}
              loading={loading}
              style={{ maxHeight: "50px" }}
              // precision={2}
            />
          </div>
        </>
      </GeneralTableHeader>
      <BusinessTable></BusinessTable>
    </div>
  );
}
