import { DataNode } from "antd/lib/tree";
import { ICatalog, IItem } from "../../interfaces/catalog.interface";

export const findSubmenuByIdAndUpdate = (
  obj: ICatalog,
  id: string,
  updateFn: any
) => {
  if (obj["_id"] === id) return updateFn(obj);
  else {
    if (obj["submenus"] && obj["submenus"].length > 0) {
      obj.submenus = obj.submenus.map((s) =>
        findSubmenuByIdAndUpdate({ ...s }, id, updateFn)
      );
    }
  }
  return obj;
};

export const _editSubmenu = (edittedSubmenu: Partial<ICatalog>) => {
  return (obj: any) => {
    obj.submenus = obj.submenus.map((s: ICatalog) =>
      s._id === edittedSubmenu._id ? { ...s, ...edittedSubmenu } : s
    );
    return obj;
  };
};
export const _addSubmenu = (newSubmenu: Partial<ICatalog>) => {
  return (obj: any) => {
    obj.submenus = [...obj?.submenus, newSubmenu];
    return obj;
  };
};
export const _deleteSubmenu = (submenu: Partial<ICatalog>) => {
  return (obj: any) => {
    obj.submenus = obj.submenus.filter((s: ICatalog) => s._id !== submenu._id);
    return obj;
  };
};

export const _addItems = (updatedItems: IItem[]) => {
  return (obj: any) => {
    try {
      obj.items = [...obj.items, ...updatedItems];

      return obj;
    } catch (error) {
      alert(error);
    }
  };
};
export const _editItem = (updatedItem: IItem) => {
  return (obj: any) => {
    try {
      obj.items = obj.items.map((i: IItem) =>
        i._id === updatedItem._id ? updatedItem : i
      );
      return obj;
    } catch (error) {
      alert(error);
    }
  };
};
export const _deleteItem = (deletedItem: IItem) => {
  return (obj: any) => {
    try {
      obj.items = obj.items.filter((i: IItem) => i._id !== deletedItem._id);

      return obj;
    } catch (error) {
      alert(error);
    }
  };
};

export const convertCatalogToTreeData = (
  obj: ICatalog,
  formattedObj: DataNode,
  leafIcon?: any
): DataNode => {
  for (var propertyName in obj) {
    // any object that is not a simple value
    if (propertyName === "_id") {
      formattedObj.key = obj[propertyName];
    }
    if (propertyName === "name") {
      formattedObj.title = obj[propertyName];
    }
    if (propertyName === "items") {
      obj.items.forEach((i) =>
        formattedObj.children?.push({
          key: i._id,
          title: i.name,
          isLeaf: true,
          // icon: leafIcon,
        })
      );
    }
    if (propertyName === "submenus") {
      obj[propertyName]?.forEach((sm) => {
        formattedObj.children?.push(
          convertCatalogToTreeData(sm, { children: [], key: "" } as DataNode)
        );
      });
    }
  }
  return formattedObj;
};

export const findSubmenu = (obj: ICatalog, _id: string) => {
  if (obj["_id"] === _id) {
    return obj;
  } else if (obj["submenus"]) {
    let submenu;
    obj["submenus"].some((sm) => {
      let result = findSubmenu(sm, _id);
      if (result?._id) {
        submenu = result;
        return true;
      }
    });
    return submenu;
  }
  return;
};
export const findParentSubmenu = (obj: ICatalog, _id: string) => {
  if (
    obj.items.findIndex((i) => i._id === _id) > -1 ||
    obj.submenus?.findIndex((s) => s._id === _id)! > -1
  ) {
    return obj;
  } else if (obj?.submenus) {
    const result = obj.submenus.reduce(
      (prev: ICatalog | undefined, cur: ICatalog) => {
        if (prev?._id) return prev;
        const foundSm = findParentSubmenu(cur, _id);
        if (foundSm?._id) return foundSm;
        return foundSm;
      },
      {} as ICatalog
    ) as ICatalog;
    return result;
  }
};

export const findItem = (obj: ICatalog, _id: string) => {
  let itemFound;
  if (obj["items"]) {
    obj["items"].some((i) => {
      if (i._id === _id) {
        itemFound = i;
        return true;
      }
    });
    //@ts-ignore
    if (itemFound?._id) return itemFound;
  }
  if (obj["submenus"]) {
    obj["submenus"].some((sm) => {
      let result = findItem(sm, _id);
      //@ts-ignore
      if (result?._id) {
        itemFound = result;
        return true;
      }
    });
    return itemFound;
  }
  return;
};
