import { Button, Form, Modal } from "antd";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { createPartnerThunk, selectCurrentPartner } from "../../partnersSlice";
import styles from "./EditPartnerForm.module.css";
import { IPartner } from "../../../../interfaces/partner.interface";

import { DEFAULT_PURCH_PRICE_PROFILE } from "../../utils/defaultPurchPriceProfile";
import { UserAddOutlined } from "@ant-design/icons";

import PartnerTeamMemberList from "./components/partner-team-member-list/PartnerTeamMemberList";
import CreatePartnerTeamMemberForm from "./components/create-partner-team-member-form/CreatePartnerTeamMemberForm";

const partnerFormFieldLayout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 12 },
};
interface IPropsPartnerTeamMemberFormModal {
  visible: boolean;
  setVisible: any;
}

let NEW_PURCH_PRICE_PROFILE = { ...DEFAULT_PURCH_PRICE_PROFILE, name: "" };

export default function PartnerTeamMemberModal({
  visible,
  setVisible,
}: IPropsPartnerTeamMemberFormModal) {
  //   const currentPartner = useAppSelector(selectCurrentPartner);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const partner = useAppSelector(selectCurrentPartner);
  const [isCreateTeamMemberFormVisible, setIsCreateTeamMemberFormVisible] =
    useState(false);

  const handleSubmit = async (values: Partial<IPartner>) => {
    setLoading(true);

    let partner: Partial<IPartner> = values;
    await dispatch(createPartnerThunk(partner));
    setLoading(false);
  };

  const handleReset = () => {
    form.resetFields();
    // setPartner({});
  };

  const handleCancel = () => {
    handleReset();
    setVisible(false);
  };

  const handleShowCreateTeamMemberForm = () => {
    setIsCreateTeamMemberFormVisible(true);
  };

  return (
    <div>
      <Modal
        visible={visible}
        title={`Team Members - ${partner.name}`}
        onCancel={handleCancel}
        footer={null}
        width={650}
      >
        <div></div>
        <PartnerTeamMemberList />
        <br />
        <Button
          type="primary"
          onClick={handleShowCreateTeamMemberForm}
          icon={<UserAddOutlined />}
        >
          Add Team Member
        </Button>
        <br />
        <br />
        {isCreateTeamMemberFormVisible && (
          <CreatePartnerTeamMemberForm
            setIsVisible={setIsCreateTeamMemberFormVisible}
          />
        )}
      </Modal>
    </div>
  );
}
