import {
  Avatar,
  Badge,
  Button,
  DatePicker,
  Form,
  Modal,
  Segmented,
  Select,
  Switch,
} from "antd";
import { useEffect, useState } from "react";
import {
  DownloadOutlined,
  ExclamationCircleOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";

import styles from "./CreateAnalyticsReportForm.module.css";

import {
  ApplePerformanceReportRequest,
  getApplePerformanceReportFromServer,
  getMainAnalyticsReportFromServer,
  MainAnalyticsReportRequest,
} from "../../reportsApi";
import { IReportRequest } from "../../../../interfaces/requestReport.interface";
import { SegmentedValue } from "antd/lib/segmented";
import moment from "moment";
import { IAnalyticsReport } from "../../../../interfaces/reports.interface";
import { TimeFormat } from "../../../../interfaces/timeFormat.enum";
import { LocalDateAsUTC } from "../../../../utils/LocalDateAsUTC";
import { useAppSelector } from "../../../../app/hooks";
import {
  selectIsKlearlyAnalyticsAdmin,
  selectUser,
} from "../../../access-control/accountSlice";

interface IPropsCreateReportFormModal {
  visible: boolean;
  setVisible: any;
}

let ReportOptions: IAnalyticsReport[] = [
  {
    type: "mainAnalyticsReport",
    label: "Main Analytics Report",
    formatTypes: ["XLSX"],
    previewUrl: "/assets/icons/report/sales-overview.svg",
    description:
      "Report containing all sales data for the selected time period.",
  },
  {
    type: "applePerformanceReport",
    label: "Apple Performance Report",
    formatTypes: ["TSV"],
    previewUrl: "/assets/icons/report/apple.svg",
    description:
      "Report sent to Apple every month/ quarter at the 1st of each month PDT / PST time. Contains information on all Tap to Pay transactions",
  },
];

let ApplePerformanceReportTimeFormatOptions: {
  value: TimeFormat;
  label: string;
}[] = [
  {
    value: "lastPTMonth",
    label: "Last Month (Pacific Time)",
  },
  {
    value: "lastPTQuarter",
    label: "Last Quarter (Pacific Time)",
  },
];
let MainAnalyticsReportTimeFormatOptions: {
  value: "custom";
  label: string;
}[] = [
  {
    value: "custom",
    label: "Custom",
  },
];
const reportFormFieldLayout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 12 },
};
export default function CreateAnalyticsReportFormModal({
  visible,
  setVisible,
}: IPropsCreateReportFormModal) {
  const [loading, setLoading] = useState(false);
  const [report, setReport] = useState<IAnalyticsReport>(ReportOptions[0]);
  const [form] = Form.useForm();
  const [timeFormat, setTimeFormat] = useState<TimeFormat>();
  const [url, setUrl] = useState<string | undefined>();
  const [noDataFound, setNoDataFound] = useState(false);
  const [isSentToEmail, setIsSentToEmail] = useState(false);
  const user = useAppSelector(selectUser);
  const isKlearlyAnalyticsAdmin = useAppSelector(selectIsKlearlyAnalyticsAdmin);

  let timeOptions =
    report?.type === "applePerformanceReport"
      ? ApplePerformanceReportTimeFormatOptions
      : MainAnalyticsReportTimeFormatOptions;

  useEffect(() => {
    form.setFieldsValue({ timeFormat: timeOptions[0].value });
    if (timeOptions[0].value === "custom") setTimeFormat("custom");
    else setTimeFormat(timeOptions[0].value);
  }, [report.type]);

  const handleReset = () => {
    setLoading(false);
    setUrl(undefined);
    // setReport({ ...ReportOptions[0] });
    setNoDataFound(false);
    setIsSentToEmail(false);
    form.resetFields();
  };

  const handleSubmit = async (
    values: Partial<IReportRequest> & { dateRange?: moment.Moment[] }
  ) => {
    setUrl(undefined);
    setLoading(true);

    if (!report) return;

    let results = {} as any;
    if (report?.type === "applePerformanceReport") {
      let reportRequest: ApplePerformanceReportRequest = {
        reportFrequency:
          values.timeFormat === "lastPTMonth" ? "monthly" : "quarterly",
        returnType: "url",
        // formatType: values.formatType!,
        // timeFormat: values.timeFormat,
        // startDate: values.startDate || "",
        // endDate: values.endDate || "",
      };
      await getApplePerformanceReportFromServer(reportRequest)
        .then((d) => (results = d))
        .catch((e) => console.log("Error found", e));
    }
    if (report?.type === "mainAnalyticsReport") {
      let reportRequest: MainAnalyticsReportRequest = {
        email: user.email,
        // formatType: values.formatType!,
        // timeFormat: values.timeFormat,
        // startDate: values.startDate || "",
        // endDate: values.endDate || "",
      };
      if (values.dateRange && values.timeFormat === "custom") {
        const [start, end] = values.dateRange;
        start.set({ milliseconds: 0, seconds: 0 });
        end.set({ milliseconds: 999, seconds: 59 });
        reportRequest.startDate = LocalDateAsUTC.getISOFromDate(start.toDate());
        reportRequest.endDate = LocalDateAsUTC.getISOFromDate(end.toDate());
      }

      results = await getMainAnalyticsReportFromServer(reportRequest)
        .then((d) => (results = d))
        .catch((e) => console.log("Error found", e));
    }

    if (results?.isEmpty) setNoDataFound(true);

    if (results?.isEmailed) setIsSentToEmail(true);
    setUrl(results?.value || "");
    setLoading(false);
  };
  const handleCancel = () => {
    handleReset();
    setVisible(false);
  };
  const handleTimeFormatChange = (value: TimeFormat) => {
    setTimeFormat(value);
  };

  const handleReportChange = (value: SegmentedValue) => {
    let report = ReportOptions.find((r) => value === r.type);
    if (!report) return;
    setReport({ ...report });
    form.setFieldsValue({ formatType: report.formatTypes[0] });
  };

  const getReportCard = (report: IAnalyticsReport) => (
    <div style={{ padding: 4 }}>
      <Avatar
        style={{
          width: "50px",
          height: "50px",
          marginTop: "10px",
          backgroundColor: "#e2bee8",
        }}
        size={"large"}
        src={report.previewUrl || ""}
      />
      <div className={styles.report_card}>
        <span>{report.label}</span>
      </div>
    </div>
  );

  return (
    <Modal
      visible={visible}
      title={"Create Analytics Report"}
      onCancel={handleCancel}
      footer={null}
      width={650}
    >
      <div className={styles.report_cards_container}>
        <Segmented
          onChange={handleReportChange}
          value={report?.type}
          options={ReportOptions.map((r) => ({
            label: r.isBeta ? (
              <Badge.Ribbon text="Beta" color="purple">
                {getReportCard(r)}
              </Badge.Ribbon>
            ) : (
              <div style={{ padding: 4 }}>
                <Avatar
                  style={{ width: "50px", height: "auto", paddingTop: "10px" }}
                  size={"large"}
                  src={r.previewUrl}
                />
                <div className={styles.report_card}>
                  <span>{r.label}</span>
                </div>
              </div>
            ),
            value: r.type,
            key: r.type,
            disabled:
              r.type === "mainAnalyticsReport" && !isKlearlyAnalyticsAdmin,
          }))}
        />
      </div>
      <br></br>
      {report.warning && (
        <div className={styles.report_warning}>{report.warning}</div>
      )}
      <div className={styles.report_description}>{report.description}</div>

      <br></br>
      <br></br>
      {report?.type ? (
        <Form onFinish={handleSubmit} form={form}>
          <Form.Item
            name="formatType"
            initialValue={report.formatTypes[0]}
            label="Format Type"
            labelCol={{ ...reportFormFieldLayout.labelCol }}
            labelAlign="left"
          >
            <Select
              style={{ width: "200px" }}
              options={report.formatTypes.map((ft) => ({
                value: ft,
                label: ft,
                key: `${report.type}+${ft}`,
              }))}
            ></Select>
          </Form.Item>
          <Form.Item
            initialValue={timeOptions[0].value}
            name="timeFormat"
            label="Time Format"
            labelCol={{ ...reportFormFieldLayout.labelCol }}
            labelAlign="left"
          >
            <Select
              style={{ width: "200px" }}
              onChange={handleTimeFormatChange}
              options={timeOptions.map((t) => ({
                value: t.value,
                label: t.label,
                key: `${report.type}+${t.label}`,
              }))}
            ></Select>
          </Form.Item>
          {timeFormat === "custom" && (
            <Form.Item
              name="dateRange"
              label={"Activity Period"}
              labelCol={{ ...reportFormFieldLayout.labelCol }}
              labelAlign="left"
            >
              <DatePicker.RangePicker
                showTime
                format="DD-MM-YYYY HH:mm"
                // disabledDate={disabledDate}
                //@ts-ignore
                // disabledTime={disabledTime}

                showSecond={false}
                showNow={false}
              />
            </Form.Item>
          )}

          <Form.Item>
            <Button
              style={{ float: "right" }}
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              Create Report
            </Button>
          </Form.Item>
        </Form>
      ) : (
        <>Please pick a report type.</>
      )}

      <div className={styles.results_container}>
        {/* {loading && <Spin></Spin>} */}
        {url && (
          <div>
            <Button
              type="link"
              icon={<DownloadOutlined />}
              href={url}
              target="_blank"
              style={{ width: "240px", fontSize: "14px" }}
            >
              Click here to view report
            </Button>
          </div>
        )}
        {isSentToEmail && (
          <div>
            <CheckCircleFilled style={{ color: "green", marginLeft: "10px" }} />{" "}
            Once complete, the report will be sent to{" "}
            <a href={user.email}>{user.email}</a>
          </div>
        )}

        {noDataFound && (
          <div>
            <ExclamationCircleOutlined
              style={{ margin: "0 10px", color: "#f5b642" }}
            />
            No data was found in the selected time period. Try selecting a
            different time period.
          </div>
        )}
      </div>
    </Modal>
  );
}
