import { Badge, Descriptions, List, Modal, Tag, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { IBusinessFull } from "../../../../interfaces/businessFull.interface";
import { UserOutlined } from "@ant-design/icons";
import styles from "./Business.module.css";
import Loader from "../../../common/loader/Loader";
import {
  getBusinessUsersThunk,
  selectCurrentBusiness,
  selectCurrentBusinessUsers,
} from "../../../businesses/businessesSlice";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
const { Paragraph } = Typography;
interface IPropsBusinessModel {
  visible: boolean;
  setVisible: any;
}

export default function SalesBusinessActivityDetails({
  visible,
  setVisible,
}: IPropsBusinessModel) {
  const [loading, setLoading] = useState(false);
  const business = useAppSelector(selectCurrentBusiness);
  const [currentBusiness, setCurrentBusiness] = useState<IBusinessFull>();
  const businessUsers = useAppSelector(selectCurrentBusinessUsers);

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (business) {
      setCurrentBusiness(business);
      fetchBusinessUsersDetails(business._id);
    }
  }, [business]);

  const fetchBusinessUsersDetails = async (businessId: string) => {
    if (!business?._id) return;
    setLoading(true);
    await dispatch(getBusinessUsersThunk(business?._id));
    setLoading(false);
  };

  const getFullDate = (date: string): string => {
    if (!date) return "";
    const dateAndTime = date.split("T");
    return dateAndTime[0].split("-").reverse().join("-");
  };

  const MissingTag = <Badge count={"Missing"} />;

  const getFullAddress = (address: {
    street?: string;
    houseNumber?: string;
    postcode?: string;
    city?: string;
    country?: string;
    houseNumberSuffix?: string;
  }): string => {
    if (!address?.street) return "";
    const {
      street = "",
      houseNumber = "",
      houseNumberSuffix = "",
      postcode = "",
      city = "",
      country = "",
    } = address;

    const fullAddress = `${street} ${houseNumber} ${houseNumberSuffix}, ${postcode} ${city} ${country}`;

    return fullAddress;
  };

  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setVisible(false);
    }, 3000);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      visible={visible}
      title={business?.businessName}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      width={1000}
    >
      {currentBusiness?._id && currentBusiness.rootUser?._id && !loading && (
        <div>
          <Descriptions title="General">
            <Descriptions.Item label="Onboarded On">
              {getFullDate(currentBusiness.createdAt)}
            </Descriptions.Item>
            <Descriptions.Item label="Internal ID">
              {currentBusiness._id}
            </Descriptions.Item>
            <Descriptions.Item label="Last Updated On">
              {getFullDate(currentBusiness.updatedAt)}
            </Descriptions.Item>
            <Descriptions.Item label="Status">
              {getFullDate(currentBusiness.status)}
            </Descriptions.Item>
            <Descriptions.Item label="Type">
              {getFullDate(currentBusiness.businessType)}
            </Descriptions.Item>
            <Descriptions.Item label="Industry">
              {getFullDate(currentBusiness.industry)}
            </Descriptions.Item>
            <Descriptions.Item label="Address">
              {getFullAddress(currentBusiness.address)}
            </Descriptions.Item>
            <Descriptions.Item label="Referral Code">
              {currentBusiness.referralCode}
            </Descriptions.Item>

            <Descriptions.Item label="Incorporation Date">
              {currentBusiness.incorporationDate || "Unknown"}
            </Descriptions.Item>
          </Descriptions>

          <Descriptions title="Merchant Contact Info">
            <Descriptions.Item label="UserName">
              {currentBusiness.rootUser?.email}
            </Descriptions.Item>
            <Descriptions.Item label="Telephone">
              {currentBusiness.rootUser.phone?.countryCode +
                currentBusiness.rootUser.phone?.number}
            </Descriptions.Item>
            <Descriptions.Item label="Name">
              {currentBusiness.rootUser?.name}
            </Descriptions.Item>

            <Descriptions.Item label="Address">
              {/* {getFullAddress(currentBusiness.rootUser.address)} */}
              {!currentBusiness.address?.street
                ? MissingTag
                : `${currentBusiness.address?.street} ${currentBusiness.address?.houseNumber},${currentBusiness.address?.postcode} ${currentBusiness.address?.city} , ${currentBusiness.address?.country}`}
            </Descriptions.Item>
          </Descriptions>

          <Descriptions title="Merchant Service Fees">
            {currentBusiness.merchantServiceChargeFees.map((f) => (
              <Descriptions.Item label={f?.name}>
                {f?.value}
                {f?.valueType === "Percentage" ? (
                  "%"
                ) : (
                  <Tag style={{ marginLeft: "8px" }}>FIXED</Tag>
                )}
              </Descriptions.Item>
            ))}
          </Descriptions>

          <Descriptions title="Expected Transaction Volumes">
            <Descriptions.Item label="Average Monthly Volume ">
              {currentBusiness.transactionInformation?.averageMonthlyVolume}
            </Descriptions.Item>
            <Descriptions.Item label="Average Monthly Count">
              {
                currentBusiness.transactionInformation
                  ?.expectedAverageMonthlyNumberOfTransactions
              }
            </Descriptions.Item>
            <Descriptions.Item label="Average Transaction Value">
              {
                currentBusiness.transactionInformation
                  ?.expectedAverageTransactionAmount
              }
            </Descriptions.Item>
            <Descriptions.Item label="Maximum Transaction Amount">
              {
                currentBusiness.transactionInformation
                  ?.expectedMaximumTransactionAmount
              }
            </Descriptions.Item>
          </Descriptions>

          {businessUsers && (
            <>
              {/* <Descriptions title="Users">
                {businessUsers.map((u) => (
                  <h5> {u.role}</h5>
                ))} */}
              <Descriptions title="Users"></Descriptions>

              <List
                size="small"
                dataSource={businessUsers}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={
                        <>
                          <UserOutlined></UserOutlined>
                          <br />
                          {item.role}
                        </>
                      }
                      title={<span>{item?.name || "Pending Invite"}</span>}
                      description={item.role}
                    ></List.Item.Meta>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "start",
                        width: "300px",
                      }}
                    >
                      <span> email: {item.email}</span>
                      <span> Phone: {item.phone?.number || "None"} </span>
                    </div>
                  </List.Item>
                )}
              />
            </>
          )}
        </div>
      )}

      {loading && (
        <div style={{ height: "500px" }}>
          <Loader text="Fetching Business Details" />
        </div>
      )}
    </Modal>
  );
}
