import { Button, Card, Input, Tag } from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useAppSelector } from "../../../../../app/hooks";
import { selectCurrentBusiness } from "../../../businessesSlice";
import { useEffect, useState } from "react";
import { NameMatchResult } from "../../../../../interfaces/surepayIbanCheckResponse.interface";
import {
  IIbanCheckServerParams,
  checkIbanServer,
  getIbanCheckResultsFromServer,
} from "../../../businessesApi";
import { IBusinessFull } from "../../../../../interfaces/businessFull.interface";

function isIbanCheckVerified(
  checkResult: NameMatchResult | undefined,
  nameSuggestion: string,
  businessName: string
) {
  if (checkResult === NameMatchResult.Match)
    return { result: true, isBusinessNameMatch: false };
  if (
    (checkResult === NameMatchResult.CouldNotMatch ||
      checkResult === NameMatchResult.NoMatch) &&
    nameSuggestion === businessName
  )
    return { result: true, isBusinessNameMatch: true };

  return { result: false, isBusinessNameMatch: false };
}

export default function IbanChecker() {
  const currentBusiness = useAppSelector(selectCurrentBusiness);
  // const [nameMatchResult, setNameMatchResult] = useState<
  //   NameMatchResult | undefined
  // >(undefined);
  const [checkResult, setCheckResult] = useState<NameMatchResult | undefined>(
    undefined
  );
  // const [name, setName] = useState("");
  const [accountHolder, setAccountHolder] = useState("");
  const [nameSuggestion, setNameSuggestion] = useState("");
  const [hasIbanCheckCache, setHasIbanCheckCache] = useState(true);
  const [isBusinessNameMatch, setIsBusinessNameMatch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setAccountHolder(
      `${currentBusiness?.rootUser?.firstName} ${currentBusiness?.rootUser?.lastName}`
    );
    setNameSuggestion("");
    getIbanCheckResults(currentBusiness);

    // return () => {
    //   second;
    // };
  }, [currentBusiness]);

  async function getIbanCheckResults(b: IBusinessFull) {
    if (!b?.bankAccountIban) return;

    setIsLoading(true);
    const results = await getIbanCheckResultsFromServer(
      b?.bankAccountIban,
      currentBusiness?.rootUser?.email
    );
    if (
      results?.response?.companyIds &&
      results?.response?.companyIds.length > 0
    ) {
      setHasIbanCheckCache(true);
      setAccountHolder(results?.companyId!);
      setCheckResult(results?.response?.companyIds[0]?.matchResult as any);
      setIsBusinessNameMatch(false);
    } else if (results?.response?.nameMatchResult) {
      setHasIbanCheckCache(true);
      setAccountHolder(results?.name!);

      const nameCheckResult = isIbanCheckVerified(
        results?.response?.nameMatchResult,
        results?.response?.nameSuggestion,
        b.businessName
      );
      setCheckResult(
        nameCheckResult.isBusinessNameMatch
          ? NameMatchResult.Match
          : results?.response?.nameMatchResult
      );
      if (nameCheckResult.isBusinessNameMatch) {
        setIsBusinessNameMatch(true);
      }
    } else {
      setHasIbanCheckCache(false);
      setIsBusinessNameMatch(false);
      //
      setCheckResult(undefined);
    }
    if (results?.response?.nameSuggestion)
      setNameSuggestion(results.response.nameSuggestion);

    setIsLoading(false);
  }

  async function handleIbanCheck(b: IBusinessFull) {
    if (!b?.bankAccountIban) return;
    setIsLoading(true);

    let check = {
      IBAN: b.bankAccountIban,
      email: b?.rootUser?.email,
    } as IIbanCheckServerParams;
    if (Number(accountHolder)) {
      check.companyId = accountHolder;
    } else {
      check.name = accountHolder;
    }
    const results = await checkIbanServer(check);

    if (results.companyIds && results.companyIds.length > 0) {
      setCheckResult(results.companyIds[0]?.matchResult as any);
    } else {
      const nameCheckResult = isIbanCheckVerified(
        results?.nameMatchResult,
        results?.nameSuggestion,
        b.businessName
      );
      setCheckResult(
        nameCheckResult.isBusinessNameMatch
          ? NameMatchResult.Match
          : results?.nameMatchResult
      );
      if (nameCheckResult.isBusinessNameMatch) {
        setIsBusinessNameMatch(true);
      }
    }

    if (results.nameSuggestion) setNameSuggestion(results.nameSuggestion);
    setHasIbanCheckCache(true);

    setIsLoading(false);
  }

  function handleAccountHolderChange(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    const newName = event.target.value;
    setAccountHolder(newName);
  }

  function getNameMatchResultColor(
    nameMatchResult: NameMatchResult | undefined,
    b: IBusinessFull
  ) {
    if (
      isIbanCheckVerified(nameMatchResult, nameSuggestion, b.businessName)
        .result
    )
      return "green";
    else if (nameMatchResult === NameMatchResult.CloseMatch) return "yellow";
    else if (
      nameMatchResult === NameMatchResult.CouldNotMatch ||
      nameMatchResult === NameMatchResult.NoMatch
    )
      return "red";
    else return "gray";
  }

  return (
    <>
      {/* {isLoading && ( */}
      <div>
        <Card
          title={
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>
                {" "}
                <UserOutlined /> Bank Holder's Name
              </span>
              <span>
                <Tag
                  color={getNameMatchResultColor(checkResult, currentBusiness)}
                >
                  {checkResult || "No Results Found"}
                </Tag>
                {isBusinessNameMatch && (
                  <Tag color={"orange"}>{"Business Name Match"}</Tag>
                )}
              </span>
            </div>
          }
          style={{ marginBottom: "15px" }}
          loading={isLoading}
        >
          <div style={{ display: "flex" }}>
            <Input
              value={accountHolder}
              //   defaultValue={"Insert Name"}
              disabled={
                isIbanCheckVerified(
                  checkResult,
                  nameSuggestion,
                  currentBusiness?.businessName
                ).result
                  ? true
                  : false
              }
              suffix={
                isIbanCheckVerified(
                  checkResult,
                  nameSuggestion,
                  currentBusiness?.businessName
                ).result ? (
                  <CheckCircleOutlined style={{ color: "green" }} />
                ) : (
                  <CloseCircleOutlined style={{ color: "red" }} />
                )
              }
              onChange={handleAccountHolderChange}
            />
            {!isIbanCheckVerified(
              checkResult,
              nameSuggestion,
              currentBusiness?.businessName
            ).result && (
              <Button
                type="primary"
                onClick={() => {
                  handleIbanCheck(currentBusiness);
                }}
              >
                Check
              </Button>
            )}
          </div>
          <div style={{ color: "red", fontSize: "smaller" }}>
            {!hasIbanCheckCache && <span>* No Cached Surepay Check Found</span>}
            <br></br>
            {nameSuggestion && (
              <span style={{ color: "gray" }}>
                {" "}
                Name suggestion: {nameSuggestion}
              </span>
            )}
          </div>
        </Card>
      </div>
      {/* )} */}

      {}
    </>
  );
}
