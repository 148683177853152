import { CONFIG } from "../../config";
import { IBusinessFullQuery } from "../../interfaces/businessFull.interface";
import { IQueryFilters } from "../../interfaces/queryFilters.interface";
import { getTokenizedAxios } from "../../utils/axiosWithTokenGen";
import { MonthBreakdown } from "./SalesSlice";

export async function getSalesRepReportFromServer(params: {
  salesRepresentative: string;
  formatType?: "JSON" | "XLSX";
}) {
  const axios = await getTokenizedAxios();
  const path = "/businesses/salesreps/bonus";
  return axios
    .get<{
      report: {
        MOMBreakdown: MonthBreakdown[];
        url?: string;
      };
    }>(CONFIG.ADMIN_SERVER.BASE_URL + path, { params })
    .then((data) => data.data);
}
