import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState, AppThunk } from "../../app/store";

import { callBotAgentServer } from "./chatBotApi";

// import { fetchCount } from './counterAPI';

export interface ChatBotState {
  sessionId: string;
  conversation: string[];
}

const initialState: ChatBotState = {
  sessionId: "",
  conversation: [],
};

export const callBotAgentThunk = createAsyncThunk(
  "chatbot/call",
  async (props: { query: string; sessionId: string }) => {
    const response = await callBotAgentServer(props.query, props.sessionId);
    return response;
  }
);

export const chatBotSlice = createSlice({
  name: "chatBot",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // setSubmenu: (state, action: PayloadAction<ICatalog | null>) => {
    //   state.currentSubmenu = action.payload;
    // },
    // resetCatalogState: (state) => {
    //   state.currentItem = {} as any;
    //   state.currentSubmenu = {} as any;
    // },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder.addCase(callBotAgentThunk.fulfilled, (state, action) => {
      state.conversation.push(action.payload.message);
      state.sessionId = action.payload.sessionId;
    });
    builder.addCase(callBotAgentThunk.rejected, (state, action) => {
      // state.conversation = action.payload.data;
    });
  },
});

// export const {  } = chatBotSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const selectConversation = (state: RootState) =>
  state.chatBot.conversation;
export const selectSessionId = (state: RootState) => state.chatBot.sessionId;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
//   (amount: number): AppThunk =>
//   (dispatch, getState) => {
//     const currentValue = selectCount(getState());
//     if (currentValue % 2 === 1) {
//       dispatch(incrementByAmount(amount));
//     }
//   };

export default chatBotSlice.reducer;
