export function getBucketGroupName(
  merchantBucketName: string
): "domesticDebitCards" | "european" | "internationalAndCommercial" {
  let domesticDebit = ["Domestic Debit"];
  let europeanAndCredit = [
    "Domestic Credit",
    "European Debit",
    "European Credit",
  ];
  let internationalAndCommercial = [
    "Domestic Corporate Debit",
    "Domestic Corporate Credit",
    "European Corporate Credit",
    "European Corporate Debit",
    "International Debit",
    "International Credit",
    "International Corporate Debit",
    "International Corporate Credit",
  ];

  if (merchantBucketName === "Domestic Debit") return "domesticDebitCards";
  else if (europeanAndCredit.some((m) => m === merchantBucketName))
    return "european";
  else return "internationalAndCommercial";
}
