import axios, { AxiosError } from "axios";
import { useAppDispatch } from "../app/hooks";
import { store } from "../app/store";
import { CONFIG } from "../config";
import {
  refreshTokenSignIn,
  resetUserState,
  setIdTokenFrom401Request,
} from "../features/access-control/accountSlice";
import { refreshTokenSignInServer } from "../features/access-control/accountAPI";

function getLocalRefreshToken() {
  const refreshToken = window.sessionStorage.getItem("refreshToken");
  return refreshToken;
}

export async function getTokenizedAxios() {
  //   const dispatch = useAppDispatch();

  const instance = axios.create({
    //   baseURL: "http://localhost:8080/api",
    headers: {
      "Content-Type": "application/json",
    },
  });

  //REQUEST INTERCEPTOR
  instance.interceptors.request.use(
    (config) => {
      const token = store.getState().account.idToken;
      if (config.headers && token) {
        config.headers["Authorization"] = token;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  //RESPONSE INTERCEPTOR
  instance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;

      if (err.response) {
        // Access Token was expired

        if (
          (err.code === "ERR_NETWORK" ||
            err.response.status === 401 ||
            err.response.status === 403) &&
          !originalConfig._retry
        ) {
          originalConfig._retry = true;
          try {
            console.log(
              "AXIOS INTERCEPTOR | Trying to refresh Id Token following a 401 status code response."
            );

            const refreshToken = getLocalRefreshToken();
            if (!refreshToken) return;

            //REMOVE THIS IF IT CAUSES TROUBLE

            let results;
            let isError = false;
            try {
              results = await refreshTokenSignInServer({ refreshToken });
            } catch (error) {
              let err = error as AxiosError<{ name: string }>;
              console.log("refresh token is expired", error);
              store.dispatch(resetUserState());
              isError = true;
              // if (err?.response?.data?.name === '"NotAuthorizedException"') {
              //   store.dispatch(resetUserState());
              //   isError = true;
              // }
            }
            if (isError) return;

            if (results?.idToken) {
              store.dispatch(
                setIdTokenFrom401Request({ idToken: results.idToken })
              );

              console.log(
                "Successfully replaced old Id token with a new Id token."
              );
            } else {
              console.log(
                "Was not able to replace old Id token. Due to:",
                results
              );
              return;
            }
            //REPLACE WITH THE LINE BELOW
            // await store.dispatch(refreshTokenSignIn({ refreshToken }));
            const idToken = store.getState().account.idToken;

            instance.defaults.headers.common["Authorization"] = idToken;
            return instance(originalConfig);
          } catch (_err) {
            const _error = _err as any;
            if (_error.response && _error.response.data) {
              return Promise.reject(_error.response.data);
            }
            return Promise.reject(_error);
          }
        }
        // if (err.response.status === 403 && err.response.data) {
        //   return Promise.reject(err.response.data);
        // }
      }
      return Promise.reject(err.response.data);
    }
  );

  return instance;
}
