import React, { useEffect } from "react";
import { IPartnerDetails } from "../../../../interfaces/partner.interface";
import { Button, Form, Input, InputNumber, Select, Space } from "antd";
import styles from "./partnerDetailsForm.module.css";
const partnerFormFieldLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
interface IPropsPartnerDetailsForm {
  onSubmit: (value: IPartnerDetails) => any;
  partnerDetails?: Partial<IPartnerDetails>;
}
export default function PartnerDetailsForm({
  partnerDetails,
  onSubmit,
}: IPropsPartnerDetailsForm) {
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
    populateForm();
  }, []);

  const populateForm = () => {
    if (!partnerDetails) return;

    form.setFieldsValue({
      name: partnerDetails?.name,
      ownerName: partnerDetails?.ownerName,
      email: partnerDetails?.email,
      phone: {
        countryCode: partnerDetails?.phone?.countryCode,
        number: partnerDetails?.phone?.number,
      },
    });
  };

  return (
    <div>
      <Form
        onFinish={onSubmit}
        form={form}
        // labelCol={{ ...partnerFormFieldLayout.labelCol }}
        // wrapperCol={{ ...partnerFormFieldLayout.wrapperCol }}
      >
        <Form.Item
          name="name"
          label="Partner Name"
          labelCol={{ ...partnerFormFieldLayout.labelCol }}
          labelAlign="left"
          rules={[
            {
              required: true,
              message: "Missing Name",
            },
          ]}
        >
          <Input style={{ width: "200px" }} />
        </Form.Item>
        <Form.Item
          name="ownerName"
          label="Owner Name"
          labelCol={{ ...partnerFormFieldLayout.labelCol }}
          labelAlign="left"
          rules={[
            {
              required: true,
              message: "Missing Owner Name",
            },
          ]}
        >
          <Input style={{ width: "200px" }} />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          labelCol={{ ...partnerFormFieldLayout.labelCol }}
          labelAlign="left"
          rules={[
            {
              required: true,
              message: "Missing Email",
            },
            { type: "email", message: "Not a valid email." },
          ]}
        >
          <Input style={{ width: "200px" }} />
        </Form.Item>
        <Form.Item
          label="Number of Contact Person"
          labelCol={{ ...partnerFormFieldLayout.labelCol }}
          labelAlign="left"
        >
          <Input.Group compact>
            <Form.Item
              name={["phone", "countryCode"]}
              initialValue={"31"}
              noStyle
              rules={[
                {
                  required: true,
                  message: "Missing Country Code",
                },
              ]}
            >
              <InputNumber
                controls={false}
                stringMode
                style={{ width: "80px" }}
                addonBefore="+"
              />
            </Form.Item>
            <Form.Item
              name={["phone", "number"]}
              noStyle
              rules={[
                {
                  required: true,
                  message: "Missing Number",
                },
              ]}
            >
              <InputNumber
                controls={false}
                stringMode
                style={{ width: "120px" }}
              />
            </Form.Item>
          </Input.Group>
        </Form.Item>

        <Form.Item>
          <Button style={{ float: "right" }} type="primary" htmlType="submit">
            OK
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
