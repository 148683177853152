import {
  createAsyncThunk,
  createSlice,
  isAnyOf,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../app/store";
import { IBusinessFull } from "../../interfaces/businessFull.interface";
import { IBusinessSimple } from "../../interfaces/businessSimple.interface";
import { ITransaction } from "../../interfaces/transaction.interface";
import { downloadFile } from "../../utils/downloadFile";
import { getBusinessThunk } from "../businesses/businessesSlice";
import {
  getBusinessesFromServer,
  getTransactionReportServer,
  getTransactionsFromServer,
} from "./transactionsApi";
import { convertTransactionsToCSV } from "./utils/convertTransactionsToCSV";

export interface TransactionsState {
  businesses: IBusinessSimple[];
  currentBusiness: IBusinessSimple;
  transactions: ITransaction[];
}

const initialState: TransactionsState = {
  businesses: [],
  currentBusiness: {} as any,
  transactions: [],
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(getBusinesses(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

export const getTransactionsThunk = createAsyncThunk(
  "transactions/getAll",
  async (props: { businessId: string }) => {
    const response = await getTransactionsFromServer(props);

    return response.transactions;
  }
);
export const getTransactionsCSVThunk = createAsyncThunk(
  "transactions/getAllCSV",
  async (props: { business: IBusinessFull }) => {
    const { business } = props;
    const response = await getTransactionReportServer({
      businessId: business._id,
    });
    const transactionsReport = response.transactionsReport;
    const fileName = "Transactions-" + new Date().toISOString();
    const csv = convertTransactionsToCSV({
      transactionsReport,
    });
    if (csv) downloadFile(csv, fileName);

    return response.transactionsReport;
  }
);

export const transactionsSlice = createSlice({
  name: "transactions",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder.addCase(getTransactionsThunk.fulfilled, (state, action) => {
      state.transactions = action.payload;
    });
  },
});

// export const { getBusinesses } = businessesSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const selectCurrentBusinesses = (state: RootState) =>
  state.transactions.currentBusiness;
export const selectTransactions = (state: RootState) =>
  state.transactions.transactions;

export default transactionsSlice.reducer;
