import { Modal } from "antd";
import { selectCurrentBusiness } from "../../businessesSlice";
import { useAppSelector } from "../../../../app/hooks";

import BusinessFiles from "./BusinessDevices";
import { useState } from "react";
import BusinessDevices from "./BusinessDevices";
interface IPropsBusinessModel {
  visible: boolean;
  setVisible: any;
}

export default function BusinessDevicesModal({
  visible,
  setVisible,
}: IPropsBusinessModel) {
  const business = useAppSelector(selectCurrentBusiness);

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      visible={visible}
      title={business?.businessName}
      onCancel={handleCancel}
      footer={null}
      width={1130}
    >
      <BusinessDevices />
    </Modal>
  );
}
