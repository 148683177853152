import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
// import { setupListeners } from "@reduxjs/toolkit/query";
import counterReducer from "../features/counter/counterSlice";
import businessesReducer from "../features/businesses/businessesSlice";
import accountReducer from "../features/access-control/accountSlice";
import errorsReducer from "../features/errors/errorsSlice";
import catalogsReducer from "../features/catalogs/catalogsSlice";
import promotionsReducer from "../features/promotions/promotionsSlice";
import transactionTagsReducer from "../features/transaction-tags/transactionTagsSlice";
import transactionsReducer from "../features/transactions/transactionsSlice";
import bucketDealsReducer from "../features/bucket-deals/bucketDealsSlice";
import notificationsSlice from "../features/notifications/notificationsSlice";
import salesReducer from "../features/sales/SalesSlice";
import terminalsReducer from "../features/terminals/TerminalsSlice";
import partnersReducer from "../features/partners/partnersSlice";
import partnerDealsReducer from "../features/partner-deals/partnerDealsSlice";
import chatBotReducer from "../features/chat-bot/chatBotSlice";
export const store = configureStore({
  reducer: {
    counter: counterReducer,
    account: accountReducer,
    businesses: businessesReducer,
    errors: errorsReducer,
    catalogs: catalogsReducer,
    promotions: promotionsReducer,
    transactionTags: transactionTagsReducer,
    transactions: transactionsReducer,
    bucketDeals: bucketDealsReducer,
    sales: salesReducer,
    notifications: notificationsSlice,
    terminals: terminalsReducer,
    partners: partnersReducer,
    partnerDeals: partnerDealsReducer,
    chatBot: chatBotReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
// setupListeners(store.dispatch);
