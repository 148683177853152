import {
  Table,
  Button,
  TablePaginationConfig,
  Statistic,
  Tag,
  Popover,
} from "antd";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import styles from "./RecentlyCoupledTable.module.css";
import {
  selectLastUpdateKey,
  selectFilters,
} from "../../../businesses/businessesSlice";

import { Empty } from "antd";
import {
  PlusCircleOutlined,
  SyncOutlined,
  WifiOutlined,
} from "@ant-design/icons";

import Loader from "../../../common/loader/Loader";

import { TableParams } from "../../../../interfaces/tableParams.interface";
import { IPagination } from "../../../../interfaces/pagination.interface";
import {
  getRecentlyCoupledTerminalsThunk,
  selectRecentlyCoupledTerminalCount,
  validateCouplingThunk,
} from "../../TerminalsSlice";
import {
  CouplingStatus,
  IPaxTerminal,
  IPaxTerminalQuery,
  IPopulatedPaxTerminal,
  PlatformType,
} from "../../../../interfaces/paxTerminal.interface";
import { useNavigate } from "react-router-dom";
import GeneralTableHeader from "../../../common/general-table-header/GeneralTableHeader";
import { formatDate } from "../../../../utils/formatDate";
import { LocalDateAsUTC } from "../../../../utils/LocalDateAsUTC";
import { selectUserPartnerId } from "../../../access-control/accountSlice";
const { Column } = Table;

export default function RecentlyCoupledTable() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const lastUpdateKey = useAppSelector(selectLastUpdateKey);
  const filters = useAppSelector(selectFilters);
  const [recentlyCoupledTerminals, setRecentlyCoupledTerminalsInTable] =
    useState<IPaxTerminal[]>([]);

  const totalCount = useAppSelector(selectRecentlyCoupledTerminalCount);
  const initialPagination = { current: 1, pageSize: 10 };

  const partnerId = useAppSelector(selectUserPartnerId);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: { ...initialPagination },
  });
  const dispatch = useAppDispatch();

  const fetchRecentlyCoupledTerminals = async (params: {
    pagination: IPagination;
    filters: Partial<IPaxTerminalQuery>;
  }) => {
    setLoading(true);

    if (partnerId) {
      params.filters.partnerId = partnerId;
    }
    await dispatch(
      getRecentlyCoupledTerminalsThunk({
        currentPage: params?.pagination?.current!,
        pageSize: params?.pagination?.pageSize!,
        ...params.filters,
      })
    )
      .then(({ payload }) => {
        //@ts-ignore
        let recentlyCoupledTerminals = payload.data;
        //@ts-ignore
        let totalCount = payload.totalCount;
        let recentlyCoupledTerminalsWithKeys = recentlyCoupledTerminals.map(
          (t: IPaxTerminal) => ({
            ...t,
            key: t?._id,
          })
        );
        setRecentlyCoupledTerminalsInTable(recentlyCoupledTerminalsWithKeys);

        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: totalCount,
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSync = async () => {
    await validateRecentlyCoupledTerminals();
    await fetchRecentlyCoupledTerminals({
      pagination: {
        current: tableParams.pagination?.current || 1,
        pageSize: tableParams.pagination?.pageSize!,
      },
      filters: {},
    });
  };

  const validateRecentlyCoupledTerminals = async () => {
    let retrievedCoupledTerminals: IPaxTerminal[] = [];
    let spreadFilters = { ...filters };
    if (partnerId) {
      spreadFilters.partnerId = partnerId;
    }
    if (recentlyCoupledTerminals?.length === 0) {
      let results = await dispatch(
        getRecentlyCoupledTerminalsThunk({
          currentPage: tableParams?.pagination?.current!,
          pageSize: tableParams?.pagination?.pageSize!,
          ...spreadFilters,
        })
      );
      //@ts-ignore
      retrievedCoupledTerminals = results.payload?.data as IPaxTerminal[];
    }
    if (
      recentlyCoupledTerminals?.length === 0 &&
      retrievedCoupledTerminals?.length === 0
    )
      return;

    const lastPushIds = recentlyCoupledTerminals.map((t) => t.lastPushId);

    setIsValidating(true);
    await dispatch(
      validateCouplingThunk({
        lastPushIds,
      })
    )
      .then(({ payload }) => {})
      .finally(() => {
        setIsValidating(false);
      });
  };

  useEffect(() => {
    validateRecentlyCoupledTerminals();

    fetchRecentlyCoupledTerminals({
      pagination: {
        current: tableParams.pagination?.current || 1,
        pageSize: tableParams.pagination?.pageSize!,
      },
      filters: {},
    });
  }, [JSON.stringify(tableParams), JSON.stringify(filters), lastUpdateKey]);

  const handleTableChange = (
    pagination: TablePaginationConfig
    // filters: Record<string, FilterValue>,
    // sorter: SorterResult<IBucketDeal>
  ) => {
    setTableParams({
      pagination,
      // filters,
      // ...sorter,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      // dispatch(emptyBucketDealsTable());
    }
  };

  return (
    <div className={styles.container} key="divTable">
      <GeneralTableHeader
        title="Recently Coupled Terminals"
        description="A list of all the recently updated terminals."
        actionButtons={[
          <Button
            type="primary"
            onClick={() => {
              navigate("/terminals/couple");
            }}
            icon={<PlusCircleOutlined></PlusCircleOutlined>}
            size={"large"}
          >
            Couple
          </Button>,
        ]}
        avatarUrl="https://secureretail.whatspos.com/admin/assets/img/model/A920PRO.png"
      >
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Statistic
              title="Count:"
              value={totalCount}
              loading={loading}
              // valueStyle={{ fontSize: "18px" }}
              // precision={2}
            />
            <Button
              disabled={isValidating || recentlyCoupledTerminals?.length === 0}
              onClick={handleSync}
              icon={
                <SyncOutlined
                  style={{ fontSize: "30px" }}
                  spin={isValidating}
                />
              }
              type="text"
              // loading={isValidating}
              size="large"
              shape="circle"
            ></Button>
          </div>
        </>
      </GeneralTableHeader>
      <div className={styles.table_header}>
        {/* <Input
          onChange={handleDebouncedNameFilterChange}
          placeholder="Business Name, Email, Kvk ..."
          value={filters.businessName}
          className={styles.searchInput}
          prefix={<SearchOutlined />}
          style={{ width: "400px" }}
        /> */}
      </div>
      <br />

      <div className={styles.table_container}>
        {recentlyCoupledTerminals.length === 0 && !loading && (
          <div style={{ height: "300px" }} className="center">
            <Empty
              description={"No Recent Terminals Found"}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            ></Empty>
          </div>
        )}
        {recentlyCoupledTerminals.length > 0 && !loading && (
          <Table
            dataSource={recentlyCoupledTerminals}
            pagination={tableParams.pagination}
            loading={loading}
            //@ts-ignore
            onChange={handleTableChange}
          >
            <Column
              title="Serial Number"
              className={styles.table_business_column}
              key="serialNumber"
              render={(_: any, record: IPaxTerminal) => (
                <div className={styles.business_details}>
                  <span key={record._id + "serialNumber"}>
                    {record.serialNumber}
                  </span>
                  {/* <span
                    style={{ color: "gray" }}
                    key={record._id + "businessRegistrationNumber"}
                  >
                    {record.businessRegistrationNumber}
                  </span> */}
                </div>
              )}
            />

            <Column
              title="Business"
              key="businessName"
              className={styles.table_column}
              render={(_: any, record: IPopulatedPaxTerminal) => (
                <span key={record._id + "businessName"}>
                  {record.businessId?.businessName || record.businessId}
                </span>
              )}
            />
            <Column
              title="Physical TID"
              key="physicalTid"
              className={styles.table_column}
              render={(_: any, record: IPopulatedPaxTerminal) => (
                <span key={record._id + "physicalTid"}>
                  {record.physicalTid}
                </span>
              )}
            />
            <Column
              title="Push Id"
              key="lastPushId"
              className={styles.table_column}
              render={(_: any, record: IPopulatedPaxTerminal) => (
                <span key={record._id + "lastPushId"}>{record.lastPushId}</span>
              )}
            />
            <Column
              title="Coupling Date"
              key="couplingDate"
              className={styles.table_column}
              render={(_: any, record: IPopulatedPaxTerminal) => (
                <span key={record._id + "couplingDate"}>
                  {formatDate(new Date(record.coupledOn), true)}
                </span>
              )}
            />
            <Column
              title="Coupling By"
              key="coupledBy"
              className={styles.table_column}
              render={(_: any, record: IPopulatedPaxTerminal) => (
                <span key={record._id + "coupledBy"}>{record.coupledBy}</span>
              )}
            />

            <Column
              title="Status"
              key="status"
              className={styles.table_column}
              render={(_: any, record: IPopulatedPaxTerminal) => (
                <div key={record._id + "status"} className="center">
                  <Tag
                    color={
                      record.couplingStatus === CouplingStatus.coupled
                        ? "green"
                        : record.couplingStatus === CouplingStatus.pending
                        ? "orange"
                        : "red"
                    }
                  >
                    {record.couplingStatus}
                  </Tag>

                  {record.couplingStatus === CouplingStatus.pending &&
                    _isMoreThan1Minute(record.coupledOn) && (
                      <Popover
                        title={"Check Wifi"}
                        content={
                          <>
                            <div style={{ maxWidth: "220px" }}>
                              The coupling is taking over 1 minute. <br />
                              Check if the terminal is connected to Wifi.
                            </div>
                          </>
                        }
                      >
                        <div
                          style={{
                            cursor: "default",
                            width: "50px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <WifiOutlined />
                          <div style={{ marginLeft: "4px" }}> ?</div>
                        </div>
                      </Popover>
                    )}
                </div>
              )}
            />
            <Column
              title="Platform"
              key="platform"
              className={styles.table_column}
              render={(_: any, record: IPopulatedPaxTerminal) => (
                <span key={record._id + "platform"}>
                  {record.platform === PlatformType.unitouch
                    ? "Unitouch"
                    : "Secure Retail"}
                </span>
              )}
            />
          </Table>
        )}
        {loading && (
          <Loader text="Fetching recently coupled terminals from server"></Loader>
        )}
      </div>
    </div>
  );
}

function _isMoreThan1Minute(date: string) {
  return new LocalDateAsUTC().time - new LocalDateAsUTC(date).time > 1000 * 60;
}

/**
 * 
 * 
 * const validateRecentlyCoupledTerminalsMemo = useMemo(async () => {
    if (!recentlyCoupledTerminals || recentlyCoupledTerminals.length === 0)
      return;
    setIsValidating(true);
    await dispatch(
      validateCouplingThunk({
        lastPushIds: recentlyCoupledTerminals.map((t) => t.lastPushId),
      })
    )
      .then(({ payload }) => {})
      .finally(() => {
        setIsValidating(false);
      });

    await fetchRecentlyCoupledTerminals({
      pagination: {
        current: tableParams.pagination?.current || 1,
        pageSize: tableParams.pagination?.pageSize!,
      },
      filters: {},
    });
  }, [recentlyCoupledTerminals]);
 */
