import React, { useState } from "react";
import { Menu, MenuProps, Spin } from "antd";
import { Button, Dropdown } from "antd";
import "./BusinessesActionMenu.module.css";
import { useAppDispatch } from "../../../../app/hooks";
import { ArrowDownOutlined } from "@ant-design/icons";
import { getBusinessesContactListServer } from "../../businessesApi";
import { downloadFile } from "../../../../utils/downloadFile";
import { unparse } from "papaparse";
import { createCSV } from "../../../promotions/utils/createCSV";

export default function BusinessesActionMenu() {
  const [loading, setLoading] = useState(false);
  const menu = (
    <Menu>
      <Menu.Item
        onClick={downloadBusinessesContactList}
        icon={
          !loading ? (
            <ArrowDownOutlined />
          ) : (
            <span className="center">
              <Spin></Spin>
            </span>
          )
        }
        disabled={loading}
        key="action-1"
      >
        Export
      </Menu.Item>
      {/* <Menu.Item>item 2</Menu.Item> */}
    </Menu>
  );

  async function downloadBusinessesContactList() {
    try {
      setLoading(true);
      const contactList = await getBusinessesContactListServer();

      const csv = _generateCSVFile(contactList);

      if (!csv) return;
      const fileName =
        "Businesses-contant-list" + new Date().toISOString() + ".csv";
      if (contactList) downloadFile(csv, fileName, "CSV");
    } catch (error) {
      console.log("ERROR", error);
    } finally {
      setLoading(false);
    }
  }

  const _generateCSVFile = (list: any[]) => {
    if (list?.length === 0) return;
    let content = unparse(list);
    let csv = createCSV(content);
    return csv;
  };

  return (
    <div>
      {" "}
      <>
        <Dropdown
          overlay={menu}
          placement="bottomLeft"
          arrow={{ pointAtCenter: true }}
        >
          <Button type="primary">Actions</Button>
        </Dropdown>
      </>
    </div>
  );
}
