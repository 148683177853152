import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

import {
  getPromotionsServer,
  updatePromotionsStatusServer,
} from "./promotionsApi";
import { removeManyPromotions } from "./utils/deleteMany";
import { sortPromotionsByAbc } from "./utils/sortPromotionsByAbc";
import {
  IPromotion,
  PromoStatus,
  PromoType,
} from "../../interfaces/promotion.interface";

export interface PromotionsState {
  promotions: IPromotion[];
  currentPromotions: IPromotion[];
  type: PromoType;
  status: PromoStatus;
  isLoading: boolean;
  filters: {
    [key: string]: ((promotions: IPromotion[]) => IPromotion[]) | null;
  };
}

const initialState: PromotionsState = {
  promotions: [],
  currentPromotions: [],
  type: "referredABusiness",
  status: "open",
  isLoading: false,
  filters: {},
};

export const getPromotionsThunk = createAsyncThunk(
  "promotions/",
  async (params: { type: PromoType; status: PromoStatus }) => {
    const response = await getPromotionsServer(params);

    return response;
  }
);

export const updatePromotionsStatusThunk = createAsyncThunk(
  "promotions/updatePromotionsStatusThunk",
  async (props: {
    promotions: IPromotion[];
    status: Partial<PromoStatus>;
    notify?: boolean;
  }) => {
    const { promotions, status, notify } = props;

    if (!(status === "pending" || status === "closed")) return;

    const promotionIds = promotions.map((p) => p._id);

    await updatePromotionsStatusServer({
      promotionIds,
      status,
      notify,
    });
    const updatedPromotions = promotions.map((p) => ({
      ...p,
      status,
    }));
    return updatedPromotions as IPromotion[];
  }
);

const applyFiltersToBusinesses = (
  promotions: IPromotion[],
  filters: {
    [key: string]: ((promotions: IPromotion[]) => IPromotion[]) | null;
  }
) => {
  const filterArr = Object.values(filters);
  const filteredBusinesses = filterArr.reduce((prev, cur) => {
    if (prev.length === 0) return [];
    if (cur) return cur(prev);
    else return prev;
  }, promotions);

  return filteredBusinesses;
};

export const promotionsSlice = createSlice({
  name: "promotions",
  initialState,
  reducers: {
    setPromotionStatus: (state, action: PayloadAction<PromoStatus>) => {
      state.status = action.payload;
      state.isLoading = false;
    },
    setPromotionType: (state, action: PayloadAction<PromoType>) => {
      state.type = action.payload;
    },
    filterPromotionsByName: (state, action: PayloadAction<string>) => {
      const name = action.payload;

      const nameFilter = (promotions: IPromotion[]) => {
        return promotions.filter(
          (p) =>
            p.businessName.toLowerCase().includes(name) ||
            p.businessReferralCode?.toLowerCase().includes(name)
          // p.rootUser?.email.toLowerCase().includes(name) ||
          // p.rootUser?.name.toLowerCase().includes(name) ||
          // p.businessReferred.startsWith(name)
        );
      };

      state.filters.name = name === "" ? null : nameFilter;
      state.currentPromotions = applyFiltersToBusinesses(
        state.promotions,
        state.filters
      );
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPromotionsThunk.fulfilled, (state, action) => {
        state.promotions = sortPromotionsByAbc(action.payload);
        state.currentPromotions = sortPromotionsByAbc(action.payload);
      })

      .addCase(updatePromotionsStatusThunk.fulfilled, (state, action) => {
        const updatedPromotions = action.payload;
        if (!updatedPromotions) return;
        const updatedOpenPromotions = removeManyPromotions(
          state.promotions,
          updatedPromotions
        );
        state.currentPromotions = updatedOpenPromotions!;
      });
  },
});

export const selectCurrentPromotions = (state: RootState) =>
  state.promotions.currentPromotions;
export const selectPromotionType = (state: RootState) => state.promotions.type;
export const selectPromotionStatus = (state: RootState) =>
  state.promotions.status;
export const selectIsLoading = (state: RootState) => state.promotions.isLoading;

export const { setPromotionStatus, setPromotionType, filterPromotionsByName } =
  promotionsSlice.actions;
export default promotionsSlice.reducer;
