import { Breadcrumb, Button } from "antd";
import { Link, Route, Routes } from "react-router-dom";
import NotificationForm from "./components/Notification-Form/NotificationForm";
import NotificationsTable from "./components/Notifications-Table/NotificationsTable";
import { PlusCircleOutlined } from "@ant-design/icons";

import styles from "./Notifications.module.css";
import { useState } from "react";
export default function Notifications() {
  const [showForm, setShowForm] = useState(false);
  return (
    <div>
      <div className={styles.nav_container}>
        <Button
          type="primary"
          onClick={() => {
            setShowForm(true);
          }}
          icon={<PlusCircleOutlined></PlusCircleOutlined>}
        >
          Schedule
        </Button>
      </div>
      <NotificationsTable></NotificationsTable>
      <NotificationForm
        visible={showForm}
        setVisible={setShowForm}
      ></NotificationForm>
    </div>
  );
}
