import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  getPartnersThunk,
  selectPartners,
  setCurrentPartner,
} from "../../partnersSlice";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Dropdown,
  List,
  Menu,
  MenuProps,
  Segmented,
  Skeleton,
  Tag,
} from "antd";
import {
  EditOutlined,
  EuroOutlined,
  TeamOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import styles from "./PartnersTable.module.css";
import Loader from "../../../common/loader/Loader";
import { selectUser } from "../../../access-control/accountSlice";
import {
  IPartner,
  PartnerType,
} from "../../../../interfaces/partner.interface";
import EditPartnerForm from "../edit-partner-form/EditPartnerForm";
import CreatePartnerReportFormModal from "../create-partner-report-form/CreatePartnerReportForm";
import PartnerTeamMemberModal from "../partner-team-member-modal/PartnerTeamMemberModal";

export default function PartnersTable() {
  const [loading, setLoading] = useState(false);
  const partners = useAppSelector(selectPartners);
  const dispatch = useAppDispatch();
  const [filters, setFilters] = useState<Partial<IPartner>>({});

  const [editPartnerFormVisible, setEditPartnerFormVisible] = useState(false);
  const [createPartnerReportFormVisible, setCreatePartnerReportFormVisible] =
    useState(false);
  const [partnerTeamMemberModalVisible, setPartnerTeamMemberModalVisible] =
    useState(false);
  useEffect(() => {
    getPartners(filters);
  }, [JSON.stringify(filters)]);

  async function getPartners(filters?: Partial<IPartner>, tableParams?: any) {
    setLoading(true);
    await dispatch(getPartnersThunk({ ...filters }));
    setLoading(false);
  }

  function handleEditPartner(partnerId: string) {
    dispatch(setCurrentPartner(partnerId));
    setEditPartnerFormVisible(true);
  }
  function handleCreatePartnerReport(partnerId: string) {
    dispatch(setCurrentPartner(partnerId));
    setCreatePartnerReportFormVisible(true);
  }
  function handlePartnerTeamMemberModal(partnerId: string) {
    dispatch(setCurrentPartner(partnerId));
    setPartnerTeamMemberModalVisible(true);
  }

  function getPartnerTypeColor(partnerType?: PartnerType) {
    switch (partnerType) {
      case PartnerType.SoftwareVendor:
        return "green";
      case PartnerType.Reseller:
        return "blue";
      case PartnerType.FullServicePartner:
        return "purple";
      case PartnerType.FullServiceWithResellers:
        return "gold";
      default:
        return "";
    }
  }
  function getPartnerTypeLabel(partnerType?: PartnerType) {
    switch (partnerType) {
      case PartnerType.SoftwareVendor:
        return "Software Vendor";
      case PartnerType.Reseller:
        return "Reseller";
      case PartnerType.FullServicePartner:
        return "Full Service Partner";
      case PartnerType.FullServiceWithResellers:
        return "Full Service With Resellers";
      default:
        return "No Partner Type";
    }
  }
  const handleTypeFilterChange = (value: PartnerType | "all") => {
    // dispatch(filterTableByStatus(value));

    setFilters((prev) => {
      if (value === "all") {
        //@ts-ignore
        delete prev.partnerType;
        return { ...prev };
      } else return { ...prev, partnerType: value };
    });
    // setTableParams((prev) => {
    //   return { ...prev, pagination: { ...initialPagination } };
    // });
  };
  return (
    <div>
      <Segmented
        key={"partnerType"}
        className={styles.segment_box}
        onChange={(value: any) => {
          handleTypeFilterChange(value);
        }}
        size="middle"
        options={[
          {
            label: <div className={styles.segment_item}>All</div>,
            value: "all",
          },

          {
            label: (
              <div className={styles.segment_item}>
                {getPartnerTypeLabel(PartnerType.SoftwareVendor)}
              </div>
            ),
            value: PartnerType.SoftwareVendor,
          },

          {
            label: (
              <div className={styles.segment_item}>
                {getPartnerTypeLabel(PartnerType.Reseller)}
              </div>
            ),
            value: PartnerType.Reseller,
          },
          {
            label: (
              <div className={styles.segment_item}>
                {getPartnerTypeLabel(PartnerType.FullServicePartner)}
              </div>
            ),
            value: PartnerType.FullServicePartner,
          },
          {
            label: (
              <div className={styles.segment_item}>
                {getPartnerTypeLabel(PartnerType.FullServiceWithResellers)}
              </div>
            ),
            value: PartnerType.FullServiceWithResellers,
          },
        ]}
      />

      {!loading && partners?.length > 0 && (
        <List
          itemLayout="horizontal"
          className={styles.list}
          dataSource={partners}
          renderItem={(item) => (
            <List.Item
              className={styles.list_item}
              actions={[
                <>
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item
                          onClick={() => {
                            handleEditPartner(item._id);
                          }}
                          icon={<EditOutlined />}
                        >
                          Edit
                        </Menu.Item>
                        <Menu.Item
                          onClick={() => {
                            handleCreatePartnerReport(item._id);
                          }}
                          icon={<EuroOutlined />}
                        >
                          Markup Report
                        </Menu.Item>
                        <Menu.Item
                          onClick={() => {
                            handlePartnerTeamMemberModal(item._id);
                          }}
                          icon={<TeamOutlined />}
                        >
                          Team Members
                        </Menu.Item>
                      </Menu>
                    }
                    placement="bottomLeft"
                  >
                    <Button type="default">Actions</Button>
                  </Dropdown>
                  {/* <Button> {">"}</Button> */}
                </>,
              ]}
            >
              <Skeleton avatar title={false} loading={loading} active>
                <List.Item.Meta
                  title={<>{item.name}</>}
                  description={
                    <div>
                      <span>{item.email}</span>
                      <br />
                      <span>{`+${item.phone.countryCode} ${item.phone.number}`}</span>
                      <br />
                    </div>
                  }
                />
                <div>
                  {" "}
                  <Tag
                    color={getPartnerTypeColor(item?.partnerType)}
                    icon={
                      !item?.partnerType ? (
                        <ExclamationCircleOutlined style={{ color: "red" }} />
                      ) : null
                    }
                  >
                    {getPartnerTypeLabel(item?.partnerType) ||
                      "No Partner Type"}
                  </Tag>{" "}
                </div>
              </Skeleton>
            </List.Item>
          )}
        />
      )}

      {loading && <Loader text="Getting Partners"></Loader>}
      {editPartnerFormVisible && (
        <EditPartnerForm
          visible={editPartnerFormVisible}
          setVisible={setEditPartnerFormVisible}
        />
      )}
      {createPartnerReportFormVisible && (
        <CreatePartnerReportFormModal
          visible={createPartnerReportFormVisible}
          setVisible={setCreatePartnerReportFormVisible}
        />
      )}
      {partnerTeamMemberModalVisible && (
        <PartnerTeamMemberModal
          visible={partnerTeamMemberModalVisible}
          setVisible={setPartnerTeamMemberModalVisible}
        />
      )}
    </div>
  );
}
