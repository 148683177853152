import React, { useEffect, useState } from "react";
import { IScheduledPush } from "../../../../../../interfaces/scheduledPush.interface";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Radio,
  Select,
  Space,
  notification,
} from "antd";
import { RangePickerProps } from "antd/lib/date-picker";
import { convertToDutchTimeZone } from "../../../../../../utils/convertTimezone";
import {
  IEmailNotification,
  NotificationType,
  TemplateParam,
} from "../../../../../../interfaces/notification.interface";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
interface IPropsNotificationStartForm {
  scheduledPush: IScheduledPush;
  onSubmit: (props: IScheduledPush) => void;
}

const TEMPLATES: {
  id: string;
  label: string;
  params: TemplateParam[];
}[] = [
  {
    id: "d-98e2ba211c1640598254a06f04789e47",
    label: "Maintenance",
    params: [
      { name: "date", label: "Date" },
      { name: "startTime", label: "Start Time" },
      { name: "endTime", label: "End Time" },
    ],
  },
  {
    id: "d-2d59049c140742c7a745f5052abf75d3",
    label: "General",
    params: [
      { name: "subject", label: "Subject" },
      { name: "paragraphOne", label: "Paragraph 1" },
      { name: "paragraphTwo", label: "Paragraph 2" },
    ],
  },
  {
    id: "d-62dcdbe821ee4bb6b2068c5b7829cfbc",
    label: "General Downtime",
    params: [],
  },
  {
    id: "d-fb4a48b67db14cb6940fe5d28566cfce",
    label: "Yazara Downtime",
    params: [],
  },
  {
    id: "d-a1f1a6db2f47447e87c172479d87bb5d",
    label: "Yazara downtime - issues resolved",
    params: [],
  },

  {
    id: "d-515ca8ebc22b4991a2c0d3abf47d3043",
    label: "Rapyd Downtime",
    params: [],
  },
  {
    id: "d-4217b600cfd4433a9fdf66fa3ce7d072",
    label: "Rapyd downtime - issues resolved",
    params: [],
  },
  {
    id: "d-c77bc13736a342119230cc8059cb2918",
    label: "Feature Release",
    params: [],
  },
];

const NotificationFieldLayout = {
  labelCol: { span: 12 },
  wrapperCol: { span: 12 },
};

const NOTIFICATION_TYPES_CONFIG: {
  label: string;
  value: NotificationType;
}[] = [
  { label: "Push", value: "NOTIFICATION" },
  { label: "In-App", value: "INAPP" },
  { label: "Email", value: "EMAIL" },
];

export default function NotificationStartForm({
  scheduledPush,
  onSubmit,
}: IPropsNotificationStartForm) {
  const [form] = Form.useForm();
  const randomUUID = uuidv4();
  const [currentType, setCurrentType] = useState(
    scheduledPush?.notification?.type
  );
  const [currentTemplateParams, setCurrentTemplateParams] = useState<
    TemplateParam[]
  >(
    scheduledPush?.notification?.type === "EMAIL"
      ? //@ts-ignore
        TEMPLATES.find((t) => scheduledPush?.notification?.templateId === t.id)
          ?.params || []
      : []
  );
  useEffect(() => {
    if (scheduledPush) {
      if ((scheduledPush?.notification as IEmailNotification)?.templateId) {
        let id = (scheduledPush?.notification as IEmailNotification)
          ?.templateId;
        setCurrentTemplateParams(
          TEMPLATES.find((t) => t.id === id)?.params || []
        );
      }
      console.log("scheduledPush", scheduledPush);
      form.setFieldsValue({
        timestamp: scheduledPush.timestamp
          ? moment(scheduledPush.timestamp)
          : "",
        notification: scheduledPush.notification,
      });
    }
  }, []);

  const handleSubmit = (
    values: IScheduledPush & { timestamp: moment.Moment } & {
      notification: { expiration?: any };
    }
  ) => {
    values.notification.analyticsIdentifier = randomUUID;
    if (values.notification.type === "INAPP" && values.notification.expiration)
      values.notification.expiration =
        values.notification.expiration?.toISOString();
    let scheduledPush = {
      ...values,
      timestamp: values.timestamp.toISOString(),
    };

    console.log("Schd", scheduledPush);
    onSubmit(scheduledPush);
  };

  const disabledDate: RangePickerProps["disabledDate"] = (selected) => {
    // Can not select days before today
    let selectedDutchDate = convertToDutchTimeZone(selected.toISOString());
    let currentDutchDate = convertToDutchTimeZone(new Date());
    return (
      selectedDutchDate &&
      selectedDutchDate.getDate() < currentDutchDate.getDate()
    );
  };
  const disabledTime: RangePickerProps["disabledTime"] = (_, type) => {
    return {};
  };

  const onTypeChange = (event: any) => {
    const value: NotificationType = event?.target?.value;
    setCurrentType(value);
  };
  const onEmailTemplateChange = (templateId: string) => {
    const templateParams = TEMPLATES.find((t) => t.id === templateId);
    if (templateParams) setCurrentTemplateParams(templateParams?.params);
  };

  return (
    <div>
      <Form form={form} onFinish={handleSubmit} style={{ width: "100%" }}>
        <Form.Item
          name="timestamp"
          label="Date & Time"
          rules={[{ required: true, message: "Timestamp is required" }]}
          help="Shown as local time zone"
          wrapperCol={{ ...NotificationFieldLayout.wrapperCol }}
          labelCol={{ ...NotificationFieldLayout.labelCol }}
          labelAlign="left"
          style={{ marginBottom: "10px" }}
        >
          <DatePicker
            showTime
            format="DD-MM-YYYY HH:mm:ss"
            // disabledDate={disabledDate}
            //@ts-ignore
            disabledTime={disabledTime}
            showSecond={false}
            showNow={false}
          />
        </Form.Item>
        <Input.Group>
          <Form.Item
            name={["notification", "type"]}
            // noStyle
            rules={[{ required: true, message: "Type is required" }]}
            label={"Type"}
            wrapperCol={{ ...NotificationFieldLayout.wrapperCol }}
            labelCol={{ ...NotificationFieldLayout.labelCol }}
            labelAlign="left"
          >
            <Radio.Group
              defaultValue="a"
              buttonStyle="solid"
              onChange={onTypeChange}
            >
              {NOTIFICATION_TYPES_CONFIG.map((g) => (
                <Radio value={g.value}>{g.label}</Radio>
              ))}
            </Radio.Group>
          </Form.Item>
          {currentType !== "EMAIL" ? (
            <>
              <Form.Item
                name={["notification", "title"]}
                // noStyle
                rules={[{ required: true, message: "Title is required" }]}
              >
                <Input placeholder="title" />
              </Form.Item>
              <Form.Item
                name={["notification", "description"]}
                // noStyle
                rules={[{ required: true, message: "Description is required" }]}
              >
                <Input.TextArea
                  rows={4}
                  placeholder="Description"
                  maxLength={200}
                />
              </Form.Item>
              <Form.Item name={["notification", "analyticsIdentifier"]}>
                <Input placeholder={randomUUID} disabled />
              </Form.Item>
              {/* {form.getFieldValue("notification")?.type === "INAPP" && ( */}
              <Form.Item shouldUpdate>
                {() => {
                  return (
                    <Form.Item
                      name={["notification", "expiration"]}
                      // rules={[
                      //   { required: true, message: "Expiration is required" },
                      // ]}
                    >
                      <DatePicker
                        showTime
                        format="DD-MM-YYYY HH:mm:ss"
                        disabled={
                          form.getFieldValue("notification")?.type !== "INAPP"
                        }
                        disabledDate={disabledDate}
                        //@ts-ignore
                        disabledTime={disabledTime}
                        showSecond={false}
                        showNow={false}
                        placeholder="Select expiration date"
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </>
          ) : (
            <>
              <Form.Item
                name={["notification", "templateId"]}
                label={"Template"}
                wrapperCol={{ ...NotificationFieldLayout.wrapperCol }}
                labelCol={{ ...NotificationFieldLayout.labelCol }}
                labelAlign="left"
              >
                <Select
                  onChange={onEmailTemplateChange}
                  options={TEMPLATES.map((t) => ({
                    value: t.id,
                    label: t.label,
                  }))}
                ></Select>
              </Form.Item>

              {currentTemplateParams?.map((p) => (
                <Form.Item
                  name={["notification", "templateParams", p.name]}
                  label={p.label}
                  wrapperCol={{ ...NotificationFieldLayout.wrapperCol }}
                  labelCol={{ ...NotificationFieldLayout.labelCol }}
                  labelAlign="left"
                >
                  <Input.TextArea
                    rows={1}
                    placeholder={p.label}
                    // maxLength={500}
                  />
                </Form.Item>
              ))}
            </>
          )}
        </Input.Group>
        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ float: "right" }}>
            Next
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
