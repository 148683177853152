// import axios from "axios";
import { CONFIG } from "../../config";
import {
  IPartner,
  IPartnerTeamMember,
} from "../../interfaces/partner.interface";
import { IReportRequest } from "../../interfaces/requestReport.interface";
import { getTokenizedAxios } from "../../utils/axiosWithTokenGen";

export async function getPartnersFromServer(
  params?: Partial<IPartner & { partnerId: string }>
): Promise<{
  partners: IPartner[];
}> {
  const axios = await getTokenizedAxios();
  const path = "/partners/";

  return axios
    .get(CONFIG.ADMIN_SERVER.BASE_URL + path, { params })
    .then((data) => data?.data);
}
export async function getPartnerFromServer(
  partnerId: string
): Promise<{ partner: IPartner }> {
  const axios = await getTokenizedAxios();
  const path = "/partners/";

  return axios
    .get(CONFIG.ADMIN_SERVER.BASE_URL + path + partnerId)
    .then((data) => data?.data);
}
export async function getPartnerMarkupReportFromServer(
  reportRequest: IReportRequest
): Promise<{
  report: string;
  isEmailed: boolean;
  isEmpty: boolean;
}> {
  const axios = await getTokenizedAxios();
  const path = "/partners/reports/markup/";

  return axios
    .get(CONFIG.ADMIN_SERVER.BASE_URL + path, { params: reportRequest })
    .then((data) => data?.data);
}

export async function createPartnerServer(
  partner: Partial<IPartner>
): Promise<IPartner> {
  const axios = await getTokenizedAxios();
  const path = "/partners/create";

  return axios
    .post(CONFIG.ADMIN_SERVER.BASE_URL + path, { partner })
    .then((data) => data?.data);
}

export async function createPartnerTeamMemberServer(params: {
  teamMember: Partial<IPartnerTeamMember>;
  partnerId: string;
}): Promise<IPartner> {
  const axios = await getTokenizedAxios();
  const path = "/partners/teamMembers/create";

  return axios
    .post(CONFIG.ADMIN_SERVER.BASE_URL + path, params)
    .then((data) => data?.data);
}
export async function editPartnerServer({
  partnerId,
  update,
}: {
  partnerId: string;
  update: Partial<IPartner>;
}): Promise<IPartner> {
  const axios = await getTokenizedAxios();
  const path = "/partners/edit/";

  return axios
    .put(CONFIG.ADMIN_SERVER.BASE_URL + path + partnerId, { update })
    .then((data) => data?.data);
}
