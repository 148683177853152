export function formatCommasAndDots(n: number, lang: "EN" | "NL"): string {
  let num;
  const stringNum = n.toFixed(2);
  let parts = stringNum.split(".");
  if (lang === "EN")
    num =
      parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
      (parts[1] ? "." + parts[1] : "");
  else
    num =
      parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") +
      (parts[1] ? "," + parts[1] : "");
  return num;
}
