export const SERVER = {
  BASE_URL:
    process.env.REACT_APP_STAGE === "prod"
      ? `https://v1.lemonpayapp.com`
      : "https://api.lemonpayapp.com",
};

export const ADMIN_SERVER = {
  BASE_URL:
    process.env.REACT_APP_STAGE === "prod"
      ? `https://admin-api-prod.lemonpayapp.com`
      : "https://admin-api-dev.lemonpayapp.com",
};
export const WEB_PORTAL = {
  BASE_URL:
    process.env.REACT_APP_STAGE === "prod"
      ? `https://www.dashboard.klearly.eu`
      : "https://www.dashboard.dev.klearly.eu",
};

export const SIZES = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
};

export const CLIENT = {
  DEVICE: {
    mobileS: `(min-width: ${SIZES.mobileS})`,
    mobileM: `(min-width: ${SIZES.mobileM})`,
    mobileL: `(min-width: ${SIZES.mobileL})`,
    tablet: `(min-width: ${SIZES.tablet})`,
    laptop: `(min-width: ${SIZES.laptop})`,
    laptopL: `(min-width: ${SIZES.laptopL})`,
    desktop: `(min-width: ${SIZES.desktop})`,
    desktopL: `(min-width: ${SIZES.desktop})`,
  },
};

export const CONFIG = { SERVER, ADMIN_SERVER, CLIENT };
