import {
  createAsyncThunk,
  createSlice,
  current,
  isAnyOf,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../app/store";
import { IBucketDeal } from "../../interfaces/bucketDeal.interface";
import {
  IInAppNotification,
  INotification,
  NotificationGroup,
  TargetAttributes,
} from "../../interfaces/notification.interface";
import { IScheduledPush } from "../../interfaces/scheduledPush.interface";

import {
  cancelScheduledPushServer,
  getPushesScheduledServer,
  pushNotificationServer,
  scheduleAPushServer,
} from "./notificationsApi";
// import { fetchCount } from './counterAPI';

export interface NotificationState {
  currentNotification: INotification | IInAppNotification;
  currentGroup: NotificationGroup;
  currentTargetAttributes: TargetAttributes;
  currentScheduledPush: IScheduledPush;
  pushesScheduled: IScheduledPush[];
}

const initialState: NotificationState = {
  currentNotification: {} as any,
  currentScheduledPush: {} as any,
  currentTargetAttributes: {} as any,
  currentGroup: "ALL_USERS",
  pushesScheduled: [],
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(getBusinesses(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const pushNotificationThunk = createAsyncThunk(
  "notification/push",
  async (params: {
    notification: Partial<INotification | IInAppNotification>;
    targetAttributes: TargetAttributes;
  }) => {
    const response = await pushNotificationServer(params);
    return response;
  }
);
export const getPushesScheduledThunk = createAsyncThunk(
  "notification/getPushesScheduled",
  async () => {
    const response = await getPushesScheduledServer();
    return response;
  }
);
export const scheduleAPushThunk = createAsyncThunk(
  "notification/scheduleAPush",
  async (params: Partial<IScheduledPush>) => {
    const response = await scheduleAPushServer(params);
    return response;
  }
);
export const cancelScheduledPushThunk = createAsyncThunk(
  "notification/cancelScheduledPush",
  async (params: { executionArn: string; scheduledPushId: string }) => {
    const response = await cancelScheduledPushServer(params);
    return response;
  }
);

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setCurrentNotification: (state, action: PayloadAction<INotification>) => {
      state.currentNotification = action.payload;
    },
    setCurrentScheduledPush: (state, action: PayloadAction<IScheduledPush>) => {
      state.currentScheduledPush = action.payload;
    },
    setCurrentGroup: (state, action: PayloadAction<NotificationGroup>) => {
      state.currentGroup = action.payload;
    },
    setCurrentTargetAttributes: (
      state,
      action: PayloadAction<TargetAttributes>
    ) => {
      state.currentTargetAttributes = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(pushNotificationThunk.fulfilled, (state, action) => {
        state.currentNotification = {} as any;
        state.currentGroup = "ALL_USERS";
      })
      .addCase(scheduleAPushThunk.fulfilled, (state, action) => {
        console.log("action.payload", action.payload);
        state.pushesScheduled = [action.payload, ...state.pushesScheduled];
        // state.pushesScheduled.push(action.payload);
      })
      .addCase(getPushesScheduledThunk.fulfilled, (state, action) => {
        state.pushesScheduled = action.payload;
      })
      .addCase(cancelScheduledPushThunk.fulfilled, (state, action) => {
        state.pushesScheduled = state.pushesScheduled.map((i) =>
          i._id == action.payload._id ? action.payload : i
        );
      });
  },
});

// export const { getBusinesses } = businessesSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const selectCurrentNotification = (state: RootState) =>
  state.notifications.currentNotification;
export const selectCurrentScheduledPush = (state: RootState) =>
  state.notifications.currentScheduledPush;
export const selectCurrentGroup = (state: RootState) =>
  state.notifications.currentGroup;
export const selectCurrentTargetAttributes = (state: RootState) =>
  state.notifications.currentTargetAttributes;
export const selectPushesScheduled = (state: RootState) =>
  state.notifications.pushesScheduled;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
//   (amount: number): AppThunk =>
//   (dispatch, getState) => {
//     const currentValue = selectCount(getState());
//     if (currentValue % 2 === 1) {
//       dispatch(incrementByAmount(amount));
//     }
//   };

export const {
  setCurrentNotification,
  setCurrentScheduledPush,
  setCurrentGroup,
  setCurrentTargetAttributes,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
