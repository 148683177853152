import { Button, Form, Input, Space } from "antd";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import {
  createPartnerTeamMemberThunk,
  selectCurrentPartner,
} from "../../../../partnersSlice";
import styles from "./CreatePartnerTeamMemberForm.module.css";
import { IPartnerTeamMember } from "../../../../../../interfaces/partner.interface";
import { InfoCircleOutlined } from "@ant-design/icons";

const partnerFormFieldLayout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 12 },
};
interface IPropsCreatePartnerTeamMemberForm {
  setIsVisible: (value: boolean) => void;
}

export default function CreatePartnerTeamMemberForm({
  setIsVisible,
}: IPropsCreatePartnerTeamMemberForm) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useAppDispatch();
  const partner = useAppSelector(selectCurrentPartner);

  const handleSubmit = async (values: Partial<IPartnerTeamMember>) => {
    setLoading(true);
    setError("");

    await dispatch(
      createPartnerTeamMemberThunk({
        partnerId: partner?._id!,
        teamMember: values,
      })
    ).then((results) => {
      //@ts-ignore
      let error = results?.error as { name: string };
      if (error && error?.name === "UsernameExistsException")
        setError(
          "Email belongs to another user. Please choose a different email."
        );

      if (results.payload) setIsVisible(false);
    });

    setLoading(false);
  };

  const handleReset = () => {
    form.resetFields();
  };

  return (
    <div>
      <Form
        onFinish={handleSubmit}
        form={form}
        // labelCol={{ ...partnerFormFieldLayout.labelCol }}
        // wrapperCol={{ ...partnerFormFieldLayout.wrapperCol }}
      >
        <Form.Item
          name="name"
          label="Name"
          labelCol={{ ...partnerFormFieldLayout.labelCol }}
          labelAlign="left"
          rules={[
            {
              required: true,
              message: "Missing Name",
            },
          ]}
        >
          <Input style={{ width: "200px" }} />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          labelCol={{ ...partnerFormFieldLayout.labelCol }}
          labelAlign="left"
          rules={[
            {
              required: true,
              message: "Missing Email",
            },
          ]}
        >
          <Input style={{ width: "200px" }} />
        </Form.Item>

        <Form.Item>
          <Button
            style={{ float: "right", marginTop: "20px" }}
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            Create
          </Button>
        </Form.Item>
      </Form>

      {error && (
        <div className={styles.error_box}>
          <Space align="center">
            <InfoCircleOutlined /> {error}
          </Space>
        </div>
      )}
    </div>
  );
}
