import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { RootState, AppThunk, store } from "../../app/store";
import {
  logoutServer,
  refreshTokenSignInServer,
  respondToSignInServer,
  signInServer,
  verifySoftwareTokenServer,
} from "./accountAPI";
// import { getBusinessesFromServer } from "./businessesApi";
// import { fetchCount } from './counterAPI';
import { v4 as uuidv4 } from "uuid";
import { AccessLevel } from "../../interfaces/accessLevel.enum";
import { PlatformType } from "../../interfaces/paxTerminal.interface";
export interface accountState {
  idToken: string;
  status: "idle" | "loading" | "failed";
  sessionToken?: string;
  MFASetupComplete?: boolean;
  MFASetupQrcode?: string;
  accessLevel: AccessLevel[];
  isRefreshingAccess: boolean;
  user: {
    name: string;
    email: string;
    partnerId?: string;
    partnerName?: string;
  };
  couplingPlatformAccess?: PlatformType;
}

const initialState: accountState = {
  idToken: "",
  sessionToken: "",
  status: "idle",
  MFASetupComplete: false,
  MFASetupQrcode: "",
  accessLevel: [],
  isRefreshingAccess: false,
  user: { name: "", email: "", partnerId: "", partnerName: "" },
};

export const signIn = createAsyncThunk(
  "account/signIn",
  async (props: { email: string; password: string }) => {
    const response = await signInServer(props);

    return response;
  }
);
export const verifySoftwareToken = createAsyncThunk(
  "account/verifySoftwareToken",
  async (props: { session: string; confirmationCode: string }) => {
    const response = await verifySoftwareTokenServer(props);
    return response;
  }
);
export const respondToSignIn = createAsyncThunk(
  "account/respondToSignIn",
  async (props: {
    session: string;
    confirmationCode: string;
    username: string;
  }) => {
    let deviceId = window.localStorage.getItem("deviceId");
    if (!deviceId) {
      deviceId = uuidv4();
    }

    const response = await respondToSignInServer({ ...props, deviceId });

    if ("tokens" in response) {
      window.sessionStorage.setItem(
        "refreshToken",
        response.tokens.refreshToken
      );
      window.localStorage.setItem("deviceId", deviceId);
    }
    return response;
  }
);

export const refreshTokenSignIn = createAsyncThunk(
  "account/refreshTokenSignIn",
  async (props: { refreshToken: string }) => {
    const response = await refreshTokenSignInServer(props);
    // The value we return becomes the `fulfilled` action payload

    return response;
  }
);
export const logout = createAsyncThunk("account/logout", async () => {
  const refreshToken = sessionStorage.getItem("refreshToken");

  if (!refreshToken) return;
  const response = await logoutServer({ refreshToken });
  sessionStorage.removeItem("refreshToken");
  return response;
});

export const accountSlice = createSlice({
  name: "accountSlice",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setIdTokenFrom401Request: (
      state,
      action: PayloadAction<{ idToken: string }>
    ) => {
      state.idToken = action.payload.idToken;
    },
    resetUserState: (state) => {
      state.idToken = "";
      state.sessionToken = "";
      state.status = "idle";
      state.MFASetupComplete = false;
      state.MFASetupQrcode = "";
      state.accessLevel = [];
      state.isRefreshingAccess = false;
      state.user = { name: "", email: "", partnerId: "" };
      state.couplingPlatformAccess = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state) => {
        state.status = "loading";
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.status = "idle";
        state.sessionToken = action.payload.session;
        state.MFASetupQrcode = action.payload.qrcode;
        state.MFASetupComplete = !action.payload.qrcode;
      })
      .addCase(verifySoftwareToken.fulfilled, (state, action) => {
        state.status = "idle";
        state.MFASetupComplete = action.payload.status === "SUCCESS";
        state.sessionToken =
          action.payload.status === "SUCCESS" ? "" : action.payload.session;
      })
      .addCase(signIn.rejected, (state, action) => {
        if (action.error.message && action.error.code) state.status = "failed";
      })
      .addCase(respondToSignIn.fulfilled, (state, action) => {
        if ("tokens" in action.payload) {
          state.idToken = action.payload.tokens.idToken;
          state.accessLevel = action.payload.accessLevel;
          state.couplingPlatformAccess = action.payload.couplingPlatformAccess;
          state.user = action.payload.user;
        }

        if ("session" in action.payload)
          state.sessionToken = action.payload.session;
        //here we also locally save the refresh token somewhere..
      })
      .addCase(respondToSignIn.rejected, (state) => {
        state.sessionToken = "";
        //here we also locally save the refresh token somewhere..
      })
      .addCase(refreshTokenSignIn.fulfilled, (state, action) => {
        let currrentRefreshToken = sessionStorage.getItem("refreshToken");
        sessionStorage.setItem("refreshToken", currrentRefreshToken!);
        state.idToken = action.payload.idToken;
        state.accessLevel = action.payload.accessLevel;
        state.couplingPlatformAccess = action.payload.couplingPlatformAccess;
        state.user = action.payload.user;
        state.isRefreshingAccess = false;
      })
      .addCase(refreshTokenSignIn.pending, (state, action) => {
        state.isRefreshingAccess = true;
      })
      .addCase(refreshTokenSignIn.rejected, (state, action) => {
        state.idToken = "";
        state.sessionToken = "";
        sessionStorage.removeItem("refreshToken");
        state.isRefreshingAccess = false;
      })
      .addCase(logout.fulfilled, (state) => {
        state.idToken = "";
        state.sessionToken = "";
        state.accessLevel = [];
        //here we also destroy the refresh token locally..
        sessionStorage.removeItem("refreshToken");
      });
  },
});

// export const { getBusinesses } = businessesSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const selectIdToken = (state: RootState) => state.account.idToken;
export const selectUserAccessLevel = (state: RootState) =>
  state.account.accessLevel;
export const selectCouplingPlatformAccessLevel = (state: RootState) =>
  state.account.couplingPlatformAccess;
export const selectIsKlearlyAdmin = (state: RootState) =>
  state.account.accessLevel?.some(
    (access) =>
      AccessLevel.KlearlyAdmin === access ||
      AccessLevel.KlearlySuperAdmin === access
  );
export const selectIsCoretechdEmployee = (state: RootState) =>
  state.account.accessLevel?.some(
    (access) => AccessLevel.CoreTechEmployee === access
  );
export const selectIsKlearlySuperAdmin = (state: RootState) =>
  state.account.accessLevel?.some(
    (access) => AccessLevel.KlearlySuperAdmin === access
  );
export const selectIsKlearlyAnalyticsAdmin = (state: RootState) =>
  state.account.accessLevel?.some(
    (access) => AccessLevel.KlearlyAnalytics === access
  );
export const selectIsKlearlySalesAdmin = (state: RootState) =>
  state.account.accessLevel?.some(
    (access) => AccessLevel.KlearlySalesAdmin === access
  );
export const selectIsKlearlySalesSuperAdmin = (state: RootState) =>
  state.account.accessLevel?.some(
    (access) =>
      AccessLevel.KlearlySalesSuperAdmin === access ||
      AccessLevel.KlearlySuperAdmin === access
  );
export const selectUserRole = (state: RootState) => {
  if (state.account.accessLevel?.includes(AccessLevel.KlearlySuperAdmin))
    return "Super Admin";
  if (state.account.accessLevel?.includes(AccessLevel.KlearlyAdmin))
    return "Admin";
  if (state.account.accessLevel?.includes(AccessLevel.CoreTechEmployee))
    return "CoreTech Partner";
  if (state.account.accessLevel?.includes(AccessLevel.Partner))
    return "Klearly Partner";
};
export const selectUser = (state: RootState) => state.account.user;
export const selectUserPartnerId = (state: RootState) =>
  state.account.user?.partnerId;
export const selectIsRefreshingAccess = (state: RootState) =>
  state.account.isRefreshingAccess;
export const selectSessionToken = (state: RootState) =>
  state.account.sessionToken;
export const selectMFASetupComplete = (state: RootState) =>
  state.account.MFASetupComplete;
export const selectMFASetupQrcode = (state: RootState) =>
  state.account.MFASetupQrcode;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
//   (amount: number): AppThunk =>
//   (dispatch, getState) => {
//     const currentValue = selectCount(getState());
//     if (currentValue % 2 === 1) {
//       dispatch(incrementByAmount(amount));
//     }
//   };
export const { setIdTokenFrom401Request, resetUserState } =
  accountSlice.actions;

export default accountSlice.reducer;
