import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Space,
  notification,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import Loader from "../../../common/loader/Loader";
import {
  createMultBucketDealsThunk,
  selectBucketDeals,
  selectCurrentBucketDeal,
} from "../../bucketDealsSlice";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { IBucketDeal } from "../../../../interfaces/bucketDeal.interface";
import styles from "./BucketDealCreator.module.css";
import BucketDealFileUploader from "../Bucket-Deal-File-Uploader/BucketDealFileUploader";
import { setError } from "../../../errors/errorsSlice";
const Option = Select.Option;
const MOCK_DEV_BUCKETS = [
  {
    name: "Domestic debit cards",
    value: 0.5,
    valueType: "Percentage",
  },
  {
    name: "Domestic credit cards",
    value: 0.5,
    valueType: "Percentage",
  },
  {
    name: "Foreign debit cards",
    value: 0.5,
    valueType: "Percentage" as "Percentage",
  },
  {
    name: "Foreign credit cards",
    value: 0.5,
    valueType: "Percentage" as "Percentage",
  },
];
interface IPropsBucketDealCreatorModel {
  visible: boolean;
  setVisible: any;
}

export default function BucketDealCreatorModal({
  visible,
  setVisible,
}: IPropsBucketDealCreatorModel) {
  const [loading, setLoading] = useState(false);
  const existingBucketDeals = useAppSelector(selectBucketDeals);
  const currentBucketDeal = useAppSelector(selectCurrentBucketDeal);
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [api, contextHolder] = notification.useNotification();

  const notifyDuplicateKVK = (duplicatesList: { [key: string]: any }[]) => {
    api.warning({
      message: `Duplicate Bucket Deals Found`,
      description: (
        <div
          style={{
            overflowY: "scroll",
            height: "600px",
          }}
        >
          <p style={{ color: "grey" }}>
            {" "}
            Please remove the following duplicate entries from the CSV file:
          </p>

          <div
            style={{
              borderBottom: "black solid 1px",
            }}
          >
            {duplicatesList.map((d) => (
              <p>- {d?.businessRegistrationNumber}</p>
            ))}
          </div>
        </div>
      ),
      placement: "top",
      duration: null,
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      businessName: currentBucketDeal.businessName,
      buckets: currentBucketDeal.buckets,
      salesRepresentative: currentBucketDeal?.salesRepresentative,
      businessRegistrationNumber: currentBucketDeal.businessRegistrationNumber,
    });
  }, [currentBucketDeal]);

  const handleCSVUpload = (
    data: {
      [key: string]: any;
    }[]
  ) => {
    if (!data || data.length == 0) {
      dispatch(
        setError({
          message:
            "CSV upload seems to contain no businesses or has incorrect format",
          code: "CSVUploadException",
        })
      );
      return;
    }

    data.every((b, i) => {
      if (!b.businessRegistrationNumber || !b.businessName) {
        dispatch(
          setError({
            message: `CSV contains an item without a registration number or business name at position ${i}`,
            code: "CSVUploadException",
          })
        );

        return;
      }
    });

    data = data.filter(
      (b) => b?.businessRegistrationNumber !== "" && b?.businessName !== ""
    );

    let duplicatesList = data.filter((uploadedBucket, i) => {
      return (
        // existingBucketDeals.find(
        //   (b) =>
        //     b.businessRegistrationNumber ==
        //     uploadedBucket.businessRegistrationNumber
        // ) ||
        data.find((adjacentUploadedBucket, j) => {
          return (
            uploadedBucket.businessRegistrationNumber ===
              adjacentUploadedBucket.businessRegistrationNumber && i !== j
          );
        })
      );
    });

    if (duplicatesList.length > 0) {
      notifyDuplicateKVK(duplicatesList);
      return;
    }

    form.setFieldsValue({
      businessDetails: data,
    });
  };

  const handleCancel = () => {
    setVisible(false);
    handleResetForm();
  };

  function isNumeric(value: any) {
    return /^\d+$/.test(value);
  }
  const handleResetForm = () => {
    form.resetFields();
    form.setFieldsValue({
      businessName: currentBucketDeal.businessName,
      buckets: currentBucketDeal.buckets,
      businessRegistrationNumber: currentBucketDeal.businessRegistrationNumber,
    });
  };

  const handleSubmit = async (
    values: Partial<IBucketDeal> & { businessDetails: Partial<IBucketDeal>[] }
  ) => {
    setLoading(true);

    const bucketDeals = values.businessDetails?.map(
      (b) =>
        ({
          businessRegistrationNumber: b.businessRegistrationNumber,
          businessName: b.businessName,
          buckets: values.buckets,
          // process.env.REACT_APP_STAGE === "prod"
          //   ? values.buckets
          //   : MOCK_DEV_BUCKETS,
        } as IBucketDeal)
    );

    await dispatch(createMultBucketDealsThunk(bucketDeals));

    setLoading(false);
    handleResetForm();
    setVisible(false);
  };

  const formItemLayout = {
    labelCol: { span: 10 },
    // wrapperCol: { span: 10 },
  };
  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 20, offset: 4 },
    },
  };
  const kvkFormItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 10 },
  };
  const buttonItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 10 },
  };
  return (
    <Modal
      visible={visible}
      title={"Create new bucket deals"}
      onCancel={handleCancel}
      footer={null}
      width={600}
    >
      <div>
        {contextHolder}

        <Form
          {...formItemLayout}
          onFinish={handleSubmit}
          form={form}
          className={styles.bucket_deal_form}
        >
          <Form.List
            name="businessDetails"
            initialValue={[
              { businessRegistrationNumber: "", businessName: "" },
            ]}
            rules={[
              {
                validator: async (_, businessDetails) => {
                  if (
                    !businessDetails ||
                    (businessDetails.length < 1 &&
                      businessDetails[0].businessRegistrationNumber)
                  ) {
                    return Promise.reject(new Error("At least 1 business"));
                  }
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: "flex", marginBottom: 8 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "businessRegistrationNumber"]}
                      rules={[
                        {
                          required: true,
                          message: "Missing Business Registration Number",
                        },
                      ]}
                    >
                      <Input placeholder="Business Registration Number" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "businessName"]}
                      rules={[
                        { required: true, message: "Missing business name" },
                      ]}
                    >
                      <Input placeholder="Business Name" />
                    </Form.Item>
                    {fields.length > 1 ? (
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    ) : null}
                  </Space>
                ))}
                <Form.Item>
                  <Space>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Business
                    </Button>
                    <BucketDealFileUploader
                      uploadCSVCallback={handleCSVUpload}
                    />
                  </Space>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
          <Form.Item
            name="salesRepresentative"
            label="Klearly Employee"
            {...kvkFormItemLayout}
          >
            <Select
              options={[
                { label: "Tom R", value: "Tom R" },
                { label: "Geus W", value: "Geus W" },
                { label: "Leonard D.B", value: "Leonard D.B" },
              ]}
            />
          </Form.Item>
          <Form.List name={"buckets"}>
            {(fields) => (
              <div>
                {fields.map(({ key, name, ...restField }) => (
                  <Space key={key} align="baseline">
                    <Form.Item {...restField} name={[name, "name"]}>
                      <Input
                        className={styles.bucket_name_input}
                        disabled
                      ></Input>
                    </Form.Item>

                    <Form.Item {...restField} name={[name, "value"]}>
                      <InputNumber
                        className={styles.bucket_value_input}
                        min={0}
                      />
                    </Form.Item>
                    <Form.Item {...restField} name={[name, "valueType"]}>
                      <Select
                        className={styles.bucket_value_input}
                        style={{ width: "90px" }}
                      >
                        <Option value={"Percentage"}>%</Option>
                        <Option value={"Fixed"}>FIXED</Option>
                      </Select>
                    </Form.Item>
                  </Space>
                ))}
              </div>
            )}
          </Form.List>

          <Form.Item {...buttonItemLayout}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>

      {loading && <Loader text="Adding Bucket Deals" />}
    </Modal>
  );
}
