import React, { useCallback, useEffect, useRef } from "react";
import { Button, Tooltip } from "antd";
import { PlusCircleOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import styles from "./BucketDealFileUploader.module.css";
import { parse } from "papaparse";

interface IPropsBucketDealFileUploader {
  uploadCSVCallback: (
    fileData: {
      [key: string]: string | number | boolean;
    }[]
  ) => any;
}
export default function BucketDealFileUploader({
  uploadCSVCallback,
}: IPropsBucketDealFileUploader) {
  const inputFile = useRef<any>(null);
  const form = useRef<any>(null);
  const onButtonClick = () => {
    inputFile?.current?.click();
  };

  const handleFileUpload = (node: any) => {
    convertCSVToJSON(node);
    form.current.reset();
  };

  const convertCSVToJSON = (node: any) => {
    if (!node.target?.files[0]) return;
    let commonConfig = { delimiter: "," };
    parse(node.target?.files[0], {
      ...commonConfig,
      header: true,
      complete: (result: any) => {
        uploadCSVCallback(result.data);
      },
    });
  };
  return (
    <form ref={form}>
      <input
        type="file"
        id="file"
        ref={inputFile}
        style={{ display: "none" }}
        onChange={handleFileUpload}
      />
      <div className={styles.button_container}>
        <Button
          type="primary"
          icon={<PlusCircleOutlined />}
          onClick={onButtonClick}
        >
          Upload CSV
        </Button>
        <Tooltip
          title={
            <div>
              <p>The CSV file should be formatted as</p>
              <></>
              <div style={{ fontSize: "10px" }}>
                <p>businessRegistrationNumber,businessName</p>
                <p>111111, businessName1</p>
                <p>222222, businessName2</p>
                <p>333333, businessName3</p>
                <p>444444, businessName4</p>
              </div>
            </div>
          }
          key={"help"}
        >
          <Button type="primary" icon={<QuestionCircleOutlined />}></Button>
        </Tooltip>
      </div>
    </form>
  );
}
