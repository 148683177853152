import {
  createSlice,
  isAnyOf,
  PayloadAction,
  SerializedError,
} from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import {
  respondToSignIn,
  signIn,
  verifySoftwareToken,
} from "../access-control/accountSlice";
import {
  approveBusinessThunk,
  completeRegistrationThunk,
  declineBusinessThunk,
  editBusinessThunk,
  getBusinessThunk,
  markAsPendingVerificationThunk,
  editBusinessUsersEmailThunk,
} from "../businesses/businessesSlice";
import {
  getPartnersThunk,
  getPartnerThunk,
  createPartnerThunk,
  createPartnerTeamMemberThunk,
  editPartnerThunk,
} from "../partners/partnersSlice";
import {
  createPartnerDealsThunk,
  deletePartnerDealThunk,
  getPartnerDealsThunk,
} from "../partner-deals/partnerDealsSlice";
import {
  addItemsThunk,
  addSubmenuThunk,
  getCatalogThunk,
  removeItemThunk,
  removeSubmenuThunk,
  updateItemThunk,
  updateSubmenuThunk,
} from "../catalogs/catalogsSlice";
import {
  getPromotionsThunk,
  updatePromotionsStatusThunk,
} from "../promotions/promotionsSlice";
import {
  createTagThunk,
  deleteTagThunk,
  editTagThunk,
  getTransactionTagsThunk,
  updateTransactionTagThunk,
} from "../transaction-tags/transactionTagsSlice";

import {
  getAllBucketDealsThunk,
  createBucketDealThunk,
  createMultBucketDealsThunk,
  deleteBucketDealThunk,
  deleteMultBucketDealThunk,
  editBucketDealThunk,
} from "../bucket-deals/bucketDealsSlice";

import {
  getTransactionsCSVThunk,
  getTransactionsThunk,
} from "../transactions/transactionsSlice";
import {
  cancelScheduledPushThunk,
  getPushesScheduledThunk,
  pushNotificationThunk,
  scheduleAPushThunk,
} from "../notifications/notificationsSlice";

export interface ErrorState {
  error: { message: string; code: string };
}

const initialState: ErrorState = {
  error: { message: "", code: "" },
};

export const errorsSlice = createSlice({
  name: "errors",
  initialState,
  reducers: {
    setError: (
      state,
      action: PayloadAction<{ message: string; code: string }>
    ) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(respondToSignIn.fulfilled, (state, action) => {
        if (!("tokens" in action.payload))
          state.error = {
            message: "Incorrect Confirmation Code",
            code: "CodeMismatchException",
          };
      })

      .addMatcher(
        isAnyOf(
          getBusinessThunk.rejected,
          respondToSignIn.rejected,
          signIn.rejected,
          verifySoftwareToken.rejected,
          approveBusinessThunk.rejected,
          declineBusinessThunk.rejected,
          markAsPendingVerificationThunk.rejected,
          getCatalogThunk.rejected,
          updateItemThunk.rejected,
          addSubmenuThunk.rejected,
          addItemsThunk.rejected,
          removeSubmenuThunk.rejected,
          removeItemThunk.rejected,
          updateSubmenuThunk.rejected,
          getPromotionsThunk.rejected,
          updatePromotionsStatusThunk.rejected,
          createTagThunk.rejected,
          deleteTagThunk.rejected,
          editTagThunk.rejected,
          getTransactionTagsThunk.rejected,
          updateTransactionTagThunk.rejected,
          getTransactionsCSVThunk.rejected,
          getTransactionsThunk.rejected,
          editBusinessThunk.rejected,
          getAllBucketDealsThunk.rejected,
          createBucketDealThunk.rejected,
          createMultBucketDealsThunk.rejected,
          deleteBucketDealThunk.rejected,
          deleteMultBucketDealThunk.rejected,
          editBucketDealThunk.rejected,
          pushNotificationThunk.rejected,
          scheduleAPushThunk.rejected,
          cancelScheduledPushThunk.rejected,
          getPushesScheduledThunk.rejected,
          completeRegistrationThunk.rejected,
          editBusinessUsersEmailThunk.rejected,
          getPartnersThunk.rejected,
          getPartnerThunk.rejected,
          createPartnerThunk.rejected,
          createPartnerTeamMemberThunk.rejected,
          editPartnerThunk.rejected,
          createPartnerDealsThunk.rejected,
          deletePartnerDealThunk.rejected,
          getPartnerDealsThunk.rejected
        ),
        (state, action) => {
          state.error = {
            message: action.error?.message! || (action?.error as any),
            code: action.error?.code!,
          };
        }
      );
  },
});

export const selectError = (state: RootState) => state.errors.error;
export const { setError } = errorsSlice.actions;
export default errorsSlice.reducer;
