import {
  Table,
  Space,
  Button,
  Input,
  TablePaginationConfig,
  Statistic,
  Select,
  Segmented,
  Tag,
} from "antd";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import styles from "./PartnerBusinessTable.module.css";
import {
  getBusinessesThunk,
  setCurrentBusiness,
  selectTotalBusinessCount,
  selectLastUpdateKey,
  selectFilters,
  setFilters,
  selectBusinesses,
} from "../../../businesses/businessesSlice";

import { Empty } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import Loader from "../../../common/loader/Loader";
import {
  BusinessStatus,
  BusinessType,
  ComplianceStatus,
  IBusinessFull,
  IBusinessFullQuery,
} from "../../../../interfaces/businessFull.interface";
import { TableParams } from "../../../../interfaces/tableParams.interface";
import { IPagination } from "../../../../interfaces/pagination.interface";
import debounce from "lodash/debounce";
import { selectUserPartnerId } from "../../../access-control/accountSlice";
import PartnerBusinessDetailsModal from "../Partner-Business-Details-Modal/PartnerBusinessDetailsModal";
import { mapStatusLabels } from "../../../businesses/utils/mapStatusLabel";
import { FilterValue } from "antd/lib/table/interface";

const { Column, ColumnGroup } = Table;

export default function PartnerBusinessTable() {
  const [loading, setLoading] = useState(false);
  const businessses = useAppSelector(selectBusinesses)?.map((b) => {
    let businessWithKey = { ...b, key: b._id };
    return businessWithKey;
  });

  const totalCount = useAppSelector(selectTotalBusinessCount);
  const initialPagination = { current: 1, pageSize: 10 };
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: { ...initialPagination },
  });

  const partnerId = useAppSelector(selectUserPartnerId);
  const [filters, setFilters] = useState<Partial<IBusinessFullQuery>>({
    businessRegistrationNumber: "",
    businessName: "",
    partnerId: partnerId,
  });

  const [isBusinessModalVisible, setIsBusinessModalVisible] = useState(false);
  // const [filters, setFilters] = useState<Partial<IBusinessFullQuery>>({});
  const dispatch = useAppDispatch();

  const fetchAllBusinesses = async (params: {
    pagination: IPagination;
    filters: Partial<IBusinessFullQuery>;
  }) => {
    setLoading(true);
    if (partnerId) {
      params.filters.partnerId = partnerId;
    }
    await dispatch(
      getBusinessesThunk({
        currentPage: params?.pagination?.current!,
        pageSize: params?.pagination?.pageSize!,
        ...params.filters,
      })
    )
      .then(({ payload }) => {
        //@ts-ignore
        let totalCount = payload.totalCount;

        setTableParams({
          ...tableParams,
          pagination: {
            ...params?.pagination,
            total: totalCount,
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchAllBusinesses({
      pagination: {
        current: tableParams.pagination?.current || 1,
        pageSize: tableParams.pagination?.pageSize!,
      },
      filters: { ...filters },
    });
  }, []);

  const handleTypeFilterChange = (value: BusinessType) => {
    let updatedFilters = { ...filters, businessType: value };
    //@ts-ignore
    if (!updatedFilters.businessType) delete updatedFilters.businessType;
    setFilters(updatedFilters);
    setTableParams((prev) => {
      return { ...prev, pagination: { ...initialPagination } };
    });
    fetchAllBusinesses({
      pagination: { ...initialPagination },
      filters: updatedFilters,
    });
  };
  const handleStatusFilterChange = (value: BusinessStatus | "all") => {
    let updatedFilters = { ...filters, status: value };
    if (updatedFilters.status === "all")
      //@ts-ignore
      delete updatedFilters.status;
    //@ts-ignore
    setFilters(updatedFilters);
    setTableParams((prev) => {
      return { ...prev, pagination: { ...initialPagination } };
    });
    fetchAllBusinesses({
      pagination: { ...initialPagination },
      //@ts-ignore
      filters: updatedFilters,
    });
  };
  const debouncedNameFilterChange = useCallback(
    debounce((nextValue) => handleNameFilterChange(nextValue), 400),
    [] // will be created only once initially
  );

  const handleDebouncedNameFilterChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    debouncedNameFilterChange(event);
  };
  const handleNameFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value.toLowerCase();
    let updatedFilters = {
      ...filters,
      name: name.replace(/\s+/g, " ").trim(),
    };
    setFilters(updatedFilters);
    setTableParams((prev) => {
      return { ...prev, pagination: { ...initialPagination } };
    });
    fetchAllBusinesses({
      pagination: { ...initialPagination },
      filters: updatedFilters,
    });
    // dispatch(
    //   setFilters({
    //     name: name.replace(/\s+/g, " ").trim(),
    //     ...initialPagination,
    //   })
    // );
    // setTableParams((prev) => {
    //   return { ...prev, pagination: { ...initialPagination } };
    // });
  };

  const handleTableChange = (pagination: TablePaginationConfig) => {
    setTableParams((prev) => {
      return { ...prev, pagination: { ...pagination } };
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      // dispatch(emptyBucketDealsTable());
    }

    fetchAllBusinesses({
      filters: filters,
      pagination: {
        current: pagination?.current || 0,
        pageSize: pagination?.pageSize || 10,
      },
    });
  };

  const showPartnerBusinessDetailsModal = (business: IBusinessFull) => {
    dispatch(setCurrentBusiness(business));
    setIsBusinessModalVisible(true);
  };

  return (
    <div className={styles.container} key="divTable">
      <Segmented
        key={"businessStatusFilter"}
        className={styles.segment_box}
        onChange={(value: any) => {
          handleStatusFilterChange(value);
        }}
        size="large"
        options={[
          {
            label: <div className={styles.segment_item}>All</div>,
            value: "all",
          },
          {
            label: (
              <div className={styles.segment_item}>Pending Submission</div>
            ),
            value: "pendingSubmission",
          },
          {
            label: (
              <div className={styles.segment_item}>Pending Verification</div>
            ),
            value: "pendingVerification",
          },
          {
            label: <div className={styles.segment_item}>Approved</div>,
            value: "verified",
          },
          {
            label: <div className={styles.segment_item}>Declined</div>,
            value: "unverified",
          },
        ]}
      />
      {/* <Space wrap>
        <span>Business Type </span>

        <Select
          defaultValue="All"
          style={{ width: 120 }}
          onChange={(value: any) => {
            handleTypeFilterChange(value);
          }}
          options={[
            {
              label: "All",
              value: "",
            },
            {
              label: "Sole Traders",
              value: "soleTrader",
            },
            {
              label: "Partnership",
              value: "partnership",
            },
            {
              label: "Private Limited Company",
              value: "privateLimitedCompany",
            },
            {
              label: "Public Limited Company",
              value: "publicLimitedCompany",
            },
            {
              label: "Other",
              value: "other",
            },
          ]}
        />
      </Space> */}

      <div className={styles.table_header}>
        <Input
          onChange={handleDebouncedNameFilterChange}
          placeholder="Merchant Name, Email, Kvk ..."
          // value={filters.businessName}
          className={styles.searchInput}
          prefix={<SearchOutlined />}
          style={{ width: "400px" }}
        />
      </div>
      <br />

      <div className={styles.table_container}>
        {businessses.length === 0 && !loading && (
          <div style={{ height: "300px" }} className="center">
            <Empty></Empty>
          </div>
        )}
        {businessses.length > 0 && !loading && (
          <Table
            dataSource={businessses}
            pagination={tableParams.pagination}
            loading={loading}
            //@ts-ignore
            onChange={handleTableChange}
          >
            <Column
              title="Business "
              className={styles.table_business_column}
              key="businessDetails"
              render={(_: any, record: IBusinessFull) => (
                <div className={styles.business_details}>
                  <span key={record._id + "businessName"}>
                    {record.businessName}
                  </span>
                  <span
                    style={{ color: "gray" }}
                    key={record._id + "businessRegistrationNumber"}
                  >
                    {record.businessRegistrationNumber}
                  </span>
                </div>
              )}
            />

            <Column
              title="Email"
              key="email"
              className={styles.table_column}
              render={(_: any, record: IBusinessFull) => (
                <span key={record._id + "email"}>{record.rootUser?.email}</span>
              )}
            />
            <Column
              title="Industry"
              key="industry"
              className={styles.table_column}
              render={(_: any, record: IBusinessFull) => (
                <span key={record._id + "industry"}>{record.industry}</span>
              )}
            />
            <Column
              title="Status"
              dataIndex="status"
              key="status"
              className={styles.table_column}
              render={(status: any, record: IBusinessFull) => {
                const { color, label } = mapStatusLabels(status);
                return (
                  <>
                    <Tag color={color} key={status}>
                      {label}
                    </Tag>
                  </>
                );
              }}
            />
            <Column
              title=""
              key="details"
              className={styles.table_column}
              render={(_: any, record: IBusinessFull) => (
                <Space
                  size="middle"
                  key={record._id}
                  className={styles.tables_buttons_container}
                >
                  <Button
                    type="primary"
                    shape="round"
                    onClick={() => {
                      showPartnerBusinessDetailsModal(record);
                    }}
                  >
                    View Details
                  </Button>
                </Space>
              )}
            />
          </Table>
        )}
        {loading && <Loader text="Fetching businesses from server"></Loader>}
      </div>
      <PartnerBusinessDetailsModal
        visible={isBusinessModalVisible}
        setVisible={setIsBusinessModalVisible}
      />
      {/* <SalesBusinessActivityDetails
        visible={isDetailModalVisible}
        setVisible={setIsDetailModalVisible}
      ></SalesBusinessActivityDetails> */}
    </div>
  );
}
