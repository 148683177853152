import "./App.css";
import { Route, Routes } from "react-router-dom";
import { Layout } from "antd";
import Navbar from "./features/navbar/Navbar";
import BusinessesPage from "./pages/BusinessesPage";
import { Content, Header } from "antd/lib/layout/layout";
import LoginPage from "./pages/login-page/LoginPage";
import AuthChecker from "./features/access-control/AuthChecker";
import CatalogsPage from "./pages/catalogs-page/CatalogsPage";
import CatalogEditorPage from "./pages/catalog-editor-page/CatalogEditorPage";
import ErrorAlerter from "./features/errors/ErrorAlerter";
import ReferralsPage from "./pages/referrals-page/ReferralsPage";
import TransactionTagsPage from "./pages/transaction-tags-page/TransactionTagsPage";
import TransactionTagsEditorPage from "./pages/transaction-tags-editor-page/TransactionTagsEditorPage";
import TransactionsPage from "./pages/transactions-page/TransactionsPage";
import TransactionsTable from "./features/transactions/components/transactions-table/TransactionsTable";
import MFASetup from "./features/access-control/MFASetup";
import BucketDealsPage from "./pages/bucket-deals-page/BucketDealsPage";
import { IRouteConfig } from "./interfaces/routeConfig.interface";
import NotificationsTable from "./features/notifications/components/Notifications-Table/NotificationsTable";
import NotificationForm from "./features/notifications/components/Notification-Form/NotificationForm";
import SalesPage from "./pages/sales-page/SalesPage";
import TerminalsPage from "./pages/terminals-page/TerminalsPage";
import { AccessLevel } from "./interfaces/accessLevel.enum";
import LandingPage from "./pages/landing-page /LandingPage";
import TerminalsCouplingPage from "./pages/terminals-coupling-page/TerminalsCoupingPage";
import ReportsPage from "./pages/reports-page/ReportsPage";
import PartnersPage from "./pages/partners-page/PartnersPage";
import PartnerDealsPage from "./pages/partner-deals-page /PartnerDealsPage";
import PartnerBusinessesPage from "./pages/partner-businesses-page /PartnerBusinessesPage";
import NotificationsPage from "./pages/notifications-page/NotificationsPage";
import ChatBotPage from "./pages/chat-bot-page/ChatBotPage";

export const routes: IRouteConfig[] = [
  {
    path: "/",
    component: <LoginPage />,
    isProtected: false,
    accessLevel: [AccessLevel.KlearlyAdmin],
  },

  {
    path: "/mfaSetup",
    component: <MFASetup />,
    isProtected: false,
    accessLevel: [AccessLevel.KlearlyAdmin],
  },
  {
    path: "/home",
    component: <LandingPage />,
    isProtected: true,
    accessLevel: [
      AccessLevel.KlearlyAdmin,
      AccessLevel.CoreTechEmployee,
      AccessLevel.Partner,
    ],
  },
  {
    path: "/businesses",
    label: "Businesses",
    component: <BusinessesPage />,
    isProtected: true,
    accessLevel: [AccessLevel.KlearlyAdmin],
  },
  // {
  //   path: "/catalogs",
  //   label: "Catalogs",
  //   component: <CatalogsPage />,
  //   isProtected: true,
  //   accessLevel: [AccessLevel.KlearlyAdmin],
  // },
  // {
  //   path: "/catalogs/:businessId",
  //   component: <CatalogEditorPage />,
  //   isProtected: true,
  //   accessLevel: [AccessLevel.KlearlyAdmin],
  // },
  // {
  //   path: "/transactionTags",
  //   label: "Transaction Tags",
  //   component: <TransactionTagsPage />,
  //   isProtected: true,
  //   accessLevel: [AccessLevel.KlearlyAdmin],
  // },
  {
    path: "/transactionTags/:businessId",
    component: <TransactionTagsEditorPage />,
    isProtected: true,
    accessLevel: [AccessLevel.KlearlyAdmin],
  },
  {
    path: "/referrals",
    label: "Promotions",
    component: <ReferralsPage />,
    isProtected: true,
    accessLevel: [AccessLevel.KlearlyAdmin],
  },
  // {
  //   path: "/transactions",
  //   label: "Transactions",
  //   component: <TransactionsPage />,
  //   isProtected: true,
  // },
  {
    path: "/transactions/:_id",
    component: <TransactionsTable />,
    isProtected: true,
    accessLevel: [AccessLevel.KlearlyAdmin],
  },
  {
    path: "/bucketDeals",
    label: "Bucket Deals",
    component: <BucketDealsPage />,
    isProtected: true,
    accessLevel: [AccessLevel.KlearlyAdmin],
  },
  {
    path: "/sales",
    label: "Sales Team",
    component: <SalesPage />,
    isProtected: true,
    accessLevel: [AccessLevel.KlearlyAdmin],
  },

  {
    path: "/notifications",
    component: <NotificationsPage />,
    label: "Notifications",
    accessLevel: [AccessLevel.KlearlyAdmin],
    isProtected: true,
  },

  // {
  //   path: "/notifications/schedule",
  //   component: <NotificationForm />,
  //   isProtected: true,
  //   accessLevel: [AccessLevel.KlearlyAdmin],
  // },
  {
    path: "/reports",
    label: "Report Creator",
    component: <ReportsPage />,
    isProtected: true,
    accessLevel: [AccessLevel.KlearlyAdmin],
  },
  {
    path: "/terminals",
    label: "Terminals",
    component: <TerminalsPage />,
    isProtected: true,
    accessLevel: [AccessLevel.CoreTechEmployee, AccessLevel.Partner],
  },

  {
    path: "/terminals/couple",
    component: <TerminalsCouplingPage />,
    isProtected: true,
    accessLevel: [AccessLevel.CoreTechEmployee, AccessLevel.Partner],
  },

  {
    path: "/partners",
    label: "Partners",
    isProtected: true,
    accessLevel: [AccessLevel.KlearlyAdmin],
    isGroup: true,
    children: [
      {
        path: "/partners/list",
        label: "Partners List",
        component: <PartnersPage />,
        isProtected: true,
        accessLevel: [AccessLevel.KlearlyAdmin],
      },
      {
        path: "/partners/deals",
        label: "Partner Deals",
        component: <PartnerDealsPage />,
        isProtected: true,
        accessLevel: [AccessLevel.KlearlyAdmin],
      },
    ],
  },
  {
    path: "/partners/merchants",
    label: "Partner Merchants",
    isProtected: true,
    accessLevel: [AccessLevel.Partner],
    isGroup: true,
    children: [
      {
        path: "/partners/merchants/deals",
        label: "New Merchants",
        component: <PartnerDealsPage />,
        isProtected: true,
        accessLevel: [AccessLevel.Partner],
      },
      {
        path: "/partners/merchants/overview",
        label: "Merchants Overview",
        component: <PartnerBusinessesPage />,
        isProtected: true,
        accessLevel: [AccessLevel.Partner],
      },
    ],
  },
  // {
  //   path: "/partners/businesses",
  //   label: "Partner Merchants",
  //   component: <PartnerBusinessesPage />,
  //   isProtected: true,
  //   accessLevel: [AccessLevel.Partner],
  // },
  // {
  //   path: "/partnerDeals",
  //   label: "Partner Deals",
  //   component: <PartnerDealsPage />,
  //   isProtected: true,
  //   accessLevel: [AccessLevel.KlearlyAdmin, AccessLevel.Partner],
  // },
  {
    path: "/chatbot",
    component: <ChatBotPage />,
    label: "Chat Bot",
    accessLevel: [AccessLevel.KlearlySuperAdmin],
    isProtected: true,
  },
];

function App() {
  return (
    <Layout className="layout">
      <Header className="header">
        <Navbar routes={routes}></Navbar>
      </Header>

      <Content className="content">
        <Routes>
          {routes.map((r) => (
            <>
              {r.isGroup ? (
                r.children?.map((c) => (
                  <Route
                    path={c.path}
                    key={c.path}
                    element={
                      c.isProtected ? (
                        <AuthChecker accessLevel={c.accessLevel} path={c.path}>
                          {c.component}
                        </AuthChecker>
                      ) : (
                        c.component
                      )
                    }
                  />
                ))
              ) : (
                <Route
                  path={r.path}
                  key={r.path}
                  element={
                    r.isProtected ? (
                      <AuthChecker accessLevel={r.accessLevel} path={r.path}>
                        {r.component}
                      </AuthChecker>
                    ) : (
                      r.component
                    )
                  }
                />
              )}
            </>
          ))}
        </Routes>
        <ErrorAlerter />
      </Content>
    </Layout>
  );
}

export default App;
