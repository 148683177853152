import {
  Badge,
  Button,
  Col,
  Descriptions,
  Divider,
  List,
  Modal,
  Tag,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { IBusinessFull } from "../../../../../interfaces/businessFull.interface";
import {
  UserOutlined,
  FileOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import Loader from "../../../../common/loader/Loader";
import {
  getBusinessFilesThunk,
  getBusinessUsersThunk,
  selectCurrentBusiness,
  selectCurrentBusinessFiles,
  selectCurrentBusinessUsers,
  selectIsLoadingBusinessUsers,
} from "../../../businessesSlice";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { downloadFile } from "../../../../../utils/downloadFile";
const { Paragraph } = Typography;
interface IPropsBusinessModel {
  visible: boolean;
  setVisible: any;
}

export default function BusinessUsers() {
  const business = useAppSelector(selectCurrentBusiness);
  const businessUsers = useAppSelector(selectCurrentBusinessUsers);
  const isLoadingBusinessUsers = useAppSelector(selectIsLoadingBusinessUsers);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (business) {
      fetchBusinessUsersDetails();
    }
  }, [business]);

  const fetchBusinessUsersDetails = async () => {
    if (!business?._id) return;
    await dispatch(getBusinessUsersThunk(business?._id));
  };

  return (
    <div style={{ position: "relative", minHeight: "100px" }}>
      {!isLoadingBusinessUsers && businessUsers && (
        <>
          <Divider />
          <Descriptions title="Users"></Descriptions>
          <List
            size="small"
            dataSource={businessUsers}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  avatar={
                    <>
                      <UserOutlined></UserOutlined>
                      <br />
                      {item.role}
                    </>
                  }
                  title={<span>{item?.name || "Pending Invite"}</span>}
                  description={item.role}
                ></List.Item.Meta>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    width: "300px",
                  }}
                >
                  <span> email: {item.email}</span>
                  <span> Phone: {item.phone?.number || "None"} </span>
                </div>
              </List.Item>
            )}
          />
          {/* </Descriptions> */}
        </>
      )}
      {isLoadingBusinessUsers && <Loader text="Fetching Business Users" />}
    </div>
  );
}
