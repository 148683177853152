import { Avatar, Button, List, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import styles from "./TransactionsTable.module.css";
import {
  PlusOutlined,
  RightCircleTwoTone,
  LikeOutlined,
  MessageOutlined,
  StarOutlined,
} from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  getTransactionsThunk,
  selectCurrentBusinesses,
  selectTransactions,
} from "../../transactionsSlice";
import { ITransaction } from "../../../../interfaces/transaction.interface";

const IconText = ({ icon, text }: { icon: React.FC; text: string }) => (
  <Space>
    {React.createElement(icon)}
    {text}
  </Space>
);

export default function TransactionsTable() {
  const [isLoading, setIsLoading] = useState(false);
  const currentTransactions = useAppSelector(selectTransactions);
  const [transactions, setTransactions] = useState([] as ITransaction[]);

  const business = useAppSelector(selectCurrentBusinesses);
  const dispatch = useAppDispatch();
  const { _id } = useParams();
  useEffect(() => {
    fetchTransactions();
    // if (currentTransaction.length > 0) setTransactions(currentTransaction);
  }, []);

  async function fetchTransactions() {
    if (!_id) return;
    setIsLoading(true);
    await dispatch(getTransactionsThunk({ businessId: _id }))
      .then((data) => setTransactions(data.payload as ITransaction[]))
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <div className={styles.list_container}>
      <h1>Transactions</h1>
      {business?._id && <h4>{business.businessName}</h4>}
      {/* <Button className={styles.button_container} icon={<PlusOutlined />}>
        Export Transactions
      </Button> */}
      <List
        itemLayout="vertical"
        dataSource={currentTransactions}
        loading={isLoading}
        className={styles.list}
        // renderItem={(item: ITransaction) => (
        //   <List.Item className={styles.list_item_container}>
        //     <List.Item.Meta title={<span>{item._id}</span>} />
        //     {/* <List.Item.Meta title={<span>{item.transactionTime}</span>} /> */}
        //     <List.Item.Meta title={<span>{item.amount}</span>} />
        //   </List.Item>
        // )}
        renderItem={(item) => (
          <List.Item
            key={item._id}
            // actions={[
            //   <IconText
            //     icon={StarOutlined}
            //     text="156"
            //     key="list-vertical-star-o"
            //   />,
            //   <IconText
            //     icon={LikeOutlined}
            //     text="156"
            //     key="list-vertical-like-o"
            //   />,
            //   <IconText
            //     icon={MessageOutlined}
            //     text="2"
            //     key="list-vertical-message"
            //   />,
            // ]}
            extra={<Avatar>{item.amount}€ </Avatar>}
          >
            <List.Item.Meta
              avatar={
                <Avatar
                  src={
                    item.cardType === "Mastercard" ? (
                      <img
                        alt="svgImg"
                        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iNDgiIGhlaWdodD0iNDgiCnZpZXdCb3g9IjAgMCA0OCA0OCIKc3R5bGU9IiBmaWxsOiMwMDAwMDA7Ij48cGF0aCBmaWxsPSIjM0Y1MUI1IiBkPSJNNDUsMzVjMCwyLjIwOS0xLjc5MSw0LTQsNEg3Yy0yLjIwOSwwLTQtMS43OTEtNC00VjEzYzAtMi4yMDksMS43OTEtNCw0LTRoMzRjMi4yMDksMCw0LDEuNzkxLDQsNFYzNXoiPjwvcGF0aD48cGF0aCBmaWxsPSIjRkZDMTA3IiBkPSJNMzAgMTRBMTAgMTAgMCAxIDAgMzAgMzRBMTAgMTAgMCAxIDAgMzAgMTRaIj48L3BhdGg+PHBhdGggZmlsbD0iI0ZGM0QwMCIgZD0iTTIyLjAxNCwzMGMtMC40NjQtMC42MTctMC44NjMtMS4yODQtMS4xNzYtMmg1LjMyNWMwLjI3OC0wLjYzNiwwLjQ5Ni0xLjMwNCwwLjYzNy0yaC02LjU5OEMyMC4wNywyNS4zNTQsMjAsMjQuNjg2LDIwLDI0aDdjMC0wLjY4Ni0wLjA3LTEuMzU0LTAuMjAxLTJoLTYuNTk4YzAuMTQyLTAuNjk2LDAuMzU5LTEuMzY0LDAuNjM3LTJoNS4zMjVjLTAuMzEzLTAuNzE2LTAuNzExLTEuMzgzLTEuMTc2LTJoLTIuOTczYzAuNDM3LTAuNTgsMC45My0xLjEyMiwxLjQ4MS0xLjU5NUMyMS43NDcsMTQuOTA5LDE5LjQ4MSwxNCwxNywxNGMtNS41MjMsMC0xMCw0LjQ3Ny0xMCwxMHM0LjQ3NywxMCwxMCwxMGMzLjI2OSwwLDYuMTYyLTEuNTc1LDcuOTg2LTRIMjIuMDE0eiI+PC9wYXRoPjwvc3ZnPg=="
                      />
                    ) : (
                      <img
                        alt="svgImg"
                        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iNDgiIGhlaWdodD0iNDgiCnZpZXdCb3g9IjAgMCA0OCA0OCIKc3R5bGU9IiBmaWxsOiMwMDAwMDA7Ij48cGF0aCBmaWxsPSIjMTU2NUMwIiBkPSJNNDUsMzVjMCwyLjIwOS0xLjc5MSw0LTQsNEg3Yy0yLjIwOSwwLTQtMS43OTEtNC00VjEzYzAtMi4yMDksMS43OTEtNCw0LTRoMzRjMi4yMDksMCw0LDEuNzkxLDQsNFYzNXoiPjwvcGF0aD48cGF0aCBmaWxsPSIjRkZGIiBkPSJNMTUuMTg2IDE5bC0yLjYyNiA3LjgzMmMwIDAtLjY2Ny0zLjMxMy0uNzMzLTMuNzI5LTEuNDk1LTMuNDExLTMuNzAxLTMuMjIxLTMuNzAxLTMuMjIxTDEwLjcyNiAzMHYtLjAwMmgzLjE2MUwxOC4yNTggMTlIMTUuMTg2ek0xNy42ODkgMzBMMjAuNTYgMzAgMjIuMjk2IDE5IDE5LjM4OSAxOXpNMzguMDA4IDE5aC0zLjAyMWwtNC43MSAxMWgyLjg1MmwuNTg4LTEuNTcxaDMuNTk2TDM3LjYxOSAzMGgyLjYxM0wzOC4wMDggMTl6TTM0LjUxMyAyNi4zMjhsMS41NjMtNC4xNTcuODE4IDQuMTU3SDM0LjUxM3pNMjYuMzY5IDIyLjIwNmMwLS42MDYuNDk4LTEuMDU3IDEuOTI2LTEuMDU3LjkyOCAwIDEuOTkxLjY3NCAxLjk5MS42NzRsLjQ2Ni0yLjMwOWMwIDAtMS4zNTgtLjUxNS0yLjY5MS0uNTE1LTMuMDE5IDAtNC41NzYgMS40NDQtNC41NzYgMy4yNzIgMCAzLjMwNiAzLjk3OSAyLjg1MyAzLjk3OSA0LjU1MSAwIC4yOTEtLjIzMS45NjQtMS44ODguOTY0LTEuNjYyIDAtMi43NTktLjYwOS0yLjc1OS0uNjA5bC0uNDk1IDIuMjE2YzAgMCAxLjA2My42MDYgMy4xMTcuNjA2IDIuMDU5IDAgNC45MTUtMS41NCA0LjkxNS0zLjc1MkMzMC4zNTQgMjMuNTg2IDI2LjM2OSAyMy4zOTQgMjYuMzY5IDIyLjIwNnoiPjwvcGF0aD48cGF0aCBmaWxsPSIjRkZDMTA3IiBkPSJNMTIuMjEyLDI0Ljk0NWwtMC45NjYtNC43NDhjMCwwLTAuNDM3LTEuMDI5LTEuNTczLTEuMDI5Yy0xLjEzNiwwLTQuNDQsMC00LjQ0LDBTMTAuODk0LDIwLjg0LDEyLjIxMiwyNC45NDV6Ij48L3BhdGg+PC9zdmc+"
                      />
                    )
                  }
                />
              }
              title={<a href={item._id}>{item._id}</a>}
              description={item.description}
            />
            {/* {item.content} */}
          </List.Item>
        )}
      />
    </div>
  );
}
