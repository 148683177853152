import { Avatar, Button, List, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { ITransactionTag } from "../../../../interfaces/transactionTags.interface";
import {
  getTransactionTagsThunk,
  selectCurrentBusiness,
  selectCurrentTransactionTags,
  setCurrentTransactionTag,
} from "../../transactionTagsSlice";
import TransactionTagEditor from "../transaction-tag-editor/TransactionTagEditor";
import styles from "./TransactionTagsList.module.css";
import { PlusOutlined, RightCircleTwoTone } from "@ant-design/icons";
import { getBusinessThunk } from "../../../businesses/businessesSlice";
export default function TransactionTagsList() {
  const [isTagEditorShown, setIsTagEditorShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const currentTransactionTags = useAppSelector(selectCurrentTransactionTags);
  const business = useAppSelector(selectCurrentBusiness);
  const [tags, setTags] = useState([] as any);
  const dispatch = useAppDispatch();
  const { businessId } = useParams();
  useEffect(() => {
    fetchTransactionTags();
    fetchCurrentBusiness();
    if (currentTransactionTags?.length > 0) setTags(currentTransactionTags);
  }, []);

  async function fetchTransactionTags() {
    if (!businessId) return;
    setIsLoading(true);
    await dispatch(getTransactionTagsThunk({ businessId }))
      .then((data) => setTags(data.payload))
      .finally(() => {
        setIsLoading(false);
      });
  }
  async function fetchCurrentBusiness() {
    if (!business.businessName && businessId) {
      await dispatch(getBusinessThunk(businessId));
    }
  }

  async function handleEditTransactionTag(transactionTag: ITransactionTag) {
    setIsTagEditorShown(true);
    dispatch(setCurrentTransactionTag(transactionTag));
  }

  async function handleCreateTransactionTag() {
    setIsTagEditorShown(true);
    dispatch(
      setCurrentTransactionTag({
        _id: "",
        name: "",
        label: "",
        type: "FREE_TEXT",
        options: [],
      })
    );
  }

  return (
    <div className={styles.list_container}>
      <h1>Transaction Tags</h1>
      {business?._id && <h4>{business.businessName}</h4>}
      <List
        itemLayout="horizontal"
        dataSource={currentTransactionTags}
        loading={isLoading}
        className={styles.list}
        renderItem={(item: any) => (
          <List.Item
            onClick={() => {
              handleEditTransactionTag(item);
            }}
            className={styles.list_item_container}
          >
            <List.Item.Meta
              avatar={<RightCircleTwoTone twoToneColor={"green"} />}
              title={<span>{item.name}</span>}
              description={item.type}
            />
          </List.Item>
        )}
      />
      <Button
        onClick={handleCreateTransactionTag}
        className={styles.button_container}
        icon={<PlusOutlined />}
      >
        Create New
      </Button>
      <TransactionTagEditor
        isVisible={isTagEditorShown}
        setIsVisible={setIsTagEditorShown}
        businessId={business._id!}
      />
    </div>
  );
}
