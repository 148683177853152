// import { Form } from "antd";
// import BucketGroupPriceFormItems from "../../../common/bucket-group-price-form-items/BucketGroupPriceFormItems";

import {
  Alert,
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
} from "antd";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  createPartnerDealsThunk,
  deletePartnerDealThunk,
  selectCurrentPartnerDeal,
} from "../../partnerDealsSlice";
import { IPurchPriceProfile } from "../../../../interfaces/partner.interface";

import {
  selectCurrentPartner,
  setCurrentPartner,
} from "../../../partners/partnersSlice";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { selectIsKlearlyAdmin } from "../../../access-control/accountSlice";

const partnerDealsFormFieldLayout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 18 },
};
interface IPropsEditPartnerDealsFormModal {
  visible: boolean;
  setVisible: any;
}

export default function EditPartnerDealForm({
  visible,
  setVisible,
}: IPropsEditPartnerDealsFormModal) {
  const currentPartner = useAppSelector(selectCurrentPartner);
  const [form] = Form.useForm();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const partnerDeal = useAppSelector(selectCurrentPartnerDeal);
  const [purchPriceProfile, setPurchPriceProfile] = useState<
    Partial<IPurchPriceProfile>
  >({});

  const isKlearlyAdmin = useAppSelector(selectIsKlearlyAdmin);

  //   const handleSubmit = async (values: Partial<IPartner>) => {
  //     setLoading(true);

  //     let partner: Partial<IPartner> = values;
  //     await dispatch(createPartnerThunk(partner));
  //     setLoading(false);
  //   };

  const handleReset = () => {
    form.resetFields();
  };

  const handleCancel = () => {
    handleReset();
    setVisible(false);
  };

  const onCreatePartnerDeal = async () => {
    setLoading(true);
    if (!currentPartner) {
      return;
    }

    partnerDeal.partnerId = currentPartner._id;

    const result = await dispatch(createPartnerDealsThunk([partnerDeal]));
    setLoading(false);
    //@ts-ignore
    let isSuccessful = result?.payload && !result?.error;
    if (isSuccessful) handleCancel();
    //@ts-ignore
    else setError(result?.error?.message);
  };

  const onSelectPartner = (partnerId: string) => {
    dispatch(setCurrentPartner(partnerId));
  };

  const handleDelete = async () => {
    setLoading(true);
    await dispatch(deletePartnerDealThunk({ partnerDealId: partnerDeal?._id }))
      .then(() => {
        setVisible(false);
      })
      .catch((error) => {
        console.log("error", error);
        throw error;
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div>
      <Modal
        visible={visible}
        title={"Edit Partner Deal"}
        onCancel={handleCancel}
        footer={null}
        width={650}
      >
        <div>
          <Form>
            {/* <Form.Item
              label={"Domestic Debit Cards"}
              name={["bucketGroups", "domesticDebitCards", "value"]}
              labelCol={{ ...partnerDealsFormFieldLayout.labelCol }}
              labelAlign="left"
              help={`Min ${purchPriceProfile?.bucketGroups?.domesticDebitCards?.value} | max ${purchPriceProfile?.bucketGroups?.domesticDebitCards?.max}`}
              className={styles.bucket_group_form_item}
            >
              <InputNumber
                controls={false}
                className={styles.bucket_value_input}
                min={purchPriceProfile?.bucketGroups?.domesticDebitCards?.value}
                max={purchPriceProfile?.bucketGroups?.domesticDebitCards?.max}
                style={{ width: "140px" }}
                addonAfter={valueTypeLabel(
                  purchPriceProfile?.bucketGroups?.domesticDebitCards?.valueType
                )}
              />
            </Form.Item>
            <Form.Item
              hidden
              name={["bucketGroups", "domesticDebitCards", "valueType"]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={"E.E.A Cards"}
              name={["bucketGroups", "european", "value"]}
              labelCol={{ ...partnerDealsFormFieldLayout.labelCol }}
              labelAlign="left"
              help={`Min ${purchPriceProfile?.bucketGroups?.european?.value} | max ${purchPriceProfile?.bucketGroups?.european?.max}`}
              className={styles.bucket_group_form_item}
            >
              <InputNumber
                controls={false}
                className={styles.bucket_value_input}
                min={purchPriceProfile?.bucketGroups?.european?.value}
                max={purchPriceProfile?.bucketGroups?.european?.max}
                style={{ width: "140px" }}
                addonAfter={valueTypeLabel(
                  purchPriceProfile?.bucketGroups?.european?.valueType
                )}
              />
            </Form.Item>
            <Form.Item hidden name={["bucketGroups", "european", "valueType"]}>
              <Input />
            </Form.Item>
            <Form.Item
              label={"Non E.E.A & Corporate Cards"}
              name={["bucketGroups", "internationalAndCommercial", "value"]}
              labelCol={{ ...partnerDealsFormFieldLayout.labelCol }}
              labelAlign="left"
              help={`Min ${purchPriceProfile?.bucketGroups?.internationalAndCommercial?.value} | max ${purchPriceProfile?.bucketGroups?.internationalAndCommercial?.max}`}
              className={styles.bucket_group_form_item}
            >
              <InputNumber
                controls={false}
                className={styles.bucket_value_input}
                min={
                  purchPriceProfile?.bucketGroups?.internationalAndCommercial
                    ?.value
                }
                max={
                  purchPriceProfile?.bucketGroups?.internationalAndCommercial
                    ?.max
                }
                style={{ width: "140px" }}
                addonAfter={valueTypeLabel(
                  purchPriceProfile?.bucketGroups?.internationalAndCommercial
                    ?.valueType
                )}
              />
            </Form.Item>
            <Form.Item
              hidden
              name={["bucketGroups", "internationalAndCommercial", "valueType"]}
            >
              <Input />
            </Form.Item> */}
            <Alert
              message="Blocked Functionality"
              description="Currently, editting is
              not permitted. If you wish to edit the partner deal please delete it
              using the button below and create it again."
              type="warning"
              showIcon
            />

            <br />
            <br />
            <Popconfirm
              title="Are you sure you want to delete this deal?"
              onConfirm={handleDelete}
            >
              <Button danger loading={loading}>
                Delete Partner Deal
              </Button>
            </Popconfirm>
          </Form>
          {error && <span>{error}</span>}
        </div>
      </Modal>
    </div>
  );
}

// export default function CreatePartnerDealsForm() {
//   return (
//     <div>
//       <Form>
//         <BucketGroupPriceFormItems
//           formItemName="domesticDebitCards"
//           label="Domestic Debit Cards"
//         />
//         <BucketGroupPriceFormItems
//           formItemName="european"
//           label="E.E.A Cards"
//         />
//         <BucketGroupPriceFormItems
//           formItemName="internationalAndCommercial"
//           label="Non E.E.A & Corporate Cards"
//         />
//       </Form>
//     </div>
//   );
// }
