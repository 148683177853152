import { IBusinessFull } from "../../../interfaces/businessFull.interface";
import { ITransaction } from "../../../interfaces/transaction.interface";
import {
  IMappedTransactionReport,
  ITransactionsReport,
} from "../../../interfaces/transactionsReport.interface";
import { TransactionStats } from "../../../interfaces/transactionStats.interface";
export function convertTransactionsToCSV(props: {
  transactionsReport: ITransactionsReport;
}) {
  try {
    const { transactionsReport } = props;

    // const {
    //   totalSalesIncVat,
    //   totalSalesExcVat,
    //   totalRefundsExcVat,
    //   totalRefundsIncVat,
    //   total0Vat,
    //   total9Vat,
    //   total21Vat,
    // } = _getTransactionsStats(transactions, vat as 0 | 9 | 21)!;

    // const totalIncVat = totalSalesIncVat - totalRefundsIncVat;
    // const totalExcVat = totalSalesExcVat - totalRefundsExcVat;
    // const MERCHANT_FEE = 1.65;
    // const merchantServiceFees = totalIncVat * (MERCHANT_FEE / 100); //? is the fee included in the amount?
    // const merchantTotalProfit = totalIncVat - merchantServiceFees; //? what is the equation ?
    // const totalLemonPayFeesVat = 0;
    // const overviewFields = {
    //   ["Business Name"]: businessName,
    //   ["KVK"]: businessRegistrationNumber,
    //   ["Transaction Period"]: _getTransactionPeriod(
    //     new Date(business.createdAt),
    //     new Date()
    //   ),
    //   ["VAT"]: vat + "%",
    //   ["Total Transactions"]: transactions.length,
    //   ["Total Sales Inc VAT"]: totalSalesIncVat.toFixed(2),
    //   ["Total Sales Exc VAT"]: totalSalesExcVat.toFixed(2),
    //   ["Total Refunds Inc VAT"]: totalRefundsIncVat.toFixed(2),
    //   ["Total Refunds Exc VAT"]: totalRefundsExcVat.toFixed(2),
    //   ["Total Inc VAT"]: totalIncVat.toFixed(2),
    //   ["Total Exc VAT"]: totalExcVat.toFixed(2),
    //   ["Total 0 VAT"]: total0Vat.toFixed(2),
    //   ["Total 9 VAT"]: total9Vat.toFixed(2),
    //   ["Total 21 VAT"]: total21Vat.toFixed(2),
    //   ["Total VAT"]: (totalIncVat - totalExcVat).toFixed(2),
    //   ["Total LemonPay Fees"]: merchantServiceFees.toFixed(2),
    //   ["Total LemonPay Fees VAT"]: totalLemonPayFeesVat,
    //   ["Merchant Total Profit"]: merchantTotalProfit.toFixed(2),
    //   ["Average Sale (EUR)"]: _getAverageSale(totalIncVat, transactions.length),
    // };

    const mappedFields = mapTransactionReport(transactionsReport);

    const fieldsForCsv = [
      Object.keys(mappedFields),
      Object.values(mappedFields),
    ];

    let csvContent =
      "data:text/csv;charset=utf-8," +
      fieldsForCsv.map((e: any) => e.join(",")).join("\n");

    var encodedUri = encodeURI(csvContent);
    return encodedUri;
  } catch (error) {
    console.log(error);
  }
}

const mapTransactionReport = (transactionReport: ITransactionsReport) => {
  const mapped = {} as IMappedTransactionReport;

  mapped["Business Name"] = transactionReport.businessName;
  mapped.KVK = transactionReport.businessRegistrationNumber;
  mapped.VAT = transactionReport.vat + "%";
  mapped["Total Transactions"] = transactionReport.totalTransactions;
  mapped["Total Sales Inc VAT"] = transactionReport.totalSalesIncVat;
  mapped["Total Sales Exc VAT"] = transactionReport.totalSalesExcVat;
  mapped["Total Refunds Exc VAT"] = transactionReport.totalRefundsExcVat;
  mapped["Total Refunds Exc VAT"] = transactionReport.totalRefundsExcVat;
  mapped["Total Inc VAT"] = transactionReport.totalIncVat;
  mapped["Total Exc VAT"] = transactionReport.totalExcVat;
  mapped["Total VAT"] = transactionReport.vat;
  mapped["Total 0 VAT"] = transactionReport.total0Vat;
  mapped["Total 9 VAT"] = transactionReport.total9Vat;
  mapped["Total 21 VAT"] = transactionReport.total21Vat;
  mapped["Total LemonPay Fees"] = transactionReport.merchantServiceFees;
  mapped["Total LemonPay Fees VAT"] = transactionReport.merchantServiceFeesVat;
  mapped["Merchant Total Profit"] = transactionReport.merchantTotalProfit;

  return mapped;
};

// function _getTransactionPeriod(startDate: Date, endDate: Date) {
//   return `${_formatDateToString(startDate)}-${_formatDateToString(endDate)}`;
// }
// function _formatDateToString(date: Date) {
//   const year = date.getFullYear();
//   const month = date.getMonth();
//   const day = date.getDate();
//   return `${day}/${month}/${year}`;
// }
// function _getAverageSale(
//   transactionVolume: number,
//   numberOfTransactionsDone: number
// ) {
//   return (transactionVolume / numberOfTransactionsDone).toFixed(2);
// }

// function _getTransactionsStats(
//   transactions: ITransaction[],
//   businessVAT: 0 | 9 | 21
// ) {
//   const results = transactions.reduce<TransactionStats>(
//     (prev: TransactionStats, cur) => {
//       const type = cur.transactionType;
//       if (cur.items && cur.items.length > 0) {
//         cur.items.forEach((item) => {
//           const vat = item?.vat || item.vat === 0 ? item.vat : 21;
//           const amount = item.price * item.quantity;
//           prev = addAmountToStats({ stats: prev, amount, type, vat });
//         });
//       } else {
//         const amount = cur.amount;
//         const vat = businessVAT;
//         prev = addAmountToStats({ stats: prev, amount, type, vat });
//       }
//       return prev;
//     },
//     {
//       totalIncVat: 0,
//       totalSalesIncVat: 0,
//       totalRefundsIncVat: 0,
//       totalExcVat: 0,
//       totalSalesExcVat: 0,
//       totalRefundsExcVat: 0,
//       total0Vat: 0,
//       total9Vat: 0,
//       total21Vat: 0,
//     } as TransactionStats
//   );

//   results!.totalIncVat =
//     results!.totalSalesIncVat - results!.totalRefundsIncVat;
//   results!.totalExcVat =
//     results!.totalSalesExcVat - results!.totalRefundsExcVat;

//   return results;
// }

// function addAmountToStats(props: {
//   stats: TransactionStats;
//   amount: number;
//   vat: number;
//   type: "sale" | "refund" | "void";
// }): TransactionStats {
//   const { stats, amount, vat, type } = props;

//   const vatPart = amount * (vat / 100);
//   const amountExcVat = amount - vatPart;

//   if (type === "sale") {
//     stats!.totalSalesIncVat += amount;
//     stats!.totalSalesExcVat += amountExcVat;
//     switch (vat) {
//       case 0:
//         stats!.total0Vat += vatPart;
//         break;
//       case 9:
//         stats!.total9Vat += vatPart;
//         break;
//       case 21:
//         stats!.total21Vat += vatPart;
//         break;
//       default:
//         break;
//     }
//   }
//   if (type === "refund" || type === "void") {
//     stats!.totalRefundsIncVat += amount;
//     stats!.totalRefundsExcVat += amountExcVat;
//     switch (vat) {
//       case 0:
//         stats!.total0Vat -= vatPart;
//         break;
//       case 9:
//         stats!.total9Vat -= vatPart;
//         break;
//       case 21:
//         stats!.total21Vat -= vatPart;
//         break;
//       default:
//         break;
//     }
//   }

//   return stats;
// }
