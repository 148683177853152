import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Popconfirm,
  Radio,
  RadioChangeEvent,
  Space,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  CloseCircleOutlined,
  PlusOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import styles from "./TransactionTagEditor.module.css";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  selectCurrentTransactionTag,
  createTagThunk,
  deleteTagThunk,
  editTagThunk,
} from "../../transactionTagsSlice";
import {
  ITagType,
  ITransactionTag,
} from "../../../../interfaces/transactionTags.interface";
import Loader from "../../../common/loader/Loader";

interface IPropsTransactionTagEditor {
  isVisible: boolean;
  setIsVisible: any;
  businessId: string;
}

export default function TransactionTagEditor({
  isVisible,
  setIsVisible,
  businessId,
}: IPropsTransactionTagEditor) {
  const currentTransactionTag = useAppSelector(selectCurrentTransactionTag);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [hasOptions, setHasOptions] = useState(false);

  const tagTypeOptions = [
    { label: "Free Text", value: "FREE_TEXT" },
    { label: "Selection", value: "SELECTION" },
  ];
  useEffect(() => {
    form.setFieldsValue(currentTransactionTag);
    setHasOptions(currentTransactionTag?.options?.length! > 0 ? true : false);
  }, [currentTransactionTag]);

  const onFinish = async (values: any) => {
    const transactionTag = handleFormValuesReformat(values) as ITransactionTag;
    setIsLoading(true);

    const dispatchAction = !currentTransactionTag._id
      ? createTagThunk
      : editTagThunk;

    await dispatch(dispatchAction({ businessId, tag: transactionTag }))
      .then(() => {
        form.resetFields();
        setIsVisible(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDeleteTag = async () => {
    setIsLoading(true);
    await dispatch(
      deleteTagThunk({
        businessId,
        tagId: currentTransactionTag._id,
      })
    )
      .then(() => {
        form.resetFields();
        setIsVisible(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
    setIsVisible(false);
  };

  const handleFormValuesReformat = (values: Partial<ITransactionTag>) => {
    if (values.type === "FREE_TEXT") values.options = [];
    else if (values.type === "SELECTION") {
      let optionsWithLabels = values.options?.map((o: any) => {
        o.label = o.value;
        return o;
      });

      values.options = optionsWithLabels;
    }

    if (currentTransactionTag._id) values._id = currentTransactionTag._id;
    return values;
  };
  const handleTagTypeChange = ({ target }: RadioChangeEvent) => {
    const value = target.value as ITagType;
    if (value === "FREE_TEXT") {
      setHasOptions(false);
    }
    if (value === "SELECTION") {
      setHasOptions(true);
    }
  };

  const handleChange = () => {
    form.setFieldsValue({ options: [] });
  };

  const handleCancel = () => {
    form.setFieldsValue(currentTransactionTag);
    setIsVisible(false);
  };
  return (
    <Modal
      title={
        !currentTransactionTag?._id
          ? `Create new transaction tag`
          : `Edit ${currentTransactionTag?.name}`
      }
      visible={isVisible}
      onCancel={handleCancel}
      footer={null}
    >
      <Form
        form={form}
        name="dynamic_form_nest_item"
        onFinish={onFinish}
        autoComplete="off"
        className={styles.form_container}
      >
        <Form.Item
          name="name"
          label="Tag Name"
          rules={[{ required: true, message: "Missing tag name" }]}
          tooltip={"Name of tag"}
        >
          <Input />
        </Form.Item>
        {/* <Form.Item
          name="label"
          label="Tag Label"
          tooltip={"Nickname of the tag"}

          // rules={[{ required: true, message: "Missing tag name" }]}
        >
          <Input />
        </Form.Item> */}

        <Form.Item
          name="type"
          label="Tag Type"
          tooltip={"Limit user to various options or allow him to give a "}

          // rules={[{ required: true, message: "Missing tag name" }]}
        >
          <Radio.Group
            options={tagTypeOptions}
            onChange={handleTagTypeChange}
            optionType="button"
          />
        </Form.Item>

        {hasOptions && (
          <>
            <Divider plain>Options</Divider>

            <Form.List name="options">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field) => (
                    <Space key={field.key} align="baseline">
                      {/* <Form.Item
                    {...field}
                    label="Label"
                    name={[field.name, "label"]}
                    // rules={[{ required: true, message: "Missing price" }]}
                  >
                    <Input />
                  </Form.Item> */}
                      <Form.Item
                        {...field}
                        label="Value"
                        name={[field.name, "value"]}
                        rules={[{ required: true, message: "Missing Value" }]}
                      >
                        <Input />
                      </Form.Item>
                      <Popconfirm
                        title={"Delete Option?"}
                        onConfirm={() => remove(field.name)}
                      >
                        <CloseCircleOutlined />
                      </Popconfirm>
                    </Space>
                  ))}

                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Option
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </>
        )}

        <Form.Item style={{ width: "100%" }}>
          {!isLoading && (
            <div className={styles.footer_buttons_container}>
              <Popconfirm
                title={"Delete Tag?"}
                onConfirm={() => {
                  handleDeleteTag();
                }}
              >
                <Button icon={<DeleteOutlined />} type="default" danger>
                  Delete
                </Button>
              </Popconfirm>
              <Button type="primary" htmlType="submit">
                {currentTransactionTag._id ? "Update" : "Create"}
              </Button>
            </div>
          )}
          {isLoading && <Loader text="Preparing business transaction tags.." />}
        </Form.Item>
      </Form>
    </Modal>
  );
}
