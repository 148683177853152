import {
  createAsyncThunk,
  createSlice,
  current,
  isAnyOf,
  original,
  PayloadAction,
} from "@reduxjs/toolkit";

import { RootState, AppThunk } from "../../app/store";
import { IBusinessSimple } from "../../interfaces/businessSimple.interface";
import { ICatalog, IItem } from "../../interfaces/catalog.interface";
import {
  getCatalogFromServer,
  createCatalogServer,
  updateCatalogServer,
  deleteCatalogServer,
  addItemsServer,
  updateItemServer,
  deleteItemServer,
  updateSubmenuServer,
  deleteSubmenuServer,
  addSubmenuServer,
} from "./catalogsApi";
import {
  _addItems,
  _deleteItem,
  _editItem,
  findSubmenuByIdAndUpdate,
  _addSubmenu,
  _editSubmenu,
  _deleteSubmenu,
  findParentSubmenu,
  findSubmenu,
} from "./_helpers";
// import { fetchCount } from './counterAPI';

export interface CatalogState {
  catalogs: { businessName: string; _id: string }[];
  currentBusiness: IBusinessSimple;
  currentCatalog: ICatalog;
  currentSubmenu: ICatalog | null;
  currentItem: IItem | null;
}

const initialState: CatalogState = {
  catalogs: [],
  currentBusiness: {} as any,
  currentCatalog: {} as any,
  currentSubmenu: {} as any,
  currentItem: {} as any,
};

export const getCatalogThunk = createAsyncThunk(
  "catalogs/getCatalog",
  async (props: { businessId: string }) => {
    const response = await getCatalogFromServer(props.businessId);
    return response;
  }
);

// export const createCatalogThunk = createAsyncThunk(
//   "catalogs/createThunk",
//   async (props: { businessId: string }) => {
//     const response = await createCatalogServer(props);
//     return response;
//   }
// );

export const updateSubmenuThunk = createAsyncThunk(
  "catalogs/updateSubmenuThunk",
  async (props: { businessId: string; submenu: Partial<ICatalog> }) => {
    const { businessId, submenu } = props;

    const updatedCatalog = await updateSubmenuServer({
      businessId,
      submenu,
    });

    return [updatedCatalog, submenu];
  }
);
export const removeSubmenuThunk = createAsyncThunk(
  "catalogs/removeSubmenuThunk",
  async (
    props: {
      businessId: string;
      submenuId: string;
    },
    { getState }
  ) => {
    const { businessId, submenuId } = props;
    const catalog = (getState() as any)?.catalogs?.currentCatalog;
    const parentSubmenu = findParentSubmenu(catalog, submenuId)!;

    const updatedCatalog = await deleteSubmenuServer({
      businessId,
      submenuId,
    });
    return [updatedCatalog, parentSubmenu];
  }
);
// export const removeCatalogThunk = createAsyncThunk(
//   "catalogs/removeCatalogThunk",
//   async (_, { getState }) => {
//     const catalog = (getState() as any)?.catalogs?.currentCatalog;

//     const updatedCatalog = await deleteCatalogServer({
//       _id: catalog._id,
//     });

//     return updatedCatalog;
//   }
// );
export const addSubmenuThunk = createAsyncThunk(
  "catalogs/addSubmenuThunk",
  async (
    props: {
      businessId: string;
      submenu: Partial<ICatalog>;
    },
    { getState }
  ) => {
    const { businessId, submenu } = props;
    const parentSubmenu = (getState() as any)?.catalogs?.currentSubmenu;
    const parentSubmenuId = parentSubmenu._id;

    const { catalog: updatedCatalog, newSubmenuId } = await addSubmenuServer({
      businessId,
      parentSubmenuId,
      submenu,
    });

    const parentSubmenuArrays = findSubmenu(
      updatedCatalog,
      parentSubmenuId
    )?.submenus;

    const [newSubmenu] = parentSubmenuArrays?.slice(-1)!;

    return [updatedCatalog, { ...submenu, _id: newSubmenuId }];
  }
);

export const addItemsThunk = createAsyncThunk(
  "catalogs/addItemsThunk",
  async (props: { items: IItem[]; businessId: string }, { getState }) => {
    const { items, businessId } = props;
    const parentSubmenuId = (getState() as any)?.catalogs?.currentSubmenu._id;

    const updatedCatalog = await addItemsServer({
      businessId,
      items,
      parentSubmenuId,
    });
    return updatedCatalog;
  }
);

export const updateItemThunk = createAsyncThunk(
  "catalogs/updateItemThunk",
  async (props: { businessId: string; item: IItem }) => {
    const { item, businessId } = props;
    const updatedCatalog = await updateItemServer({
      businessId,
      item,
    });

    return [updatedCatalog, item];
  }
);
export const removeItemThunk = createAsyncThunk(
  "catalogs/deleteItemThunk",
  async (
    props: {
      businessId: string;
      itemId: string;
    },
    { getState }
  ) => {
    const { businessId, itemId } = props;
    const catalog = (getState() as any)?.catalogs?.currentCatalog;
    const parentSubmenu = findParentSubmenu(catalog, itemId)!;
    const updatedCatalog = await deleteItemServer({ businessId, itemId });
    return [updatedCatalog, parentSubmenu];
  }
);

export const catalogsSlice = createSlice({
  name: "catalogs",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setItem: (state, action: PayloadAction<IItem | null>) => {
      state.currentItem = action.payload;
    },
    setSubmenu: (state, action: PayloadAction<ICatalog | null>) => {
      state.currentSubmenu = action.payload;
    },
    resetCatalogState: (state) => {
      state.currentItem = {} as any;
      state.currentSubmenu = {} as any;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder

      .addCase(getCatalogThunk.fulfilled, (state, action) => {
        state.currentCatalog = action.payload;
        state.currentSubmenu = action.payload;
      })
      .addCase(addSubmenuThunk.fulfilled, (state, action) => {
        const [updatedCatalog, newSubmenu] = action.payload;
        state.currentCatalog = updatedCatalog as ICatalog;

        state.currentSubmenu = { ...newSubmenu } as ICatalog;
      })
      .addCase(updateSubmenuThunk.fulfilled, (state, action) => {
        const [updatedCatalog, updatedSubmenu] = action.payload;

        state.currentCatalog = updatedCatalog as ICatalog;
        state.currentSubmenu = updatedSubmenu as ICatalog;
      })
      .addCase(removeSubmenuThunk.fulfilled, (state, action) => {
        const [updatedCatalog, currentSubmenu] = action.payload;
        state.currentCatalog = updatedCatalog;
        state.currentSubmenu = currentSubmenu;
      })

      .addCase(updateItemThunk.fulfilled, (state, action) => {
        const [updatedCatalog, updatedItem] = action.payload;
        state.currentCatalog = updatedCatalog as ICatalog;
        state.currentItem = updatedItem as IItem;
      })
      .addCase(removeItemThunk.fulfilled, (state, action) => {
        const [updatedCatalog, currentSubmenu] = action.payload;
        state.currentCatalog = updatedCatalog;
        state.currentSubmenu = currentSubmenu;
        state.currentItem = {} as any;
      })
      .addCase(addItemsThunk.fulfilled, (state, action) => {
        state.currentCatalog = action.payload;
      });
  },
});

export const { setItem, setSubmenu, resetCatalogState } = catalogsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const selectCatalogs = (state: RootState) => state.catalogs.catalogs;
export const selectCurrentCatalog = (state: RootState) =>
  state.catalogs.currentCatalog;
export const selectSubmenu = (state: RootState) =>
  state.catalogs.currentSubmenu;
export const selectItem = (state: RootState) => state.catalogs.currentItem;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
//   (amount: number): AppThunk =>
//   (dispatch, getState) => {
//     const currentValue = selectCount(getState());
//     if (currentValue % 2 === 1) {
//       dispatch(incrementByAmount(amount));
//     }
//   };

export default catalogsSlice.reducer;
