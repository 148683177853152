import { CONFIG } from "../../config";
import { getTokenizedAxios } from "../../utils/axiosWithTokenGen";
import {
  IEmailNotification,
  IInAppNotification,
  INotification,
  TargetAttributes,
} from "../../interfaces/notification.interface";
import { IScheduledPush } from "../../interfaces/scheduledPush.interface";
export async function pushNotificationServer(params: {
  notification: Partial<
    IInAppNotification | INotification | IEmailNotification
  >;
  targetAttributes: TargetAttributes;
}) {
  const axios = await getTokenizedAxios();
  const path = "/notifications/push";
  return axios
    .post<any>(CONFIG.ADMIN_SERVER.BASE_URL + path, params)
    .then((data) => data.data);
}
export async function scheduleAPushServer(params: Partial<IScheduledPush>) {
  const axios = await getTokenizedAxios();
  const path = "/notifications/scheduleAPush";
  return axios
    .post<IScheduledPush>(CONFIG.ADMIN_SERVER.BASE_URL + path, params)
    .then((data) => data.data);
}
export async function getPushesScheduledServer() {
  const axios = await getTokenizedAxios();
  const path = "/notifications/getPushesScheduled";
  return axios
    .get<IScheduledPush[]>(CONFIG.ADMIN_SERVER.BASE_URL + path)
    .then((data) => data.data);
}
export async function cancelScheduledPushServer(params: {
  executionArn: string;
  scheduledPushId: string;
}) {
  const axios = await getTokenizedAxios();
  const path = "/notifications/cancelScheduledPush";
  return axios
    .post<IScheduledPush>(CONFIG.ADMIN_SERVER.BASE_URL + path, params)
    .then((data) => data.data);
}
