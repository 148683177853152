import { PageHeader, Row } from "antd";

import { ReactElement } from "react";
import styles from "./GeneralTableHeader.module.css";

export interface IPropsGeneralTableHeader {
  title: string;
  subtitle?: string;
  description?: string;
  actionButtons?: ReactElement[];
  onBack?: () => void;
  showBackButton?: boolean;
  content?: ReactElement;
  imageUrl?: string;
  children?: ReactElement;
  avatarUrl?: string;
}

export default function GeneralTableHeader({
  title,
  subtitle,
  description,
  actionButtons,
  onBack,
  showBackButton,
  imageUrl,
  avatarUrl,
  children,
}: IPropsGeneralTableHeader) {
  return (
    <div>
      <PageHeader
        onBack={showBackButton ? onBack : undefined}
        avatar={avatarUrl ? { src: avatarUrl } : undefined}
        className={styles.container}
        title={title}
        subTitle={subtitle}
        extra={
          actionButtons && actionButtons?.length > 0 ? actionButtons : undefined
        }
      >
        {
          <>
            {description && (
              <div
                style={{
                  flex: 1,
                  color: "#7b7980",
                  marginBottom: "24px",
                  transform: "translate(0, -12px)",
                  maxWidth: "600px",
                }}
              >
                {description}
              </div>
            )}
            <Row>
              <div style={{ flex: 1, color: "#7b7980" }}>{children}</div>
              {imageUrl && (
                <div className="image">
                  {
                    <img
                      alt="content"
                      height={"50px"}
                      width="100%"
                      src={imageUrl}
                    />
                  }
                </div>
              )}
            </Row>
          </>
        }
      </PageHeader>
      {/* <Divider></Divider> */}
    </div>
  );
}
