// import axios from "axios";
import { CONFIG } from "../../config";
import { IPartner } from "../../interfaces/partner.interface";
import { IPartnerDeal } from "../../interfaces/partnerDeal.interface";
import { IQueryFilters } from "../../interfaces/queryFilters.interface";
import { getTokenizedAxios } from "../../utils/axiosWithTokenGen";

export async function getPartnerDealsFromServer(
  params: IQueryFilters<IPartnerDeal>
): Promise<{
  data: IPartnerDeal[];
  totalCount: number;
  currentCount: number;
}> {
  const axios = await getTokenizedAxios();
  const path = "/partnerDeals/";

  return axios
    .get(CONFIG.ADMIN_SERVER.BASE_URL + path, { params })
    .then((data) => data?.data);
}

export async function createPartnerDealsServer(
  partnerDeals: Partial<IPartnerDeal>[]
): Promise<{ partnerDeals: IPartnerDeal[] }> {
  const axios = await getTokenizedAxios();
  const path = "/partnerDeals/create";

  return axios
    .post(CONFIG.ADMIN_SERVER.BASE_URL + path, { partnerDeals })
    .then((data) => data?.data);
}
// export async function editPartnerServer({
//   partnerId,
//   update,
// }: {
//   partnerId: string;
//   update: Partial<IPartner>;
// }): Promise<{ partner: IPartner }> {
//   const axios = await getTokenizedAxios();
//   const path = "/partners/";

//   return axios
//     .put(CONFIG.ADMIN_SERVER.BASE_URL + path + partnerId, update)
//     .then((data) => data.data);
// }
export async function deletePartnerDealServer({
  partnerDealId,
}: {
  partnerDealId: string;
}): Promise<IPartnerDeal> {
  const axios = await getTokenizedAxios();
  const path = "/partnerDeals/";

  return axios
    .delete(CONFIG.ADMIN_SERVER.BASE_URL + path + partnerDealId)
    .then((data) => data?.data);
}
