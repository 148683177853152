export function changeTimeZone(date: string | Date, timeZone: string) {
  if (typeof date === "string") {
    return new Date(
      new Date(date).toLocaleString("en-US", {
        timeZone,
      })
    );
  }

  const updatedDate = new Date(
    date.toLocaleString("en-US", {
      timeZone,
    })
  );
  return updatedDate;
}

export function convertToDutchTimeZone(date: string | Date) {
  return changeTimeZone(date, "Europe/Berlin");
}
