import { Badge, Button, Descriptions, List, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { FileOutlined, DownloadOutlined } from "@ant-design/icons";
import Loader from "../../../common/loader/Loader";
import {
  getBusinessFilesThunk,
  selectCurrentBusiness,
  selectCurrentBusinessFiles,
  selectIsLoadingBusinessFiles,
} from "../../businessesSlice";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { downloadFile } from "../../../../utils/downloadFile";
import { BusinessFileType } from "../../../../interfaces/businessFile.interface";

export default function BusinessFiles() {
  const business = useAppSelector(selectCurrentBusiness);
  const businessFiles = useAppSelector(selectCurrentBusinessFiles);
  const isLoadingBusinessFiles = useAppSelector(selectIsLoadingBusinessFiles);

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (business) {
      fetchBusinessFiles();
    }
  }, [business]);

  const fetchBusinessFiles = async () => {
    if (!business?._id) return;

    await dispatch(
      getBusinessFilesThunk({
        email: business.rootUser.email,
        businessRegistrationNumber: business.businessRegistrationNumber,
        businessName: business.businessName,
        iban: business.bankAccountIban,
        name: business.rootUser.name,
      })
    );
  };

  const getFileDisplayName = (fileType: BusinessFileType) => {
    switch (fileType) {
      case BusinessFileType.TMA_FILE:
        return "TMA Contract";
      case BusinessFileType.TMA_METADATA_FILE:
        return "TMA Signature";
      case BusinessFileType.COMPANY_INFO_FILE:
        return "Company Info Extract";
      case BusinessFileType.BANK_CONFIRMATION_FILE:
        return "Bank Account Confirmation";

      default:
        return "";
    }
  };

  return (
    <div style={{ position: "relative", minHeight: "100px" }}>
      {!isLoadingBusinessFiles && businessFiles && businessFiles.length > 0 && (
        <>
          <Descriptions title="Additional Files"></Descriptions>

          <List
            size="small"
            dataSource={businessFiles}
            renderItem={(item) => (
              <List.Item key={item.fileName}>
                <List.Item.Meta
                  avatar={
                    <>
                      <FileOutlined />
                      <span
                        style={{
                          marginLeft: "4px",
                          width: "200px",
                          display: "inline-block",
                        }}
                      >
                        {getFileDisplayName(item.type)}
                      </span>

                      <br />
                      <span style={{ opacity: "0.7", fontSize: "smaller" }}>
                        {" "}
                        {item.fileExtension}
                      </span>
                    </>
                  }
                  title={
                    <span>{item?.fileName || <Badge count={"Missing"} />}</span>
                  }
                ></List.Item.Meta>

                <Button
                  type="primary"
                  icon={<DownloadOutlined />}
                  onClick={() => {
                    downloadFile(item.file, item.fileName, item.fileExtension);
                  }}
                  disabled={!item.fileName}
                ></Button>
              </List.Item>
            )}
          />
        </>
      )}
      <div>
        {isLoadingBusinessFiles && <Loader text="Fetching Business Files" />}
      </div>
    </div>
  );
}
