import { Button, Form, Input, Modal, Spin, Typography } from "antd";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { IBusinessFull } from "../../../../interfaces/businessFull.interface";
import { IBusinessSimple } from "../../../../interfaces/businessSimple.interface";
import {
  approveBusinessThunk,
  completeRegistrationThunk,
  selectCurrentBusiness,
} from "../../businessesSlice";
interface IPropsBusinessModel {
  visible: boolean;
  setVisible: any;
}

export default function SubmitModal({
  visible,
  setVisible,
}: IPropsBusinessModel) {
  const [loading, setLoading] = useState(false);
  const business = useAppSelector(selectCurrentBusiness);
  const { _id, rootUser } = business;
  const dispatch = useAppDispatch();

  const onFinish = () => {
    handleBusinessSubmission();
  };

  const handleBusinessSubmission = () => {
    const email = rootUser?.email;
    if (!email) return;
    setLoading(true);
    dispatch(completeRegistrationThunk({ businessId: _id }))
      .then(() => setVisible(false))
      .finally(() => setLoading(false));
  };

  const handleCancel = () => {
    setVisible(false);
  };
  return (
    <Modal
      visible={visible}
      title={business?.businessName}
      onCancel={handleCancel}
      footer={null}
    >
      <Form
        onFinish={onFinish}
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
      >
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button
            key="submit"
            type="primary"
            loading={loading}
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
