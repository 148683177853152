import {
  createAsyncThunk,
  createSlice,
  current,
  isAnyOf,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../app/store";
import { IBucketDeal } from "../../interfaces/bucketDeal.interface";
import { IPagination } from "../../interfaces/pagination.interface";
import { IQueryFilters } from "../../interfaces/queryFilters.interface";
import { getUniqueKey } from "../../utils/getUniqueKey";

import {
  getAllBucketDealsServer,
  editBucketDealServer,
  createBucketDealServer,
  deleteBucketDealServer,
  createMultBucketDealsServer,
  deleteMultBucketDealsServer,
} from "./bucketDealsApi";
// import { fetchCount } from './counterAPI';

export interface BucketDealsState {
  bucketDeals: IBucketDeal[];
  bucketDealsInTable: IBucketDeal[];
  currentBucketDeal: IBucketDeal;
  totalCount: number;
  filters: {
    [key: string]: ((bucketDeals: IBucketDeal[]) => IBucketDeal[]) | null;
  };
  lastUpdateKey: string;
}

const initialState: BucketDealsState = {
  bucketDeals: [],
  bucketDealsInTable: [],
  totalCount: 0,
  currentBucketDeal: {} as any,
  filters: {},
  lastUpdateKey: "",
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(getBusinesses(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getAllBucketDealsThunk = createAsyncThunk(
  "bucketDeals/getAll",
  async (params: IQueryFilters<IBucketDeal>) => {
    const { data, totalCount } = await getAllBucketDealsServer(params);

    return { data, totalCount };
  }
);
export const createBucketDealThunk = createAsyncThunk(
  "bucketDeals/create",
  async (params: Partial<IBucketDeal>) => {
    const response = await createBucketDealServer(params);

    return response;
  }
);
export const createMultBucketDealsThunk = createAsyncThunk(
  "bucketDeals/createMany",
  async (params: IBucketDeal[]) => {
    const response = await createMultBucketDealsServer(params);

    return response;
  }
);

export const editBucketDealThunk = createAsyncThunk(
  "bucketDeals/edit",
  async (props: { _id: string; update: Partial<IBucketDeal> }) => {
    const response = await editBucketDealServer(props);
    return response;
  }
);

export const deleteBucketDealThunk = createAsyncThunk(
  "bucketDeals/delete",
  async (props: { _id: string }) => {
    const response = await deleteBucketDealServer(props._id);
    return response;
  }
);
export const deleteMultBucketDealThunk = createAsyncThunk(
  "bucketDeals/deleteMany",
  async (props: { _ids: string }) => {
    const response = await deleteMultBucketDealsServer(props._ids);
    return response;
  }
);

//utils//
const applyFiltersToBucketDeals = (
  bucketDeals: IBucketDeal[],
  filters: {
    [key: string]: ((bucketDeals: IBucketDeal[]) => IBucketDeal[]) | null;
  }
) => {
  const filterArr = Object.values(filters);
  const filteredBucketDeals = filterArr.reduce((prev, cur) => {
    if (prev.length === 0) return [];
    if (cur) return cur(prev);
    else return prev;
  }, bucketDeals);

  return filteredBucketDeals;
};

export const bucketDealsSlice = createSlice({
  name: "bucketDeals",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    filterTableByName: (state, action: PayloadAction<string>) => {
      const name = action.payload;

      const nameFilter = (bucketDeals: IBucketDeal[]) => {
        return bucketDeals.filter(
          (b) =>
            b.businessRegistrationNumber.toLowerCase().includes(name) ||
            b.businessName?.toLowerCase().startsWith(name)
        );
      };

      state.filters.name = name === "" ? null : nameFilter;
      state.bucketDealsInTable = applyFiltersToBucketDeals(
        state.bucketDeals,
        state.filters
      );
    },
    setCurrentBucketDeal: (state, action: PayloadAction<IBucketDeal>) => {
      state.currentBucketDeal = action.payload;
    },
    emptyBucketDealsTable: (state) => {
      state.bucketDealsInTable = [];
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getAllBucketDealsThunk.fulfilled, (state, action) => {
        state.bucketDeals = action.payload.data;
        state.totalCount = action.payload.totalCount;
        state.bucketDealsInTable = action.payload.data;
      })
      .addCase(createBucketDealThunk.fulfilled, (state, action) => {
        state.bucketDeals.push(action.payload);
        state.bucketDealsInTable.push(action.payload);
      })
      .addCase(createMultBucketDealsThunk.fulfilled, (state, action) => {
        state.bucketDeals.push(...action.payload);
        state.bucketDealsInTable.push(...action.payload);
      })

      .addCase(editBucketDealThunk.fulfilled, (state, action) => {
        state.currentBucketDeal = action.payload;
        state.bucketDeals = state.bucketDeals.map((b) =>
          b._id === action.payload._id ? action.payload : b
        );
        state.bucketDealsInTable = state.bucketDealsInTable.map((b) =>
          b._id === action.payload._id ? action.payload : b
        );
      })
      .addCase(deleteBucketDealThunk.fulfilled, (state, action) => {
        state.currentBucketDeal = {
          _id: "",
          buckets: [],
          businessRegistrationNumber: "",
          businessName: "",
        };

        state.bucketDeals = state.bucketDeals.filter(
          (b) => b._id !== action.payload._id
        );
        state.bucketDealsInTable = state.bucketDeals.filter(
          (b) => b._id !== action.payload._id
        );
        state.lastUpdateKey = getUniqueKey();
      })
      .addCase(deleteMultBucketDealThunk.fulfilled, (state, action) => {
        state.currentBucketDeal = {
          _id: "",
          buckets: [],
          businessRegistrationNumber: "",
          businessName: "",
        };

        state.bucketDeals = state.bucketDeals.filter(
          (b) => !action.payload.includes(b._id)
        );
        state.bucketDealsInTable = state.bucketDeals.filter(
          (b) => !action.payload.includes(b._id)
        );
        state.lastUpdateKey = getUniqueKey();
      });
  },
});

// export const { getBusinesses } = businessesSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const selectBucketDeals = (state: RootState) =>
  state.bucketDeals.bucketDeals;
export const selectBucketDealsInTable = (state: RootState) =>
  state.bucketDeals.bucketDealsInTable;

export const selectCurrentBucketDeal = (state: RootState) =>
  state.bucketDeals.currentBucketDeal;

export const selectLastUpdateKey = (state: RootState) =>
  state.bucketDeals.lastUpdateKey;
// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
//   (amount: number): AppThunk =>
//   (dispatch, getState) => {
//     const currentValue = selectCount(getState());
//     if (currentValue % 2 === 1) {
//       dispatch(incrementByAmount(amount));
//     }
//   };

export const {
  setCurrentBucketDeal,
  filterTableByName,
  emptyBucketDealsTable,
} = bucketDealsSlice.actions;

export default bucketDealsSlice.reducer;
