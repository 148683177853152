import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../app/store";

import {
  getPartnerDealsFromServer,
  createPartnerDealsServer,
  deletePartnerDealServer,
} from "./partnerDealsApi";
import { IPartner } from "../../interfaces/partner.interface";
import { IPartnerDeal } from "../../interfaces/partnerDeal.interface";
import { IQueryFilters } from "../../interfaces/queryFilters.interface";
import { TableParams } from "../../interfaces/tableParams.interface";

// import { fetchCount } from './counterAPI';

export interface PartnerDealsState {
  partnerDeals: IPartnerDeal[];
  totalCount: number;
  filters: Partial<IPartnerDeal>;
  currentPartnerDeal: IPartnerDeal;
}

const initialState: PartnerDealsState = {
  partnerDeals: [] as IPartnerDeal[],
  totalCount: 0,
  filters: {},
  currentPartnerDeal: {} as IPartnerDeal,
};

export const getPartnerDealsThunk = createAsyncThunk(
  "partnerDeals/getAll",
  async (params: IQueryFilters<IPartnerDeal>) => {
    const response = await getPartnerDealsFromServer(params);
    return response;
  }
);

export const createPartnerDealsThunk = createAsyncThunk(
  "partnerDeals/create",
  async (partnerDeals: Partial<IPartnerDeal>[]) => {
    const response = await createPartnerDealsServer(partnerDeals);
    return response;
  }
);
// export const editPartnerThunk = createAsyncThunk(
//   "partnerDeals/edit",
//   async (params: { partnerId: string; update: Partial<IPartner> }) => {
//     const response = await editPartnerServer(params);
//     return response;
//   }
// );
export const deletePartnerDealThunk = createAsyncThunk(
  "partnerDeals/delete",
  async (params: { partnerDealId: string }) => {
    const response = await deletePartnerDealServer(params);
    return response;
  }
);

export const partnerDealsSlice = createSlice({
  name: "partnerDeals",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setFilters: (state, action: PayloadAction<Partial<IPartnerDeal>>) => {
      state.filters = action.payload;
    },
    setCurrentPartnerDeal: (state, action: PayloadAction<IPartnerDeal>) => {
      state.currentPartnerDeal = action.payload;
    },
    // setSubmenu: (state, action: PayloadAction<ICatalog | null>) => {
    //   state.currentSubmenu = action.payload;
    // },
    // resetCatalogState: (state) => {
    //   state.currentItem = {} as any;
    //   state.currentSubmenu = {} as any;
    // },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(createPartnerDealsThunk.fulfilled, (state, action) => {
        state.partnerDeals.push(action.payload.partnerDeals[0]);
      })

      .addCase(getPartnerDealsThunk.fulfilled, (state, action) => {
        state.partnerDeals = action.payload?.data;
      })

      .addCase(deletePartnerDealThunk.fulfilled, (state, action) => {
        state.partnerDeals = state.partnerDeals.filter(
          (p) => p._id !== action.payload._id
        );
      });
  },
});

export const { setFilters, setCurrentPartnerDeal } = partnerDealsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const selectPartnerDeals = (state: RootState) =>
  state.partnerDeals.partnerDeals;
export const selectCurrentPartnerDeal = (state: RootState) =>
  state.partnerDeals.currentPartnerDeal;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
//   (amount: number): AppThunk =>
//   (dispatch, getState) => {
//     const currentValue = selectCount(getState());
//     if (currentValue % 2 === 1) {
//       dispatch(incrementByAmount(amount));
//     }
//   };

export default partnerDealsSlice.reducer;
