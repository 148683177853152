// export function downloadFile(content: string, name?: string, format?: "CSV") {
//   let formattedContent =
//     format === "CSV"
//       ? "data:text/csv;charset=utf-8," + encodeURIComponent(content)
//       : content;

//   var link = document.createElement("a");
//   link.setAttribute("href", formattedContent);
//   link.setAttribute("download", (name || "my_data") + ".csv");
//   document.body.appendChild(link);
//   link.click();
// }

export function downloadFile(
  content: string,
  name?: string,
  format: "CSV" | "PDF" | "JSON" = "CSV"
) {
  let formattedContent: string;

  if (format === "CSV") {
    // Check if content is Base64-encoded
    if (/^[A-Za-z0-9+/]+[=]{0,2}$/.test(content)) {
      formattedContent =
        "data:text/csv;charset=utf-8," + encodeURIComponent(atob(content));
    } else {
      formattedContent = content;
    }
    name = name || "my_data.csv";
  } else if (format === "PDF") {
    // Assuming content is a Base64-encoded PDF string
    formattedContent = "data:application/pdf;base64," + content;
    name = name || "my_document.pdf";
  } else if (format === "JSON") {
    formattedContent =
      "data:application/json;base64," + encodeURIComponent(content);
    name = name || "my_data.json";
  } else {
    throw new Error("Unsupported format");
  }

  const link = document.createElement("a");
  link.setAttribute("href", formattedContent);
  link.setAttribute("download", name);
  document.body.appendChild(link);
  link.click();
}
