import { Alert, Card, Descriptions, Typography } from "antd";
import React from "react";
import { selectCurrentPartner } from "../../../partners/partnersSlice";
import { useAppSelector } from "../../../../app/hooks";
import BucketGroupValue from "../partner-deals-table/subcomponents/bucket-group-value/BucketGroupValue";
import { WEB_PORTAL } from "../../../../config";

const { Text, Title, Paragraph, Link } = Typography;
export default function InviteMerchantLink() {
  const partner = useAppSelector(selectCurrentPartner);

  const onboardingLink = `${WEB_PORTAL.BASE_URL}/signup/?ref=${partner?.refCode}`;
  return (
    <>
      {partner?.refCode && (
        <Card
          style={{
            width: "100%",
            boxShadow: "0px 0px 11px -2px rgba(224,224,224,1)",
            padding: 0,
            marginBottom: "15px",
          }}
        >
          {/* <Title level={4} style={{ marginBottom: 0 }}>
        Invite New Merchants{" "}
      </Title> */}
          {partner?.defaultPricing ? (
            <>
              <Paragraph
                style={{ marginBottom: 0 }}
                strong
                copyable={{ text: onboardingLink }}
              >
                To invite new merchants use the following link:{" "}
                <Link href={onboardingLink} target="_blank">
                  Web Onboarding (Link)
                </Link>
              </Paragraph>
              <Paragraph style={{ color: "rgb(123, 121, 128)" }}>
                Merchants onboarding through this link will receive the default
                pricing set by Klearly, unless a custom merchant deal is
                created.
              </Paragraph>
              <Descriptions bordered size="middle">
                <Descriptions.Item label="Domestic Debit">
                  <BucketGroupValue
                    value={partner.defaultPricing?.domesticDebitCards?.value}
                    valueType={
                      partner.defaultPricing?.domesticDebitCards?.valueType
                    }
                  />
                </Descriptions.Item>
                <Descriptions.Item label="E.E.A">
                  <BucketGroupValue
                    value={partner.defaultPricing?.european?.value}
                    valueType={partner.defaultPricing?.european?.valueType}
                  />
                </Descriptions.Item>
                <Descriptions.Item label="Non E.E.A & Commercial">
                  <BucketGroupValue
                    value={
                      partner.defaultPricing?.internationalAndCommercial?.value
                    }
                    valueType={
                      partner.defaultPricing?.internationalAndCommercial
                        ?.valueType
                    }
                  />
                </Descriptions.Item>
              </Descriptions>
            </>
          ) : (
            <>
              <Paragraph strong>
                Invite new merchants using a custom link
              </Paragraph>
              <Alert
                message="No default pricing found - Please contact Klearly to set up your default pricing."
                type="warning"
                showIcon
              />
            </>
          )}
        </Card>
      )}
    </>
  );
}
