import { Card, Input, Segmented, Select, Switch } from "antd";
import React, { ChangeEvent, useCallback } from "react";
import styles from "./BusinessTableFilters.module.css";
import { TableParams } from "../../../../../interfaces/tableParams.interface";
import { useAppSelector } from "../../../../../app/hooks";
import { selectPartners } from "../../../../partners/partnersSlice";
import {
  BusinessStatus,
  BusinessType,
  ComplianceStatus,
  IBusinessFullQuery,
} from "../../../../../interfaces/businessFull.interface";
import { debounce } from "lodash";
import { SearchOutlined } from "@ant-design/icons";
interface BusinessTableFiltersProps {
  filters: Partial<IBusinessFullQuery>;
  setFilters: React.Dispatch<React.SetStateAction<Partial<IBusinessFullQuery>>>;
  initialPagination: { current: number; pageSize: number };
  setTableParams: React.Dispatch<React.SetStateAction<TableParams>>;
}

export default function BusinessTableFilters({
  filters,
  setFilters,
  setTableParams,
  initialPagination,
}: BusinessTableFiltersProps) {
  const partners = useAppSelector(selectPartners);
  const handleStatusFilterChange = (value: BusinessStatus | "all") => {
    // dispatch(filterTableByStatus(value));
    setFilters((prev) => {
      if (value === "all") {
        //@ts-ignore
        delete prev.status;
        return { ...prev, ...initialPagination };
      } else return { ...prev, status: value, ...initialPagination };
    });
    setTableParams((prev) => {
      return { ...prev, pagination: { ...initialPagination } };
    });
  };
  const handleReachoutStatusChange = (value: ComplianceStatus | "all") => {
    setFilters((prev) => {
      if (!value || value === "all") {
        delete prev.complianceStatus;
        return { ...prev, ...initialPagination };
      } else
        return {
          ...prev,
          complianceStatus: value,
          ...initialPagination,
        };
    });

    setTableParams((prev) => {
      return { ...prev, pagination: { ...initialPagination } };
    });
  };

  const handleTypeFilterChange = (value: BusinessType | "all") => {
    // dispatch(filterTableByType(value));
    setFilters((prev) => {
      if (value === "all") {
        //@ts-ignore
        delete prev.businessType;
        return { ...prev, ...initialPagination };
      } else {
        return { ...prev, businessType: value, ...initialPagination };
      }
    });
    setTableParams((prev) => {
      return { ...prev, pagination: { ...initialPagination } };
    });
  };
  const handlePartnerIdFilterChange = (value: string | "all") => {
    // dispatch(filterTableByType(value));
    setFilters((prev) => {
      if (value === "all") {
        delete prev.partnerId;
        return { ...prev, ...initialPagination };
      } else {
        return { ...prev, partnerId: value, ...initialPagination };
      }
    });
    setTableParams((prev) => {
      return { ...prev, pagination: { ...initialPagination } };
    });
  };

  const debouncedNameFilterChange = useCallback(
    debounce((nextValue) => handleNameFilterChange(nextValue), 400),
    [] // will be created only once initially
  );

  const handleDebouncedNameFilterChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    debouncedNameFilterChange(event);
  };
  const handleNameFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value.toLowerCase();
    // dispatch(filterTableByName(name));
    setFilters((prev) => {
      if (!name || name === "") {
        //@ts-ignore
        delete prev.name;

        return { ...prev, ...initialPagination };
      } else {
        let updatedFilters = {
          ...prev,
          ...initialPagination,
          name: name.replace(/\s+/g, " ").trim(),
        };
        return { ...updatedFilters };
      }
    });
    setTableParams((prev) => {
      return { ...prev, pagination: { ...initialPagination } };
    });
  };
  return (
    <div>
      <Segmented
        size="small"
        key={"businessStatusFilter"}
        className={styles.segment_box}
        onChange={(value: any) => {
          handleStatusFilterChange(value);
        }}
        options={[
          {
            label: <div className={styles.segment_item}>All</div>,
            value: "all",
          },
          {
            label: (
              <div className={styles.segment_item}>Pending Submission</div>
            ),
            value: "pendingSubmission",
          },
          {
            label: (
              <div className={styles.segment_item}>Pending Verification</div>
            ),
            value: "pendingVerification",
          },
          {
            label: <div className={styles.segment_item}>Approved</div>,
            value: "verified",
          },
          {
            label: <div className={styles.segment_item}>Declined</div>,
            value: "unverified",
          },
          {
            label: <div className={styles.segment_item}>Inactivated</div>,
            value: "inactivated",
          },
        ]}
      />
      <Segmented
        key={"businessTypeFilter"}
        className={styles.segment_box}
        onChange={(value: any) => {
          handleTypeFilterChange(value);
        }}
        size="small"
        options={[
          {
            label: <div className={styles.segment_item}>All</div>,
            value: "all",
          },
          // {
          //   label: <div className={styles.segment_item}>Pending Action</div>,
          //   value: "pendingAction",
          // },
          {
            label: <div className={styles.segment_item}>Sole Traders</div>,
            value: "soleTrader",
          },
          {
            label: <div className={styles.segment_item}>Partnership</div>,
            value: "partnership",
          },
          {
            label: (
              <div className={styles.segment_item}>Private Limited Company</div>
            ),
            value: "privateLimitedCompany",
          },
          {
            label: (
              <div className={styles.segment_item}>Public Limited Company </div>
            ),
            value: "publicLimitedCompany",
          },
          {
            label: <div className={styles.segment_item}>Other </div>,
            value: "other",
          },
        ]}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "15px",
          marginBottom: "15px",
        }}
      >
        <div>
          <Segmented
            key={"comlianceReachoutStatusFilter"}
            // className={styles.segment_box}
            onChange={(value: any) => {
              handleReachoutStatusChange(value);
            }}
            size="small"
            options={[
              {
                label: <div className={styles.segment_item}>All</div>,
                value: "all",
              },
              // {
              //   label: <div className={styles.segment_item}>Pending Action</div>,
              //   value: "pendingAction",
              // },
              {
                label: <div className={styles.segment_item}>Not Reach Out</div>,
                value: ComplianceStatus.NotContacted,
              },
              // {
              //   label: <div className={styles.segment_item}>In Progress</div>,
              //   value: ComplianceStatus.InProgress,
              // },
              {
                label: <div className={styles.segment_item}>Reached Out</div>,
                value: ComplianceStatus.ReachedOut,
              },
            ]}
          />
        </div>
        <div>
          <span style={{ marginLeft: "10px" }}>Partner:</span>

          <Select
            style={{ width: "200px", marginLeft: "20px" }}
            placeholder="Select a partner"
            onChange={(value: any) => handlePartnerIdFilterChange(value)}
          >
            <Select.Option value="all">All</Select.Option>
            {partners.map((partner) => (
              <Select.Option key={partner._id} value={partner._id}>
                {partner.name}
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>
      <Input
        onChange={handleDebouncedNameFilterChange}
        placeholder="Business Name, Email, Kvk ..."
        value={filters.businessName}
        className={styles.searchInput}
        prefix={<SearchOutlined />}
      />
    </div>
  );
}
