import React, { useState } from "react";
import CreateAnalyticsReportFormModal from "../create-analytics-report-form/CreateAnalyticsReportForm";
import { Button } from "antd";

export default function AnalyticsReportPanel() {
  const [
    isCreateAnalyticsReportFormModalVisible,
    setIsCreateAnalyticsReportFormModalVisible,
  ] = useState(false);
  return (
    <div
      style={{ display: "flex", justifyContent: "end", marginBottom: "40px" }}
    >
      <CreateAnalyticsReportFormModal
        setVisible={setIsCreateAnalyticsReportFormModalVisible}
        visible={isCreateAnalyticsReportFormModalVisible}
      />
      <Button
        type="primary"
        style={{ marginRight: "80px", float: "right" }}
        onClick={() => {
          setIsCreateAnalyticsReportFormModalVisible(true);
        }}
      >
        {" "}
        Analytics Reports
      </Button>
    </div>
  );
}
