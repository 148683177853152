export const SalesRepresentativesList: {
  label: String;
  value: string;
  monthsToExp: number;
  email: string;
}[] = [
  { label: "Tom R", value: "Tom R", monthsToExp: 13, email: "tom@klearly.eu" }, //Changed to 13 due to the "month" it takes for them to due transactions
  {
    label: "Geus W",
    value: "Geus W",
    monthsToExp: 13,
    email: "geus@lemonpay.nl",
  },
  {
    label: "Leonard D.B",
    value: "Leonard D.B",
    monthsToExp: 13,
    email: "leonard@klearly.eu",
  },
  {
    label: "Sven O",
    value: "Sven O",
    monthsToExp: 13,
    email: "sven@klearly.eu",
  },
  {
    label: "Sijmen H",
    value: "Sijmen H",
    monthsToExp: 13,
    email: "sijmen@klearly.nl",
  },
  {
    label: "Anton W",
    value: "Anton W",
    monthsToExp: 13,
    email: "anton@klearly.nl",
  },
  {
    label: "Jelle V",
    value: "Jelle V",
    monthsToExp: 19,
    email: "jelle@klearly.nl",
  },
];
