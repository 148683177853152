import { Form, Input, InputNumber, Space } from "antd";
import Select from "antd/lib/select";
import { Option } from "antd/lib/mentions";
import styles from "../BusinessEditor.module.css";
export default function MerchantServiceFeesEditor() {
  return (
    <>
      <Form.List name={"merchantServiceChargeFees"}>
        {(fields) => (
          <div>
            {fields.map(({ key, name, ...restField }) => (
              <Space key={key} align="baseline">
                <Form.Item {...restField} name={[name, "name"]}>
                  <Input className={styles.bucket_name_input} disabled></Input>
                </Form.Item>

                <Form.Item {...restField} name={[name, "value"]}>
                  <InputNumber
                    controls={false}
                    className={styles.bucket_value_input}
                    min={0}
                  />
                </Form.Item>
                <Form.Item {...restField} name={[name, "valueType"]}>
                  <Select
                    className={styles.bucket_value_input}
                    style={{ width: "85px" }}
                  >
                    <Option value={"Percentage"}>%</Option>
                    <Option value={"Fixed"}>FIXED</Option>
                  </Select>
                </Form.Item>
              </Space>
            ))}
          </div>
        )}
      </Form.List>
    </>
  );
}
