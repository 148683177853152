import React, { useState } from "react";
import PartnersTable from "./components/partners-table/PartnersTable";
import { Button } from "antd";
import CreatePartnerForm from "./components/create-partner-form/CreatePartnerForm";
import GeneralTableHeader from "../common/general-table-header/GeneralTableHeader";
import { PlusCircleOutlined } from "@ant-design/icons";
import styles from "./Partners.module.css";

export default function Partners() {
  const [isCreatePartnerFormVisible, setIsCreatePartnerFormVisible] =
    useState(false);
  const showCreatePartnerFormModal = () => {
    setIsCreatePartnerFormVisible(true);
  };
  return (
    <div className={styles.container}>
      <GeneralTableHeader
        title="Partners"
        actionButtons={[
          <Button
            type="primary"
            onClick={showCreatePartnerFormModal}
            icon={<PlusCircleOutlined></PlusCircleOutlined>}
            size={"middle"}
          >
            Create New
          </Button>,
        ]}
      />
      <PartnersTable />
      <CreatePartnerForm
        visible={isCreatePartnerFormVisible}
        setVisible={setIsCreatePartnerFormVisible}
      />
    </div>
  );
}
