import { Button, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  selectMFASetupComplete,
  selectMFASetupQrcode,
  selectSessionToken,
  verifySoftwareToken,
} from "./accountSlice";
import { CheckCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import styles from "./Account.module.css";
interface IPropsMFASetup {
  qrcode: string;
  session: string;
}
export default function MFASetup() {
  const mfaSetupComplete = useAppSelector(selectMFASetupComplete);
  const qrcode = useAppSelector(selectMFASetupQrcode);
  const session = useAppSelector(selectSessionToken);
  const [isVerifyingCode, setIsVerifyingCode] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const onMFASetupFinish = (values: any) => {
    const { confirmationCode } = values;
    if (session) {
      setIsVerifyingCode(true);
      dispatch(verifySoftwareToken({ confirmationCode, session })).finally(() =>
        setIsVerifyingCode(false)
      );
    }
  };
  useEffect(() => {
    if (!qrcode) navigate("/");
  }, []);

  return (
    <div className="centering-container">
      <h2>MFA Setup</h2>
      {!mfaSetupComplete && qrcode && (
        <div className={styles.mfa_container}>
          <h4>(1) Scan the QR code below using your authenticator app</h4>
          <img src={qrcode}></img>

          <h4>
            (2) Enter the 6 digits showing under 'LemonPay Admin
            Authentification'
          </h4>
          <Form onFinish={onMFASetupFinish} layout="vertical">
            <Form.Item
              label=""
              name="confirmationCode"
              // tooltip={"Confirmation Code"}
              rules={[
                {
                  required: true,
                  message: "Please enter your time based OTP!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button
                type="primary"
                htmlType="submit"
                loading={isVerifyingCode}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}
      {mfaSetupComplete && (
        <div className="centering-container">
          <CheckCircleOutlined style={{ fontSize: "100px", color: "black" }} />
          <br />
          <h5>Complete the setup by signing in again.</h5>
          <br />
          <Button type="primary" onClick={() => navigate("/")}>
            Sign In
          </Button>
        </div>
      )}
    </div>
  );
}
