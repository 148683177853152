import { Form, Modal } from "antd";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../app/hooks";
import { selectCurrentBusiness } from "../../../businesses/businessesSlice";

import {
  getAccountingReportFromServer,
  getPayoutReportFromServer,
  getSalesOverviewReportFromServer,
  getTransactionsOverviewReportFromServer,
} from "../../reportsApi";
import { IReportRequest } from "../../../../interfaces/requestReport.interface";

import moment from "moment";
import {
  IMerchantReport,
  IReportResponse,
} from "../../../../interfaces/reports.interface";
import CreateReportForm from "../../../common/create-report-form/CreateReportForm";

interface IPropsCreateReportFormModal {
  visible: boolean;
  setVisible: any;
}

let ReportOptions: IMerchantReport[] = [
  {
    type: "salesOverviewReport",
    label: "Sales Overview",
    formatTypes: ["PDF", "XLSX"],
    previewUrl: "/assets/icons/report/sales-overview.svg",
    description:
      "Total sales, refunds and fees collected in one overview. Convenient for your daily closing.",
  },
  {
    type: "transactionsOverviewReport",
    label: "Transactions Overview",
    formatTypes: ["PDF", "CSV", "XLSX"],
    previewUrl: "/assets/icons/report/transactions-overview.svg",
    description:
      "All your transactions of a certain time period in one overview.",
  },
  {
    type: "payoutReport",
    label: "Payout",
    formatTypes: ["PDF", "XLSX"],
    previewUrl: "/assets/icons/report/pay-out.svg",
    description: `All transactions that were paid out during the selected time period.`,
    // warning: "DO NOT SEND TO CLIENTS",
    // isBeta: true,
  },
  {
    type: "accountingReport",
    label: "Accounting",
    formatTypes: ["XLSX"],
    previewUrl: "/assets/icons/report/accounting.svg",
    description:
      "Associate transactions done within the selected time period to their payouts. (Includes both paid & future settlements)",
    // warning: "DO NOT SEND TO CLIENTS",
    // isBeta: true,
  },
];

export default function CreateMerchantReportFormModal({
  visible,
  setVisible,
}: IPropsCreateReportFormModal) {
  const currentBusiness = useAppSelector(selectCurrentBusiness);
  const [report, setReport] = useState<IMerchantReport>(ReportOptions[0]);
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      dateRange: [
        moment().set({
          date: moment().date() - 1,
          minute: 0,
          hour: currentBusiness?.settings?.endOfDay?.hour || 0,
        }),
        moment().set({
          minute: 59,
          hour: currentBusiness?.settings?.endOfDay?.hour - 1 || 23,
        }),
      ],
    });
  }, [currentBusiness, report.type]);

  const handleSubmit = async (
    reportRequest: IReportRequest,
    reportType?: string
  ) => {
    reportRequest.businessId = currentBusiness._id;
    let results = {} as IReportResponse;
    if (reportType === "salesOverviewReport")
      await getSalesOverviewReportFromServer(reportRequest)
        .then((d) => (results = d))
        .catch((e) => console.log("Error found", e));

    if (reportType === "transactionsOverviewReport")
      await getTransactionsOverviewReportFromServer(reportRequest)
        .then((d) => (results = d))
        .catch((e) => console.log("Error found", e));

    if (reportType === "payoutReport")
      await getPayoutReportFromServer(reportRequest)
        .then((d) => (results = d))
        .catch((e) => console.log("Error found", e));
    if (reportType === "accountingReport")
      await getAccountingReportFromServer(reportRequest)
        .then((d) => (results = d))
        .catch((e) => console.log("Error found", e));

    return results;
  };
  const handleCancel = () => {
    console.log("reseting");
    // handleReset();
    setVisible(false);
  };

  return (
    <Modal
      visible={visible}
      title={"Create Report For " + currentBusiness?.businessName}
      onCancel={handleCancel}
      footer={null}
      width={650}
    >
      <CreateReportForm
        onSubmit={handleSubmit}
        reportOptions={ReportOptions}
        defaultCustomTimeOffset={currentBusiness?.settings?.endOfDay?.hour}
      ></CreateReportForm>
    </Modal>
  );
}
