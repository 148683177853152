import { Form, FormInstance, Input, Space, Switch } from "antd";
import { FlagOutlined } from "@ant-design/icons";

export default function FlagEditor({ form }: { form: FormInstance }) {
  return (
    <>
      <Form.List name="onboardingFlags">
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => (
              <>
                <Space align="start" key={key}>
                  <FlagOutlined
                    style={{
                      color: "red",
                      marginTop: "10px",
                    }}
                  />
                  <Form.Item name={[name, "type"]} key={key} {...restField}>
                    <Input
                      style={{
                        color: "black",
                        fontSize: "12px",
                        width: "270px",
                        borderRadius: "5px",
                        backgroundColor: "white",
                        border: "none",
                      }}
                      disabled
                    />
                  </Form.Item>
                  <Form.Item
                    name={[name, "isResolved"]}
                    key={key + "isresolved"}
                    {...restField}
                  >
                    <Switch
                      defaultChecked={
                        form?.getFieldValue("onboardingFlags") &&
                        form?.getFieldValue("onboardingFlags")[
                          fields[index].key
                        ].isResolved
                      }
                      checkedChildren="Resolved"
                      unCheckedChildren="Resolve"
                    />
                  </Form.Item>
                </Space>
              </>
            ))}
          </>
        )}
      </Form.List>
    </>
  );
}
