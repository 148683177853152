import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  selectIsLoadingBusinesses,
  selectTotalBusinessCount,
  setFilters,
} from "../businesses/businessesSlice";
import PartnerBusinessTable from "./components/Partner-Business-Table/PartnerBusinessTable";
import GeneralTableHeader from "../common/general-table-header/GeneralTableHeader";
import { Statistic } from "antd";

function PartnerBusinesses() {
  const dispatch = useAppDispatch();
  const totalCount = useAppSelector(selectTotalBusinessCount);
  const loading = useAppSelector(selectIsLoadingBusinesses);
  useEffect(() => {
    dispatch(setFilters({}));
  }, []);

  return (
    <div>
      <GeneralTableHeader
        title="Merchants Overview"
        description="Merchants will appear in this list only once they open an
        account with Klearly."
        children={
          <div style={{ height: "50px" }}>
            <>
              <Statistic
                title="Amount of Merchants:"
                value={totalCount}
                loading={loading}
                style={{ maxWidth: "200px" }}
                // precision={2}
              />
            </>
          </div>
        }
      />
      <PartnerBusinessTable></PartnerBusinessTable>
    </div>
  );
}

export default PartnerBusinesses;
