// import axios from "axios";
import { CONFIG } from "../../config";

import { getTokenizedAxios } from "../../utils/axiosWithTokenGen";

export async function callBotAgentServer(
  query: string,
  sessionId: string
): Promise<{ message: string; sessionId: string }> {
  const axios = await getTokenizedAxios();
  const path = "/bot/call";

  return axios
    .post(CONFIG.ADMIN_SERVER.BASE_URL + path, { query, sessionId })
    .then((data) => data.data);
}
