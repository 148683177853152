import { Button, Divider, Drawer, Dropdown, Menu, Space } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  selectIdToken,
  selectIsKlearlyAdmin,
  selectUser,
  selectUserAccessLevel,
  selectUserRole,
} from "../access-control/accountSlice";
import styles from "./Navbar.module.css";
import { logout } from "../access-control/accountSlice";
import { IRouteConfig } from "../../interfaces/routeConfig.interface";
import useIsMobile from "../../hooks/useIsMobile";

import { MenuUnfoldOutlined, CaretDownOutlined } from "@ant-design/icons";
import { AccessLevel } from "../../interfaces/accessLevel.enum";
interface IPropsNavbar {
  routes: IRouteConfig[];
}
export default function Navbar({ routes }: IPropsNavbar) {
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();
  const [open, setOpen] = useState(false);
  const user = useAppSelector(selectUser);
  const role = useAppSelector(selectUserRole);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const userAccessLevel = useAppSelector(selectUserAccessLevel);
  // useEffect(() => {
  // }, [userAccessLevel]);

  const navigate = useNavigate();
  const logoutUser = () => {
    dispatch(logout()).then(() => navigate("/"));
  };

  const NavItem = (r: {
    path: string;
    label?: string;
    accessLevel?: AccessLevel[];
  }) => {
    return r.label &&
      r.accessLevel?.some((a) => userAccessLevel.includes(a)) ? (
      <>
        <NavLink
          className={({ isActive }) =>
            isActive ? styles.active_link : styles.non_active_link
          }
          end
          to={r.path}
          onClick={() => setOpen(false)}
        >
          <Button
            type="link"
            size="large"
            className={styles.nav_button}
            style={{ width: "100%", textAlign: "left" }}
          >
            {r.label}
          </Button>
        </NavLink>
      </>
    ) : (
      <></>
    );
  };

  const NavItems = useMemo(() => {
    return (
      <>
        {routes.map((r) => {
          return (
            <>
              {r.isGroup &&
              r.label &&
              r.accessLevel?.some((a) => userAccessLevel.includes(a)) ? (
                <Dropdown
                  overlay={
                    <Menu className={styles.nav_group_container}>
                      {r?.children?.map((c) => (
                        <Menu.Item>
                          <NavItem
                            label={c?.label}
                            path={c?.path}
                            accessLevel={c?.accessLevel}
                          />
                        </Menu.Item>
                      ))}
                    </Menu>
                  }
                >
                  <Space size="middle" key={r.path}>
                    <Button
                      type="link"
                      size="large"
                      className={styles.nav_button}
                    >
                      {r?.label}
                      <CaretDownOutlined />
                    </Button>
                  </Space>
                </Dropdown>
              ) : (
                <NavItem
                  label={r.label}
                  path={r.path}
                  accessLevel={r?.accessLevel}
                />
              )}
            </>
          );
        })}
      </>
    );
  }, [routes, userAccessLevel]); // Dependencies array

  // ... rest of the component remains unchanged
  return (
    <div className={styles.row}>
      {userAccessLevel && (
        <>
          <div className={styles.left_container}>
            <div className={styles.company_icon}>
              <img
                style={{ width: "20px" }}
                src="https://lemonpay-design-templates.s3.eu-central-1.amazonaws.com/klearly-logo.svg"
              />
            </div>
            {user?.email && (
              <>
                {isMobile ? (
                  <div className="center">
                    <Button
                      type="primary"
                      onClick={showDrawer}
                      icon={<MenuUnfoldOutlined />}
                    ></Button>
                    <Drawer
                      title="Klearly"
                      placement="left"
                      onClose={onClose}
                      visible={open}
                      width="200px"
                    >
                      {NavItems}
                    </Drawer>
                  </div>
                ) : (
                  NavItems
                )}
              </>
            )}
          </div>
          <div className={styles.right_container}>
            {user?.email && (
              <Dropdown
                overlay={
                  <Menu className={styles.account_details_menu} theme="dark">
                    <div className={styles.user_details}>
                      <div className={styles.user_details_icon}>
                        <img
                          style={{ width: "20px" }}
                          src="https://lemonpay-design-templates.s3.eu-central-1.amazonaws.com/klearly-logo.svg"
                        />
                      </div>
                      {user?.name && (
                        <div className={styles.user_details_name}>
                          {user?.name}
                        </div>
                      )}
                      {user?.email && (
                        <div className={styles.user_details_email}>
                          {user?.email}
                        </div>
                      )}
                      {role && (
                        <div className={styles.user_details_role}>{role}</div>
                      )}
                      {user?.partnerName && (
                        <div className={styles.user_details_partner_name}>
                          {user?.partnerName}
                        </div>
                      )}
                    </div>
                    <Divider
                      style={{
                        margin: 0,
                        color: "white",
                        backgroundColor: "white",
                        opacity: 0.2,
                      }}
                    />
                    <Menu.Item>
                      <Space
                        size="middle"
                        onClick={() => {
                          logoutUser();
                        }}
                      >
                        <Button
                          type="link"
                          className={styles.nav_logout_button}
                        >
                          Logout
                        </Button>
                      </Space>
                    </Menu.Item>
                  </Menu>
                }
              >
                <Space size="middle" key={"logout"}>
                  <Button
                    type="link"
                    size="middle"
                    shape="round"
                    className={styles.nav_button}
                  >
                    {user?.name?.split(" ")[0]}
                    <CaretDownOutlined />
                  </Button>
                </Space>
              </Dropdown>
            )}
          </div>
        </>
      )}
    </div>
  );
}
