import {
  Table,
  Space,
  Tag,
  Button,
  Input,
  TablePaginationConfig,
  Statistic,
  Popover,
  Badge,
  List,
  Typography,
  Tooltip,
} from "antd";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import styles from "./BusinessTable.module.css";
import {
  getBusinessesThunk,
  setCurrentBusiness,
  selectTotalBusinessCount,
  selectLastUpdateKey,
} from "../../businessesSlice";
import BusinessDetailsModal from "../Business-Details-Modal/BusinessDetailsModal";
import BusinessEditorModal from "../Business-Editor-Modal/BusinessEditor";
import { Empty } from "antd";
import { Segmented } from "antd";
import {
  CheckCircleOutlined,
  CloseOutlined,
  SearchOutlined,
  FlagOutlined,
  InfoCircleOutlined,
  EditOutlined,
  FileOutlined,
  HddOutlined,
  CopyrightOutlined,
} from "@ant-design/icons";
import ApprovalModal from "../Approval-Modal/ApprovalModal";
import DeclinedModal from "../Declined-Modal/DeclinedModal";
import PendingVerificationModal from "../Pending-Verification-Modal/PendingVerificationModal";
import Loader from "../../../common/loader/Loader";
import {
  ComplianceStatus,
  IBusinessFull,
  IBusinessFullQuery,
  IOnboardingFlag,
} from "../../../../interfaces/businessFull.interface";
import SubmitModal from "../Submit-Modal /SubmitModal";
import { TableParams } from "../../../../interfaces/tableParams.interface";
import { IBucketDeal } from "../../../../interfaces/bucketDeal.interface";
import { IPagination } from "../../../../interfaces/pagination.interface";
import debounce from "lodash/debounce";
import BusinessFilesModal from "../Business-Files-Modal/BusinessFilesModal";
import { mapStatusLabels } from "../../utils/mapStatusLabel";
import {
  getPartnersThunk,
  selectPartners,
} from "../../../partners/partnersSlice";
import { IPartner } from "../../../../interfaces/partner.interface";
import BusinessTableFilters from "./components/BusinessTableFilters";
import BusinessDevicesModal from "../Business-Devices-Modal/BusinessDevicesModal";
const { Column, ColumnGroup } = Table;

export default function BusinessTable() {
  const [isDetailModalVisible, setIsDetailModalVisible] = useState(false);
  const [isEditorModalVisible, setIsEditorModalVisible] = useState(false);
  const [isFilesModalVisible, setIsFilesModalVisible] = useState(false);
  const [isDevicesModalVisible, setIsDevicesModalVisible] = useState(false);
  const [isApprovalModalVisible, setIsApprovalModalVisible] = useState(false);
  const [isDeclinedModalVisible, setIsDeclinedModalVisible] = useState(false);
  const [isSubmitModalVisible, setIsSubmitModalVisible] = useState(false);
  const [
    isPendingVerificationModalVisible,
    setIsPendingVerificationdModalVisible,
  ] = useState(false);
  const [loading, setLoading] = useState(false);
  const lastUpdateKey = useAppSelector(selectLastUpdateKey);
  const partners = useAppSelector(selectPartners);
  const [businessesInTable, setBusinessesInTable] = useState([]);
  const totalCount = useAppSelector(selectTotalBusinessCount);
  const initialPagination = { current: 1, pageSize: 10 };
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: { ...initialPagination },
  });
  const [filters, setFilters] = useState<Partial<IBusinessFullQuery>>({});
  const dispatch = useAppDispatch();

  const fetchAllBusinesses = async (params: {
    pagination: IPagination;
    filters: Partial<IBucketDeal>;
  }) => {
    setLoading(true);
    await dispatch(
      getBusinessesThunk({
        currentPage: params?.pagination?.current!,
        pageSize: params?.pagination?.pageSize!,
        ...filters,
      })
    )
      .then(({ payload }) => {
        //@ts-ignore
        let businesses = payload.data;
        //@ts-ignore
        let totalCount = payload.totalCount;
        let businessesWithKeys = businesses.map((b: IBusinessFull) => ({
          ...b,
          key: b._id,
        }));
        setBusinessesInTable(businessesWithKeys);

        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: totalCount,
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchPartners = async () => {
    if (partners?.length === 0) await dispatch(getPartnersThunk());
  };
  useEffect(() => {
    fetchAllBusinesses({
      pagination: {
        current: tableParams.pagination?.current || 1,
        pageSize: tableParams.pagination?.pageSize!,
      },
      filters,
    });
    fetchPartners();
  }, [JSON.stringify(tableParams), JSON.stringify(filters), lastUpdateKey]);

  const showBusinessDetailsModal = (business: IBusinessFull) => {
    dispatch(setCurrentBusiness(business));
    setIsDetailModalVisible(true);
  };
  const showBusinessEditorModal = (business: IBusinessFull) => {
    dispatch(setCurrentBusiness(business));
    setIsEditorModalVisible(true);
  };
  const showBusinessFilesModal = (business: IBusinessFull) => {
    dispatch(setCurrentBusiness(business));
    setIsFilesModalVisible(true);
  };
  const showBusinessDevicesModal = (business: IBusinessFull) => {
    dispatch(setCurrentBusiness(business));
    setIsDevicesModalVisible(true);
  };

  const showApprovalModal = (business: IBusinessFull) => {
    dispatch(setCurrentBusiness(business));
    setIsApprovalModalVisible(true);
  };
  const showDeclinedModal = (business: IBusinessFull) => {
    dispatch(setCurrentBusiness(business));
    setIsDeclinedModalVisible(true);
  };
  const showSubmitModal = (business: IBusinessFull) => {
    dispatch(setCurrentBusiness(business));
    setIsSubmitModalVisible(true);
  };

  const debouncedNameFilterChange = useCallback(
    debounce((nextValue) => handleNameFilterChange(nextValue), 400),
    [] // will be created only once initially
  );

  const handleDebouncedNameFilterChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    debouncedNameFilterChange(event);
  };
  const handleNameFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value.toLowerCase();
    // dispatch(filterTableByName(name));
    setFilters((prev) => {
      if (!name || name === "") {
        delete prev.name;

        return { ...prev, ...initialPagination };
      } else {
        let updatedFilters = {
          ...prev,
          ...initialPagination,
          name: name.replace(/\s+/g, " ").trim(),
        };
        return { ...updatedFilters };
      }
    });
    setTableParams((prev) => {
      return { ...prev, pagination: { ...initialPagination } };
    });
  };

  const handleTableChange = (
    pagination: TablePaginationConfig
    // filters: Record<string, FilterValue>,
    // sorter: SorterResult<IBucketDeal>
  ) => {
    setTableParams({
      pagination,
      // filters,
      // ...sorter,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      // dispatch(emptyBucketDealsTable());
    }
  };

  const getOnboardingFlagsList = (
    onboardingFlags: IOnboardingFlag[],
    business?: IBusinessFull
  ) => {
    return (
      <List
        // header={<div>Header</div>}
        // footer={<div>Footer</div>}
        bordered
        dataSource={onboardingFlags}
        renderItem={(item) => (
          <>
            <List.Item>
              <Typography.Text mark>[{item.type}]</Typography.Text>{" "}
              {item.message}
            </List.Item>
            {business?.payoutOptions?.schedule !== "daily" &&
              onboardingFlags.find(
                (f) => f.type === "PAYOUT_SCHEDULE_CHANGE"
              ) === null && (
                <List.Item>
                  <Typography.Text mark>
                    ["PAYOUT_SCHEDULE_CHANGE"]
                  </Typography.Text>{" "}
                  {item.message}
                </List.Item>
              )}
          </>
        )}
      />
    );
  };

  return (
    <div className={styles.container} key="divTable">
      <BusinessTableFilters
        setTableParams={setTableParams}
        initialPagination={initialPagination}
        filters={filters}
        setFilters={setFilters}
      />
      <br />

      <div
        className={styles.table_container}
        style={{ backgroundColor: loading ? "transparent" : "inherit" }}
      >
        {businessesInTable.length === 0 && !loading && (
          <div style={{ height: "300px" }} className="center">
            <Empty></Empty>
          </div>
        )}
        {businessesInTable.length > 0 && !loading && (
          <Table
            dataSource={businessesInTable}
            pagination={tableParams.pagination}
            //@ts-ignore
            onChange={handleTableChange}
          >
            <Column
              title="KVK"
              dataIndex="businessRegistrationNumber"
              className={styles.table_column}
              key="businessRegistrationNumber"
              render={(_: any, record: IBusinessFull) => (
                <div className={styles.identifiers_container}>
                  <span key={record._id + "kvk"}>
                    {record.businessRegistrationNumber}
                  </span>
                  {/* <span
                    className={styles.internal_id_text}
                    key={record._id + "_id"}
                  >
                    {record._id}
                  </span> */}
                </div>
              )}
            />
            <Column
              title="Business Name"
              dataIndex="businessName"
              className={styles.table_business_name_column}
              key="businessName"
              render={(_: any, record: IBusinessFull) => (
                <span>
                  <span key={record._id + "businessName"}>
                    {record.businessName}
                  </span>
                  {record.businessName !== record.businessTradeName && (
                    <span style={{ opacity: 0.7 }}>
                      <br />
                      <CopyrightOutlined /> {record.businessTradeName}
                    </span>
                  )}
                </span>
              )}
            />
            <Column
              title="Email"
              key="email"
              className={styles.table_column_email}
              render={(_: any, record: IBusinessFull) => (
                <span key={record._id + "email"}>{record.rootUser?.email}</span>
              )}
            />
            <Column
              title="Name"
              key="name"
              className={styles.table_column}
              render={(_: any, record: IBusinessFull) => (
                <span key={record._id + "name"}>{record.rootUser?.name}</span>
              )}
            />
            <Column
              title="Status"
              dataIndex="status"
              key="status"
              className={styles.table_column}
              render={(status: any, record: IBusinessFull) => {
                const { color, label } = mapStatusLabels(status);
                return (
                  <>
                    <Tag color={color} key={status}>
                      {label}
                    </Tag>
                    {record?.complianceContact?.status ===
                      ComplianceStatus.ReachedOut && (
                      <Tag color={"orange"} key={status}>
                        {ComplianceStatus.ReachedOut}
                      </Tag>
                    )}
                  </>
                );
              }}
            />
            <Column
              title="Partner"
              dataIndex="partnerId"
              key="partnerId"
              className={styles.table_column}
              render={(partner: IPartner, record: IBusinessFull) =>
                partner?.name
              }
            />
            <Column
              title="Flags"
              dataIndex="onboardingFlags"
              key="onboardingFlags"
              className={styles.table_flag_column}
              render={(
                onboardingFlags: IOnboardingFlag[],
                record: IBusinessFull
              ) => {
                let flags =
                  onboardingFlags?.length <= 0
                    ? null
                    : onboardingFlags.filter((f) => !f.isResolved);
                return flags &&
                  flags.length > 0 &&
                  (record.status === "pendingSubmission" ||
                    record.status === "pendingVerification") ? (
                  <Popover
                    content={getOnboardingFlagsList(flags || [])}
                    title="Onboarding Flags"
                  >
                    <Badge count={flags?.length}>
                      <FlagOutlined
                        style={{
                          color: "red",
                          fontSize: "20px",
                          marginRight: "5px",
                          marginLeft: "10px",
                        }}
                      />
                    </Badge>
                  </Popover>
                ) : null;
              }}
            />
            <Column
              title=""
              key="action"
              className={styles.table_column_buttons}
              render={(_: any, record: IBusinessFull) => (
                <Space
                  key={record._id}
                  className={styles.tables_buttons_container}
                >
                  {record.status === "pendingVerification" && (
                    <>
                      <Button
                        type="dashed"
                        onClick={() => {
                          showApprovalModal(record);
                        }}
                        icon={<CheckCircleOutlined />}
                        style={{ color: "green" }}
                      >
                        Approve
                      </Button>
                      <Button
                        type="dashed"
                        danger
                        onClick={() => {
                          showDeclinedModal(record);
                        }}
                        icon={<CloseOutlined />}
                      >
                        Decline
                      </Button>
                    </>
                  )}
                  {record.status === "pendingSubmission" && (
                    <>
                      <Button
                        type="dashed"
                        onClick={() => {
                          showSubmitModal(record);
                          // completeOnboarding(record);
                        }}
                        icon={<CheckCircleOutlined />}
                      >
                        Submit
                      </Button>
                      <Button
                        type="dashed"
                        danger
                        onClick={() => {
                          showDeclinedModal(record);
                        }}
                        icon={<CloseOutlined />}
                      >
                        Decline
                      </Button>
                    </>
                  )}
                </Space>
              )}
            />
            <Column
              title=""
              key="details"
              className={styles.table_column}
              render={(_: any, record: IBusinessFull) => (
                <Space
                  size="middle"
                  key={record._id}
                  className={styles.tables_action_buttons_container}
                >
                  <Tooltip
                    color="rgb(91 76 252)"
                    placement="right"
                    title={"Info"}
                  >
                    <Button
                      shape="circle"
                      type="default"
                      icon={<InfoCircleOutlined />}
                      onClick={() => {
                        showBusinessDetailsModal(record);
                      }}
                    ></Button>
                  </Tooltip>
                  <Tooltip
                    color="rgb(91 76 252)"
                    placement="right"
                    title={"Edit"}
                  >
                    <Button
                      shape="circle"
                      type="default"
                      icon={<EditOutlined />}
                      onClick={async () => {
                        showBusinessEditorModal(record);
                      }}
                    ></Button>
                  </Tooltip>
                  <Tooltip
                    color="rgb(91 76 252)"
                    placement="right"
                    title={"Files"}
                  >
                    <Button
                      type="default"
                      shape="circle"
                      icon={<FileOutlined />}
                      onClick={async () => {
                        showBusinessFilesModal(record);
                      }}
                    ></Button>
                  </Tooltip>
                  <Tooltip
                    color="rgb(91 76 252)"
                    placement="right"
                    title={"Devices"}
                  >
                    <Button
                      type="default"
                      shape="circle"
                      icon={<HddOutlined />}
                      onClick={async () => {
                        showBusinessDevicesModal(record);
                      }}
                    ></Button>
                  </Tooltip>
                </Space>
              )}
            />
          </Table>
        )}
        {loading && <Loader text="Fetching businesses from server"></Loader>}
      </div>

      <BusinessDetailsModal
        visible={isDetailModalVisible}
        setVisible={setIsDetailModalVisible}
      ></BusinessDetailsModal>
      <BusinessEditorModal
        visible={isEditorModalVisible}
        setVisible={setIsEditorModalVisible}
      ></BusinessEditorModal>
      <BusinessFilesModal
        visible={isFilesModalVisible}
        setVisible={setIsFilesModalVisible}
      ></BusinessFilesModal>
      <BusinessDevicesModal
        visible={isDevicesModalVisible}
        setVisible={setIsDevicesModalVisible}
      ></BusinessDevicesModal>

      <SubmitModal
        visible={isSubmitModalVisible}
        setVisible={setIsSubmitModalVisible}
      />
      <ApprovalModal
        visible={isApprovalModalVisible}
        setVisible={setIsApprovalModalVisible}
      />
      <DeclinedModal
        visible={isDeclinedModalVisible}
        setVisible={setIsDeclinedModalVisible}
      />
      <PendingVerificationModal
        visible={isPendingVerificationModalVisible}
        setVisible={setIsPendingVerificationdModalVisible}
      />
      <PendingVerificationModal
        visible={isPendingVerificationModalVisible}
        setVisible={setIsPendingVerificationdModalVisible}
      />
    </div>
  );
}
