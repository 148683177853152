import { Spin } from "antd";
import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Navigate } from "react-router-dom";
import {
  refreshTokenSignIn,
  resetUserState,
  selectIdToken,
  selectIsRefreshingAccess,
  selectUserAccessLevel,
} from "./accountSlice";
import { AccessLevel } from "../../interfaces/accessLevel.enum";

interface IPropsAuthChecker {
  children: React.ReactNode;
  accessLevel: AccessLevel[];
  path: string;
}

export default function AuthChecker({
  children,
  accessLevel,
  path,
}: IPropsAuthChecker): any {
  const userAccessLevel = useAppSelector(selectUserAccessLevel);
  const isRefreshingAccess = useAppSelector(selectIsRefreshingAccess);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const refreshToken = _getLocalRefreshToken();
  // Define the allowed access state using useMemo to avoid unnecessary recalculations
  const isAllowed = useMemo(
    () => _isAllowedAccess(accessLevel, userAccessLevel),
    [accessLevel, userAccessLevel]
  );

  useEffect(() => {
    if (isAllowed) {
      console.log("Access granted.");
    } else if (refreshToken && !isRefreshingAccess) {
      console.log("Attempting to refresh token.");
      dispatch(refreshTokenSignIn({ refreshToken }));
    } else if (!isRefreshingAccess) {
      console.log("No valid access level or refresh token, redirecting.");
      dispatch(resetUserState);
      navigate("/");
    }
    // We intentionally exclude dispatch and navigate from the dependency array to avoid re-running the effect due to changes in those functions.
    // Including them could potentially cause an infinite loop or excessive re-rendering if they are re-created on every render.
    // This is based on the assumption that these functions are stable and don't change on every render.
    // If they are not stable, consider wrapping them in useCallback or ensuring their stability by other means.
  }, [isAllowed, isRefreshingAccess, accessLevel]);

  if (isRefreshingAccess) {
    return <Spin />;
  }

  if (!isAllowed && refreshToken) {
    console.log("Not allowed, redirecting.");
    return <Navigate to={path} replace />;
  } else if (!isAllowed) {
    return <Navigate to="/" replace />;
  }

  return children;
}

function _getLocalRefreshToken() {
  return sessionStorage.getItem("refreshToken");
}

function _isAllowedAccess(
  allowedAccessLevel: AccessLevel[],
  userAccessLevel: AccessLevel[]
) {
  return allowedAccessLevel.some((a) => userAccessLevel?.includes(a));
}
