import { Button, Card, Divider, Select, Statistic, Tag } from "antd";
import styles from "./RevenueOverview.module.css";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  getSalesRepsAnalyticsThunk,
  selectSalesRepsAnalytics,
  selectSalesRepsReportUrl,
  selectLoadingAnalytics,
  MonthBreakdown,
  selectSalesReps,
} from "../../SalesSlice";
import { selectFilters } from "../../../businesses/businessesSlice";
import { IBusinessFullQuery } from "../../../../interfaces/businessFull.interface";
import {
  DownloadOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import { getSalesOverviewReportFromServer } from "../../../reports/reportsApi";
import { getSalesRepReportFromServer } from "../../SalesApi";
import {
  selectIsKlearlySalesSuperAdmin,
  selectUser,
} from "../../../access-control/accountSlice";

interface IRevenueProps {}
const MOCK_ANALYTICS: MonthBreakdown[] = [
  {
    month: "January",
    grossAmount: 1023200,
    bonus: 100,
    netRevenueAfterMarkup: 40,
  },
  {
    month: "February",
    grossAmount: 2042300,
    bonus: 200,
    netRevenueAfterMarkup: 30,
  },
  {
    month: "March",
    grossAmount: 323000,
    bonus: 300,
    netRevenueAfterMarkup: 20,
  },
];
export default function RevenueOverview() {
  const isLoading = useAppSelector(selectLoadingAnalytics);
  const [loadingReport, setLoadingReport] = useState(false);
  const analytics = useAppSelector(selectSalesRepsAnalytics);
  const reportUrl = useAppSelector(selectSalesRepsReportUrl);
  const dispatch = useAppDispatch();
  const salesReps = useAppSelector(selectSalesReps);
  const isSalesSuperAdmin = useAppSelector(selectIsKlearlySalesSuperAdmin);
  const user = useAppSelector(selectUser);

  const filteredSalesReps = salesReps.filter((rep) =>
    isSalesSuperAdmin ? true : rep?.email === user?.email
  );

  const [currentSalesRep, setCurrentSalesRep] = useState(
    !isSalesSuperAdmin ? filteredSalesReps?.[0] : undefined
  );

  const getSalesRepsAnalytics = async (params: {
    salesRepresentative: string;
  }) => {
    await dispatch(
      getSalesRepsAnalyticsThunk({
        salesRepresentative: params.salesRepresentative,
      })
    );
  };

  useEffect(() => {
    if (!currentSalesRep) return;
    getSalesRepsAnalytics({ salesRepresentative: currentSalesRep.value });
  }, [JSON.stringify(user.email)]);

  const handleDownloadReport = async () => {
    try {
      if (!currentSalesRep) return;
      setLoadingReport(true);
      let { report } = await getSalesRepReportFromServer({
        salesRepresentative: currentSalesRep.value,
        formatType: "XLSX",
      });

      window.open(report.url, "_blank");
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoadingReport(false);
    }
  };

  const handleSalesRepresentativeFilterChange = async (value: string) => {
    setCurrentSalesRep(salesReps.find((rep) => rep.value === value));
    await getSalesRepsAnalytics({ salesRepresentative: value });
  };

  return (
    <>
      <div key="divTable">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "30px",
          }}
        >
          <Select
            defaultValue={currentSalesRep?.value}
            style={{ width: 180 }}
            onChange={(value: string) => {
              handleSalesRepresentativeFilterChange(value);
            }}
            options={filteredSalesReps}
          />
          <Button
            type="primary"
            onClick={handleDownloadReport}
            loading={loadingReport}
            icon={<DownloadOutlined style={{ fontSize: "20px" }} />}
          >
            Commission Report
          </Button>
        </div>
        {!currentSalesRep?.value ? (
          <h4>Please select a sales representative.</h4>
        ) : (
          <>
            {isLoading ? (
              <h4>Loading...</h4>
            ) : (
              <>
                {analytics && analytics.length > 0 && (
                  <div className={styles.container}>
                    {analytics.map((m, i) => {
                      let previousMonth = i > 0 ? analytics[i - 1] : null;
                      let growth = previousMonth
                        ? ((m.grossAmount - previousMonth.grossAmount) /
                            previousMonth.grossAmount) *
                          100
                        : 0;

                      let growthColor =
                        i === analytics.length - 1
                          ? //a color for the current monthto show that it still not finalized but not black
                            "#ff7f00"
                          : growth > 0
                          ? "#3f8600"
                          : growth < 0
                          ? "#cf1322"
                          : "#000000";

                      let growthIcon =
                        i === analytics.length - 1 ? null : growth > 0 ? (
                          <ArrowUpOutlined />
                        ) : growth < 0 ? (
                          <ArrowDownOutlined />
                        ) : null;
                      return (
                        <Card
                          className={styles.card}
                          style={{
                            border:
                              i === analytics.length - 1
                                ? "black solid 1px"
                                : "none",
                          }}
                        >
                          <h4>
                            {m.month}{" "}
                            {i === analytics.length - 1 ? (
                              <Tag>Current</Tag>
                            ) : (
                              ""
                            )}{" "}
                          </h4>
                          <Divider />
                          <Statistic
                            valueStyle={{
                              fontSize: "20px",
                              color: growthColor,
                            }}
                            title="Gross Volume"
                            value={m.grossAmount}
                            precision={2}
                            loading={isLoading}
                            prefix={"€"}
                            suffix={growthIcon}
                            // suffix="%"
                          />

                          <Statistic
                            title="Sales Bonus"
                            valueStyle={{
                              fontSize: "20px",
                              // color: growthColor,
                            }}
                            value={m.bonus || 0}
                            precision={2}
                            loading={isLoading}
                            prefix={"€"}
                            // suffix={growthIcon}
                            // valueStyle={{ color: "#3f8600" }}

                            // suffix="%"
                          />
                          <Statistic
                            title="Active Merchants"
                            valueStyle={{
                              fontSize: "20px",
                              // color: growthColor,
                            }}
                            value={m.totalActiveMerchants || 0}
                            loading={isLoading}
                            prefix={"#"}
                            // suffix={growthIcon}
                            // valueStyle={{ color: "#3f8600" }}

                            // suffix="%"
                          />
                        </Card>
                      );
                    })}
                    {/* {
                      <Button
                        type="text"
                        onClick={handleDownloadReport}
                        loading={loadingReport}
                        icon={<DownloadOutlined style={{ fontSize: "20px" }} />}
                        style={{ height: "100px" }}
                      >
                        <br />
                        <span>
                          Download Commission
                          <br />
                          Report
                        </span>
                      </Button>
                    } */}
                  </div>
                )}

                {analytics && analytics.length === 0 && (
                  <h4>
                    No volume seen in the past last couple of months for
                    merchants belonging to {currentSalesRep?.label}.
                  </h4>
                )}
              </>
            )}
          </>
        )}
      </div>

      <br />
      <br />
    </>
  );
}
