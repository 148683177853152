import {
  Badge,
  Button,
  Col,
  DatePicker,
  Descriptions,
  Form,
  List,
  Modal,
  Select,
  Tag,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { IBusinessFull } from "../../../../../interfaces/businessFull.interface";
import {
  UserOutlined,
  FileOutlined,
  DownloadOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import Loader from "../../../../common/loader/Loader";
import {
  getBusinessFilesThunk,
  getBusinessUsersThunk,
  selectCurrentBusiness,
  selectCurrentBusinessFiles,
  selectCurrentBusinessUsers,
  selectIsLoadingBusinessUsers,
} from "../../../businessesSlice";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { downloadFile } from "../../../../../utils/downloadFile";
import { extractDeviceLogs } from "../../../businessesApi";
import { LocalDateAsUTC } from "../../../../../utils/LocalDateAsUTC";
import { error } from "console";
const { Paragraph } = Typography;
interface IPropsExtractLogsFormModel {
  visible: boolean;
  setVisible: any;
  deviceId: string;
}

const extractLogsFormFieldLayout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 12 },
};
export default function ExtractLogsFormModal({
  deviceId,
  visible,
  setVisible,
}: IPropsExtractLogsFormModel) {
  const business = useAppSelector(selectCurrentBusiness);
  const [url, setUrl] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [error, setError] = useState("");
  const handleSubmit = async (
    values: { fileFormat: "zip" | "txt" } & { dateRange?: moment.Moment[] }
  ) => {
    try {
      setError("");
      setUrl(undefined);
      setLoading(true);

      if (!values.dateRange) return;

      const [start, end] = values.dateRange;
      start.set({ milliseconds: 0, seconds: 0 });
      end.set({ milliseconds: 999, seconds: 59 });
      let startDate = LocalDateAsUTC.getISOFromDate(start.toDate());
      let endDate = LocalDateAsUTC.getISOFromDate(end.toDate());
      const results = await extractDeviceLogs({
        deviceId,
        businessId: business._id,
        start: startDate,
        end: endDate,
        fileFormat: values?.fileFormat || "zip",
      });

      setUrl(results?.url || "");
    } catch (error) {
      console.log("error", error);
      setError(JSON.stringify(error));
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setVisible(false);
    resetState();
  };
  const resetState = () => {
    setUrl(undefined);
    setLoading(false);
    setError("");
  };

  return (
    <Modal
      visible={visible}
      title={`${business?.businessName} - ${deviceId?.slice(
        -6
      )} -  Extract Logs`}
      onCancel={handleCancel}
      footer={null}
      width={500}
    >
      <Form onFinish={handleSubmit} form={form}>
        <Form.Item
          name="dateRange"
          label={"Date Range"}
          labelCol={{ ...extractLogsFormFieldLayout.labelCol }}
          labelAlign="left"
        >
          <DatePicker.RangePicker
            showTime
            format="DD-MM-YYYY HH:mm"
            // disabledDate={disabledDate}
            //@ts-ignore
            // disabledTime={disabledTime}

            showSecond={false}
            showNow={false}
          />
        </Form.Item>
        <Form.Item
          name="fileFormat"
          label={"File Format"}
          initialValue={"zip"}
          labelCol={{ ...extractLogsFormFieldLayout.labelCol }}
          labelAlign="left"
          hidden
        >
          <Select
            options={[
              { value: "zip", label: ".zip" },
              { value: "txt", label: ".txt" },
            ]}
          ></Select>
        </Form.Item>

        <Form.Item>
          <Button
            style={{ float: "right" }}
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            Extract logs
          </Button>
        </Form.Item>
      </Form>
      {/* {isLoadingBusinessUsers && <Loader text="Fetching Business Users" />} */}

      {url && url?.length > 0 && (
        <div>
          <CheckCircleFilled style={{ margin: "0 10px", color: "green" }} />
          <span>
            Uploading might take a few minutes. To view click
            <a style={{ marginLeft: "5px" }} href={url} target="_blank">
              here
            </a>
            .
          </span>
        </div>
      )}

      {error && error?.length && <div>{error}</div>}
    </Modal>
  );
}
