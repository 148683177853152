import {
  Avatar,
  Badge,
  Button,
  DatePicker,
  Form,
  Modal,
  Segmented,
  Select,
  Switch,
} from "antd";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../app/hooks";
import { DownloadOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import styles from "./CreateReportForm.module.css";
import { IReportRequest } from "../../../interfaces/requestReport.interface";
import { LocalDateAsUTC } from "../../../utils/LocalDateAsUTC";
import { SegmentedValue } from "antd/lib/segmented";
import { selectUser } from "../../access-control/accountSlice";
import moment from "moment";
import {
  IReport,
  IReportResponse,
} from "../../../interfaces/reports.interface";
import { TimeFormat } from "../../../interfaces/timeFormat.enum";

interface IPropsCreateReportForm {
  onSubmit: (
    reportRequest: IReportRequest,
    reportType?: string
  ) => Promise<IReportResponse>;
  reportOptions: IReport[];

  defaultCustomTimeOffset?: number;
  allowEmailSending?: boolean;
}

let timeFormatOptions: { value: TimeFormat; label: string }[] = [
  {
    value: "today",
    label: "Today",
  },
  {
    value: "yesterday",
    label: "Yesterday",
  },
  {
    value: "thisWeek",
    label: "This Week",
  },
  {
    value: "lastWeek",
    label: "Last Week",
  },
  {
    value: "thisMonth",
    label: "This Month",
  },
  {
    value: "lastMonth",
    label: "Last Month",
  },
  {
    value: "custom",
    label: "Custom",
  },
];
const reportFormFieldLayout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 12 },
};
export default function CreateReportForm({
  onSubmit,
  reportOptions,
  defaultCustomTimeOffset = 0,
  allowEmailSending = true,
}: IPropsCreateReportForm) {
  const [loading, setLoading] = useState(false);
  const user = useAppSelector(selectUser);
  const [report, setReport] = useState<IReport>(reportOptions[0]);
  const [form] = Form.useForm();
  const [timeFormat, setTimeFormat] = useState<TimeFormat>();
  const [url, setUrl] = useState<string | undefined>();
  const [noDataFound, setNoDataFound] = useState(false);
  const [isSentToEmail, setIsSentToEmail] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      dateRange: [
        moment().set({
          date: moment().date() - 1,
          minute: 0,
          hour: defaultCustomTimeOffset || 0,
        }),
        moment().set({
          minute: 59,
          hour: defaultCustomTimeOffset - 1 || 23,
        }),
      ],
    });
  }, [report.type]);

  // const handleReset = () => {
  //   setLoading(false);
  //   setUrl(undefined);
  //   // setReport({ ...ReportOptions[0] });
  //   setNoDataFound(false);
  //   setIsSentToEmail(false);
  //   form.resetFields();
  // };

  const handleSubmit = async (
    values: Partial<IReportRequest> & { dateRange?: moment.Moment[] }
  ) => {
    setUrl(undefined);
    setNoDataFound(false);
    setLoading(true);
    setIsSentToEmail(false);

    let reportRequest: IReportRequest = {
      formatType: values.formatType!,
      timeFormat: values.timeFormat,
      startDate: values.startDate || "",
      endDate: values.endDate || "",
    };

    if (values.dateRange && values.timeFormat === "custom") {
      const [start, end] = values.dateRange;
      start.set({ milliseconds: 0, seconds: 0 });
      end.set({ milliseconds: 999, seconds: 59 });
      reportRequest.startDate = LocalDateAsUTC.getISOFromDate(start.toDate());
      reportRequest.endDate = LocalDateAsUTC.getISOFromDate(end.toDate());
    }

    reportRequest.receiveViaEmail = values.receiveViaEmail;

    if (values.receiveViaEmail) {
      reportRequest.receiveViaEmail = true;
      reportRequest.email = user.email;
    }

    let results = {} as {
      transactionsReport?: string | null;
      report?: string | null;
      isEmailed: boolean;
      isEmpty: boolean;
    };

    results = await onSubmit(reportRequest, report?.type);
    if (results?.isEmpty) setNoDataFound(true);

    if (results?.isEmailed) setIsSentToEmail(true);
    setUrl(results?.transactionsReport || results?.report || "");
    setLoading(false);
  };

  const handleTimeFormatChange = (value: TimeFormat) => {
    setTimeFormat(value);
  };

  const handleReportChange = (value: SegmentedValue) => {
    let report = reportOptions.find((r) => value === r.type);
    if (!report) return;
    setReport({ ...report });
    form.setFieldsValue({ formatType: report.formatTypes[0] });
  };

  const getReportCard = (report: IReport) => (
    <div style={{ padding: 4 }}>
      <Avatar
        style={{
          width: "50px",
          height: "50px",
          marginTop: "10px",
          backgroundColor: "#e2bee8",
        }}
        size={"large"}
        src={report.previewUrl || ""}
      />
      <div className={styles.report_card}>
        <span>{report.label}</span>
      </div>
    </div>
  );

  return (
    <div>
      <div className={styles.report_cards_container}>
        <Segmented
          onChange={handleReportChange}
          value={report?.type}
          options={reportOptions.map((r) => ({
            label: r.isBeta ? (
              <Badge.Ribbon text="Beta" color="purple">
                {getReportCard(r)}
              </Badge.Ribbon>
            ) : (
              <div style={{ padding: 4 }}>
                <Avatar
                  style={{ width: "50px", height: "auto", paddingTop: "10px" }}
                  size={"large"}
                  src={r.previewUrl}
                />
                <div className={styles.report_card}>
                  <span>{r.label}</span>
                </div>
              </div>
            ),
            value: r.type,
            key: r.type,
          }))}
        />
      </div>
      <br></br>
      {report.warning && (
        <div className={styles.report_warning}>{report.warning}</div>
      )}
      <div className={styles.report_description}>{report.description}</div>

      <br></br>
      <br></br>
      {report?.type ? (
        <Form onFinish={handleSubmit} form={form}>
          <Form.Item
            name="formatType"
            initialValue={report.formatTypes[0]}
            label="Format Type"
            labelCol={{ ...reportFormFieldLayout.labelCol }}
            labelAlign="left"
          >
            <Select
              style={{ width: "200px" }}
              options={report.formatTypes.map((ft) => ({
                value: ft,
                label: ft,
                key: `${report.type}+${ft}`,
              }))}
            ></Select>
          </Form.Item>
          <Form.Item
            initialValue={"yesterday"}
            name="timeFormat"
            label="Time Format"
            labelCol={{ ...reportFormFieldLayout.labelCol }}
            labelAlign="left"
          >
            <Select
              style={{ width: "200px" }}
              onChange={handleTimeFormatChange}
              options={timeFormatOptions.map((t) => ({
                value: t.value,
                label: t.label,
                key: `${report.type}+${t.label}`,
              }))}
            ></Select>
          </Form.Item>
          {timeFormat === "custom" && (
            <Form.Item
              name="dateRange"
              label={
                report.type === "payoutReport"
                  ? "Payout Dates"
                  : "Purchase Dates"
              }
              labelCol={{ ...reportFormFieldLayout.labelCol }}
              labelAlign="left"
            >
              <DatePicker.RangePicker
                showTime
                format="DD-MM-YYYY HH:mm"
                // disabledDate={disabledDate}
                //@ts-ignore
                // disabledTime={disabledTime}

                showSecond={false}
                showNow={false}
              />
            </Form.Item>
          )}

          {!allowEmailSending && (
            <Form.Item
              name="receiveViaEmail"
              label={"Receive Via Email"}
              labelCol={{ ...reportFormFieldLayout.labelCol }}
              labelAlign="left"
            >
              <Switch
                defaultChecked={false}
                checkedChildren="Yes"
                unCheckedChildren="No"
              />
            </Form.Item>
          )}

          <Form.Item>
            <Button
              style={{ float: "right" }}
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              Create Report
            </Button>
          </Form.Item>
        </Form>
      ) : (
        <>Please pick a report type.</>
      )}

      <div className={styles.results_container}>
        {/* {loading && <Spin></Spin>} */}
        {url && (
          <div>
            <Button
              type="link"
              icon={<DownloadOutlined />}
              href={url}
              target="_blank"
              style={{ width: "240px", fontSize: "14px" }}
            >
              Click here to view report
            </Button>
          </div>
        )}

        {isSentToEmail && (
          <div>
            Report was sent to the following email address{" "}
            <a href={user.email}>{user.email}</a>
          </div>
        )}
        {noDataFound && (
          <div>
            <ExclamationCircleOutlined
              style={{ margin: "0 10px", color: "#f5b642" }}
            />
            No data was found in the selected time period. Try selecting a
            different time period.
          </div>
        )}
      </div>
    </div>
  );
}
