export function formatDate(date: Date, withTime: boolean = true) {
  const year = date.getUTCFullYear();
  const month = ("0" + (date.getUTCMonth() + 1)).slice(-2);
  const day = ("0" + date.getUTCDate()).slice(-2);
  const hours = ("0" + date.getUTCHours()).slice(-2);
  const minutes = ("0" + date.getUTCMinutes()).slice(-2);
  const time = `${hours}:${minutes}`;
  return withTime
    ? `${day}-${month}-${year}    ${time}`
    : `${day}-${month}-${year}`;
}
export function formatLocalDate(date: Date, withTime: boolean = true) {
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);
  const hours = ("0" + date.getHours()).slice(-2);
  const minutes = ("0" + date.getMinutes()).slice(-2);
  const time = `${hours}:${minutes}`;
  return withTime
    ? `${day}-${month}-${year}    ${time}`
    : `${day}-${month}-${year}`;
}
