import { Form, Input, InputNumber } from "antd";
import Select, { BaseOptionType } from "antd/lib/select";
import { useAppSelector } from "../../../../../app/hooks";
import { selectIsKlearlySuperAdmin } from "../../../../access-control/accountSlice";

export default function CompanyProfileEditor() {
  const isKlearlySuperAdmin = useAppSelector(selectIsKlearlySuperAdmin);
  const bankFieldLayout = {
    labelCol: { span: 12 },
    wrapperCol: { span: 12 },
  };
  const getBusinessTypeOptions = () => {
    return [
      { label: "Sole Trader", value: "soleTrader" },
      { label: "Public Limited Company", value: "publicLimitedCompany" },
      { label: "Private Limited Company", value: "privateLimitedCompany" },
      { label: "Partnership", value: "partnership" },
      { label: "Other", value: "other" },
    ] as BaseOptionType[];
  };
  return (
    <>
      <Form.Item
        wrapperCol={{ ...bankFieldLayout.wrapperCol }}
        labelCol={{ ...bankFieldLayout.labelCol }}
        label="Business Name"
        name="businessName"
        labelAlign="left"
      >
        <Input></Input>
      </Form.Item>
      <Form.Item
        wrapperCol={{ ...bankFieldLayout.wrapperCol }}
        labelCol={{ ...bankFieldLayout.labelCol }}
        label="Business Trade Name"
        name="businessTradeName"
        labelAlign="left"
      >
        <Input></Input>
      </Form.Item>

      <Form.Item
        wrapperCol={{ ...bankFieldLayout.wrapperCol }}
        labelCol={{ ...bankFieldLayout.labelCol }}
        label="Business Type"
        name="businessType"
        labelAlign="left"
      >
        <Select style={{ minWidth: 190 }} options={getBusinessTypeOptions()} />
      </Form.Item>
      <Form.Item
        wrapperCol={{ ...bankFieldLayout.wrapperCol }}
        labelCol={{ ...bankFieldLayout.labelCol }}
        label="VAT"
        name="vat"
        labelAlign="left"
      >
        <InputNumber addonAfter="%" min={0} />
      </Form.Item>

      <Form.Item
        wrapperCol={{ ...bankFieldLayout.wrapperCol }}
        labelCol={{ ...bankFieldLayout.labelCol }}
        rules={[
          {
            required: true,
            message: "Missing Business Registration #",
          },
        ]}
        style={{
          padding: "20px 10px",
          border: "solid rgba(227, 227, 227, 0.49) 1px",
        }}
        label="Business Registration #"
        name="businessRegistrationNumber"
        labelAlign="left"
        help="Only super admins can edit this field. Please contact support if you need to change this."
      >
        <Input disabled={!isKlearlySuperAdmin}></Input>
      </Form.Item>
    </>
  );
}
