// import { Form } from "antd";
// import BucketGroupPriceFormItems from "../../../common/bucket-group-price-form-items/BucketGroupPriceFormItems";

import { Button, Form, Modal } from "antd";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { createPartnerDealsThunk } from "../../partnerDealsSlice";
import styles from "./CreatePartnerDealsForm.module.css";
import { IPurchPriceProfile } from "../../../../interfaces/partner.interface";
import { DEFAULT_PURCH_PRICE_PROFILE } from "../../utils/defaultPurchPriceProfile";
import {
  IMerchantDetails,
  IPartnerDeal,
  PartnerBucketGroups,
} from "../../../../interfaces/partnerDeal.interface";
import MerchantDetailsForm from "../merchant-details-form/merchantDetailsForm";
import {
  selectCurrentPartner,
  setCurrentPartner,
} from "../../../partners/partnersSlice";
import BucketGroupsForm from "../create-edit-bucket-groups-form/CreateEditBucketGroupsForm";
import { selectIsKlearlyAdmin } from "../../../access-control/accountSlice";
import PartnerSelector from "../partner-selector/PartnerSelector";
import CreateEditBucketGroupsForm from "../create-edit-bucket-groups-form/CreateEditBucketGroupsForm";

const partnerDealsFormFieldLayout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 18 },
};
interface IPropsCreatePartnerDealsFormModal {
  visible: boolean;
  setVisible: any;
}

export default function CreatePartnerDealsForm({
  visible,
  setVisible,
}: IPropsCreatePartnerDealsFormModal) {
  const currentPartner = useAppSelector(selectCurrentPartner);
  const [form] = Form.useForm();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const [partnerDeal, setPartnerDeal] = useState<Partial<IPartnerDeal>>({});
  const [purchPriceProfile, setPurchPriceProfile] = useState<
    Partial<IPurchPriceProfile>
  >({});
  const isKlearlyAdmin = useAppSelector(selectIsKlearlyAdmin);

  const [step, setStep] = useState(1);
  //   const handleSubmit = async (values: Partial<IPartner>) => {
  //     setLoading(true);

  //     let partner: Partial<IPartner> = values;
  //     await dispatch(createPartnerThunk(partner));
  //     setLoading(false);
  //   };

  const handleReset = () => {
    form.resetFields();
    setPartnerDeal({});
    setError("");
    setStep(1);
  };

  const handleCancel = () => {
    handleReset();
    setVisible(false);
  };

  const onSubmitMerchantDetails = (merchantDetails: IMerchantDetails) => {
    setPartnerDeal((prev) => ({ ...prev, ...merchantDetails }));
    setStep(2);
  };

  const onSubmitBucketGroups = (params: {
    purchPriceProfileId: string;
    bucketGroups: PartnerBucketGroups;
  }) => {
    setPartnerDeal((prev) => ({ ...prev, ...params }));
    setStep(3);
  };
  const onCreatePartnerDeal = async () => {
    setLoading(true);
    if (!currentPartner) {
      return;
    }

    partnerDeal.partnerId = currentPartner._id;

    const result = await dispatch(createPartnerDealsThunk([partnerDeal]));
    setLoading(false);
    //@ts-ignore
    let isSuccessful = result?.payload && !result?.error;
    if (isSuccessful) handleCancel();
    //@ts-ignore
    else setError(result?.error?.message);
  };

  const goBack = () => {
    setStep((prev) => {
      if (prev <= 1) return prev;
      return prev - 1;
    });
    setError("");
  };

  const onSelectPartner = (partnerId: string) => {
    dispatch(setCurrentPartner(partnerId));
  };

  const getAllowedPurchProfile = () => {
    let allowedPurchProfiles = currentPartner?.purchPriceProfiles?.filter(
      (pp) => {
        if (!pp?.whitelist || pp?.whitelist?.length === 0) return true;
        else {
          return pp.whitelist.includes(
            partnerDeal?.businessRegistrationNumber || ""
          );
        }
      }
    );
    return allowedPurchProfiles || [DEFAULT_PURCH_PRICE_PROFILE];
  };
  return (
    <div>
      <Modal
        visible={visible}
        title={"Create New Merchant Deal"}
        onCancel={handleCancel}
        footer={null}
        width={650}
      >
        <div>
          {step !== 1 && (
            <Button type="default" onClick={goBack}>
              Back
            </Button>
          )}
          <br />
          <br />
          <div className={styles.form_container}>
            {step === 1 && (
              <>
                {isKlearlyAdmin && (
                  <Form.Item
                    labelCol={{ ...partnerDealsFormFieldLayout.labelCol }}
                    labelAlign="left"
                    label="Partner"
                  >
                    <PartnerSelector
                      onChange={onSelectPartner}
                      allowClear={false}
                    ></PartnerSelector>
                  </Form.Item>
                )}
                <MerchantDetailsForm
                  merchantDetails={{
                    businessName: partnerDeal?.businessName || "",
                    businessRegistrationNumber:
                      partnerDeal?.businessRegistrationNumber || "",
                  }}
                  onSubmit={onSubmitMerchantDetails}
                ></MerchantDetailsForm>
              </>
            )}
            {step === 2 && (
              <>
                <CreateEditBucketGroupsForm
                  purchPriceProfiles={getAllowedPurchProfile()}
                  onSubmit={onSubmitBucketGroups}
                  bucketGroupsToEdit={currentPartner?.defaultPricing || null}
                ></CreateEditBucketGroupsForm>
              </>
            )}
            {step === 3 && (
              <div>
                <Button
                  type="primary"
                  onClick={onCreatePartnerDeal}
                  loading={loading}
                >
                  Create Partner Deal
                </Button>
              </div>
            )}

            {error && <span>{error}</span>}
          </div>
        </div>
      </Modal>
    </div>
  );
}

// export default function CreatePartnerDealsForm() {
//   return (
//     <div>
//       <Form>
//         <BucketGroupPriceFormItems
//           formItemName="domesticDebitCards"
//           label="Domestic Debit Cards"
//         />
//         <BucketGroupPriceFormItems
//           formItemName="european"
//           label="E.E.A Cards"
//         />
//         <BucketGroupPriceFormItems
//           formItemName="internationalAndCommercial"
//           label="Non E.E.A & Corporate Cards"
//         />
//       </Form>
//     </div>
//   );
// }
