import {
  Table,
  Tag,
  Card,
  Popconfirm,
  TablePaginationConfig,
  Descriptions,
} from "antd";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  UpCircleOutlined,
  DownCircleOutlined,
  DownOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import styles from "./NotificationsTable.module.css";
import {
  cancelScheduledPushThunk,
  getPushesScheduledThunk,
  selectPushesScheduled,
} from "../../notificationsSlice";
import dayjs from "dayjs";
import Loader from "../../../common/loader/Loader";

import { IScheduledPush } from "../../../../interfaces/scheduledPush.interface";
import { useNavigate } from "react-router-dom";
import { TableParams } from "../../../../interfaces/tableParams.interface";
import Column from "antd/lib/table/Column";
import TargetAttributeTagsPreview from "../Target-Attribute-Tags-Preview/TargetAttributeTagsPreview";

const range = (start: number, end: number) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};

export default function NotificationsTable() {
  const [loading, setLoading] = useState(false);
  const pushesScheduled = useAppSelector(selectPushesScheduled)?.map((p, i) => {
    //@ts-ignore
    return { ...p, key: p._id };
  });

  useEffect(() => {
    console.log("I should update the table");
  }, [JSON.stringify(pushesScheduled)]);

  const initialPagination = { current: 1, pageSize: 10 };
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: { ...initialPagination },
  });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  useEffect(() => {
    _getPushesScheduled();
  }, []);

  async function _getPushesScheduled() {
    setLoading(true);
    await dispatch(getPushesScheduledThunk());
    setLoading(false);
  }

  function getStatusColor(
    status: "pending" | "succeeded" | "canceled" | "failed"
  ) {
    return status === "pending"
      ? "blue"
      : status === "succeeded"
      ? "green"
      : status === "canceled"
      ? "yellow"
      : "red";
  }

  async function handleCancel(params: {
    executionArn: string;
    scheduledPushId: string;
  }) {
    setLoading(true);
    await dispatch(cancelScheduledPushThunk(params));
    setLoading(false);
  }

  const handleTableChange = (pagination: TablePaginationConfig) => {
    setTableParams({
      pagination,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      // dispatch(emptyBucketDealsTable());
    }
  };
  return (
    <div className={styles.container}>
      {!loading && pushesScheduled?.length > 0 && (
        <Table
          dataSource={pushesScheduled}
          pagination={tableParams.pagination}
          loading={loading}
          expandable={{
            // expandIcon: () => <ArrowDownOutlined></ArrowDownOutlined>,
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <UpOutlined
                  style={{ fontSize: "15", opacity: 0.6 }}
                  onClick={(e) => onExpand(record, e)}
                />
              ) : (
                <DownOutlined
                  style={{ fontSize: "15", opacity: 0.6 }}
                  onClick={(e) => onExpand(record, e)}
                />
              ),

            expandedRowRender: (record) => (
              <p style={{ margin: 0 }}>
                {
                  <Card>
                    <div>
                      <Descriptions
                        title={""}
                        labelStyle={{ opacity: 0.6, width: "130px" }}
                        contentStyle={{ width: "280px" }}
                        column={1}
                      >
                        {record.notification?.type === "EMAIL" &&
                          record.notification?.templateParams && (
                            <>
                              {Object.entries(
                                record.notification?.templateParams || {}
                              ).map(([key, value]) => (
                                <Descriptions.Item label={key}>
                                  {value}
                                </Descriptions.Item>
                              ))}
                            </>
                          )}
                        {record.notification?.type !== "EMAIL" &&
                          record.notification?.title && (
                            <>
                              <Descriptions.Item label={"Title"}>
                                {record.notification?.title}
                              </Descriptions.Item>
                              <Descriptions.Item label={"Description"}>
                                {record.notification?.description}
                              </Descriptions.Item>
                            </>
                          )}
                      </Descriptions>
                    </div>
                  </Card>
                }
              </p>
            ),
          }}
          //@ts-ignore
          onChange={handleTableChange}
        >
          <Column
            title="Timestamp"
            dataIndex="timestamp"
            className={styles.table_business_name_column}
            key="timestamp"
            render={(_: any, record: IScheduledPush) => (
              <span>
                <span style={{ marginRight: "10px" }}>
                  {dayjs(record.timestamp).format("DD/MM/YYYY")}{" "}
                </span>
                <br />
                <span>{dayjs(record.timestamp).format("HH:mm")}</span>{" "}
              </span>
            )}
          />
          <Column
            title="Type"
            key="type"
            dataIndex="type"
            className={styles.table_column}
            render={(_: any, record: IScheduledPush) => (
              <span key={record._id + "type"}>{record.notification.type}</span>
            )}
          />
          <Column
            title="Sent To"
            dataIndex="targetAttributes"
            key="targetAttributes"
            className={styles.table_column}
            render={(_: any, record: IScheduledPush) => (
              <TargetAttributeTagsPreview
                scheduledPush={record}
                key={record._id + "targetAttributes"}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  maxWidth: "300px",
                }}
              ></TargetAttributeTagsPreview>
            )}
          />
          <Column
            title="Target Count"
            dataIndex="targetCount"
            key="targetCount"
            className={styles.table_column}
            render={(_: any, record: IScheduledPush) => (
              <span key={record._id + "targetCount"}>
                {record?.targetCount || "N/A"}
              </span>
            )}
          />

          <Column
            title="Status"
            dataIndex="status"
            className={styles.table_column}
            key="status"
            render={(_: any, record: IScheduledPush) =>
              record.status === "pending" ? (
                <>
                  <Tag color={getStatusColor(record.status)}>
                    {record.status}
                  </Tag>

                  <Popconfirm
                    title="Are you sure you want to cancel the push?"
                    cancelText="Back"
                    onConfirm={() => {
                      handleCancel({
                        executionArn: record.executionArn,
                        scheduledPushId: record._id,
                      });
                    }}
                  >
                    <a key="list-loadmore-edit">cancel</a>
                  </Popconfirm>
                </>
              ) : (
                <Tag color={getStatusColor(record.status)}>{record.status}</Tag>
              )
            }
          />
        </Table>
      )}

      {loading && <Loader text="Getting Pushes Scheduled"></Loader>}
    </div>
  );
}
