// This date class represents the local time as the UTC or Zulu time.
// This will make date manipulations are done independently of the local time of the OS.

//Examples of possible inputs:
//2023-05-10T12:00:00+07:00
//2023-05-10T12:00:00-07:00
//2023-05-10T12:00:00Z
//2023-05-10T12:00:00

const WEEK_IN_MS = 604800000;
const MONTH_IN_MS = 2419200000;
const DAY_IN_MS = 86400000;
const MINUTE_IN_MS = 60000;

export class LocalDateAsUTC {
  private d: Date = new Date();

  private timeZoneOffset = 1; // Default local time will be according to The Netherlands

  //Gets the local date represented as an iso string with a Z or 00:00+ suffix. If not, will attach the Z to the iso string.
  //Get no ISOString, will get the local time in the Netherlands represented as the UTC date.
  constructor(ISOString?: string, timeZoneOffset?: number) {
    // this.timeZoneOffset = timeZoneOffset;
    if (ISOString) {
      this._attachZToISO(ISOString);
      if (timeZoneOffset) {
        this.addMinutes(timeZoneOffset * 60);
        this.timeZoneOffset = timeZoneOffset || 1;
      }
    } else {
      this.d = new Date();
      this.timeZoneOffset = timeZoneOffset || 1;
      this.d.setUTCHours(this.d.getUTCHours() + this.timeZoneOffset);
    }
  }

  get rawDate() {
    return this.d;
  }
  get date() {
    return this.d.getUTCDate();
  }
  get day() {
    return this.d.getUTCDay();
  }
  get year() {
    return this.d.getUTCFullYear();
  }
  get month() {
    return this.d.getUTCMonth();
  }
  get hours() {
    return this.d.getUTCHours();
  }
  get minutes() {
    return this.d.getUTCMinutes();
  }

  get ISO() {
    return this.d.toISOString();
  }

  get YMD() {
    return this.ISO.split("T")[0];
  }
  get hourAndMinute() {
    return (
      this.hours.toString().padStart(2, "0") +
      ":" +
      this.minutes.toString().padStart(2, "0")
    );
  }

  get actualZuluISO() {
    //returns the actual UTC time in Greenwhich meridian, UK
    let zuluDate = new Date(
      new Date(this.ISO).setUTCHours(this.hours - this.timeZoneOffset)
    );
    return zuluDate.toISOString();
  }

  get time() {
    return this.d.getTime();
  }

  monthShortName(language?: "EN" | "NL") {
    let month = this.d.toLocaleString("default", { month: "short" });
    if (language === "NL") {
      const dutchMonthNames = {
        Jan: "Jan",
        Feb: "Feb",
        Mar: "Mrt",
        Apr: "Apr",
        May: "Mei",
        Jun: "Jun",
        Jul: "Jul",
        Aug: "Aug",
        Sep: "Sep",
        Oct: "Okt",
        Nov: "Nov",
        Dec: "Dec",
      };

      month = dutchMonthNames[month as keyof typeof dutchMonthNames];
    }
    return month;
  }

  formatDate(
    separator: "." | "/" | "-",
    format: { m?: "MM" | "mm"; d?: "DD" | "dd"; y?: "YYYY" | "YY" },
    order: "YMD" | "DMY" | "MDY" = "YMD",
    includeTime: boolean = false
  ) {
    const { m, d, y } = format;

    let fDate =
      d === "DD"
        ? ("0" + this.date).slice(-2)
        : d === "dd"
        ? String(this.date)
        : "";
    let fMonth =
      m === "MM"
        ? ("0" + (this.month + 1)).slice(-2)
        : m === "mm"
        ? String(this.month + 1)
        : "";
    let fYear =
      y === "YY"
        ? ("0" + this.year).slice(-2)
        : y === "YYYY"
        ? String(this.year)
        : "";
    let result = "";

    if (order === "YMD") {
      if (fYear) result += fYear + separator;
      result += fMonth + separator + fDate;
    }
    if (order === "MDY") {
      result += fMonth + separator + fDate;
      if (fYear) result += separator + fYear;
    }
    if (order === "DMY") {
      if (fDate) result += fDate + separator;
      result += fMonth;
      if (fYear) result += separator + fYear;
    }

    if (includeTime) {
      result += ` ${this.hourAndMinute}`;
    }
    return result;
  }
  setHours(
    hours: number,
    min?: number | undefined,
    sec?: number | undefined,
    ms?: number | undefined
  ) {
    this.d.setUTCHours(hours, min, sec, ms);
    return new LocalDateAsUTC(this.d.toISOString());
  }
  setDate(date: number) {
    this.d.setUTCDate(date);
    return new LocalDateAsUTC(this.d.toISOString());
  }
  setMonth(month: number) {
    this.d.setUTCDate(month);
    return new LocalDateAsUTC(this.d.toISOString());
  }
  setYear(year: number) {
    this.d.setUTCFullYear(year);
    return new LocalDateAsUTC(this.d.toISOString());
  }

  roundToEndOfDay(offsetHour: number = 0, offsetMinute: number = 0) {
    const originalHour = this.d.getUTCHours();
    const originalMinute = this.d.getUTCMinutes();
    this.d.setUTCHours(23 + offsetHour, 59 + offsetMinute, 59, 999);

    if (
      originalHour < offsetHour ||
      (originalHour === offsetHour && originalMinute < offsetMinute)
    ) {
      // If it's before the offset hour, we subtract 1 day and set the time to the offset time
      this.d.setUTCDate(this.d.getUTCDate() - 1);
    }

    return new LocalDateAsUTC(this.d.toISOString());
  }
  roundToStartOfDay(offsetHour: number = 0, offsetMinute: number = 0) {
    const originalHour = this.d.getUTCHours();
    const originalMinute = this.d.getUTCMinutes();

    // Check if the current hour is less than the offset hour
    if (
      originalHour < offsetHour ||
      (originalHour === offsetHour && originalMinute < offsetMinute)
    ) {
      // If it's before the offset hour, we subtract 1 day and set the time to the offset time
      this.d.setUTCDate(this.d.getUTCDate() - 1);
    }
    this.d.setUTCHours(offsetHour, offsetMinute, 0, 0);

    return new LocalDateAsUTC(this.d.toISOString());
  }

  roundToStartOfYear(offsetHour: number = 0, offsetMinute: number = 0) {
    const originalMonth = this.d.getUTCMonth();
    const originalDate = this.d.getUTCDate();
    const originalHour = this.d.getUTCHours();
    const originalMinute = this.d.getUTCMinutes();

    this.d.setUTCMonth(0);
    this.d.setUTCDate(1);
    this.d.setUTCHours(offsetHour, offsetMinute, 0, 0);

    if (
      originalMonth === 0 &&
      originalDate === 1 &&
      (originalHour < offsetHour ||
        (originalHour === offsetHour && originalMinute < offsetMinute))
    ) {
      //if the date is 2023-03-01T02:00:00 and the cutoff time is 06:00 then you need to subtract one month to get 2023-02-01T06:00:00
      this.d.setUTCFullYear(this.d.getUTCFullYear() - 1);
    }

    return new LocalDateAsUTC(this.d.toISOString());
  }
  roundToEndOfYear(offsetHour: number = 0, offsetMinute: number = 0) {
    this.d.setUTCMonth(11);
    this.d.setUTCDate(31);
    this.d.setUTCHours(23, 59, 999, 999);

    return new LocalDateAsUTC(this.d.toISOString()).addMinutes(
      offsetHour * 60 + offsetMinute
    );
  }
  roundToStartOfMonth(offsetHour: number = 0, offsetMinute: number = 0) {
    const originalDate = this.d.getUTCDate();
    const originalHour = this.d.getUTCHours();
    const originalMinute = this.d.getUTCMinutes();

    this.d.setUTCDate(1);
    this.d.setUTCHours(offsetHour, offsetMinute, 0, 0);
    if (
      originalDate === 1 &&
      (originalHour < offsetHour ||
        (originalHour === offsetHour && originalMinute < offsetMinute))
    ) {
      //if the date is 2023-03-01T02:00:00 and the cutoff time is 06:00 then you need to subtract one month to get 2023-02-01T06:00:00
      this.d.setUTCMonth(this.d.getUTCMonth() - 1);
    }

    return new LocalDateAsUTC(this.d.toISOString());
  }
  roundToEndOfMonth(offsetHour: number = 0, offsetMinute: number = 0) {
    let currentDate = new LocalDateAsUTC(this.d.toISOString());
    const originalDate = this.d.getUTCDate();
    const originalHour = this.d.getUTCHours();
    const originalMinute = this.d.getUTCMinutes();

    if (
      originalDate === 1 &&
      (originalHour < offsetHour ||
        (originalHour === offsetHour && originalMinute < offsetMinute))
    ) {
      //if the date is 2023-03-01T02:00:00 and the cutoff time is 06:00 then you need to subtract one month to get 2023-02-01T06:00:00
      this.roundToEndOfDay(offsetHour, offsetMinute);
      return new LocalDateAsUTC(this.d.toISOString()); //2023-03-01T05:59:59:00
    }
    //if the date is 2023-03-03T02:00:00 and the cutoff time is 06:00 then you need to subtract one month to get 2023-02-01T06:00:00

    currentDate.roundToStartOfMonth(offsetHour, offsetMinute); //2023-03-01T06:00:00
    this.d.setUTCDate(1); ////2023-03-01T06:00:00
    this.d.setUTCHours(offsetHour, offsetMinute, 0, 0);
    ////2023-03-01T06:00:00
    currentDate.addMonths(1);
    //to get 2023-04-01T06:00:00
    currentDate.subtractDay();
    //to get 2023-03-31T06:00:00
    let lastDayOfMonth = currentDate.date;
    //31
    this.setDate(lastDayOfMonth);
    //to get 2023-03-31T06:00:00
    this.roundToEndOfDay(offsetHour, offsetMinute);
    //to get 2023-04-01T05:59:99
    return new LocalDateAsUTC(this.d.toISOString()); //2023-03-01T05:59:59:00
  }

  roundToStartOfWeek(offsetHour: number = 0, offsetMinute: number = 0) {
    if (offsetHour || offsetMinute) {
      this.subtractMinutes(offsetHour * 60 + offsetMinute);
    }

    const originalDay = this.d.getUTCDay();
    //1 = Monday from offsetHour2
    const dateOfLastMonday =
      this.d.getUTCDate() - originalDay + (originalDay == 0 ? -6 : 1); // adjust when day is sunday
    this.d.setUTCDate(dateOfLastMonday);
    this.d.setUTCHours(offsetHour, offsetMinute, 0, 0);
    return new LocalDateAsUTC(this.d.toISOString());
  }
  roundToLastMonday() {
    const day = this.d.getUTCDay();
    const dateOfLastMonday = this.d.getUTCDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    this.d.setUTCDate(dateOfLastMonday);
    return new LocalDateAsUTC(this.d.toISOString());
  }

  roundToLastLastMonday() {
    const day = this.d.getUTCDay();
    const dateOfLastMonday = this.d.getUTCDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    this.d.setUTCDate(dateOfLastMonday);
    this.subtractWeek();
    return new LocalDateAsUTC(this.d.toISOString());
  }
  roundToNextDayOfWeek(day: number) {
    this.d.setDate(this.date + ((day + 7 - this.day) % 7));
    return new LocalDateAsUTC(this.d.toISOString());
  }
  subtractWeek() {
    this.d.setTime(this.time - 7 * DAY_IN_MS);
    return new LocalDateAsUTC(this.d.toISOString());
  }
  subtractHours(hours: number) {
    this.d.setHours(this.hours - hours);
    return new LocalDateAsUTC(this.d.toISOString());
  }

  subtractMonths(months: number) {
    this.d.setUTCMonth(this.month - months);
    return new LocalDateAsUTC(this.d.toISOString());
  }
  subtractDay() {
    this.d.setTime(this.time - 1 * DAY_IN_MS);
    return new LocalDateAsUTC(this.d.toISOString());
  }
  subtractMillisecond() {
    this.d.setTime(this.time - 1);
    return new LocalDateAsUTC(this.d.toISOString());
  }
  subtractDays(days: number) {
    this.d.setTime(this.time - days * DAY_IN_MS);
    return new LocalDateAsUTC(this.d.toISOString());
  }

  addMinutes(minutes: number) {
    this.d.setTime(this.time + minutes * MINUTE_IN_MS);
    return new LocalDateAsUTC(this.d.toISOString());
  }
  subtractMinutes(minutes: number) {
    this.d.setTime(this.time - minutes * MINUTE_IN_MS);
    return new LocalDateAsUTC(this.d.toISOString());
  }
  addWeek() {
    this.d.setTime(this.time + 7 * DAY_IN_MS);
    return new LocalDateAsUTC(this.d.toISOString());
  }
  addDays(days: number) {
    this.d.setTime(this.time + days * DAY_IN_MS);

    return new LocalDateAsUTC(this.d.toISOString());
  }
  addMonths(months: number) {
    this.d.setUTCMonth(this.month + months);

    return new LocalDateAsUTC(this.d.toISOString());
  }

  copyFrom(date: LocalDateAsUTC) {
    this.d = new Date(date.ISO);
    return new LocalDateAsUTC(this.d.toISOString());
  }

  static isMonthApart(date1: LocalDateAsUTC, date2: LocalDateAsUTC) {
    const MILLI_IN_MONTH = 2419200000; //1000*60*60*24*28

    return MILLI_IN_MONTH <= Math.abs(date1.time - date2.time);
  }

  static getWeeksBetween(date1: LocalDateAsUTC, date2: LocalDateAsUTC) {
    let weeks: { start: LocalDateAsUTC; end: LocalDateAsUTC }[] = [];

    //now our Sunday check
    let currentDate = new LocalDateAsUTC(date1.ISO);

    //set the date to a day before Sunday
    if (currentDate.day > 0)
      currentDate.setDate(currentDate.date - currentDate.day);

    while (currentDate.time <= date2.time && weeks.length < 100) {
      let start = new LocalDateAsUTC(currentDate.ISO);
      let end = new LocalDateAsUTC(currentDate.ISO).addDays(6);
      weeks.push({ start, end });
      currentDate.addDays(7);
    }
    return weeks;
  }
  static getGroupsOfXBetween(
    date1: LocalDateAsUTC,
    date2: LocalDateAsUTC,
    daysInGroup: number
  ) {
    let groups: { start: LocalDateAsUTC; end: LocalDateAsUTC }[] = [];

    let currentDate = new LocalDateAsUTC(date1.ISO);
    let maxDate = new LocalDateAsUTC(date2.ISO);

    while (currentDate.time <= date2.time) {
      let start = new LocalDateAsUTC(currentDate.ISO);
      let end = new LocalDateAsUTC(currentDate.ISO).addDays(daysInGroup - 1);

      groups.push({
        start,
        end: end.time > maxDate.time ? maxDate : end,
      });
      currentDate.addDays(daysInGroup);
    }
    return groups;
  }

  static countDaysBetween(date1: LocalDateAsUTC, date2: LocalDateAsUTC) {
    const diffInMs = Math.abs(date1.time - date2.time);
    // Convert milliseconds to days
    const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
    return diffInDays;
  }

  static getDaysBetween(date1: LocalDateAsUTC, date2: LocalDateAsUTC) {
    let dates = [] as LocalDateAsUTC[];
    let start = new LocalDateAsUTC(date1.ISO);
    let end = new LocalDateAsUTC(date2.ISO);
    while (start.time <= end.time) {
      dates.push(new LocalDateAsUTC(start.ISO));
      start.addDays(1);
    }

    return dates;
  }

  static countCalenderMonthsBetween(
    date1: LocalDateAsUTC,
    date2: LocalDateAsUTC
  ) {
    let months = [] as number[];
    let start = new LocalDateAsUTC(date1.ISO).roundToStartOfMonth();
    let end = new LocalDateAsUTC(date2.ISO);
    while (start.time <= end.time) {
      months.push(start.month + 1);
      start.addMonths(1);
    }
    return months;
  }
  static getCalenderMonthsBetween(
    date1: LocalDateAsUTC,
    date2: LocalDateAsUTC
  ) {
    let months = [] as LocalDateAsUTC[];
    let start = new LocalDateAsUTC(date1.ISO).roundToStartOfMonth();
    let end = new LocalDateAsUTC(date2.ISO);
    while (start.time <= end.time) {
      months.push(new LocalDateAsUTC(start.ISO));
      start.addMonths(1);
    }
    return months;
  }

  static getISOFromDate(date: Date) {
    let year = date.getFullYear().toString();
    let month = `${date.getMonth() + 1}`.padStart(2, "0");
    let dateOfMonth = `${date.getDate()}`.padStart(2, "0");
    let hours = `${date.getHours()}`.padStart(2, "0");
    let minutes = `${date.getMinutes()}`.padStart(2, "0");
    let seconds = `${date.getSeconds()}`.padStart(2, "0");
    let milliseconds = `${date.getMilliseconds()}`.padStart(2, "0");
    const isoString = `${year}-${month}-${dateOfMonth}T${hours}:${minutes}:${seconds}.000Z`;
    return isoString;
  }
  private _attachZToISO = (ISOString: string) => {
    if (!/Z$/.test(ISOString)) {
      let YMD = String(ISOString).split("T")[0];
      let HMST = String(ISOString).split("T")[1];

      if (/\+|-/.test(HMST)) {
        this.d = new Date(ISOString);
      } else {
        this.d = new Date(ISOString + "Z");
      }
    } else {
      this.d = new Date(ISOString);
    }
  };
}

// const addDays = (date: Date, days: number) => {
//   let newDate = new Date(date.valueOf());
//   newDate.setDate(newDate.getDate() + days);
//   return newDate;
// };
// //now our Sunday check
// let currentDate = startDate;

// //set the date to a day before Sunday
// if (currentDate.getDay() > 0)
//   currentDate.setDate(currentDate.getDate() - currentDate.getDay());

// while (currentDate <= endDate && weeks.length < 100) {
//   let startWeekDate = currentDate;
//   let endWeekDate = addDays(currentDate, 6);

//   let label = `${_getShortenedDate(currentDate)} - ${_getShortenedDate(
//     endWeekDate
//   )}`;
//   weeks.push({ start: startWeekDate, end: endWeekDate, label, sum: 0 });
//   currentDate = addDays(startWeekDate, 7);
// }
