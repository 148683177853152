import React, { useEffect } from "react";
import SalesBusinessActivityTable from "./components/Sales-Business-Table/SalesBusinessActivityTable";
import { useAppDispatch } from "../../app/hooks";
import { resetFilters, setFilters } from "../businesses/businessesSlice";
import RevenueOverview from "./components/Revenue-Overview/RevenueOverview";
import GeneralTableHeader from "../common/general-table-header/GeneralTableHeader";
// import SalesActionMenu from "./components/SalesActionMenu";

function Sales() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setFilters({}));
    return () => {
      dispatch(resetFilters());
    };
  }, []);

  return (
    <div>
      <GeneralTableHeader
        title="Sales Team Manager"
        description="Associate a merchant with its sales representative and track sales commissions."
        // actionButtons={[<SalesActionMenu />]}
        avatarUrl="https://e7.pngegg.com/pngimages/568/533/png-clipart-sales-use-case-force-de-vente-computer-programming-animated-film-sales-team-child-reading-thumbnail.png"
      >
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          ></div>
        </>
      </GeneralTableHeader>
      <RevenueOverview></RevenueOverview>
      <SalesBusinessActivityTable></SalesBusinessActivityTable>
    </div>
  );
}

export default Sales;
