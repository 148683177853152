import {
  Button,
  Input,
  Select,
  Space,
  Table,
  TablePaginationConfig,
} from "antd";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import styles from "./ReportsBusinessTable.module.css";
import {
  getBusinessesThunk,
  setCurrentBusiness,
  selectTotalBusinessCount,
  selectLastUpdateKey,
  selectFilters,
  setFilters,
} from "../../../businesses/businessesSlice";

import { Empty } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import Loader from "../../../common/loader/Loader";
import {
  BusinessType,
  IBusinessFull,
  IBusinessFullQuery,
} from "../../../../interfaces/businessFull.interface";
import { TableParams } from "../../../../interfaces/tableParams.interface";
import { IPagination } from "../../../../interfaces/pagination.interface";
import debounce from "lodash/debounce";
import CreateReportFormModal from "../create-merchant-report-form/CreateMerchantReportForm";
import AnalyticsReportPanel from "../analytics-reports-panel/AnalyticsReportPanel";
const { Column, ColumnGroup } = Table;

export default function ReportsBusinessTable() {
  const [isCreateReportFormModalVisible, setIsCreateReportFormModalVisible] =
    useState(false);

  const [loading, setLoading] = useState(false);
  const lastUpdateKey = useAppSelector(selectLastUpdateKey);
  const filters = useAppSelector(selectFilters);
  const [businessesInTable, setBusinessesInTable] = useState([]);
  const initialPagination = { current: 1, pageSize: 10 };
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: { ...initialPagination },
  });
  // const [filters, setFilters] = useState<Partial<IBusinessFullQuery>>({});
  const dispatch = useAppDispatch();

  const fetchAllBusinesses = async (params: {
    pagination: IPagination;
    filters: Partial<IBusinessFullQuery>;
  }) => {
    setLoading(true);
    await dispatch(
      getBusinessesThunk({
        currentPage: params?.pagination?.current!,
        pageSize: params?.pagination?.pageSize!,
        ...filters,
      })
    )
      .then(({ payload }) => {
        //@ts-ignore
        let businesses = payload.data;
        //@ts-ignore
        let totalCount = payload.totalCount;
        let businessesWithKeys = businesses.map((b: IBusinessFull) => ({
          ...b,
          key: b._id,
        }));
        setBusinessesInTable(businessesWithKeys);

        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: totalCount,
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchAllBusinesses({
      pagination: {
        current: tableParams.pagination?.current || 1,
        pageSize: tableParams.pagination?.pageSize!,
      },
      filters,
    });
  }, [JSON.stringify(tableParams), JSON.stringify(filters), lastUpdateKey]);

  const showCreateReportFormModal = (business: IBusinessFull) => {
    dispatch(setCurrentBusiness(business));
    setIsCreateReportFormModalVisible(true);
  };

  const handleSalesRepresentativeFilterChange = (value: string) => {
    dispatch(setFilters({ salesRepresentative: value, ...initialPagination }));
    setTableParams((prev) => {
      return { ...prev, pagination: { ...initialPagination } };
    });
  };

  const handleTypeFilterChange = (value: BusinessType) => {
    dispatch(setFilters({ businessType: value, ...initialPagination }));
    setTableParams((prev) => {
      return { ...prev, pagination: { ...initialPagination } };
    });
  };
  const debouncedNameFilterChange = useCallback(
    debounce((nextValue) => handleNameFilterChange(nextValue), 400),
    [] // will be created only once initially
  );

  const handleDebouncedNameFilterChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    debouncedNameFilterChange(event);
  };
  const handleNameFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value.toLowerCase();
    dispatch(
      setFilters({
        name: name.replace(/\s+/g, " ").trim(),
        ...initialPagination,
      })
    );
    setTableParams((prev) => {
      return { ...prev, pagination: { ...initialPagination } };
    });
  };

  const handleTableChange = (
    pagination: TablePaginationConfig
    // filters: Record<string, FilterValue>,
    // sorter: SorterResult<IBucketDeal>
  ) => {
    setTableParams({
      pagination,
      // filters,
      // ...sorter,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      // dispatch(emptyBucketDealsTable());
    }
  };

  return (
    <div className={styles.container} key="divTable">
      <AnalyticsReportPanel />
      <div className={styles.table_header}>
        <Input
          onChange={handleDebouncedNameFilterChange}
          placeholder="Business Name, Email, Kvk ..."
          value={filters.businessName}
          className={styles.searchInput}
          prefix={<SearchOutlined />}
          style={{ width: "300px" }}
        />
      </div>
      <br />

      <div className={styles.table_container}>
        {businessesInTable.length === 0 && !loading && (
          <div style={{ height: "300px" }} className="center">
            <Empty></Empty>
          </div>
        )}
        {businessesInTable.length > 0 && !loading && (
          <Table
            dataSource={businessesInTable}
            pagination={tableParams.pagination}
            loading={loading}
            //@ts-ignore
            onChange={handleTableChange}
          >
            <Column
              title="Business "
              className={styles.table_business_column}
              key="businessDetails"
              render={(_: any, record: IBusinessFull) => (
                <div className={styles.business_details}>
                  <span key={record._id + "businessName"}>
                    {record.businessName}
                  </span>
                  <span
                    style={{ color: "gray" }}
                    key={record._id + "businessRegistrationNumber"}
                  >
                    {record.businessRegistrationNumber}
                  </span>
                </div>
              )}
            />

            <Column
              title="Email"
              key="email"
              className={styles.table_column}
              render={(_: any, record: IBusinessFull) => (
                <span key={record._id + "email"}>{record.rootUser?.email}</span>
              )}
            />
            <Column
              title="Industry"
              key="industry"
              className={styles.table_column}
              render={(_: any, record: IBusinessFull) => (
                <span key={record._id + "industry"}>{record.industry}</span>
              )}
            />

            <Column
              title=""
              key="details"
              className={styles.table_column}
              render={(_: any, record: IBusinessFull) => (
                <Space
                  size="middle"
                  key={record._id}
                  className={styles.tables_buttons_container}
                >
                  <Button
                    type="default"
                    onClick={async () => {
                      showCreateReportFormModal(record);
                    }}
                  >
                    Create Report
                  </Button>
                </Space>
              )}
            />
          </Table>
        )}
        {loading && <Loader text="Fetching businesses from server"></Loader>}
      </div>
      {isCreateReportFormModalVisible && (
        <CreateReportFormModal
          visible={isCreateReportFormModalVisible}
          setVisible={setIsCreateReportFormModalVisible}
        ></CreateReportFormModal>
      )}
    </div>
  );
}
