import React from "react";
import Sales from "../../features/sales/Sales";

export default function SalesPage() {
  return (
    <div>
      <Sales></Sales>
    </div>
  );
}
