import { Spin } from "antd";
import React from "react";
import styles from "./Loader.module.css";
interface IPropsLoader {
  text?: string;
}

export default function Loader({ text }: IPropsLoader) {
  return (
    <div className={styles.container}>
      <div className={styles.loader_box}>
        <Spin size="large"></Spin>
        <div>{text}</div>
      </div>
    </div>
  );
}
