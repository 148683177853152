import { Table, Space, Button, Input, Select } from "antd";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import styles from "./PartnerDealsTable.module.css";

import { SearchOutlined } from "@ant-design/icons";
import { Empty } from "antd";
import Loader from "../../../common/loader/Loader";
import { IBucketDeal } from "../../../../interfaces/bucketDeal.interface";
import { ColumnsType, TablePaginationConfig } from "antd/lib/table";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import { IPagination } from "../../../../interfaces/pagination.interface";
import { TableParams } from "../../../../interfaces/tableParams.interface";
import { IPartnerDeal } from "../../../../interfaces/partnerDeal.interface";
import {
  getPartnerDealsThunk,
  selectPartnerDeals,
  setCurrentPartnerDeal,
} from "../../partnerDealsSlice";
import {
  selectIsKlearlyAdmin,
  selectUserPartnerId,
} from "../../../access-control/accountSlice";

import PartnerSelector from "../partner-selector/PartnerSelector";
import BucketGroupValue from "./subcomponents/bucket-group-value/BucketGroupValue";
import EditPartnerDealForm from "../edit-partner-deal-form/EditPartnerDealForm";
import { LocalDateAsUTC } from "../../../../utils/LocalDateAsUTC";
import { formatDate } from "../../../../utils/formatDate";

const { Column, ColumnGroup } = Table;

export default function PartnerDealsTable() {
  const [loading, setLoading] = useState(false);
  const partnerId = useAppSelector(selectUserPartnerId);
  const isKlearlyAdmin = useAppSelector(selectIsKlearlyAdmin);
  const partnerDeals = useAppSelector(selectPartnerDeals)?.map((pd) => {
    let ps = { ...pd, key: pd._id };
    return ps;
  });
  console.log("partnerDeals", partnerDeals);
  const initialPagination = { current: 1, pageSize: 10 };
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: { ...initialPagination },
  });
  const [filters, setFilters] = useState<Partial<IPartnerDeal>>({
    businessRegistrationNumber: "",
    businessName: "",
    partnerId: partnerId,
  });

  const [isEditPartnerDealFormVisible, setIsEditPartnerDealFormVisible] =
    useState(false);

  const dispatch = useAppDispatch();

  const handleNameFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value.toLowerCase();
    // dispatch(filterTableByName(name));

    let updatedFilters = { ...filters, businessName: name };

    //@ts-ignore
    if (!updatedFilters.businessName) delete updatedFilters.businessName;
    setFilters(updatedFilters);
    setTableParams((prev) => {
      return { ...prev, pagination: { ...initialPagination } };
    });
    fetchAllPartnerDeals({
      filters: updatedFilters,
      pagination: { ...initialPagination },
    });
  };
  const handleKVKFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    const kvk = event.target.value.toLowerCase();

    let updatedFilters = { ...filters, businessRegistrationNumber: kvk };
    setFilters(updatedFilters);
    setTableParams((prev) => {
      return { ...prev, pagination: { ...initialPagination } };
    });
    fetchAllPartnerDeals({
      filters: updatedFilters,
      //@ts-ignore
      pagination: { ...tableParams.pagination },
    });
  };
  const handlePartnerIdFilterChange = (value: any) => {
    // const partnerIds = values?.map((v) => v.value)?.join(",");
    let updatedFilters = { ...filters, partnerId: value };
    setFilters(updatedFilters);
    setTableParams((prev) => {
      return { ...prev, pagination: { ...initialPagination } };
    });
    fetchAllPartnerDeals({
      filters: updatedFilters,
      //@ts-ignore
      pagination: { ...tableParams.pagination },
    });
  };

  const fetchAllPartnerDeals = async (params: {
    filters: Partial<IPartnerDeal>;
    pagination?: IPagination;
  }) => {
    setLoading(true);
    const {
      pagination = {
        current: tableParams.pagination?.current || 1,
        pageSize: tableParams.pagination?.pageSize!,
      },
      filters,
    } = params;
    await dispatch(
      getPartnerDealsThunk({
        currentPage: pagination?.current!,
        pageSize: pagination?.pageSize!,
        ...filters,
      })
    )
      .then(({ payload }) => {
        //@ts-ignore
        let totalCount = payload?.totalCount;

        setTableParams((prev) => ({
          ...prev,
          pagination: {
            ...prev.pagination,
            total: totalCount,
          },
        }));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchAllPartnerDeals({
      pagination: {
        current: tableParams.pagination?.current || 1,
        pageSize: tableParams.pagination?.pageSize!,
      },
      filters,
    });

    // if admin only
  }, []);

  const showEditPartnerDealModal = (partnerDeal: IPartnerDeal) => {
    dispatch(setCurrentPartnerDeal(partnerDeal));
    setIsEditPartnerDealFormVisible(true);
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    tableFilters: Record<string, FilterValue>,
    sorter: SorterResult<IPartnerDeal>
  ) => {
    setTableParams({
      pagination: { ...pagination },
      filters: tableFilters,
      ...sorter,
    });

    // console.log("tableParams", tableParams);

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      // dispatch(emptyBucketDealsTable());
    }

    fetchAllPartnerDeals({
      filters: filters,
      pagination: {
        current: pagination?.current || 0,
        pageSize: pagination?.pageSize || 10,
      },
    });
  };

  // const formatDate=()=>{

  // }
  return (
    <div className={styles.container} key="divTable">
      <div className={styles.table_container}>
        <Space align="center" style={{ width: "100%" }}>
          <Input
            onChange={handleNameFilterChange}
            className={styles.searchInput}
            placeholder="Business Name"
            value={filters.businessName}
            prefix={<SearchOutlined />}
          />
          <Input
            onChange={handleKVKFilterChange}
            className={styles.searchInput}
            placeholder="KVK Number"
            prefix={<SearchOutlined />}
          />
          {
            //if partner hide this

            <PartnerSelector
              onChange={handlePartnerIdFilterChange}
            ></PartnerSelector>
          }
        </Space>
        <br />
        <br />
        {partnerDeals?.length === 0 && !loading && (
          <div style={{ height: "300px" }} className="center">
            <Empty></Empty>
          </div>
        )}
        {partnerDeals.length > 0 && !loading && (
          <Table
            // columns={columns}
            // rowSelection={{
            //   type: "checkbox",
            //   ...rowSelection,
            // }}
            dataSource={partnerDeals}
            loading={loading}
            pagination={tableParams.pagination}
            //@ts-ignore
            onChange={handleTableChange}
          >
            {isKlearlyAdmin && (
              <Column
                className={styles.partner_column}
                title="Partner"
                dataIndex="partnerName"
                key="partnerName"
              />
            )}
            <Column
              title="KVK"
              dataIndex="businessRegistrationNumber"
              key="businessRegistrationNumber"
            />
            <Column title="Name" dataIndex="businessName" key="name" />
            <Column
              title="Domestic Debit"
              dataIndex={["bucketGroups", "domesticDebitCards"]}
              key="domesticDebitCards"
              className={styles.pricing_column}
              render={(_: any, record: IPartnerDeal) => (
                <BucketGroupValue
                  value={record?.bucketGroups?.domesticDebitCards.value}
                  valueType={record?.bucketGroups?.domesticDebitCards.valueType}
                />
              )}
            />
            <Column
              title="E.E.A"
              dataIndex={["bucketGroups", "european"]}
              key="european"
              className={styles.pricing_column}
              render={(_: any, record: IPartnerDeal) => (
                <BucketGroupValue
                  value={record?.bucketGroups?.european.value}
                  valueType={record?.bucketGroups?.european.valueType}
                />
              )}
            />
            <Column
              title="Non E.E.A & Commercial"
              dataIndex={["bucketGroups", "internationalAndCommercial"]}
              className={styles.pricing_column}
              key="internationalAndCommercial"
              render={(_: any, record: IPartnerDeal) => (
                <BucketGroupValue
                  value={record?.bucketGroups?.internationalAndCommercial.value}
                  valueType={
                    record?.bucketGroups?.internationalAndCommercial.valueType
                  }
                />
              )}
            />

            <Column
              title=""
              key="details"
              render={(_: any, record: IPartnerDeal) => (
                <Space size="middle" key={record._id}>
                  {record?.isUsed &&
                  record?.usedOn &&
                  record.businessRegistrationNumber !== "86158236" ? (
                    <>
                      <span className={styles.used_on_text}>
                        Used on
                        <br />
                        {formatDate(
                          new LocalDateAsUTC(record?.usedOn).rawDate,
                          false
                        )}
                      </span>
                    </>
                  ) : (
                    <Button
                      type="primary"
                      onClick={() => {
                        showEditPartnerDealModal(record);
                      }}
                    >
                      Edit
                    </Button>
                  )}
                </Space>
              )}
            />
          </Table>
        )}
      </div>

      {loading && <Loader text="Fetching partner deals from server"></Loader>}

      {
        <EditPartnerDealForm
          visible={isEditPartnerDealFormVisible}
          setVisible={setIsEditPartnerDealFormVisible}
        />
      }
      {/* <BucketDealDetailsModal
        visible={isDetailModalVisible}
        setVisible={setIsDetailModalVisible}
      ></BucketDealDetailsModal>
      <BucketDealEditorModal
        visible={isEditorModalVisible}
        setVisible={setIsEditorModalVisible}
      ></BucketDealEditorModal>
      <BucketDealCreatorModal
        visible={isCreatorModalVisible}
        setVisible={setIsCreatorModalVisible}
      ></BucketDealCreatorModal> */}
    </div>
  );
}
