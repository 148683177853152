// import axios from "axios";
import { store } from "../../app/store";
import { CONFIG } from "../../config";
import { ITransaction } from "../../interfaces/transaction.interface";
import { ITransactionsReport } from "../../interfaces/transactionsReport.interface";
import { getTokenizedAxios } from "../../utils/axiosWithTokenGen";

export async function getBusinessesFromServer() {
  //gets all users owners and their businesses

  const axios = await getTokenizedAxios();
  const path = "/businesses/getAll";
  return axios.get(CONFIG.SERVER.BASE_URL + path).then((data) => data.data);
}
export async function getTransactionsFromServer({
  businessId,
}: {
  businessId: string;
}) {
  const axios = await getTokenizedAxios();
  const path = "/transactions/getBusinessTransactionsHistory";

  return axios
    .get<{ transactions: ITransaction[] }>(CONFIG.SERVER.BASE_URL + path, {
      params: { businessId },
    })
    .then((data) => data.data);
}

export async function getTransactionReportServer({
  businessId,
}: {
  businessId: string;
}) {
  const axios = await getTokenizedAxios();
  const path = "/transactions/report";

  return axios
    .get<{ transactionsReport: ITransactionsReport }>(
      CONFIG.SERVER.BASE_URL + path,
      {
        params: { businessId },
      }
    )
    .then((data) => data.data);
}
