import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Space,
  Popconfirm,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";

import Loader from "../../../common/loader/Loader";
import {
  deleteBucketDealThunk,
  editBucketDealThunk,
  selectCurrentBucketDeal,
} from "../../bucketDealsSlice";
import { DeleteOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { createBucketDealThunk } from "../../bucketDealsSlice";
import { IBucketDeal } from "../../../../interfaces/bucketDeal.interface";
import styles from "./BucketDealEditor.module.css";
const Option = Select.Option;
interface IPropsBucketDealEditorModel {
  visible: boolean;
  setVisible: any;
}

export default function BucketDealEditorModal({
  visible,
  setVisible,
}: IPropsBucketDealEditorModel) {
  const [loading, setLoading] = useState(false);
  const currentBucketDeal = useAppSelector(selectCurrentBucketDeal);
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  useEffect(() => {
    form.setFieldsValue({
      businessName: currentBucketDeal.businessName,
      buckets: currentBucketDeal.buckets,
      salesRepresentative: currentBucketDeal?.salesRepresentative,
      businessRegistrationNumber: currentBucketDeal.businessRegistrationNumber,
    });
  }, [currentBucketDeal]);

  const handleCancel = () => {
    setVisible(false);
  };

  function isNumeric(value: any) {
    return /^\d+$/.test(value);
  }

  const handleSubmit = async (values: IBucketDeal) => {
    setLoading(true);
    if (!currentBucketDeal._id) await dispatch(createBucketDealThunk(values));
    if (currentBucketDeal._id)
      await dispatch(
        editBucketDealThunk({ _id: currentBucketDeal._id, update: values })
      );
    setLoading(false);
    setVisible(false);
  };

  const handleDelete = async () => {
    setLoading(true);
    await dispatch(deleteBucketDealThunk({ _id: currentBucketDeal._id }));
    setLoading(false);
    setVisible(false);
  };

  const formItemLayout = {
    labelCol: { span: 10 },
    // wrapperCol: { span: 10 },
  };
  const kvkFormItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 10 },
  };
  const buttonItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 10 },
  };
  return (
    <Modal
      visible={visible}
      title={currentBucketDeal?.businessName}
      onCancel={handleCancel}
      footer={null}
      width={600}
    >
      <div>
        {currentBucketDeal._id && (
          <div className={styles.bucket_deal_delete_button_container}>
            <Popconfirm
              placement="topRight"
              title={`Are you sure you want to delete this bucket deal?`}
              onConfirm={handleDelete}
              okText="Yes"
              cancelText="No"
              key={"3"}
            >
              <Button
                icon={<DeleteOutlined />}
                danger
                type="primary"
                key="1"
                loading={loading}
              >
                Delete
              </Button>
            </Popconfirm>
          </div>
        )}
        <Form {...formItemLayout} onFinish={handleSubmit} form={form}>
          <Form.Item
            name="businessRegistrationNumber"
            label="KVK"
            {...kvkFormItemLayout}
          >
            <Input
              disabled={
                currentBucketDeal.businessRegistrationNumber ? true : false
              }
            />
          </Form.Item>
          <Form.Item name="businessName" label="Name" {...kvkFormItemLayout}>
            <Input />
          </Form.Item>
          <Form.Item
            name="salesRepresentative"
            label="Klearly Employee"
            {...kvkFormItemLayout}
          >
            <Select
              options={[
                { label: "Tom R", value: "Tom R" },
                { label: "Geus W", value: "Geus W" },
                { label: "Leonard D.B", value: "Leonard D.B" },
              ]}
            />
          </Form.Item>
          <Form.List name={"buckets"}>
            {(fields) => (
              <div>
                {fields.map(({ key, name, ...restField }) => (
                  <Space key={key} align="baseline">
                    <Form.Item {...restField} name={[name, "name"]}>
                      <Input
                        className={styles.bucket_name_input}
                        disabled
                      ></Input>
                    </Form.Item>

                    <Form.Item {...restField} name={[name, "value"]}>
                      <InputNumber
                        className={styles.bucket_value_input}
                        min={0}
                      />
                    </Form.Item>
                    <Form.Item {...restField} name={[name, "valueType"]}>
                      <Select
                        className={styles.bucket_value_input}
                        style={{ width: "90px" }}
                      >
                        <Option value={"Percentage"}>%</Option>
                        <Option value={"Fixed"}>FIXED</Option>
                      </Select>
                    </Form.Item>
                  </Space>
                ))}
              </div>
            )}
          </Form.List>

          <Form.Item {...buttonItemLayout}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>

      {loading && <Loader text="Working on Bucket Deal" />}
    </Modal>
  );
}
