import { CONFIG } from "../../config";
import { IBucketDeal } from "../../interfaces/bucketDeal.interface";
import { IBusinessFull } from "../../interfaces/businessFull.interface";
import { IPagination } from "../../interfaces/pagination.interface";
import { IQueryFilters } from "../../interfaces/queryFilters.interface";
import { getTokenizedAxios } from "../../utils/axiosWithTokenGen";

export async function createBucketDealServer(params: Partial<IBucketDeal>) {
  const axios = await getTokenizedAxios();
  const path = "/bucketDeals/create";
  return axios
    .post<IBucketDeal>(CONFIG.ADMIN_SERVER.BASE_URL + path, params)
    .then((data) => data.data);
}
export async function createMultBucketDealsServer(
  params: Partial<IBucketDeal>[]
) {
  const axios = await getTokenizedAxios();
  const path = "/bucketDeals/createMany";
  return axios
    .post<IBucketDeal[]>(CONFIG.ADMIN_SERVER.BASE_URL + path, {
      bucketDeals: params,
    })
    .then((data) => data.data);
}

export async function deleteBucketDealServer(_id: string) {
  const axios = await getTokenizedAxios();
  const path = "/bucketDeals/deleteOne/";
  return axios
    .delete<{ _id: string }>(CONFIG.ADMIN_SERVER.BASE_URL + path + _id)
    .then((data) => ({
      _id,
    }));
}
export async function deleteMultBucketDealsServer(_ids: string) {
  const axios = await getTokenizedAxios();
  const path = "/bucketDeals/deleteMany/";
  return axios
    .delete<string[]>(CONFIG.ADMIN_SERVER.BASE_URL + path + _ids)
    .then((data) => {
      console.log("data.data", data.data);

      return data.data;
    });
}
export async function editBucketDealServer(params: {
  _id: string;
  update: Partial<IBucketDeal>;
}) {
  const { _id, update } = params;
  const axios = await getTokenizedAxios();
  const path = "/bucketDeals/edit/";

  return axios
    .put<IBucketDeal>(CONFIG.ADMIN_SERVER.BASE_URL + path + _id, update)
    .then((data) => data.data);
}

export async function getAllBucketDealsServer(
  params: IQueryFilters<IBucketDeal>
) {
  const axios = await getTokenizedAxios();
  const path = "/bucketDeals/getAll";
  return axios
    .get(CONFIG.ADMIN_SERVER.BASE_URL + path, {
      params,
    })
    .then((data) => data.data as { data: IBucketDeal[]; totalCount: number });
}
