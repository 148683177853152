import { Form, InputNumber, Radio, Select, Space } from "antd";

import styles from "./BucketGroupPriceFormItems.module.css";

const bucketGroupPriceFieldLayout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 12 },
};
interface IPropsBucketGroupPriceFormItems {
  formItemName: string;
  label: string;
  showMaxField?: boolean;
}

export default function BucketGroupPriceFormItems({
  formItemName,
  label,
}: IPropsBucketGroupPriceFormItems) {
  return (
    <>
      <br />
      <span> {label}</span>
      <br />
      <br />
      <Space align="baseline">
        <Space size={0}>
          <Form.Item
            // labelCol={{ ...bucketGroupPriceFieldLayout.labelCol }}
            // labelAlign="left"
            label={"Klearly Markup"}
            name={["bucketGroups", formItemName, "value"]}
          >
            <InputNumber
              controls={false}
              className={styles.bucket_value_input}
              min={0}
              style={{ width: "55px" }}
            />
          </Form.Item>
          <Form.Item
            // labelCol={{ ...bucketGroupPriceFieldLayout.labelCol }}
            // labelAlign="left"
            //   label={"Value Type"}
            name={["bucketGroups", formItemName, "valueType"]}
          >
            <Radio.Group>
              <Radio.Button value={"Percentage"}>%</Radio.Button>
              <Radio.Button value={"Fixed"}>FIXED</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Space>
        <Form.Item
          // labelCol={{ ...bucketGroupPriceFieldLayout.labelCol }}
          // labelAlign="left"
          label="Max Price"
          name={["bucketGroups", formItemName, "max"]}
        >
          <InputNumber
            controls={false}
            className={styles.bucket_value_input}
            min={0}
            max={4}
            style={{ width: "55px" }}
          />
        </Form.Item>
      </Space>
    </>
  );
}
