import { Divider, Form, FormInstance, Input, InputNumber, Switch } from "antd";
import Select, { BaseOptionType } from "antd/lib/select";
import { isEmailValid } from "../../../../../utils/isEmail";
import {
  BusinessSettingsEndOfShiftCalculationType,
  IBusinessFull,
  ScheduleType,
} from "../../../../../interfaces/businessFull.interface";
import { useEffect, useState } from "react";

const handleSendListValidation = (emails: string[]) => {
  let illegalEmail = "";
  emails.forEach((e) => {
    if (!isEmailValid(e)) illegalEmail = e;
  });
  return illegalEmail;
};
const settingsFieldLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 12 },
};
const inputFieldLayout = {
  labelCol: { span: 12 },
  wrapperCol: { span: 7 },
};
const checkboxFieldLayout = {
  labelCol: { span: 20 },
  wrapperCol: { span: 20 },
};
const shortNumberFieldLayout = {
  labelCol: { span: 18 },
  wrapperCol: { span: 12 },
};
export default function SettingsEditor({
  currentBusiness,
}: {
  currentBusiness: IBusinessFull;
  form: FormInstance<any>;
}) {
  const [showTipOptions, setShowTipOptions] = useState(
    currentBusiness?.settings?.payments?.enableTips
  );

  const [
    showSalesOverviewScheduleTypeOptions,
    setShowSalesOverviewScheduleTypeOptions,
  ] = useState(
    currentBusiness?.settings?.reports?.types?.endOfDaySalesOverviewReport
      ?.isEnabled
  );
  const [showEndOfShiftPreferences, setShowEndOfShiftPreferences] = useState(
    currentBusiness?.settings?.endOfShift?.enableEndOfShift
  );
  const [showEndOfShiftCode, setShowEndOfShiftCode] = useState(
    currentBusiness?.settings?.endOfShift?.requireEndOfShiftCode
  );
  const onTipEnablmentChange = (value: any) => {
    setShowTipOptions(value);
  };
  const onSalesOverviewEnablmentChange = (value: any) => {
    setShowSalesOverviewScheduleTypeOptions(value);
  };
  const onEnableEndOfShiftChange = (value: any) => {
    setShowEndOfShiftPreferences(value);
  };
  const onRequireEndOfShiftCodeChange = (value: any) => {
    setShowEndOfShiftCode(value);
  };

  useEffect(() => {
    setShowSalesOverviewScheduleTypeOptions(
      currentBusiness?.settings?.reports?.types?.endOfDaySalesOverviewReport
        ?.isEnabled
    );
    setShowTipOptions(currentBusiness?.settings?.payments?.enableTips);
  }, [
    currentBusiness._id,
    currentBusiness?.settings?.reports?.types?.endOfDaySalesOverviewReport
      ?.isEnabled,
    currentBusiness?.settings?.payments?.enableTips,
  ]);

  return (
    <>
      <h3>Reports</h3>

      <Form.Item
        label="Email List"
        name={["settings", "reports", "sendList"]}
        rules={[
          {
            validator: async (_, persons) => {
              if (!persons || persons.length < 1) return;
              const illegalValue = handleSendListValidation(persons);

              if (illegalValue)
                return Promise.reject(
                  new Error(
                    `"Send List contains a non email value ${illegalValue} `
                  )
                );
            },
          },
        ]}
      >
        <Select
          mode="tags"
          style={{ width: 300 }}
          placeholder="Sender List"
          key={"selectEmails"}
          // onChange={handleChange}
          options={currentBusiness?.settings?.reports?.sendList?.map(
            (email, i) => ({
              label: email,
              value: email,
              key: `${email} ${i}`,
            })
          )}
        />
      </Form.Item>

      <Divider plain>Automated Report List</Divider>

      <Form.Item
        name={[
          "settings",
          "reports",
          "types",
          "endOfDaySalesOverviewReport",
          "isEnabled",
        ]}
        label={"Daily Sales Overview Report"}
        labelCol={{ ...checkboxFieldLayout.labelCol }}
        labelAlign="left"
      >
        <Switch
          defaultChecked={
            currentBusiness?.settings?.reports?.types
              ?.endOfDaySalesOverviewReport?.isEnabled
          }
          checkedChildren="Yes"
          unCheckedChildren="No"
          onChange={onSalesOverviewEnablmentChange}
        />
      </Form.Item>
      {showSalesOverviewScheduleTypeOptions && (
        <Form.Item
          name={[
            "settings",
            "reports",
            "types",
            "endOfDaySalesOverviewReport",
            "scheduleType",
          ]}
          label={"Schedule Type"}
          labelCol={{ ...settingsFieldLayout.labelCol }}
          labelAlign="left"
        >
          <Select
            style={{ width: 270 }}
            placeholder="When should the report be sent"
            key={"selectDailySalesOverviewScheduleType"}
            defaultValue={"daily-end-of-day"}
            // onChange={handleChange}
            options={[
              {
                label: `End of day (cutoff time)`,
                value: "daily-end-of-day" as ScheduleType,
                key: "1",
              },
              {
                label: "Day after (09:30 Dutch Time)",
                value: "daily-day-after" as ScheduleType,
                key: "2",
              },
            ]}
          />
        </Form.Item>
      )}

      <Form.Item
        name={[
          "settings",
          "reports",
          "types",
          "dailyPayoutReport",
          "isEnabled",
        ]}
        label={"Daily Payout Report"}
        labelCol={{ ...checkboxFieldLayout.labelCol }}
        labelAlign="left"
      >
        <Switch
          defaultChecked={
            currentBusiness?.settings?.reports?.types?.dailyPayoutReport
              ?.isEnabled
          }
          checkedChildren="Yes"
          unCheckedChildren="No"
        />
      </Form.Item>
      <Form.Item
        name={[
          "settings",
          "reports",
          "types",
          "monthlyAccountingReport",
          "isEnabled",
        ]}
        label={"Monthly Bookkeeping Report"}
        labelCol={{ ...checkboxFieldLayout.labelCol }}
        labelAlign="left"
      >
        <Switch
          defaultChecked={
            currentBusiness?.settings?.reports?.types?.monthlyAccountingReport
              ?.isEnabled
          }
          checkedChildren="Yes"
          unCheckedChildren="No"
        />
      </Form.Item>
      <Divider plain></Divider>
      <h3>Cutoff Time</h3>

      <Form.Item
        label="Cutoff Hour"
        name={["settings", "endOfDay", "hour"]}
        labelCol={{ ...shortNumberFieldLayout.labelCol }}
        labelAlign="left"
      >
        <InputNumber
          // min={0}
          // max={12}
          formatter={(value) => `${value}:00`}
          parser={(value) => value!.replace(":00", "")}
        ></InputNumber>
      </Form.Item>
      <Divider plain></Divider>
      <h3>End of Shift</h3>

      <Form.Item
        name={["settings", "endOfShift", "enableEndOfShift"]}
        label={"Enable End of Shift"}
        labelCol={{ ...checkboxFieldLayout.labelCol }}
        labelAlign="left"
      >
        <Switch
          defaultChecked={
            currentBusiness?.settings?.endOfShift?.enableEndOfShift
          }
          onChange={onEnableEndOfShiftChange}
          checkedChildren="Yes"
          unCheckedChildren="No"
        />
      </Form.Item>

      {showEndOfShiftPreferences && (
        <>
          <Form.Item
            name={["settings", "endOfShift", "calculationType"]}
            label={"Calculation Type"}
            labelCol={{ ...inputFieldLayout.labelCol }}
            labelAlign="left"
          >
            <Select
              style={{ width: "100%" }}
              placeholder="Calculation Type"
              key={"Calculation Type"}
              // onChange={handleChange}
              options={[
                {
                  label: "All devices",
                  value: BusinessSettingsEndOfShiftCalculationType.PerBusiness,
                  key: `All Devices`,
                },
                {
                  label: "Per device",
                  value: BusinessSettingsEndOfShiftCalculationType.PerDevice,
                  key: `Per Device`,
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            name={["settings", "endOfShift", "calculateVolumeFromLastShift"]}
            label={"Reset Shift Volume "}
            labelCol={{ ...checkboxFieldLayout.labelCol }}
            labelAlign="left"
          >
            <Switch
              defaultChecked={
                currentBusiness?.settings?.endOfShift
                  ?.calculateVolumeFromLastShift
              }
              checkedChildren="Yes"
              unCheckedChildren="No"
            />
          </Form.Item>
          <Form.Item
            name={["settings", "endOfShift", "requireEndOfShiftCode"]}
            label={"Require Shift Code"}
            labelCol={{ ...checkboxFieldLayout.labelCol }}
            labelAlign="left"
          >
            <Switch
              defaultChecked={
                currentBusiness?.settings?.endOfShift?.requireEndOfShiftCode
              }
              onChange={onRequireEndOfShiftCodeChange}
              checkedChildren="Yes"
              unCheckedChildren="No"
            />
          </Form.Item>

          {showEndOfShiftCode && (
            <Form.Item
              name={["settings", "endOfShift", "endOfShiftCode"]}
              label={"End of Shift Code"}
              labelCol={{ ...shortNumberFieldLayout.labelCol }}
              labelAlign="left"
            >
              <Input minLength={0} maxLength={4}></Input>
            </Form.Item>
          )}

          <Form.Item
            labelCol={{ ...inputFieldLayout.labelCol }}
            name={["settings", "endOfShift", "shiftReportDefaultEmail"]}
            label={"Default Shift Report Email"}
            labelAlign="left"
          >
            <Input max={100}></Input>
          </Form.Item>
        </>
      )}
      <Divider plain></Divider>

      <h3>Payment Flow</h3>
      <Form.Item
        name={["settings", "payments", "enableCashPayments"]}
        label={"Enable Cash Payments"}
        labelCol={{ ...checkboxFieldLayout.labelCol }}
        labelAlign="left"
      >
        <Switch
          defaultChecked={
            currentBusiness?.settings?.payments?.enableCashPayments
          }
          checkedChildren="Yes"
          unCheckedChildren="No"
        />
      </Form.Item>
      <Form.Item
        name={["settings", "payments", "enableDiscounts"]}
        label={"Enable Cash Discounts"}
        labelCol={{ ...checkboxFieldLayout.labelCol }}
        labelAlign="left"
      >
        <Switch
          defaultChecked={currentBusiness?.settings?.payments?.enableDiscounts}
          checkedChildren="Yes"
          unCheckedChildren="No"
        />
      </Form.Item>
      <Form.Item
        name={["settings", "payments", "enableTips"]}
        label={"Enable Tips"}
        labelCol={{ ...checkboxFieldLayout.labelCol }}
        labelAlign="left"
      >
        <Switch
          defaultChecked={currentBusiness?.settings?.payments?.enableTips}
          checkedChildren="Yes"
          unCheckedChildren="No"
          onChange={onTipEnablmentChange}
        />
      </Form.Item>
      {currentBusiness?.settings?.payments?.enableTips && showTipOptions && (
        <>
          <Form.Item
            name={[
              "settings",
              "payments",
              "tipPercentagePresets",
              "percentage1",
            ]}
            label={"Tip Option 1"}
            labelCol={{ ...shortNumberFieldLayout.labelCol }}
            labelAlign="left"
          >
            <InputNumber
              controls={false}
              min={0}
              max={100}
              addonAfter={"%"}
            ></InputNumber>
          </Form.Item>
          <Form.Item
            name={[
              "settings",
              "payments",
              "tipPercentagePresets",
              "percentage2",
            ]}
            label={"Tip Option 2"}
            labelCol={{ ...shortNumberFieldLayout.labelCol }}
            labelAlign="left"
          >
            <InputNumber
              controls={false}
              min={0}
              max={100}
              addonAfter={"%"}
            ></InputNumber>
          </Form.Item>
          <Form.Item
            name={[
              "settings",
              "payments",
              "tipPercentagePresets",
              "percentage3",
            ]}
            label={"Tip Option 3"}
            labelCol={{ ...shortNumberFieldLayout.labelCol }}
            labelAlign="left"
          >
            <InputNumber
              controls={false}
              min={0}
              max={100}
              addonAfter={"%"}
            ></InputNumber>
          </Form.Item>
        </>
      )}

      <Form.Item
        name={["settings", "payments", "enableSkipTags"]}
        label={"Enable Skip Tags"}
        labelCol={{ ...checkboxFieldLayout.labelCol }}
        labelAlign="left"
      >
        <Switch
          defaultChecked={currentBusiness?.settings?.payments?.enableSkipTags}
          checkedChildren="Yes"
          unCheckedChildren="No"
        />
      </Form.Item>
      <Form.Item
        name={["settings", "payments", "decimalFirstAmountEntry"]}
        label={"Enable Decimal First Amount Entry"}
        labelCol={{ ...checkboxFieldLayout.labelCol }}
        labelAlign="left"
      >
        <Switch
          defaultChecked={
            currentBusiness?.settings?.payments?.decimalFirstAmountEntry
          }
          checkedChildren="Yes"
          unCheckedChildren="No"
        />
      </Form.Item>
      <Divider plain></Divider>
      <h3>Receipt</h3>
      <Form.Item
        name={["settings", "receipt", "showAddress"]}
        label={"Show Address"}
        labelCol={{ ...checkboxFieldLayout.labelCol }}
        labelAlign="left"
      >
        <Switch
          defaultChecked={currentBusiness?.settings?.receipt.showAddress}
          checkedChildren="Yes"
          unCheckedChildren="No"
        />
      </Form.Item>
      <Form.Item
        labelCol={{ ...settingsFieldLayout.labelCol }}
        name={["settings", "receipt", "qrUrl"]}
        label={"QR Code URL"}
        labelAlign="left"
      >
        <Input max={200}></Input>
      </Form.Item>
      <Form.Item
        name={["settings", "receipt", "extraText"]}
        label={"Extra Text"}
        labelCol={{ ...settingsFieldLayout.labelCol }}
        labelAlign="left"
      >
        <Input.TextArea></Input.TextArea>
      </Form.Item>
    </>
  );
}

/**
 * export interface IBusinessSettings {
  payments: {
    enableCashPayments: boolean;
    enableDiscounts: boolean;
    enableTips: boolean;
    optionalTipping: boolean; //default true
    tipPercentagePresets: {
      percentage1: number;
      percentage2: number;
      percentage3: number;
      percentage4: number;
    };
    enableSkipTags: boolean;
    decimalFirstAmountEntry: boolean;
  };
  endOfDay: {
    hour: number;
    minute: number;
  };
  receipt: {
    logoUrl: string;
    showAddress: boolean;
    extraText: string;
    qrUrl: string;
  };
  reports: {
    showTips: boolean;
    sendList: string[];
    types: {
      endOfDaySalesOverviewReport: IReportSettings;
      weeklyPayoutReport: IReportSettings;
    };
  };
}
 */
