import { Modal } from "antd";
import { selectCurrentBusiness } from "../../businessesSlice";
import { useAppSelector } from "../../../../app/hooks";

import BusinessFiles from "./BusinessFiles";
interface IPropsBusinessModel {
  visible: boolean;
  setVisible: any;
}

export default function BusinessFilesModal({
  visible,
  setVisible,
}: IPropsBusinessModel) {
  const business = useAppSelector(selectCurrentBusiness);

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      visible={visible}
      title={business?.businessName}
      onCancel={handleCancel}
      footer={null}
      width={1130}
    >
      <BusinessFiles></BusinessFiles>
    </Modal>
  );
}
