import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

import { SalesRepresentativesList } from "./SalesRepresentativesList";
import { IBusinessFullQuery } from "../../interfaces/businessFull.interface";
import { getSalesRepReportFromServer } from "./SalesApi";

export interface MonthBreakdown {
  month: string;
  grossAmount: number;
  netRevenueAfterMarkup: number;
  bonus: number;
  merchantBonusBreakdown?: { [key: string]: number };
  totalActiveMerchants?: number;
  averageBonusPerMerchant?: number;
}
export interface SalesState {
  salesReps: {
    label: String;
    value: string;
    monthsToExp: number;
    email: string;
  }[];
  salesRepsAnalytics: MonthBreakdown[];
  salesRepsReportUrl: string;
  loadingAnalytics: boolean;
}

const initialState: SalesState = {
  salesReps: SalesRepresentativesList,
  salesRepsAnalytics: [],
  salesRepsReportUrl: "",
  loadingAnalytics: false,
};

export const getSalesRepsAnalyticsThunk = createAsyncThunk(
  "sales/analytics",
  async (params: {
    salesRepresentative: string;
    formatType?: "XLSX" | "JSON";
  }) => {
    const report = await getSalesRepReportFromServer(params);
    return report;
  }
);

export const salesSlice = createSlice({
  name: "sales",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSalesRepsAnalyticsThunk.fulfilled, (state, action) => {
      state.salesRepsAnalytics = action.payload.report.MOMBreakdown;
      state.salesRepsReportUrl = action.payload.report.url || "";
      state.loadingAnalytics = false;
    });
    builder.addCase(getSalesRepsAnalyticsThunk.pending, (state, action) => {
      state.loadingAnalytics = true;
    });
    builder.addCase(getSalesRepsAnalyticsThunk.rejected, (state, action) => {
      state.loadingAnalytics = false;
      state.salesRepsAnalytics = [];
      state.salesRepsReportUrl = "";
    });
  },
});

export const selectSalesReps = (state: RootState) => state.sales.salesReps;
export const selectSalesRepsAnalytics = (state: RootState) =>
  state.sales.salesRepsAnalytics;
export const selectSalesRepsReportUrl = (state: RootState) =>
  state.sales.salesRepsReportUrl;
export const selectLoadingAnalytics = (state: RootState) =>
  state.sales.loadingAnalytics;

export default salesSlice.reducer;
