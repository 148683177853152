// import axios from "axios";
import { CONFIG } from "../../config";
import { IBusinessPromotionStatus } from "../../interfaces/businessPromotionStatus.interface";
import {
  IPromotion,
  PromoStatus,
  PromoType,
} from "../../interfaces/promotion.interface";
import { getTokenizedAxios } from "../../utils/axiosWithTokenGen";

export async function getBusinessesToBeRewardedFromServer(): Promise<{
  open: IBusinessPromotionStatus[];
  pending: IBusinessPromotionStatus[];
  closed: IBusinessPromotionStatus[];
}> {
  const axios = await getTokenizedAxios();
  const path = "/businesses/toBeRewarded";
  return axios
    .get(CONFIG.ADMIN_SERVER.BASE_URL + path)
    .then((data) => data.data);
}
export async function getPromotionsServer(params: {
  type: PromoType;
  status: PromoStatus;
}) {
  const axios = await getTokenizedAxios();
  const path = "/promotions/";
  return axios
    .get<IPromotion[]>(CONFIG.ADMIN_SERVER.BASE_URL + path, { params })
    .then((data) => data.data);
}
export async function updatePromotionsStatusServer(props: {
  promotionIds: string[];
  status: "pending" | "closed";
  businessIds?: string[];
  notify?: boolean;
}) {
  const { promotionIds, status, businessIds, notify } = props;
  const axios = await getTokenizedAxios();
  const path = "/promotions/updatePromotionsStatus";
  return axios
    .patch(CONFIG.ADMIN_SERVER.BASE_URL + path, {
      promotionIds,
      status,
      businessIds,
      notify,
    })
    .then((data) => data.data);
}
