import React, { useEffect } from "react";
import { IPartnerDetails } from "../../../../interfaces/partner.interface";
import { Button, Form, Input, InputNumber, Select, Space } from "antd";
import styles from "./partnerDetailsForm.module.css";
import { IMerchantDetails } from "../../../../interfaces/partnerDeal.interface";

const merchantDetailsFormFieldLayout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 18 },
};

interface IPropsMerchantDetailsForm {
  onSubmit: (value: IMerchantDetails) => any;
  merchantDetails?: Partial<IMerchantDetails>;
}
export default function MerchantDetailsForm({
  merchantDetails,
  onSubmit,
}: IPropsMerchantDetailsForm) {
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
    populateForm();
  }, []);

  const populateForm = () => {
    if (!merchantDetails) return;

    form.setFieldsValue({
      businessName: merchantDetails?.businessName,
      businessRegistrationNumber: merchantDetails?.businessRegistrationNumber,
    });
  };

  return (
    <div>
      <Form
        onFinish={onSubmit}
        form={form}
        // labelCol={{ ...partnerFormFieldLayout.labelCol }}
        // wrapperCol={{ ...partnerFormFieldLayout.wrapperCol }}
      >
        <Form.Item
          name="businessName"
          label="Merchant Name"
          labelCol={{ ...merchantDetailsFormFieldLayout.labelCol }}
          labelAlign="left"
          rules={[{ required: true, message: "Please add a merchant name." }]}
        >
          <Input style={{ width: "200px" }} />
        </Form.Item>
        <Form.Item
          name="businessRegistrationNumber"
          label="KVK"
          labelCol={{ ...merchantDetailsFormFieldLayout.labelCol }}
          labelAlign="left"
          rules={[
            {
              required: true,
              message: "Please add a KVK number.",
            },
            {
              min: 8,
              message: "Please put a valid number, equal to 8 digits.",
            },
          ]}
        >
          <Input style={{ width: "200px" }} minLength={8} maxLength={8} />
        </Form.Item>

        <Form.Item>
          <Button style={{ float: "right" }} type="primary" htmlType="submit">
            OK
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
