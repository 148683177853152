import axios from "axios";
import { CONFIG } from "../../config";
import { getTokenizedAxios } from "../../utils/axiosWithTokenGen";
import { AccessLevel } from "../../interfaces/accessLevel.enum";
import { PlatformType } from "../../interfaces/paxTerminal.interface";

export async function signInServer(props: {
  email: string;
  password: string;
}): Promise<{ session: string; qrcode?: string }> {
  const axiosWithToken = await getTokenizedAxios();
  const { email, password } = props;

  const path = "/account/signInLemonPayAdmin";
  return axiosWithToken
    .post(CONFIG.ADMIN_SERVER.BASE_URL + path, { email, password })
    .then((data) => data?.data);
}

export async function verifySoftwareTokenServer(props: {
  session: string;
  confirmationCode: string;
}): Promise<{
  session: string;
  status: "SUCCESS" | "ERROR";
}> {
  const { session, confirmationCode } = props;

  const path = "/account/verifySoftwareToken";
  return axios
    .post(CONFIG.ADMIN_SERVER.BASE_URL + path, {
      session,
      confirmationCode,
    })
    .then((data) => data.data);
}
export async function respondToSignInServer(props: {
  session: string;
  confirmationCode: string;
  username: string;
  deviceId: string;
}): Promise<
  | {
      tokens: { idToken: string; refreshToken: string };
      accessLevel: AccessLevel[];
      couplingPlatformAccess: PlatformType | undefined;
      user: { email: string; name: string; partnerId?: string };
    }
  | { session: string }
> {
  const { session, confirmationCode, username, deviceId } = props;

  const path = "/account/respondToSignInAdminChallenge";
  return axios
    .post(CONFIG.ADMIN_SERVER.BASE_URL + path, {
      session,
      confirmationCode,
      username,
      deviceId,
    })
    .then((data) => data.data)
    .catch((error) => {
      throw error?.response?.data;
    });
}

export function refreshTokenSignInServer(props: {
  refreshToken: string;
}): Promise<{
  idToken: string;
  accessLevel: AccessLevel[];
  couplingPlatformAccess: PlatformType | undefined;
  user: { email: string; name: string; partnerId?: string };
}> {
  const { refreshToken } = props;
  const path = "/account/refreshTokenSignIn";
  return axios
    .post(CONFIG.ADMIN_SERVER.BASE_URL + path, {
      refreshToken,
      isAdmin: true,
    })
    .then((data) => data?.data);
}
export async function logoutServer(props: {
  refreshToken: string;
}): Promise<{}> {
  const { refreshToken } = props;
  const axios = await getTokenizedAxios();
  const path = "/account/logout";
  return axios
    .post(CONFIG.ADMIN_SERVER.BASE_URL + path, {
      refreshToken,
      isAdmin: true,
    })
    .then((data) => data.data);
}
